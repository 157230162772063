import { 
    Edit, 
    Toolbar,
    Labeled,
    TextField,
    useNotify,
    useGetOne,
    TextInput,
    SimpleForm,
    TopToolbar,
    ListButton,
    SelectInput,
    ReferenceField,
    LinearProgress,
    useDataProvider,
    useRecordContext,
    useRegisterMutationMiddleware,
} from 'react-admin';
import { Sucursales } from '../../utils';
import { useFormContext } from 'react-hook-form';
import AlegraIcon from './components/alegraIcon';
import PrintIcon from '@mui/icons-material/Print';
import React, { useState, useCallback } from 'react';
import { DeleteRemision } from './components/DeleteRemision';
import { Box, Typography, Grid, Button } from '@mui/material';
import { OrdenesPendientesShow } from './OrdenesPendientesShow';
import { TotalesComponent } from './components/TotalesComponent';
import { CustomEditableDatagrid } from './components/CustomEditableDatagrid';

const EditTitle = () => {
    const record = useRecordContext();
    return <span>Remisión {record ? `${record.alegraNumeracion}` : ''}</span>;    
};

const RemisionEditActions = () => (
    <TopToolbar>
        <ListButton resource="remisions" label='Remisiones'/>
        <ListButton resource="salidaOrdens" label='Salidas'/>
    </TopToolbar>
);

export const RemisionEdit = () => {

    const [ refreshDatagrid, setRefreshDatagrid ] = useState();
    
    const refetchData = (v) => {
        setRefreshDatagrid(v)
    }

   return(
        <Edit redirect='list' title={<EditTitle/>} actions={<RemisionEditActions/>}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <Grid container spacing={1} justifyContent={'space-between'} alignContent={'stretch'}>
                    <Grid item xs={12} md={9} container direction='row'>
                        <Grid item xs={12} md={12}>
                            <Labeled>
                                <ReferenceField source = 'clienteId' reference='clientes'>
                                    <TextField source="razonSocial" label="Cliente"sx={{padding:'10px', fontSize: '25px'}} />
                                </ReferenceField>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SelectInput source="ciudadId" label="Ciudad" choices={Sucursales} optionText='name' fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{justifyContent: 'right'}}>
                        <NumeracionEstadoField/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <CustomEditableDatagrid refreshDatagrid={refreshDatagrid}/>
                    </Grid>
                    <Grid item  xs={12} md={12} container  direction='row' justifyContent={'flex-end'} >
                        <Grid item xs={12} md={7} >
                            <TextInput source="observaciones"  fullWidth/>
                        </Grid>
                        <TotalesComponent changed={refetchData}/>
                    </Grid>
                </Grid>
            </SimpleForm>
            <OrdenesPendientesShow/>
        </Edit>
    );
};

const NumeracionEstadoField = () => {
    const record  = useRecordContext()
    const { setValue, getValues } = useFormContext();
    const { data,  isLoading , loaded } = useGetOne('alegraRemissions', { id: record?.alegraId });
    let estado = getValues('estado');
    const alegraPdf = getValues('pdf');
    const alegraUrl = getValues('url');
    //estado = 'Anulada'
    let style = { 
        Facturada: { backgroundColor: 'green', color: 'white'}, 
        Anulada: { backgroundColor: '#c91104', color: 'white'}, 
        Emitida: { backgroundColor: 'lightgrey', color: 'black'}
    };

    if(data && data.status === "closed") {
        if (data.status === "closed") {
            record.estado = "Facturada";
            setValue('estado', 'Facturada');
        }
        if (data.status === "void") {
            record.estado = "Anulada";
            setValue('estado', 'Anulada');
        }
    };

    const handlePrint = (e) => {
        // Open data.pdf link in another window
        window.open(alegraPdf, '_blank');
    };
    const handleOpen = (e) => {
        // Open data.url link in another window
        window.open(alegraUrl, '_blank');
    };

    return (
        <Grid container direction='row' spacing={3} alignitems='stretch'>
            <Grid item xs={12} md={6} container direction='column' justifyContent='space-evenly'>
                <Grid item xs={12} md={6}  >
                    <Button type='button' onClick={(e)=>handlePrint(e)} endIcon={<PrintIcon />} variant='outlined' sx={{width:'100%', marginTop:'3px'}}>
                        Imprimir
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Button type='button' onClick={(e)=>handleOpen(e)} endIcon={<AlegraIcon />} variant='outlined' sx={{width:'100%'}} >
                        VER 
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} >
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' ,boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.1)', width:'123px', marginBottom:'5px'}}>
                    <Typography variant="h6" style={{ fontSize: '24px',fontWeight: 'bold'}}>{record.alegraNumeracion}</Typography>
                    {isLoading ? <LinearProgress sx ={{width:'100px'}}/> :
                        <Box sx={{backgroundColor: style[estado].backgroundColor, width: '120%', padding:0 }}>
                            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold', display:'flex', justifyContent:'center', width:'100%', color: style[estado].color}}>
                                {estado}
                            </Typography>
                        </Box>
                    }   
                </Box>
            </Grid>
        </Grid>
    );
};

const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        let newParams = {};
        try {
            const response = await editAlegraRemision(data);
            console.log('RESPONSE inside middleware', response);
            if (response.data.id) {
                notify('Remisión creada en Alegra', { type: 'success' }, { smart_count: 1 });
            };
            newParams = {
                ...params,
                data: {
                    ...data,
                    alegraId: response.data.id,
                    alegraNumeracion: response.data.number,
                    pdf: `https://app.alegra.com/remission/print/id/${response.data.id}`,
                    url: `https://app.alegra.com/remission/view/id/${response.data.id}`,
                    estado: 'Emitida',
                    fechaCrea: new Date(),
                    fechaVence: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                }
            };
            console.log('NEW PARAMS', newParams);
        } catch (error) {
            console.error('ERROR in createAlegraRemision', error);
            if (error.message === 'El id del ítem es un campo obligatorio') {
                notify('Error: Caja No Seleccionada', { type: 'error' });
            } else {
                notify(`Error: ${error.message}`, { type: 'error' });
            }
            return; // Exit if there is an error in creating Alegra Remision
        };

        try {
            // Uncomment this when pdfAlt results in the same PDF as Alegra
            // const resp2 = await dataProvider.getOne('alegraRemissions', { id: response.data.id });
            // const newParams2 = { ...newParams, data: { ...newParams.data, pdfAlt: resp2.data.pdf } };
            // await next(resource, newParams2, options);

            await next(resource, newParams, options);

            const testing = false; // true if you don't want to update the sqlOrdenOut table

            if (!testing) {
                if (data?.ordenes) {
                    for (const orden of data.ordenes) {
                        try {
                            await dataProvider.update('sqlOrdenOut', { filter: { numero: orden.numero, versionop: orden.version, remision: newParams.data.alegraNumeracion.match(/\d+/)[0] } });
                        } catch (error) {
                            console.error(`ERROR updating sqlOrdenOut for order ${orden.numero}`, error);
                            notify(`Error updating sqlOrdenOut for order ${orden.numero}: ${error.message}`, { type: 'error' });
                        }
                    }
                }
                await discountFromStock(data.itemsFact);
            };
        } catch (error) {
            console.error('ERROR in next or discountFromStock', error);
            notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 1 });
        };
    }, [dataProvider, notify]);

    const discountFromStock = async (items) => {
        const cajas = items.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0) {
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);

        for (const caja of cajas) {
            try {
                const stock = await dataProvider.getOne('stocks', { id: caja.caja });
                const newStock = {
                    ...stock.data,
                    areaRestante: stock.data.areaRestante - caja.area,
                };
                delete newStock.retales;
                await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            } catch (error) {
                console.error(`ERROR updating stock for caja ${caja.caja}`, error);
                notify(`Error updating stock for caja ${caja.caja}: ${error.message}`, { type: 'error' });
            }
        }
    };

    const editAlegraRemision = async (values) => {
        const alegraJson = {
            documentName: 'remission',
            date: new Date(),
            dueDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            anotation: values.observaciones,
            client: { id: values.alegraClienteId },
            seller: values.vendedorAlegraId ? { id: values.vendedorAlegraId } : undefined,
            items: values.itemsFact.map(item => {
                let descripcion = '';
                if (item.ancho > 0 && item.largo > 0 && item.area > 0) {
                    descripcion = `${item.descripcion}\n${item.ancho} x ${item.largo}   Cant: ${item.cantidad}`;
                    if (item.odc && item.odc.trim().length > 0) {
                        descripcion = `${descripcion} - ODC:${item.odc}`;
                    }
                } else {
                    descripcion = item.descripcion;
                    if (item.odc) {
                        descripcion = `${descripcion} - ODC:${item.odc}`;
                    }
                }
                return {
                    id: item.alegraItemId,
                    reference: item.ordenVersion,
                    description: descripcion,
                    quantity: item.area > 0 ? item.area : item.cantidad,
                    price: item.valorUnitario,
                    tax: [{ id: item.iva ? 3 : 0 }],
                };
            }),
            costCenter: { id: Sucursales?.find(sucursal => sucursal.id === values.ciudadId)?.costCenterId },
            warehouse: { id: Sucursales?.find(sucursal => sucursal.id === values.ciudadId)?.alegraID },
        };
        console.log('ALEGRA JSON', alegraJson);
        return dataProvider.create('alegraRemissions', { data: alegraJson });
    };

    useRegisterMutationMiddleware(middleware);

    return (
        <Toolbar>
            <Grid container justifyContent="space-between">
            {/*
            
                <Grid item xs={12} md={6}>
                    <SaveButton
                        key={'emitir'}
                        label="Actualizar Remisión"
                        icon={<SendAndArchiveIcon />}
                        alwaysEnable
                    />
                </Grid>
            */
            } 
                <Grid item xs={12} md={10}>
                    {/* Additional content can go here */}
                </Grid>
                <Grid item xs={12} md={1}>
                    <DeleteRemision />
                </Grid>
            </Grid>
        </Toolbar>
    );
};