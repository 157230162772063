import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import CheckIcon from '@mui/icons-material/Check';
import IconCancel from '@mui/icons-material/Cancel';
import { ListStyled } from '../style/ExposicionStyles';
import TableContainer from '@mui/material/TableContainer';
import { MaxWidthBox } from '../../../utils/utilsExposicion';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Form, Button, useUpdate, useNotify, SaveButton, useGetList, useRecordContext } from 'react-admin';

export const DialogCajaStock = ({ responsableId }) => {

    const notify = useNotify();
    const [update] = useUpdate();
    const record = useRecordContext();
    const referencia = record?.referencia;
    const [showDialog, setShowDialog] = useState(false);

    const { data: stock } = useGetList('stocks', {
        meta: { searchable: 'true' },
        filter: { materialID: { eq: referencia }},
        sort: { field: 'consecutivo', order: 'DESC' },
    });
    
    const filterCajaActivaIngresada = stock?.filter(caja => caja?.activa === true && caja?.ingresada === true) || [];
    const filterCajaActiva = stock?.filter(caja => caja?.activa === true && (caja?.ingresada === false || caja?.ingresada === null || caja?.ingresada === undefined)) || [];
    const filterCajaIngresada = stock?.filter(caja => caja?.activa === false && caja?.ingresada === true) || [];
    const maxConsecutivoIngresada = filterCajaActivaIngresada?.length > 0 ? Math.max(...filterCajaActivaIngresada?.map(caja => caja?.consecutivo)) : -1;
    let nextConsecutiveBox = filterCajaActiva?.find(caja => caja?.consecutivo === maxConsecutivoIngresada + 1);
  
    if (!nextConsecutiveBox) {
        nextConsecutiveBox = filterCajaIngresada?.find(caja => caja?.consecutivo === maxConsecutivoIngresada + 1);
    };
  
    if (!nextConsecutiveBox && filterCajaActiva?.length > 0) {
        nextConsecutiveBox = filterCajaActiva[0];
    };
  
    const cajaId = nextConsecutiveBox?.id;
    const activa = nextConsecutiveBox?.activa;
    const cantidad = nextConsecutiveBox?.cantidad;
    const areaTotal = nextConsecutiveBox?.areaTotal;
    const ingresada = nextConsecutiveBox?.ingresada;
    const materialID = nextConsecutiveBox?.materialID;
    const anchoLamina = nextConsecutiveBox?.anchoLamina;
    const largoLamina = nextConsecutiveBox?.largoLamina;
    const consecutivo = nextConsecutiveBox?.consecutivo;

    const filterConsecutivo = consecutivo !== null && consecutivo !== undefined;

    const handleClick = () => setShowDialog(true);
    const handleCloseClick = () => setShowDialog(false);
  
    const handleSubmit = async () => {
        if (!filterCajaActiva) {
            notify('No hay cajas disponibles para ingresar', { type: 'warning' });
            console.log('No hay cajas disponibles para ingresar');
            return;
        };
  
        const lastActiveIngresadaCaja = filterCajaActivaIngresada?.reduce((maxCaja, caja) => {
            return caja?.consecutivo > maxCaja?.consecutivo ? caja : maxCaja;
        }, { consecutivo: -1 });
  
        console.log('Última caja activa e ingresada', lastActiveIngresadaCaja);
  
        const nextConsecutiveBox = 
            filterCajaActiva?.find(caja => caja?.consecutivo === lastActiveIngresadaCaja?.consecutivo + 1) ||
            filterCajaIngresada?.find(caja => caja?.consecutivo === lastActiveIngresadaCaja?.consecutivo + 1) ||
            filterCajaActiva[0];
            console.log('Siguiente caja consecutiva para actualizar', nextConsecutiveBox);
  
        if (!nextConsecutiveBox) {
            notify('No se encontró la siguiente caja consecutiva para actualizar', { type: 'error' });
            return;
        }
  
        const cajaConsecutivoId = nextConsecutiveBox?.id;
        console.log('cajaConsecutivoId', cajaConsecutivoId);
        const responsablePinId = responsableId ?? nextConsecutiveBox?.responsableId;
  
        if (!responsableId) {
            notify('Digite el PIN del responsable para ingresar la caja', { type: 'warning' });
            return;
        }
  
        let dataToUpdate;
        if (nextConsecutiveBox?.ingresada === false || nextConsecutiveBox?.ingresada === null || nextConsecutiveBox?.ingresada === undefined) {
            dataToUpdate = {
                ingresada: true,
                usuarioIngresa: responsablePinId,
            };
        } else if (nextConsecutiveBox?.activa === false && nextConsecutiveBox?.ingresada === true) {
            dataToUpdate = {
                activa: true,
                ingresada: true,
                usuarioActiva: responsablePinId,
                usuarioIngresa: responsablePinId,
            };
        } else {
            notify('La caja ya está en el estado deseado', { type: 'warning' });
            return;
        }
  
        update('stocks', {
            id: cajaConsecutivoId,
            data: {
                id: cajaConsecutivoId,
                ...dataToUpdate,
            },
            previousData: nextConsecutiveBox,
        },
        {
            onSuccess: () => {
                setShowDialog(false);
                notify('Caja actualizada con éxito');
            },
            onFailure: (error) => {
                console.error('Error al actualizar la caja:', error);
                notify('Error al actualizar la caja:', { type: 'error' });
            }
        });
    };

    return (
        <>
            {filterCajaActivaIngresada && filterCajaActivaIngresada?.length > 0 && ( 
                <Button startIcon={<AddBoxRoundedIcon style={ListStyled?.dialogCaja?.buttonDialog}/>} variant='rounded' onClick={handleClick}/>
            )}
            {filterCajaActivaIngresada && filterCajaActivaIngresada?.length > 0 ? ( 
                <Dialog fullWidth maxWidth={MaxWidthBox({filterConsecutivo})} open={showDialog} onClose={handleCloseClick}>
                    <Form resource='stocks' onSubmit={handleSubmit} sanitizeEmptyValues>
                        <>
                            {filterConsecutivo ? (
                                <DialogTitle style={ListStyled?.dialogCaja?.dialogTitle}>
                                    <div style={ListStyled?.dialogCaja?.fontStyle}>{'Gestión de Caja'}</div>
                                </DialogTitle>
                            ):( <span/> )}
                            <DialogContent>
                                <DialogContentText>
                                    {filterConsecutivo ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={ListStyled?.dialogCaja?.table} aria-label='simple table'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>Caja</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>Material</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>Ancho</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>Largo</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>No. Láminas</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>Área Total</TableCell>
                                                        {activa ? (
                                                            <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>Activa</TableCell>
                                                        ):( <span/> )}
                                                        {ingresada ? (
                                                            <TableCell sx={ListStyled?.tableStyled} align='center' style={ListStyled?.dialogCaja?.tabbleCell}>Ingresada</TableCell>
                                                        ):( <span/> )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={cajaId} sx={ListStyled?.dialogCaja?.tbTableRow}>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center'>{consecutivo}</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center'>{materialID}</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center'>
                                                            {anchoLamina?.toLocaleString('es-ES', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                        </TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center'>
                                                            {largoLamina?.toLocaleString('es-ES', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                        </TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center'>{cantidad}</TableCell>
                                                        <TableCell sx={ListStyled?.tableStyled} align='center'>
                                                            {areaTotal?.toLocaleString('es-ES', { minimumFractionDigits: 1, maximumFractionDigits: 2 })}
                                                        </TableCell>
                                                        {activa ? (
                                                            <TableCell sx={ListStyled?.tableStyled} align='center'>{<CheckIcon/>}</TableCell>
                                                        ):( <span/> )}
                                                        {ingresada ? (
                                                            <TableCell sx={ListStyled?.tableStyled} align='center'>{<CheckIcon/>}</TableCell>
                                                        ):( <span/> )}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ):( <div> {`No existe una caja para ingresar, espere a que haya una activa.`} </div> )}
                                </DialogContentText>
                            </DialogContent>
                            {filterConsecutivo ? (
                                <DialogActions style={ListStyled?.dialogCaja?.dialogActions}>
                                    <Button label='ra.action.cancel' onClick={handleCloseClick}>
                                        <IconCancel sx={ListStyled?.submitButton}/>
                                    </Button>
                                    <SaveButton icon={<SendAndArchiveIcon/>} label='Ingresar' alwaysEnable sx={ListStyled?.submitButton}/>
                                </DialogActions>
                            ):( <span/> )}
                        </>
                    </Form>
                </Dialog>
            ):(
                <span/>
            )}
        </>
    );
};