import { MaterialOrdenStyled } from "./style/SalidaStyles";
import { ExportButton, TopToolbar, useRecordContext } from "react-admin";
import { Table, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material';

export const SalidaOrdenTool = () => (
    <TopToolbar>
        <ExportButton/>
    </TopToolbar>
);

export const MaterialOrden = () => {

    const record = useRecordContext();
    const materialOrden = record?.materialOrden || [];
    const totalArea = materialOrden?.reduce((acc, item) => acc + item?.areaSalida, 0);
    const totalCantidad = materialOrden?.reduce((acc, item) => acc + item?.cantidadPlanchas, 0);
    const valorUnitario = materialOrden?.reduce((acc, item) => acc + item?.valorUnitarioOrden, 0);
    const valorUni = valorUnitario?.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
    });

    console.log('valorUni------->', valorUni);
    console.log('valorUnitario------->', valorUnitario);

    return (
        <Box sx={{ margin: '1em', minWidth:'100%' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={MaterialOrdenStyled} align="center"><b>Caja</b></TableCell>
                        <TableCell sx={MaterialOrdenStyled} align="center"><b>Ancho</b></TableCell>
                        <TableCell sx={MaterialOrdenStyled} align="center"><b>Largo</b></TableCell>
                        <TableCell sx={MaterialOrdenStyled} align="center"><b>Cantidad</b></TableCell>
                        <TableCell sx={MaterialOrdenStyled} align="center"><b>Área</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {materialOrden?.map((item, idx) => (
                        <TableRow key={idx}>
                            <TableCell sx={MaterialOrdenStyled} align="center">{item.cajaExpo}</TableCell>
                            <TableCell sx={MaterialOrdenStyled} align="center">{item.anchoSalida?.toFixed(2)}</TableCell>
                            <TableCell sx={MaterialOrdenStyled} align="center">{item.largoSalida?.toFixed(2)}</TableCell>
                            <TableCell sx={MaterialOrdenStyled} align="center">{item.cantidadPlanchas}</TableCell>
                            <TableCell sx={MaterialOrdenStyled} align="center">{item.areaSalida?.toFixed(2)}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell sx={MaterialOrdenStyled} align="center"><b>Total</b></TableCell>
                        <TableCell colSpan={2}></TableCell>
                        <TableCell sx={MaterialOrdenStyled} align="center">{totalCantidad}</TableCell>
                        <TableCell sx={MaterialOrdenStyled} align="center">{totalArea?.toFixed(2)}</TableCell>
                    </TableRow>
                    {valorUni !== 0 && (
                        <TableRow>
                            <TableCell sx={MaterialOrdenStyled} align="center"colSpan={1}><b>Valor Unitario</b></TableCell>
                            <TableCell sx={MaterialOrdenStyled} align="center">{valorUni}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    )
};