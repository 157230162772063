import Grid from '@mui/material/Grid';
import UndoIcon from '@mui/icons-material/Undo';
import { AmplifyFileInput } from '../../Amplify';
import { useFormContext } from 'react-hook-form';
import { Sucursales, Calibre } from '../../utils';
import React, { useEffect, useState } from 'react';
import { Create, TextInput, useGetOne, SimpleForm, ArrayInput, TopToolbar, ListButton, SelectInput, NumberInput, BooleanInput, DateTimeInput, ReferenceInput, AutocompleteInput, SimpleFormIterator } from 'react-admin';

const CreateActions = () => (
    <TopToolbar>
        <ListButton icon={<UndoIcon/>} resource='exposicions' label="Ver Exposiciones"/>
    </TopToolbar>
);

const CitySelectInput = ({ usuario }) => {
    const { setValue } = useFormContext();
    const [filterCity, setFilterCity] = useState('');

    useEffect(() => {
        if (usuario && usuario?.sedes && usuario?.sedes?.length > 0) {
            const sedeUser = usuario?.sedes[0];
            const ciudadUser = Sucursales?.find(sucursal => sucursal?.user === sedeUser);
            const ciudadUserId = ciudadUser?.id;
            if (ciudadUser) {
                setFilterCity(ciudadUserId);
                setValue('ciudad', ciudadUserId);
            }
        }
    }, [usuario, setValue]);

    return (
        <SelectInput 
            fullWidth 
            source="ciudad" 
            defaultValue={filterCity} 
            choices={[
                { id: 'CALI', name: 'CALI' },
                { id: 'ENVIGADO', name: 'ENVIGADO' },
                { id: 'BARRANQUILLA', name: 'BARRANQUILLA' },
            ]}
        />
    );
};

export const ExposicionCreate = () => {

    const user = localStorage.getItem('user');
    const { data: usuario } = useGetOne('usuarios', { id: user });

    return (
        <Create actions={<CreateActions/>} redirect="list">
            <SimpleForm p={2}>
                <Grid item container direction='row' columnSpacing={2} justifyContent='space-between' style={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" source="expoName" label='Nombre Exposición'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" source="plateName" label='Nombre Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" source="plateType" label='Tipo Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <AutocompleteInput size='small' fullWidth variant='outlined' source='calibre' label='Calibre' choices={Calibre}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <CitySelectInput usuario={usuario} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} style={{ marginTop: '8px' }}>
                        <ReferenceInput label="Referencia" source="referencia" reference="stocks">
                            <AutocompleteInput 
                                fullWidth 
                                size='small'
                                label="Referencia" 
                                optionText='id' 
                            />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} style={{ marginTop: '8px' }}>
                        <DateTimeInput fullWidth color="primary" source="dateExposed" label='Fecha Exposicion'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} style={{ marginTop: '8px' }}>
                        <NumberInput fullWidth source="areaTotalLen" label="Área Total Len" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} style={{ marginTop: '8px' }}>
                        <BooleanInput fullWidth color="primary" defaultValue={true} source="pendienteSalida" label='Pendiente Salida'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} style={{ marginTop: '8px' }}>
                        <BooleanInput fullWidth color="primary" source="anulada" label='Anulada'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <AmplifyFileInput fullWidth color="primary" source="imageExp" label='Imagen Exposición'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ArrayInput source="len" label="LEN">
                            <SimpleFormIterator inline linkType={false}>
                                <TextInput source="name" label="Nombre" />
                                <NumberInput source="lenWidth" label="Ancho" />
                                <NumberInput source="lenHeight" label="Altura" />
                                <NumberInput source="area" label="area" />
                                <TextInput source="op" label="Op" />
                                <TextInput source="version" label="Versión" />
                                <AmplifyFileInput source="image" label="Imagen Plancha" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};