

import React, { useEffect, useState} from "react";
import {  TextInput, NumberInput, useRecordContext, BooleanField, 
        NumberField, useList, TextField, ListContextProvider, ReferenceInput, SelectInput, useRefresh, useNotify,
        BooleanInput, useDataProvider, AutocompleteInput, ReferenceField,
    } from 'react-admin';
import { EditableDatagrid, RowForm, useRowContext, EditRowButton } from "@react-admin/ra-editable-datagrid";
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import DeleteRowItemButton from "../components/DeleteRowItemButton";




export const EditableDatagridCustom = (props) => {

    const { getValues } = useFormContext();
    const refresh = useRefresh();
    const listContext = useList({ data: getValues('itemsFact') });
    const [changed, setChanged] = useState(false);

    const handleUpdate = (v)=>
        {
            refresh();
            //console.log('REFRESHING') 
            setChanged(v);  
        }
        useEffect(() => {
            if (props.refreshDatagrid !== undefined) {
                handleUpdate(true); // Trigger refresh when parent toggles refreshDatagrid
            }
        }, [props.refreshDatagrid]);
    
    return (
    <ListContextProvider value={listContext}>
        <EditableDatagrid    
                size='small'              
                createForm={<ItemFormCreate data={listContext.data} changed={(v)=>handleUpdate(v)} />}
                editForm={<ItemFormEdit data={listContext.data} changed={(v)=>handleUpdate(v)} />} 
                bulkActionButtons={false}
                pagination={{page:1, perPage:100}} 
                actions={<ActionsEDG/>}
            >

                <TextField source='ordenVersion' label='Orden'  refresh={changed?.toString()}/>
                <TextField source="descripcion" refresh={changed?.toString()}/>
                <TextField source="material"  refresh={changed?.toString()} label='Producto'/>
                <ReferenceField source="caja"  reference='stocks'  label='Caja'>
                    <TextField source="consecutivo"  refresh={changed?.toString()} />
                </ReferenceField>
                <NumberField source="ancho"  refresh={changed?.toString()}/>
                <NumberField source="largo"  refresh={changed?.toString()}/>
                <NumberField source="cantidad"  refresh={changed?.toString()}/>
                <NumberField source="area"  refresh={changed?.toString()}/>
                <NumberField source="valorUnitario" label='V. Unitario'  refresh={changed?.toString()}/>
                <BooleanField source="iva" refresh={props.refreshDatagrid?.toString() || changed?.toString() } />
                <NumberField source="valorTotal" label='V. Total' refresh={changed?.toString()}/>
        </EditableDatagrid>
    </ListContextProvider>
    )
}


const ItemFormEdit = (props) => {
    const { close } = useRowContext();
    const record = useRecordContext();
    const refresh = useRefresh();
    const { setValue, getValues } = useFormContext();
    const indexOfRow = props.data.findIndex(item => item.idItem === record?.idItem);
    const material = getValues(`itemsFact.${indexOfRow}.material`);
    const materialId = getValues(`itemsFact.${indexOfRow}.alegraItemId`);
    const dataProvider = useDataProvider();
    const [materialName, setMaterialName] = useState(material);
    const [materialAlegraID, setMaterialAlegraID] = useState(materialId);
    const [iva , setIva] = useState(getValues(`itemsFact.${indexOfRow}.iva`));
    const idRow  = getValues(`itemsFact.${indexOfRow}.idItem`)
    //console.log('IDROW', idRow)
  
    const handleSubmit = (data) => {
        data.area = data.ancho * data.largo* data.cantidad;
        data.valorTotal = data.valorUnitario * data.area;

        if (data.material?.includes("ARTE") || data.material?.includes("FLETE")){
            data.valorTotal = data.valorUnitario * data.cantidad;
            data.area = 0
            //data.iva = data.material?.includes("FLETES") ? false : iva;
        }
        //console.log('DATA edit', data)
        setValue(`itemsFact.${indexOfRow}.idItem`, idRow);
        setValue(`itemsFact.${indexOfRow}.ordenVersion`, data.ordenVersion);
        setValue(`itemsFact.${indexOfRow}.descripcion`, data.descripcion);
        setValue(`itemsFact.${indexOfRow}.material`, materialName);
        setValue(`itemsFact.${indexOfRow}.caja`, data.caja);
        setValue(`itemsFact.${indexOfRow}.ancho`, data.ancho);
        setValue(`itemsFact.${indexOfRow}.largo`, data.largo);
        setValue(`itemsFact.${indexOfRow}.cantidad`, data.cantidad);
        setValue(`itemsFact.${indexOfRow}.valorUnitario`, data.valorUnitario);
        setValue(`itemsFact.${indexOfRow}.area`, data.area);
        setValue(`itemsFact.${indexOfRow}.valorTotal`, data.valorTotal);
        setValue(`itemsFact.${indexOfRow}.iva`, iva);
        setValue(`itemsFact.${indexOfRow}.alegraItemId`, materialAlegraID);
        close();
        props.changed(true);
    }

    const filter2query = (filter) => {

        if (filter){
            return {id: {contains: filter.toUpperCase()}};
        }
        return {};
    }
    const handleMaterialChange = async (event) => {
        if (!event || event === '' ) return;
        //setValue(`itemsFact.${indexOfRow}.material`, event);
        setMaterialName(event);
        await dataProvider.getOne('materials', {id: event}).then((response) => {
            //setValue(`itemsFact.${indexOfRow}.alegraItemId`, response.data.alegraId);
            setMaterialAlegraID(response.data.alegraId)
        });
        if(event.includes("FLETES")){
            //setIva(false);
        }
        refresh();
    }
    return (
        <RowForm onSubmit={handleSubmit} >
            <TextInput source='ordenVersion' label='Orden'/>
            <TextInput source="descripcion" fullWidth/>
            <ReferenceInput source="material" reference="materials" perPage={500}  >
                <AutocompleteInput optionText='id' optionValue='id' size='small' label='Producto' filterToQuery={filter2query} onChange={handleMaterialChange} />
            </ReferenceInput>
            {materialName&&(!materialName.includes("ARTE")&&!materialName.includes("FLETES"))?
            <ReferenceInput source="caja" reference="stocks"  filter= {{and: {activa: {eq: true}, materialID: {eq: materialName}}}}  sort={{field: 'consecutivo', order:'DESC'}} queryOptions={{meta:{searchable:'true'}}} perPage={500} >
                <SelectInput optionText='id' optionValue='id' size='small' sx={{width:'50px'}}/>
            </ReferenceInput>:null}
            {materialName&&(!materialName.includes("ARTE")&&!materialName.includes("FLETES"))?<NumberInput source="ancho"   sx={{ width: 80 }}/>:null}
            {materialName&&(!materialName.includes("ARTE")&&!materialName.includes("FLETES"))?<NumberInput source="largo"  sx={{ width: 80 }}/>:null}
            <NumberInput source="cantidad" sx={{width:'70px'}}/>
            <NumberInput source="valorUnitario" label='V.Unitario' sx={{width:'100px'}}/>
            <BooleanInput source="iva" label='IVA'  defaultValue={true} sx={{width:'60px'}}/>
        </RowForm>
    );
};


const ItemFormCreate = (props) => {
    const { close } = useRowContext();
    const refresh = useRefresh();
    const { setValue, getValues } = useFormContext();
    const [indexOfRow, setInitialRowIndex] = useState(null);
    //console.log('INDEX', indexOfRow)
    useEffect(() => {
        if(indexOfRow === null){
            setInitialRowIndex(props.data.length);
            //console.log('propsdatalength', props.data.length)
        }
    }, [indexOfRow])
    
    
    const fullValues = getValues();

    //console.log('FULLVALUES', fullValues)
    const dataProvider = useDataProvider();
    const [materialName, setMaterialName] = useState('');
    const [materialAlegraID, setMaterialAlegraID] = useState('');
    const [iva , setIva] = useState(fullValues.applyIva);


    const handleSubmit = (data) => {
        //console.log('DATA create', data)
        data.area = data.ancho * data.largo* data.cantidad;
        data.valorTotal = data.valorUnitario * data.area;
        if (data.material?.includes("ARTE") || data.material?.includes("FLETES")){
            data.valorTotal = data.valorUnitario * data.cantidad;
            data.area = 0
            //data.iva = data.material?.includes("FLETES") ? iva : data.iva;
        }
        setValue(`itemsFact.${indexOfRow}.idItem`, uuid());
        setValue(`itemsFact.${indexOfRow}.ordenVersion`, data.ordenVersion);
        setValue(`itemsFact.${indexOfRow}.descripcion`, data.descripcion);
        setValue(`itemsFact.${indexOfRow}.material`, materialName);
        setValue(`itemsFact.${indexOfRow}.caja`, data.caja);
        setValue(`itemsFact.${indexOfRow}.ancho`, data.ancho);
        setValue(`itemsFact.${indexOfRow}.largo`, data.largo);
        setValue(`itemsFact.${indexOfRow}.cantidad`, data.cantidad);
        setValue(`itemsFact.${indexOfRow}.valorUnitario`, data.valorUnitario);
        setValue(`itemsFact.${indexOfRow}.area`, data.area);
        setValue(`itemsFact.${indexOfRow}.valorTotal`, data.valorTotal);
        setValue(`itemsFact.${indexOfRow}.iva`, iva);
        setValue(`itemsFact.${indexOfRow}.alegraItemId`, materialAlegraID);
                
        close();
        props.changed(true);
    }

    const filter2query = (filter) => {

        if (filter){
            return {id: {contains: filter.toUpperCase()}};
        }
        return {};
    }
    const handleMaterialChange = async (event) => {
        if (!event || event === '' ) return;
        //setValue(`itemsFact.${indexOfRow}.material`, event);
        setMaterialName(event);
        await dataProvider.getOne('materials', {id: event}).then((response) => {
            //setValue(`itemsFact.${indexOfRow}.alegraItemId`, response.data.alegraId);
            setMaterialAlegraID(response.data.alegraId)
        });

        if(event.includes("FLETES")){
            //setIva(false);
            //setValue(`itemsFact.${indexOfRow}.iva`, false);
        }
        //refresh();
    }
    
    return (
        <RowForm onSubmit={handleSubmit}  >
            <TextInput source='ordenVersion' label='Orden'/>
            <TextInput source="descripcion" fullWidth/>
            <ReferenceInput source="material" reference="materials" perPage={500} >
                <AutocompleteInput optionText='id' optionValue='id' size='small' label='Producto' filterToQuery={filter2query} onChange={handleMaterialChange} />
            </ReferenceInput>   
            {materialName&&(!materialName.includes("ARTE") && !materialName.includes("FLETES"))?
            <ReferenceInput source="caja" reference="stocks"  filter= {{and: {activa: {eq: true}, materialID: {eq: materialName}}}} sort={{field: 'consecutivo', order:'ASC'}} queryOptions={{meta:{searchable:'true'}}} perPage={500} >
                <SelectInput optionText='consecutivo' optionValue='id' size='small'  sx={{width:'50px'}}/>
            </ReferenceInput>:<></>}
            {materialName&&(!materialName.includes("ARTE") && !materialName.includes("FLETES"))?<NumberInput source="ancho"   sx={{ width: 100 }}/>:null}
            {materialName&&(!materialName.includes("ARTE") && !materialName.includes("FLETES"))?<NumberInput source="largo"  sx={{ width: 100 }}/>:null}
            <NumberInput source="cantidad" sx={{width:'70px'}}/>
            <NumberInput source="valorUnitario" label='V.Unitario'  sx={{width:'100px'}}/>
            {materialName ? (!materialName.includes("FLETES"))?<BooleanInput source="iva" label='IVA'  defaultValue={true} sx={{width:'60px'}}/>:<BooleanInput source="iva" label='IVA' defaultValue={false} sx={{width:'60px'}}/>:null}
        </RowForm>
    );
};


const removeByAttr = (arr, attr, value) => {
    if (value === undefined) {
        throw new Error("Value must be provided");
    }

    let i = arr.length;
    while (i--) {
        if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
            arr.splice(i, 1);
        }
    }
    return arr;
}

const ActionsEDG = () => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const { getValues, setValue } = useFormContext();
    const data = getValues();
    const handleDelete = (e) => {
        const newItems = removeByAttr(data.itemsFact, 'idItem', record.idItem);
        setValue('itemsFact', newItems);
        refresh();
    }
    

    return (
    <>
        <EditRowButton />
        <DeleteRowItemButton deleteItem={handleDelete} record={record}/>
    </>
    )
}