import React from 'react';
import {
    Datagrid,
    DateField,
    NumberField,
    TextField,
    BooleanField,
    ReferenceField,
    List,
    SearchInput,
    FunctionField,
} from 'react-admin';
import Chip from '@mui/material/Chip';

const StockFilters = [
    <SearchInput source = 'q' alwaysOn/>,
  ];

export const StockList = () => {
return (
    <List filters={StockFilters} queryOptions={{meta:{searchable:'true', filterable: ['materialID']}}} perPage={50} sort={{field: 'consecutivo', order: 'DESC'}}>
        <Datagrid>
            <TextField source="id" />
            <DateField source="createdAt" label='Fecha Creación' />
            <NumberField source="consecutivo" label= '#'/>
            <NumberField source="anchoLamina" />
            <NumberField source="largoLamina" />
            <NumberField source="cantidad" />
            <NumberField source="areaTotal" />
            <NumberField source="areaRestante" />
            <FunctionField label='%' render={(r)=>(`${(r.areaRestante/r.areaTotal*100).toFixed(1)}%`)} />
            <FunctionField label='Estado' render={(r)=>( 
                                                      r.activa&&r.cerrada ? <Chip label="Error" variant="outlined" color ='error' />:
                                                      r.cerrada           ? <Chip label="Cerrada" variant="outlined" color ='primary' /> : 
                                                      r.ingresada         ? <Chip label="Ingresada" variant="outlined" color ='success' /> : 
                                                      r.activa            ? <Chip label="Activa" variant="outlined" color ='info' /> : 
                                                                            <Chip label="Nueva" variant="outlined" color ='warning' />
                                                      )} />
            <ReferenceField source="usuarioActiva" reference='usuarios'>
                <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
            </ReferenceField>
            <ReferenceField source="usuarioIngresa" reference='usuarios'>
                <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
            </ReferenceField>
            <ReferenceField source="usuarioCierra" reference='usuarios'>
                <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
            </ReferenceField>
        </Datagrid>
    </List>
);
};

