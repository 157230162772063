import { Sucursales } from '../../utils';
import { useEffect, useState } from 'react';
import { Detalles } from './components/SalidaOrdenLayout';
import { SalidaFilters } from './components/SalidaFilters';
import { SalidaOrdenTool } from './components/VariousComponents';
import { SalidaOrdenActions } from './components/SalidaOrdenActions';
import { DatagridSalidaOrden, ListStyled } from './components/style/SalidaStyles';
import { List, Datagrid, TextField, useGetOne, DateField, useGetList, NumberField, FunctionField, ReferenceField } from 'react-admin';

export const SalidaOrdenList = () => {

  const user = localStorage?.getItem('user');
  const [filterCity, setFilterCity] = useState({});
  const { data: usuario } = useGetOne('usuarios', { id: user });
  const { data: vendedores } = useGetList('usuarios', { filter: {} });
  const vendedoresConAlegra = vendedores?.filter(item => item?.alegraVendedorId);

  useEffect(() => {
    if (usuario && usuario?.sedes && usuario?.sedes?.length > 0) {
      const filterSedeUsuario = usuario?.sedes?.map(sede => {
        const ciudadSucursal = Sucursales?.find(sucursal => sucursal?.user === sede);
        const oldOP = ciudadSucursal?.oldOP;  
        return oldOP ? { ciudadSalida: { eq: oldOP } } : null;
      })?.filter(ciudad => ciudad !== null);
      if (filterSedeUsuario?.length > 0) {
        setFilterCity({ or: filterSedeUsuario });
        console.log('FilterCity set:', { or: filterSedeUsuario });
      } else {
        setFilterCity({});
        console.log('No se aplicó el filtro, no hay ciudades encontradas.');
      };
    };
  }, [usuario]);

  return (
    <List 
      perPage={50}
      filter={filterCity}
      aside={<SalidaFilters/>}
      actions={<SalidaOrdenTool/>}
    >  
      <Datagrid expandSingle expand={<Detalles/>} sx={DatagridSalidaOrden} bulkActionButtons={<SalidaOrdenActions vendedores={vendedoresConAlegra}/>}>
        <TextField label='Op' source='opSalida' sx={ListStyled?.componentFont}/>
        <TextField label='Version' source='versionSalida' sx={ListStyled?.componentFont}/>
        <TextField label='Cliente' source='clienteSalidaName' sx={ListStyled?.componentFont}/>
        <TextField label='Nombre Trabajo' source='trabajoSalida' sx={ListStyled?.componentFont}/>
        <TextField label='Ciudad' source='ciudadSalida' sx={ListStyled?.componentFont}/>
        <NumberField label='Planchas' source='totalPlanchas' sx={ListStyled?.componentFont}/>
        <TextField label='Tipo Trabajo' source='tipoTrabajo' sx={ListStyled?.componentFont}/>
        <DateField source='fechaSalida' label='Salida Producción' showTime={true} sx={ListStyled?.componentFont}/>
        <ReferenceField label='Vendedor' source='vendedorId' reference='usuarios' link={false} sx={ListStyled?.componentFont}>
          <FunctionField render={fullName => `${fullName?.nombres} ${fullName?.apellidos}`}/>
        </ReferenceField>
      </Datagrid>
    </List>
  );
};