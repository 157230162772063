import {
    Form,
    Button,
    useUpdate,
    useNotify,
    TextInput,
    SaveButton,
    useGetMany,
    useListContext,
    useRecordContext,
} from 'react-admin';
import React, { useState, useEffect } from 'react';
import IconCancel from '@mui/icons-material/Cancel';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { UserReposicion } from '../../exposicion/components/InputsMedidas';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ListStyled } from '../styles/OrdenSalidaStyles';

export const ReposicionExpo = ({ title }) => {

    const notify = useNotify();
    const [update] = useUpdate();
    const record = useRecordContext();
    const expId = record?.expID || [];
    const [motivo, setMotivo] = useState('');
    const { selectedIds } = useListContext();
    const [showDialog, setShowDialog] = useState(false);
    const { data: exposed } = useGetMany('exposicions', { ids: expId });
    const [selectedUser, setSelectedUser] = useState({ id: '', name: '' });

    console.log('selectedIds---->', selectedIds);
  
    useEffect(() => {
        if (record?.motivoReposicion) {
          setMotivo(record?.motivoReposicion);
        };
    }, [record]);
    
    useEffect(() => {
        setSelectedUser({ id: record?.responsableRepo });
    }, [record]);

    const handleClick = () => setShowDialog(true);
    const handleCloseClick = () => setShowDialog(false);
    const motivoChange = (e) => setMotivo(e?.target?.value);
  
    const handleSubmit = () => {
        try {
            const selectedExpo = exposed?.filter(expoItem => selectedIds?.includes(expoItem?.id));
            selectedExpo?.forEach(expoItem => {
                const dataUpdate = {
                    id: expoItem?.id,
                    reposicion: true,
                    motivo: motivo || expoItem?.motivo,
                    reposicionName: selectedUser?.name || expoItem?.reposicionName,
                    responsableReposicion: selectedUser?.id || expoItem?.responsableReposicion,
                };
                update('exposicions', {
                    id: expoItem?.id,
                    data: dataUpdate,
                    previousData: expoItem
                }, {
                    onSuccess: () => {
                        setShowDialog(false);
                        notify('Solicitud y motivo agregados correctamente.', { type: 'success' });
                    },
                        onFailure: () => {
                        notify('Error al reponer la exposición.', { type: 'error' })
                    },
                });
                console.log('dataUpdate', dataUpdate);
            });
        } catch (error) {
            setShowDialog(false);
            notify('Error al reponer la exposición.', { type: 'error' });
            console.error('Error al reponer la exposición:', error?.message || error);
        };
    };

    const disabledSaveButton = !(motivo?.trim() && selectedUser?.id);
  
    return (
        <>
            <Button sx={ListStyled?.submitButton} variant='contained' onClick={handleClick} label='Reponer Exposición' startIcon={<FlipCameraAndroidIcon/>}/>
            <Dialog fullWidth maxWidth='xs' open={showDialog} onClose={handleCloseClick}>
                <DialogTitle style={{ background: 'black', color: 'white' }}>
                    <Box display='flex' alignItems='center' justifyContent='center'>
                        <Typography fontWeight='bold' variant='h5'>{title}</Typography>
                    </Box>
                </DialogTitle>
                <Form resource='exposicions' onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2} justifyContent='space-evenly'> 
                            <Grid item sm={12} md={12} lg={12}>
                                <TextInput sx={ListStyled?.inputs} fullWidth multiline maxRows={4} size='small' label='Motivo' source='motivoReposicion' onChange={motivoChange}/>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <UserReposicion onUserSelect={setSelectedUser}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClick} label='ra.action.cancel' color='primary' sx={ListStyled?.submitButton}>
                            <IconCancel/>
                        </Button>
                        <SaveButton sx={ListStyled?.submitButton} label='Enviar' icon={<SettingsBackupRestoreIcon/>} disabled={disabledSaveButton}/>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );
};