import * as React from 'react';
import Divider from '@mui/material/Divider';
import { Grid, Typography} from '@mui/material';
import { ImageCDI } from './components/ImageCDI';
import { LenContent } from './components/LenContent';
import { ExpoShowTopBar } from './components/VariousComponets';
import { ShowInfoExposicion } from './layouts/ShowInfoExposicion';
import { SimpleShowLayout, TextField, FunctionField, NumberField, Labeled, ReferenceField, Show, useRecordContext } from 'react-admin';

const ShowTitle = () => {
  const record = useRecordContext();
  const expoName = (record?.expoName || 'EXPOSICIÓN' ).toUpperCase().replace(/[_-]/g, '');
  if (!record ){
    return 'Exposicion';
  } 
  return <Typography style={{fontWeight:'bold'}}> {`EXPOSICIÓN - ${expoName}`} </Typography>
};

export const ExposicionShow = () => {
  return (
    <Show title={<ShowTitle/>} actions={<ExpoShowTopBar/>} >
      <SimpleShowLayout>
        <Grid container flexDirection='row' justifyContent="space-evenly" spacing={2} style={{marginTop:'2rem'}}>
          <Grid item container xs={7} sm={4} md={2} lg={2} style={{ marginLeft:'5rem' }} sx={{'& img':{minWidth:250, minHeight:250}}}>
            <ImageCDI/>
          </Grid>
          <Divider orientation="vertical" flexItem style={{ marginLeft:'1rem' }}/>
          <Grid item container xs={6} sm={3} md={2} lg={2} style={{ marginLeft:'3rem', }}>
            <ShowInfoExposicion/>
          </Grid>
          <Divider orientation="vertical" flexItem style={{marginLeft:'5rem'}}/>
          <Grid item container flexDirection='column' justifyContent="space-evenly" style={{marginLeft:'2rem'}} spacing={1} xs={12} sm={12} md={6} lg={4}>
            <Labeled>
              <ReferenceField label='RESPONSABLE' source='responsableId' reference='usuarios' link={false}>
                <FunctionField render={record => `${record?.nombres} ${record?.apellidos}`} style={{ fontWeight: 'bold' }}/>
              </ReferenceField>
            </Labeled>
            <Labeled>
              <ReferenceField source='cajaId' reference="stocks" label='NO. CAJA' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <NumberField source="consecutivo" style={{ fontWeight: 'bold' }} />
              </ReferenceField>            
            </Labeled>
            <Labeled>
              <NumberField source="taras" label="Taras" style={{fontWeight:'bold'}}/>
            </Labeled>
            <Labeled>
              <TextField source='motivo' label='Motivo Anulación' style={{fontWeight:'bold'}}/>
            </Labeled>
            <Labeled>
              <NumberField source='cajaId' label='Cajas Usada' style={{fontWeight:'bold'}}/>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={12} >
            <LenContent/>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};