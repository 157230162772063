// src/sanitizer.js
import inputTypeFields from './inputTypeFields.json';

export function sanitizeData(inputTypeName, data, fieldPath = '') {
  if (data === null || data === undefined) {
    return data;
  }
  if (!inputTypeFields || !inputTypeFields[inputTypeName]) {
    throw new Error(`Unknown input type: ${inputTypeName}`);
  }

  const allowedFields = inputTypeFields[inputTypeName];
  const sanitizedData = {};

  Object.keys(allowedFields).forEach(key => {
    if (data && typeof data === 'object' && data.hasOwnProperty(key)) {
      const fieldTypeString = allowedFields[key];
      const fieldValue = data[key];
      const currentFieldPath = fieldPath ? `${fieldPath}.${key}` : key;

      const { baseType, isList, isNonNull } = parseType(fieldTypeString);

      if (fieldValue === null || fieldValue === undefined) {
        if (isNonNull) {
          throw new Error(`Field "${currentFieldPath}" is non-nullable but value is ${fieldValue}`);
        }
        sanitizedData[key] = fieldValue;
      } else if (isList) {
        if (!Array.isArray(fieldValue)) {
          throw new Error(`Field "${currentFieldPath}": Expected an array but got ${typeof fieldValue}`);
        }
        if (inputTypeFields[baseType]) {
          // List of nested input types
          sanitizedData[key] = fieldValue.map((item, index) =>
            sanitizeData(baseType, item, `${currentFieldPath}[${index}]`)
          );
        } else {
          // List of scalars
          sanitizedData[key] = fieldValue.map((item, index) =>
            validateScalar(item, baseType, `${currentFieldPath}[${index}]`)
          );
        }
      } else if (inputTypeFields[baseType]) {
        // Nested input type
        sanitizedData[key] = sanitizeData(baseType, fieldValue, currentFieldPath);
      } else {
        // Scalar field
        sanitizedData[key] = validateScalar(fieldValue, baseType, currentFieldPath);
      }
    }
  });

  return sanitizedData;
}

function parseType(typeString) {
  let isList = false;
  let isNonNull = false;
  let baseType = typeString.trim();

  // Handle non-null '!' at the end
  if (baseType.endsWith('!')) {
    isNonNull = true;
    baseType = baseType.slice(0, -1).trim();
  }

  // Handle list '[Type]'
  if (baseType.startsWith('[') && baseType.endsWith(']')) {
    isList = true;
    baseType = baseType.slice(1, -1).trim();

    // Check for non-null inside list '[Type!]'
    if (baseType.endsWith('!')) {
      baseType = baseType.slice(0, -1).trim();
      // Note: If you need to handle non-null items in lists, you can add additional logic
    }
  }

  return { baseType, isList, isNonNull };
}

function validateScalar(value, type, fieldPath) {
  if (value === null || value === undefined) {
    return value; // Allow null or undefined values (non-null should be enforced in sanitizeData)
  }

  switch (type) {
    case 'String':
      if (typeof value !== 'string') {
        throw new Error(`Field "${fieldPath}": Expected String but got ${typeof value}`);
      }
      return value;
    case 'Int':
      if (!Number.isInteger(value)) {
        throw new Error(`Field "${fieldPath}": Expected Int but got ${value} (${typeof value})`);
      }
      return value;
    case 'Float':
      if (typeof value !== 'number') {
        throw new Error(`Field "${fieldPath}": Expected Float but got ${typeof value}`);
      }
      return value;
    case 'Boolean':
      if (typeof value !== 'boolean') {
        throw new Error(`Field "${fieldPath}": Expected Boolean but got ${typeof value}`);
      }
      return value;
    case 'ID':
      if (typeof value !== 'string' && typeof value !== 'number') {
        throw new Error(`Field "${fieldPath}": Expected ID (String or Int) but got ${typeof value}`);
      }
      return String(value);
    case 'AWSDateTime':
      if (!isValidAWSDateTime(value)) {
        throw new Error(`Field "${fieldPath}": Invalid AWSDateTime value: ${value}`);
      }
      return value;
    case 'AWSDate':
      if (!isValidAWSDate(value)) {
        throw new Error(`Field "${fieldPath}": Invalid AWSDate value: ${value}`);
      }
      return value;
    // Handle other scalar types as needed
    default:
      throw new Error(`Field "${fieldPath}": Unknown scalar type ${type}`);
  }
}

function isValidAWSDateTime(value) {
  // Basic ISO 8601 date-time validation
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
  return iso8601Regex.test(value);
}

function isValidAWSDate(value) {
  // Regular expression to match 'YYYY-MM-DD'
  const awsDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return awsDateRegex.test(value);
}
