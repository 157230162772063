
import React, { useState } from 'react';
import { InvoiceVoidLayout } from "./InvoiceVoidLayout";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { SimpleForm, useRecordContext, useNotify, useRefresh, Toolbar, useRedirect, useUpdate, useGetList, useDeleteMany, useDataProvider } from 'react-admin';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import { CircularProgress, Grid } from '@mui/material';
import {  useFormContext, useWatch } from 'react-hook-form';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { sanitizeData } from '../../../utils/sanitizer';



const VoidInvoiceButton = () => {

    const record = useRecordContext();
    const notify = useNotify();

 
    return(
        <EditInDialogButton  title={`Anulación Factura #${record.alegraNumeracion}`} maxWidth="lg" label=''  inline icon={<BlockOutlinedIcon color={record&&record.estado==='void'?'light':'error'}/>} id={record.id}>
            <SimpleForm 
                sx={{minWidth:'1000px'}} 
                toolbar={<CustomToolbar />}
            >
                <InvoiceVoidLayout record={record}/>
            </SimpleForm>
        </EditInDialogButton>
        );
    }

const CustomToolbar = () => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { getValues , setValue } = useFormContext();
    const [updateStatus, setUpdateStatus] = useState('idle');
    const creditNoteData = useWatch({ name: 'creditNoteData' });
    const dataProvider = useDataProvider();

    const [update, { isPending, error }] = useUpdate();
    const [deleteMany, { isPending2, error2 }] = useDeleteMany();
    const notify = useNotify();
    const record = useRecordContext();
    const { data: clientes } = useGetList('clientes', {filter: {id:{eq:record?.clienteId}}, meta: {searchable: true}});
    // const  clientes =[[{id:1, name:'Cliente 1'}]];
        
        ///start here///////////////////
        /// DEFINE WHAT PARAMETERS ARE NEEDED TO DELETE/UPDATE IN THE OLD RECORD, AND TO CREATE FROM THE NEW RECORD


    let recordModified = {record: {...record, clientData:{}}};
    
    if (clientes && clientes.length > 0) {
        recordModified.record['clientData'] = clientes[0];
        recordModified.record['observaciones'] = record.observaciones ? record.observaciones + ' - Reemplaza Factura '+record.alegraNumeracion: 'REEMPLAZA LA FACTURA '+record.alegraNumeracion;
        recordModified.record['previousInvoiceID'] = record.id;

    }

    const handleDuplicate = () => {
        console.log('handleDuplicate', recordModified);
        let { productFact, remisiones,  ordenes, updatedAt, createdAt, ...dataCleaned } = record;
        dataCleaned.estado = 'void';
        dataCleaned.creditNoteID = creditNoteData?.id || '';
        dataCleaned.previousInvoiceID = recordModified?.record?.id || '';
        recordModified.record['kindOfDuplicate'] = 'duplicate'
        recordModified.record['itemsFact'] = [];
        delete recordModified?.record?.id;
        //recordModified.record['productfact2'] = recordModified.record['productFact'];

        //add oldFacturaID to each productFact
        recordModified.record.productFact.items =  recordModified.record.productFact.items.map(item => {
            return {...item, oldFacturaID: item.facturaID}
        })

        productFact.items.forEach(item => {
            if (item.remisionID){
                const productFactDataCleaned = sanitizeData('UpdateProductFactInput', { ...item,  oldFacturaID: item.facturaID});
                update('productFacts', {id: productFactDataCleaned.id, data: {...productFactDataCleaned}})
            }
            }
        )

        console.log('DUPLICATE DATA record Mod', recordModified);
        //update('facturas', {id: record.id, data: {...dataCleaned}})
        redirect('create', 'facturas', undefined, {}, { ...recordModified });
    }
    const handleReMake = () => {
        console.log('handleRemake', recordModified);
        let { productFact, remisiones,  ordenes, ...dataCleaned } = record;
        dataCleaned.estado = 'void';
        dataCleaned.creditNoteID = creditNoteData?.id||'';
        const {objState:data, productFact: pFact} = remakeData(recordModified.record)
        
        //update('facturas', {id: record.id, data: {...dataCleaned}})
        
        deleteMany('productFact', {ids: pFact.items.map(item => item.idItem)})
        
        redirect('create', 'facturas', undefined, {}, { ...data });
    }

    const handleVoid = async () => {
        //update Invoice Data
        console.log('handleVoid', record);        
    
                
        ////////////////////////////////////
        //  UPDATE FACTURAS WITH VOID STATUS
        const dataToUpdate = {
            ...record,
            estado: 'void',
            creditNoteID: creditNoteData?.id || '',
          };
        
          // Sanitize the data
        const dataCleaned = sanitizeData('UpdateFacturaInput', dataToUpdate);
        update('facturas', {id: record.id, data: {...dataCleaned}})
        console.log('FACTURAS UPDATED', dataCleaned)

        ////////////////////////////////////////////////////////////////////////////////////////
        //RESTORING STOCK ONLY FROM THE ITEMS THAT ARE PRESENT IN BOTH PRODUCTFACT AND ITEMSFACT
        // the rest doesn't need to be restored because they are going to be updated to delete facturaID
        const productFactIdsToRestoreStock = record.productFact.items
        .filter(productFact => record.itemsFact.some(itemFact => productFact.idItem === itemFact.idItem))

        console.log('productFactIdsToRestoreStock', productFactIdsToRestoreStock)
        if (productFactIdsToRestoreStock.length > 0) {
            restoreStock(productFactIdsToRestoreStock);
        }
        
        //////////////////////////////////////////////////////////////////
        // DELETE PRODUCT FACTS THAT ARE IN BOTH PRODUCTFACT AND ITEMSFACT
        const productFactIdsToDelete = productFactIdsToRestoreStock.map(productFact => productFact.id);
        deleteMany('productFacts', { ids: productFactIdsToDelete });

        console.log('PRODUCT FACTS DELETE', productFactIdsToDelete)

        ////////////////////////////////////////////////////
        // go to sqlOrdenFacturada and revert factura status
        if(record.itemsFact.length > 0){
            const updatePromises = [];
            console.log('RECORD INSIDE SQLORDENFACTIN', record.itemsFact)
            const groupedItems = record.itemsFact.reduce((acc, item) => {
                // ignore items that don't have orden or version
                if (!item.orden || !item.version) {
                    return acc;
                }
                // generate key for the group orden-version
                const key = `${item.orden}-${item.version}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});
            
            updatePromises.push(
                ...Object.keys(groupedItems).map((key) => {
                    const [orden, version] = key.split('-');
                    return dataProvider.update('sqlOrdenFacturadaIn', {
                        filter: { numero: orden, versionop: version },
                    });
                })
            );

            await Promise.all(updatePromises);
        }

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        //go to each productFact, check the ones that have remisionID associated  and set facturaID to null
        if (record.productFact.items.length > 0) {
            record.productFact.items.forEach(item => {
                if (item.remisionID){
                    const productFactDataCleaned = sanitizeData('UpdateProductFactInput', { ...item, facturaID:null, facturaNro: null,  oldFacturaID: item.facturaID});
                    update('productFacts', {id: productFactDataCleaned.id, data: {...productFactDataCleaned}})
                }
                }
            )
        }


        ///////////////////////////////////////////////
        // go to each remision and delete the invoiceID
        if (record.remisiones && record.remisiones.items.length > 0){
            record.remisiones.items.forEach(remision => {
                const dataCleaned = sanitizeData('UpdateRemissionInput', { ...remision, facturaID: null, estado: 'Emitida'});
                update('remissions', {id: dataCleaned.id, data: {...dataCleaned}})
                console.log('REMISSIONS UPDATED', dataCleaned)
            }
            )
        }
        /////////////////
        //go back to list
        redirect('list', 'facturas');
    }


    const restoreStock = async (items) => {
        const cajas = items.reduce((acc, item) => {
    
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        let responseArray = []; 
        for (const caja of cajas) {
            console.log('CAJA', caja)
            if(caja === null || caja.area<=0) continue;
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            //console.log('STOCK INSIDE RESTORE', stock)
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante + caja.area,
            }
            console.log('NEW STOCK', newStock)
            delete newStock.retales
            const response = await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            responseArray.push(response);
            
        }
        return responseArray;
    }

    if(creditNoteData&&creditNoteData?.invoices[0].fullNumber !== record?.alegraNumeracion){
        notify('La factura no coincide con la nota crédito', {type:'warning'});
    }
    if (error) { 
        console.log(error)
        notify(`Error al anular la factura: ${error[0]?.message}`, {type:'error'});
        }

    return (
        <Toolbar>
            <Grid container fullWidth justifyContent={'space-between'}>
                <Grid item md={6}>
                    <Button
                        onClick={handleDuplicate}
                        variant='outlined'
                        disabled={!creditNoteData && creditNoteData?.invoices[0].fullNumber !== record?.alegraNumeracion}
                        startIcon={isPending ? <CircularProgress /> : <ContentCopyIcon />}
                    >
                        Duplicar
                    </Button>
                </Grid>
                {/* <Button onClick={handleReMake}  variant='outlined' disabled={!creditNoteData&&creditNoteData?.invoices[0].fullNumber !== record?.alegraNumeracion } startIcon = {isPending ? <CircularProgress/>:<EditNoteIcon/>}>
                    Modificar
                </Button> */}
                <Grid item md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={handleVoid}
                        variant='outlined'
                        color="error"
                        disabled={!creditNoteData && creditNoteData?.invoices[0].fullNumber !== record?.alegraNumeracion}
                        startIcon={isPending ? <CircularProgress /> : <BlockOutlinedIcon />}
                    >
                        Anular y Liberar
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
    );
}   




const remakeData = (record) => {
    const user = localStorage.getItem('user')
    const { productFact, itemsFact, remisiones,  ordenes, ...dataCleaned } = record;
    const newItemsFact = [...itemsFact, ...record.productFact.items]
        .filter((item, index, self) => 
            index === self.findIndex((t) => t.idItem === item.idItem)
        )
        .map(item => { 
            const { idItem, odc, alegraItemId, orden, iva, type, plateType, material, calibre, ordenVersion, descripcion,
                valorUnitario, version, ancho, largo, cantidad, cajaNro, caja, area, valorTotal } = item;
            return {idItem, odc:odc?.trim(), alegraItemId, orden, iva, type, plateType, material, calibre, ordenVersion, 
                descripcion, valorUnitario, version, ancho, largo, cantidad, cajaNro, caja, area, valorTotal}
        });
    console.log('NEW ITEMS FACT', newItemsFact)

    const objState = {
        record: {
            usuarioID: user,
            clienteId: record.clienteId || null,
            vendedorAlegraId: record.vendedorAlegraId || null,
            vendedorID: record.vendedorID || null,
            ciudadId : record.ciudadId || null,
            applyIva : record.applyIva || null,
            clienteName: record.clienteName || null,
            clientData: record.clientData,
            alegraClienteId: record.alegraClienteId || null,
            productFact: {items:[]},
            formaPago: record.clientData.datosFacturacion.formaPago || null,
            remisionesIDs: [],
            observaciones: record.observaciones,
            itemsFact: newItemsFact,
            emails: record.emails || [],
            kindOfDuplicate: 'remake'
        }
    }
    console.log('REMAKE DATA', objState)
    return {objState: objState, productFact:  productFact}

}

export default VoidInvoiceButton;