
import React from 'react';
import { BooleanInput, NumberInput, ReferenceInput, AutocompleteInput, TextInput, required} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Grid } from '@mui/material';


const StockEditLayout = (props) => {
    const user= localStorage.getItem('user')

    const isActivado = useWatch({name: 'activa'});
    const isIngresado = useWatch({name: 'ingresada'});
    const isCerrado = useWatch({name: 'cerrada'});
    const { setValue, getValues } = useFormContext();

 
  
    const handleActiva = (e) => {
      if (e.target.checked)
      {
        setValue('usuarioActiva', user)
        setValue('cerrada', false)

      }
      else
      {
        setValue('usuarioCierra', '')
      }
    }
  
    const handleIngreso = (e) => {
      if (e.target.checked)
      {
        setValue('usuarioIngresa', user)
        setValue('fechaIngreso', new Date().toISOString())
        setValue('cerrada', false)
      }
      else
      {
        setValue('usuarioCierra', '')

      }
    }   
  
    const handleCierra = (e) => {
      if (e.target.checked)
      {
        setValue('fechaSalida', new Date().toISOString())
        setValue('usuarioCierra', user)
        setValue('activa', false)
        setValue('ingresada', false)
        
      }
      else
      {
        setValue('usuarioCierra', '')
      }
    }
    console.log('record from getValues()', getValues());

    return (
        <Grid container spacing={2} >
          <Grid item sm={12} md={4} >
              <TextInput source="lote" fullWidth/>
          </Grid>
          <Grid item sm={12} md={2} >
              <BooleanInput source="activa" onChange={handleActiva} fullWidth />
          </Grid>
          <Grid item sm={12} md={2} >
              <BooleanInput source="ingresada" onChange={handleIngreso} fullWidth />
          </Grid>
          <Grid item sm={12} md={2} >
              <BooleanInput source="cerrada" onChange={handleCierra} fullWidth defaultValue={false} disabled={!(!isIngresado === !isActivado)} />
          </Grid>
          <Grid item sm={12} md={2} >
            <NumberInput source="consecutivo" validate={required()} disabled fullWidth/>
          </Grid>
          <Grid item sm={12} md={12} >
            <ReferenceInput source="materialID" reference="materials" fullWidth>
              <AutocompleteInput optionText='id' label='Material'  size='small'  disabled/>
            </ReferenceInput>
          </Grid>
          <Grid item sm={12} md={4} >
            <NumberInput source="anchoLamina" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} >
            <NumberInput source="largoLamina" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} >
            <NumberInput source="cantidad" fullWidth />
          </Grid>
          <Grid item sm={12} md={6} >
            <NumberInput source="areaTotal" />
          </Grid>
          <Grid item sm={12} md={6} >
            <NumberInput source="areaRestante" />
          </Grid>
        </Grid>
    );
  }
  



  
  export default StockEditLayout