import React, {useCallback, useState, useEffect} from 'react';
import { List, Datagrid, TextField, DateField, useTranslate, useListContext, SimpleForm, TopToolbar, CreateButton, ShowButton ,
    useRecordContext, useNotify, Pagination, useDataProvider, useRefresh , WrapperField,
    SearchInput,
    NumberField,
} from 'react-admin';
import { ListActions } from './components/ImportInvoice';



import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadingIcon from '@mui/icons-material/Downloading';
import Chip from '@mui/material/Chip';
import AlegraIcon from "../components/alegraIcon";
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Tooltip from '@mui/material/Tooltip';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VoidInvoiceButton from './components/VoidInvoiceButton';

const FacturaFilters = [
    <SearchInput source='q' alwaysOn  />,
    //<TextInput source="clienteName.wildcard" label='Cliente' alwaysOn format={v=>v?.replaceAll('*', '')||''}/>,
]

const ListPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 300]} />;


const FacturaList = () => (
    <List   queryOptions={{meta:{searchable:'true', filterable: ['clienteName', 'alegraNumeracion.wildcard']}}} pagination={<ListPagination/>} title='Facturas'
            filters={FacturaFilters} debounce={1000}
            sort={{field: 'fechaCrea', order: 'DESC'}}
            actions={<ListActions/>}
    >
        <Datagrid 
              sx={{
                "& .column-clienteName": { 
                    maxWidth: "250px",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}}
            >
            <TextField source="alegraNumeracion" label='Número'/>
            <TextField source="clienteName" label='Cliente' />
            <DateField source="fechaCrea" label='Fecha Creación'/>
            <DateField source="fechaVence" label='Fecha Vence'/>
            {/* <FunctionField source="stamp.legalStatus" label='DIAN' render={record=>(getSelectedReferencesDIANStatus(record))}/> */}
            <WrapperField label="Estado" >
                <StatusField />
            </WrapperField>
            <NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 1 }} />
            <NumberField source="totalSinRetenciones" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 1 }} label='Total Menos Retenciones'/>
            <WrapperField label="Acciones">
                <ShowButton label={''}/>
                <AlegraButton />
                <ImprimirButton />
                {/*<EditButtonIcon />*/}
                {/* <DeleteRemision type='icon' /> */}
                <VoidInvoiceButton /> 
            </WrapperField> 
        </Datagrid>
    </List>
);



const getSelectedReferencesDIANStatus = (record) => {
    if(record.stamp.legalStatus === 'PENDING'){ return <Tooltip title='Pendiente por Emitir'><PendingOutlinedIcon label = 'Pendiente' color='info'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_WAITING_RESPONSE'){ return <Tooltip title='Pendiente Respuesta DIAN'><TimerOutlinedIcon label = 'Esperando Respuesta' color='warn'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_ACCEPTED'){ return <Tooltip title='Aprobada'><CheckCircleIcon label = 'Aprobada' color='success'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_REJECTED'){ return <Tooltip title="Rechazada"><BlockOutlinedIcon  label = 'Rechazada' color='error'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS'){ return <Tooltip title="Aprobada con Observaciones"> <CheckCircleOutlinedIcon label = 'Aprobada' color='success'/> </Tooltip>}
}
const AlegraButton = () => {
    const record = useRecordContext();
    return(
        <IconButton
            variant='outlined'
            onClick={() => {
                window.open(record.url, '_blank');
            }}
        >
            <AlegraIcon/>
        </IconButton>
    );
}

// const EditButtonIcon = () => {
//     const redirect = useRedirect();
//     const record = useRecordContext();

//     return(
//         <IconButton
//             variant='outlined'
//             onClick={() => {
//                 redirect(`/facturas/${record.id}`);
//             }}
//         >
//             <EditIcon/>
//         </IconButton>
//     );
// }

const StatusField = () => {       
    const record = useRecordContext();
    const translate = useTranslate();
    if (record.estado === "closed") {
        return <Tooltip title='Cobrada'><PaidOutlinedIcon color='success'/></Tooltip>;
    }
    if (record.estado === "open") {
        return <Tooltip title="Por Cobrar"><PendingOutlinedIcon/></Tooltip>;
    }
    if (record.estado === "draft") {
        return <Tooltip title='Borrador'><DriveFileRenameOutlineOutlinedIcon variant='outlined' /></Tooltip>;
    }
    if (record.estado === "void") {
        return <Tooltip title='Anulada'><BlockOutlinedIcon source="estado" /></Tooltip>;;
    }
    return <Chip source="estado"  color="primary" variant='outlined' label={record.estado}/>;
};


const ImprimirButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false); 
    const notify = useNotify();
    
    //console.log('RECORD', record)
    const handlePrint = async () => {
        setLoading(true);
        const invoice = await dataProvider.getOne('alegraInvoices', {id: record.alegraId})
        //console.log('INVOICE', invoice);
        if (!invoice || !invoice.data || !invoice.data.pdf){
            setLoading(false);
            notify('No fue posible generar el PDF', 'error');
            return;
        }
        window.open(invoice.data.pdf, '_blank');
        setLoading(false);
    }
    return(
    <IconButton
        variant='outlined'
        onClick={handlePrint}
    >{

        loading ?  <DownloadingIcon/> :
        <PrintIcon/>
    }
    </IconButton>
);
}







export default FacturaList;