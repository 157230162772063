import UndoIcon from '@mui/icons-material/Undo';
import { ChatBubble } from './chatBubbleOrden';
import { ShowExposicion } from './ShowExposicion';
import React, { useState, useEffect } from 'react';
import IconCancel from '@mui/icons-material/Cancel';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { BubbleStyles, DialogSalidaStyled, ListStyled, ToolBarStyled, fontTableCell, AddDiferencia } from '../styles/OrdenSalidaStyles';
import { useGetList, ListButton, TopToolbar, ExportButton, FunctionField, useRecordContext, EditButton, ReferenceField, Form, Button, TextInput, useUpdate, useNotify, SaveButton } from 'react-admin';
import { Box, Grid, Card, Switch, Tooltip, Typography, CardContent, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableRow, TableBody, TableCell, TableHead } from '@mui/material';

const LabelLayout = ({ showFilters }) => {
  return ( 
    showFilters ?
      <Typography sx={ToolBarStyled?.typography}>
        {`Ocultar Filtros`}
      </Typography>
    : 
    <Typography sx={ToolBarStyled?.typography}>
      {`Mostrar Filtros`}
    </Typography>
  )
};

const TopBarOrden = ({ showFilters, toggleFilters }) => {
  return (
    <TopToolbar sx={ToolBarStyled?.topToolBar}>
      {showFilters ? <OrdenTopBar/> : null}
      <FormControlLabel
        onClick={toggleFilters}
        sx={ToolBarStyled?.formControlLabel}
        control={<Switch sx={ToolBarStyled?.toolBar}/>}
        label={<LabelLayout showFilters={showFilters}/>}
      />
    </TopToolbar>
  )
};

const OrdenTopBar = () => {
  return (
    <TopToolbar>
      <ExportButton/>
      {/* <CreateButton/> */}
      <ListButton icon={<UndoIcon/>} resource='exposicions' label='Ver Exposiciones'/>
    </TopToolbar>
  )
};

const FunctionOrdenFiled = () => {
  return (
    <Card>
      <CardContent>
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <FunctionField render={record => record?.motivoDiferencia === null ? <AgregarDiferencia/> : null }/>
        </Box>
      </CardContent>
    </Card>
  )
};

const ResponsableView = () => {
  return (
    <FunctionField label='RESPONSABLE' render={r => {
      if (r?.salidaProd === true /*&& r?.reposicion === false*/) {
        return (
          <ReferenceField source='responsableSalida' reference='usuarios' link={false}>
            <FunctionField render={r => `${r?.nombres?.toUpperCase()} ${r?.apellidos?.toUpperCase()}`} sx={ListStyled?.componentFont}/>
          </ReferenceField>
        );
      } else if (r?.reposicion === true) {
        return (
          <ReferenceField source='responsableRepo' reference='usuarios' link={false}>
            <FunctionField render={r => `${r?.nombres?.toUpperCase()} ${r?.apellidos?.toUpperCase()}`} sx={ListStyled?.componentFont}/>
          </ReferenceField>
        );
      } else {
        return <span/>
      }
    }}/>
  );
};

const DialogSalidaOrden = ({ setRecord }) => {
  
  const record = useRecordContext();
  const reposicion = record?.reposicion;
  const salidaProd = record?.salidaProd;
  const totalExp = record?.totalPlanchasExp;
  const calibreOrden = record?.calibreOrden;
  const totalOrd = record?.totalPlanchasOrden;
  const calibresExp = record?.calibresExp?.[0] || 0;
  const motivoDiferencia = record?.motivoDiferencia;

  const buttonDisabled = salidaProd === true;
  const planchaCorrectly = totalOrd <= totalExp;
  const differentCalibre = calibreOrden === calibresExp;
  const buttonAble = salidaProd === false && (reposicion !== false || reposicion !== true);

  return (
    <>
      {motivoDiferencia === null && totalExp > totalOrd ? (
        <AgregarDiferencia/>
      ):(
        <FunctionField source='salidaOrdenes' label='SALIDA' 
          render={record => record?.salidaProd === true && record?.reposicion === false ? (
            <ResponsableView/> 
          ):(
            planchaCorrectly && buttonAble && differentCalibre ? (
              <EditButton
                size='small'
                label='SALIDA'
                disabled={buttonDisabled}
                icon={<ShoppingCartIcon/>}
                sx={DialogSalidaStyled?.button}
                onClick={() => setRecord(record)}
                color='inherit' variant='contained'
                startIcon={<ShoppingCartCheckoutIcon/>}
                style={{ alignItems: 'center', marginLeft: '-1em' }}
              />
            ):(
              <span/>
            )
          )}
        />
      )}
    </>
  );
};

const IconState = () => {
  return (
    <FunctionField
      render={r => {
        if (r?.salidaProd === true) {
          return (
            <Tooltip title='Orden finalizada'>
              <AssignmentTurnedInIcon sx={ListStyled?.iconStateSize} color='success'/>
            </Tooltip>
          );
        } else if (r?.reposicion === true) {
          return (
            <Tooltip title='Orden con reposición'>
              <AssignmentLateOutlinedIcon sx={ListStyled?.iconStateSize} color='warning'/>
            </Tooltip>
          );
        } else if (r?.calibreOrden !== r?.calibresExp?.[0]) {
          return (
            <Tooltip title='La orden tiene un calibre diferente al de la exposición'>
              <AssignmentLateOutlinedIcon sx={ListStyled?.iconStateSize} color='warning'/>
            </Tooltip>
          );
        } else if (r?.totalPlanchasExp > r?.totalPlanchasOrden) {
          if (r?.motivoDiferencia === null) {
            return (
              <Tooltip title={`Se añadieron ${r?.totalPlanchasExp} planchas, excediendo el total de ${r?.totalPlanchasOrden} plancha(s) requerida(s)`}>
                <LiveHelpOutlinedIcon sx={ListStyled?.iconStateSize} color='error'/>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title='Orden lista para darle salida con motivo de diferencia'>
                <AssignmentTurnedInOutlinedIcon sx={ListStyled?.iconStateSize} style={{color: 'rgba(255, 128, 0, 0.65)'}}/>
              </Tooltip>
            );
          }
        } else if (r?.totalPlanchasExp === r?.totalPlanchasOrden) {
          return (
            <Tooltip title='Orden lista para darle salida'>
              <AssignmentTurnedInOutlinedIcon sx={ListStyled?.iconStateSize} color='action'/>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title='Orden pendiente'>
              <AssignmentOutlinedIcon sx={ListStyled?.iconStateSize} color='disabled'/>
            </Tooltip>
          );
        }
      }}
    />
  );
};

const SectionLayout = ({ titleMedidas, titleObs, observaciones, titleExpo, filterMotivo, BubbleMotivo, maxObs, wordsSize }) => (
  <Grid container >
    <Grid item sm={6} md={6} lg={6}>
      <Grid item sm={12} md={12} lg={12}>
        {titleMedidas()}
        <OrdenMaterial maxObs={maxObs}/>
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        {titleObs()}
        <ChatBubble observaciones={observaciones} sx={BubbleStyles?.sx} wordsSize={wordsSize}/>
      </Grid>
    </Grid>
    <Grid item sm={12} md={12} lg={6}>
      {titleExpo()}
      <ShowExposicion/>
      {filterMotivo ? <BubbleMotivo/> : <span/>}
    </Grid>
  </Grid>
);

export const Detalles = () => {

  const record = useRecordContext();
  const orden = parseInt(record?.op);
  const version = parseInt(record?.version);
  const filterMotivo = record?.motivoDiferencia !== null;

  const filterObservaciones = {
    NUMERO: orden,
    VERSIONOP: version,
  };

  const { data: observaciones, isLoading: loadingObserv } = useGetList('sqlObservaciones', {
    filter: filterObservaciones,
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'VERSIONOP', order: 'DESC' },
  });

  if (loadingObserv) {
    return <p>Loading...</p>;
  };

  const maxObs = observaciones?.length > 1;
  const wordsSize = observaciones?.OBSERV?.length < 91;

  const titleExpo = () => (
    <Typography>
      <div style={ListStyled?.titleStyled}>
        {`EXPOSICIÓN`}
      </div>
    </Typography>
  );

  const titleMedidas = () => (
    <Typography>
      <div style={ListStyled?.titleStyled}>
        {`MEDIDAS`}
      </div>
    </Typography>
  );

  const titleObs = () => (
    maxObs ? (
      <Typography>
        <div style={ListStyled?.titleStyled}>
          {`OBSERVACIONES`}
        </div>
      </Typography>
    ) : <span/>
  );

  return (
    <SectionLayout
      maxObs={maxObs}
      titleObs={titleObs}
      titleExpo={titleExpo}
      wordsSize={wordsSize}
      titleMedidas={titleMedidas}
      filterMotivo={filterMotivo}
      BubbleMotivo={BubbleMotivo}
      observaciones={observaciones}
      ShowExposicion={ShowExposicion}
    />
  );
};

export const BubbleMotivo = () => {

  const record = useRecordContext();
  const motivoDiferencia = record?.motivoDiferencia;

  return (
    motivoDiferencia === null ? ( <span/> ):(
      <Box sx={BubbleStyles?.body}>
        <Typography variant='body3' sx={BubbleStyles?.titleStyle}>{`Motivo Diferencia`}</Typography>
        <Typography variant='body1' sx={BubbleStyles?.textStyle}>{motivoDiferencia}</Typography>
      </Box>
    )
  );
};

const AgregarDiferencia = () => {

  const notify = useNotify();
  const record = useRecordContext();
  const [motivoDif, setMotivoDif] = useState('');
  const [update, { isLoading, error }] = useUpdate();
  const [showDialog, setShowDialog] = useState(false);
  const totalPlanchasExp = record?.totalPlanchasExp || 0;
  const totalPlanchasOrden = record?.totalPlanchasOrden || 0;
  const filterAble = totalPlanchasExp > totalPlanchasOrden;

  useEffect(() => {
    if (record?.motivoDiferencia && record?.motivoReposicion) {
      setMotivoDif(record?.motivoDiferencia);
    };
  }, [record]);

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  const handleMotivoChange = (e) => {
    const { name, value } = e.target;
    if (name === 'motivoDiferencia') {
      setMotivoDif(value);
    };
  };

  const handleSubmit = async (values) => {
    update('ordenSalidas', {
      id: record?.id,
      data: {
        id: record?.id,
        motivoDiferencia: values?.motivoDiferencia !== '' ? values?.motivoDiferencia : record?.motivoDiferencia,
      },
      previousData: record,
    },
    {
      onSuccess: () => notify('Solicitud y motivo agregados correctamente.'),
      onFailure: () => notify('Error al agregar el solicitud.', { type: 'error' })
    });
    console.log('Antes de Agregar el Motivo', record);
    console.log('Después de Agregar el Motivo', values);
  };

  if (error) {
    return (
      <Button style={AddDiferencia?.errorButton}>
        {`Error ${error?.message}`}
      </Button>
    );
  };

  return (
    filterAble ? (
      <>
        <Button size='small' label='Motivo' variant='contained' onClick={handleClick} sx={AddDiferencia?.sxButton} style={AddDiferencia?.styleButton} startIcon={<BorderColorOutlinedIcon/>}/>
        <Dialog fullWidth maxWidth='xs' open={showDialog} onClose={handleCloseClick} aria-label='Motivo Agregado'>
          <DialogTitle style={AddDiferencia?.dialogTitleStyle}>
            <Typography style={AddDiferencia?.typography}>{`AGREGAR MOTIVO DIFERENCIA`}</Typography>
          </DialogTitle>
          <Form resource='ordenSalidas' id='create-ordenSalid' onSubmit={handleSubmit}>
            <DialogContent>
              <Grid item xs={12}>
                <TextInput sx={ListStyled?.inputs} fullWidth multiline maxRows={5} size='small' label='Motivo Diferencia' source='motivoDiferencia' onChange={handleMotivoChange}/>
              </Grid>
            </DialogContent>
            <DialogActions style={AddDiferencia?.dialogActions}>
              <Button label='ra.action.cancel' onClick={handleCloseClick} disabled={isLoading} sx={ListStyled?.submitButton}>
                <IconCancel/>
              </Button>
              <SaveButton icon={<BorderColorOutlinedIcon/>} label='Agregar Motivo' disabled={motivoDif === null} sx={ListStyled?.submitButton}/>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    ) : (
      <span/>
    )
  );
};

export const OrdenMaterial = () => {

  const record = useRecordContext();
  const infoRetal = record?.infoRetal || [];
  const ordenMaterial = record?.ordenMaterial || [];

  const groupedMaterials = ordenMaterial?.reduce((acc, material) => {
    const { caja, opAncho: ancho, opLargo: largo, cantidad } = material;
    const [dim1, dim2] = [ancho, largo].sort((a, b) => a - b);
    const key = `${dim1}-${dim2}-${caja}`;

    if (!acc[key]) {
      acc[key] = {
        ancho: dim1,
        largo: dim2,
        caja,
        cantidad: 0,
        area: 0,
      };
    }

    acc[key].cantidad += cantidad;
    acc[key].area += dim1 * dim2 * cantidad;

    return acc;
  }, {});

  const materialArray = Object.values(groupedMaterials)?.map(group => {
    const opAncho = group?.ancho;
    const opLargo = group?.largo;
    const area = opAncho * opLargo * group?.cantidad;

    return {
      opAncho,
      opLargo,
      cantidad: group?.cantidad,
      cajaExpo: group?.caja,
      area,
      total: group?.cantidad,
    };
  });

  const totalArea = materialArray?.reduce((acc, item) => acc + item?.area, 0);
  const totalCantidad = materialArray?.reduce((acc, item) => acc + item?.cantidad, 0);

  const title = (
    <Box>
      <Typography>
        <div style={ListStyled?.titleStyled}>
          {`INFORMACIÓN DE MATERIAL`}
        </div>
      </Typography>
    </Box>
  );

  return (
    <Grid container flexDirection='column' justifyContent='space-evenly'>
      <Grid item sm={12} md={12} lg={12}>
        <Box sx={{ minWidth: '100%' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`#CAJA`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`ANCHO (mm)`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`LARGO (mm)`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`CANTIDAD`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`ÁREA(mm)`}</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {materialArray?.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell sx={fontTableCell?.cell} align='center'>{item?.cajaExpo}</TableCell>
                  <TableCell sx={fontTableCell?.cell} align='center'>{item?.opAncho?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell sx={fontTableCell?.cell} align='center'>{item?.opLargo?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell sx={fontTableCell?.cell} align='center'>{item?.cantidad}</TableCell>
                  <TableCell sx={fontTableCell?.cell} align='center'>
                    {item?.area?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell sx={fontTableCell?.cell} align='center'><b>Total</b></TableCell>
                <TableCell colSpan={2}></TableCell>
                <TableCell sx={fontTableCell?.cell} align='center'>{totalCantidad}</TableCell>
                <TableCell sx={fontTableCell?.cell} align='center' colSpan={2}>{`${totalArea?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <Box sx={{ minWidth: '100%' }}>
          {title}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`TIPO MATERIAL`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`ID MATERIAL`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`ANCHO`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`LARGO`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`DESPERDICIO`}</b></TableCell>
                <TableCell sx={fontTableCell?.title} align='center'><b>{`DESPERDICIO %`}</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infoRetal?.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell sx={fontTableCell?.cell} align='center'>{item?.typeMaterial}</TableCell>
                  <TableCell sx={fontTableCell?.cell} align='center'>{item?.uuid}</TableCell>
                  {item?.typeMaterial === 'Lamina Completa' ? (
                    <>
                      <TableCell sx={fontTableCell?.cell} align='center'>{item?.anchoLamina}</TableCell>
                      <TableCell sx={fontTableCell?.cell} align='center'>{item?.largoLamina}</TableCell>
                    </>
                  ):(
                    <>
                      <TableCell sx={fontTableCell?.cell} align='center'>{item?.anchoRetal}</TableCell>
                      <TableCell sx={fontTableCell?.cell} align='center'>{item?.largoRetal}</TableCell>
                    </>
                  )}
                  <TableCell sx={fontTableCell?.cell} align='center'>{item?.desperdicio?.toFixed(1)}</TableCell>
                  <TableCell sx={fontTableCell?.cell} align='center'>{`${item?.porcentajeDesp?.toFixed(2)}%`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  );
};



export { LabelLayout, TopBarOrden, OrdenTopBar, FunctionOrdenFiled, ResponsableView, DialogSalidaOrden, IconState, AgregarDiferencia };