

import React, { useEffect } from "react";
import { useRefresh,BooleanInput, TextInput, useRecordContext} from 'react-admin';
import {  Typography, Grid } from '@mui/material';


import { useFormContext } from 'react-hook-form';
import { formatCurrencyString, BaseRetenciones } from "../../../utils";



export const TotalesComponentFactura = (props) => {
    const {getValues, setValue} = useFormContext();
    const record = useRecordContext();
    const data = getValues();
    // console.log('DATA', data);
    // console.log('RECORD', record);

    const refresh = useRefresh();
    useEffect(() => {
        if( (data&&data.itemsFact && data.itemsFact.length>0)||(data&&data.productFact && data.productFact.items.length>0)){
            let totalArea = 0;
            let subtotalIva = 0;
            let subtotalNoIva = 0;
            if (data.itemsFact?.length > 0){
                //console.log('DATA IN TOTAL COMP iside data.itemsFact', data);
                totalArea = data.itemsFact.reduce((acc, item) => acc + item.area, 0);
                subtotalIva = data.itemsFact.reduce((acc, item) => item.iva ? acc + item.valorTotal : acc, 0);
                subtotalNoIva = data.itemsFact.reduce((acc, item) => !item.iva ? acc + item.valorTotal : acc, 0);
            }
            if (data.productFact?.items.length > 0){
                //console.log('DATA IN TOTAL COMP inside data.productFact', data.productFact.items);
                totalArea += data.productFact.items.reduce((acc, item) => acc + item.area, 0);
                subtotalIva += data.productFact.items.reduce((acc, item) => item.iva ? acc + item.valorTotal : acc, 0);
                subtotalNoIva += data.productFact.items.reduce((acc, item) => !item.iva ? acc + item.valorTotal : acc, 0);

            }
             let iva = subtotalIva * 0.19 ;
             let subtotal = subtotalIva + subtotalNoIva;
             let total = subtotal + iva;
             let retenciones = [];
            
            ////console.log('DATA', data.clientData);
            if (data.clientData&&data.clientData.datosFacturacion.retenciones){
                retenciones = [...data.clientData.datosFacturacion.retenciones];
                retenciones.map((retencion) => {
                    let valorBase = retencion.type === 'IVA' ? iva : subtotal;
                    retencion.valorBase = valorBase;
                    retencion.valor = valorBase * (retencion.percentage / 100);
                    return retencion;
                });
                ////console.log('RETENCIONES AFTER', retenciones, subtotal);
            }
            
                
            
            // if (retenciones && retenciones.length > 0){
            //     for (let i = 0; i < retenciones.length; i++){
            //         ////console.log('RETENCIONES', retenciones[i].name);
            //         if(retenciones[i].name.toLowerCase().includes('compras')  && subtotal<BaseRetenciones[1].valor){
            //             ////console.log('COMPRAS', subtotal, BaseRetenciones[1].valor);
            //             retenciones.splice(i, 1);
            //         }
            //         else if(retenciones[i].name.toLowerCase().includes('servicios') && subtotal<BaseRetenciones[0].valor){
            //             ////console.log('Servicios', subtotal, BaseRetenciones[0].valor);

            //             retenciones.splice(i, 1);
            //         }
            //     }
            // }
            
            
            setValue('retenciones', retenciones);
            setValue('totalArea', totalArea);
            setValue('subtotal', subtotal);
            setValue('iva', iva);
            setValue('total', total);
            
            
            const totalSinRetenciones = retenciones&&retenciones.length > 0 ? total - retenciones.reduce((acc, retencion) => acc + retencion.valor, 0):total;
            setValue('totalSinRetenciones', totalSinRetenciones);

            //console.log('TOTAL SIN RETENCIONES', total);
            //console.log('Total Area', totalSinRetenciones);
            }   
            else {
                setValue('totalArea', 0);
                setValue('subtotal', 0);
                setValue('iva', 0);
                setValue('total', 0);
                setValue('totalSinRetenciones', 0);
                setValue('retenciones', []);
            }
        }, [data, setValue ]);

 
    // const handleDisableIva = (e) => {
    //     ////console.log('CHECKBOX', e.target.checked)
    //     for (let i = 0; i < data.itemsFact.length; i++){
    //         if (data.itemsFact[i].iva === e.target.checked) continue;
    //         if (data.itemsFact[i].material.includes("FLETES") && data.itemsFact[i].iva === false) continue;
    //         setValue(`itemsFact.${i}.iva`, e.target.checked);
    //     }
    //     refresh();
    //     props.changed(e.target.checked);
    // }
    return (
        <Grid item  xs={12} md={12} container  direction={'row'} justifyContent={'flex-end'} >
        <Grid item xs={12} md={12}>
            <TextInput source="observaciones"  fullWidth sx={{'& .MuiInputBase-multiline' :{height:'70px'}}} multiline/>
        </Grid>
        <Grid item sm={12} md={12} container direction='row' >
            <Grid item md={6} container direction={'row'}>
                <Grid item md={6}>
                    <Typography variant="h6" align="right">Total Area:</Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" align="left" sx={{marginLeft:'37px'}}>
                        {data?.totalArea?.toLocaleString('es-CO') || 0}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" align="right"><b>Subtotal:</b></Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" align="left" sx={{marginLeft:'20px'}}>
                        {formatCurrencyString(data?.subtotal) || 0} 
                    </Typography>
                </Grid>
                <Grid item container direction='row' justifyContent='flex-end'  md={6}>
                    <Grid item md={1.5}>    
                        <BooleanInput source="applyIva" label={false}   helperText={false}/>
                    </Grid>
                    <Grid item md={2} alignContent='center'>
                        <Typography  variant="h6" align="right"><b>IVA:</b></Typography>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" align="left" sx={{marginLeft:'20px', marginTop:'5px'}}>
                        { formatCurrencyString(data?.iva)  || 0} 
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" align="right"><b>Total:</b></Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" align="left" sx={{marginLeft:'20px'}}>
                        { formatCurrencyString(data?.total) || 0} 
                    </Typography>
                </Grid>
            </Grid>
            <Grid item md={6} container direction={'row'}>

                {
                    data.retenciones&&data.retenciones.length > 0 && data.retenciones.map((retencion, index) => (
                        <Grid item container md={12} key={index} direction={'row'}>
                            <Grid item md={6}>
                            <Typography variant="h6" align="right">
                                <b>{retencion.name} {retencion.percentage}%: </b>
                            </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="h6" align="left" sx={{marginLeft:'20px'}}>
                                    {formatCurrencyString(retencion.valor)}
                                </Typography>
                            </Grid>
                            
                        </Grid>
                    ))
                }
                
            <Grid item md={6}>
                <Typography variant="h6" align="right"><b>Total Menos Retenciones:</b></Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="left" sx={{marginLeft:'20px'}}>
                    { formatCurrencyString(data?.totalSinRetenciones) || 0} 
                </Typography>
            </Grid>
            </Grid>
        </Grid>
        </Grid>

    )
}         


