import { 
    useGetList,
    CreateButton, 
    useListContext,
    Button,
} from "react-admin";
import React from "react";
import { Sucursales } from "../../../utils";

export const SalidaOrdenActions = ({ vendedores }) => {

    const user = localStorage.getItem('user');
    const { data, selectedIds } = useListContext(); 
    const selectedData = data?.filter(record => selectedIds?.includes(record?.id)); 
    const isSameCliente = selectedData?.length > 0 && selectedData?.every(record => record?.clienteSalidaName === selectedData[0]?.clienteSalidaName); 
    const isSameVendedor = selectedData?.length > 0 && selectedData?.every(record => record?.vendedorName === selectedData[0]?.vendedorName); 
    const hasReposicion = selectedData?.length > 1 && selectedData?.some(record => record.REPOSICION === 1)
    const tipoJobBefore = selectedData[0]?.tipoTrabajo?.match(/\/(\w+)/)?.[1];
    const tipoJobAfter = selectedData[0]?.tipoTrabajo?.match(/^[^/]+/)?.[0];
    const materialOrden = selectedData?.map(record => record?.materialOrden);

    const { data: clientes } = useGetList('clientes', {
        filter: getFilter(selectedData, 'clientes'), 
        meta: { searchable: 'true'}}, { enabled: isSameCliente && isSameVendedor}
    ); //console.log('Clientes encontrado', clientes);
    
    const stockFilter = getFilter(selectedData, 'referencia', materialOrden);

    const { data: stock } = useGetList('stocks', {
        filter: stockFilter, 
        pagination:{ perPage: 50, page: 1 }, 
        sort: { field: 'consecutivo', order: 'ASC'} , 
        meta: { searchable: true}}, { enabled: materialOrden && materialOrden?.length > 0}
    );

    const { data: materiales } = useGetList(
        'materials', { pagination:{ perPage: 50, page: 1 }}, { enabled: isSameCliente && isSameVendedor}
    ); //console.log('materiales--->', materiales);

    const { data: observaciones } = useGetList(
        'sqlObservaciones', { filter: getFilter(selectedData, 'observaciones')}, { enabled: isSameCliente && isSameVendedor}
    );
    console.log("obs",  observaciones, isSameCliente, isSameVendedor)

    const observacionesWithRenamedId = observaciones?.map(observacion => {
        const { id, ...rest } = observacion;
        return {
            ...rest,
            idObserv: id
        };
    });

    let ordenes = [];
    if ( clientes && clientes?.length > 0 && isSameCliente){
        for (let i = 0; i < selectedData.length; i++){
            const sucursales = Sucursales?.find(suc => suc?.oldOP === selectedData[i]?.ciudadSalida)
            let sucursalMod = {}
            if ( sucursales ){
                const { id, ...ciudad } = sucursales;
                sucursalMod = {...ciudad, idCiudad: id}
            };
            let orden = {};
            orden['numero'] = parseInt(selectedData[i]?.opSalida);
            //orden['odc'] = selectedData[i].ordenCP;
            orden['version'] = parseInt(selectedData[i]?.versionSalida);
            orden['applyIva'] = clientes[0]?.datosFacturacion.iva;
            orden['tipoProducto'] = selectTipoProducto(selectedData[i]);
            orden['ciudad'] = sucursalMod;
            orden['observaciones'] = observacionesWithRenamedId?.filter(observacion => observacion?.NUMERO?.toString() === selectedData[i]?.opSalida && observacion?.VERSIONOP?.toString() === selectedData[i]?.versionSalida && observacion?.PRIORIDAD === 1)
            orden['descripcion'] = selectedData[i]?.trabajoSalida;
            if(orden.tipoProducto?.includes('fotopolimero')){
                orden['tipoPlancha'] = tipoJobAfter;
                orden['material'] = selectedData[i]?.refMaterial;
                orden['calibre'] = selectedData[i]?.calibreOrden || 0;
                orden['referenciaPlanchas'] = selectedData[i]?.refPlanchas;
                orden['totalPlanchas'] = selectedData[i]?.totalPlanchas || 0;
                orden['planchasxColor'] = selectedData[i]?.planchasXColor || 0;
                orden['qtyLen'] = selectedData[i]?.lenQty || 0;
            }
           ordenes.push(orden);
        };
    };

    for (let i = 0; i < ordenes?.length; i++){
        let items = getItemsforOrden(ordenes[i], selectedData, stock, clientes, materiales);
        //console.log('items', items);
        ordenes[i].itemsFact = [];
        if(items?.length > 0){
            ordenes[i]?.itemsFact.push(...items);
        };
    };
    
    let itemsFlat = ordenes && ordenes?.length > 0 ? ordenes?.reduce((acc, orden) => {
        if (orden?.itemsFact?.length > 0){
            acc.push(...orden?.itemsFact);
        };
        return acc;
    }, []) : [];

    let state = {
        record: {
            usuarioID: user,
            clienteId: clientes && clientes?.length ? clientes[0]?.id : null,
            vendedorAlegraId: vendedores?.find(vendedor => vendedor?.cedula === selectedData[0]?.clienteSalidaName)?.alegraVendedorId || null, 
            ciudadId : ordenes[0]?.ciudad?.idCiudad || null,
            applyIva : clientes && clientes?.length ? clientes[0]?.datosFacturacion?.iva: false,
            clienteName: clientes && clientes?.length ? clientes[0]?.razonSocial : null,
            alegraClienteId: clientes && clientes?.length ? clientes[0]?.alegraId : null,
            ordenes: ordenes,
            itemsFacts: itemsFlat
        }
    };

    console.log("STATE", state)

    const filterRemision = itemsFlat?.length === 0 || !isSameCliente || !isSameVendedor || hasReposicion;

    return (
        <div>
            <Button variant='contained' disabled={filterRemision} state={state}>
                {`In progress...`}
            </Button>
            {/* <CreateButton label="Crear Remision" resource="remisions" disabled={filterRemision} state={state}/> */}
        </div>
    );
}

const selectTipoProducto = (data) => {
    const tipoPrincipal = data?.tipoTrabajo?.match(/\/(\w+)/)?.[1];
    let productos = [];    
    if(tipoPrincipal === 'FOTOPOLIMERO' ){
        productos.push('fotopolimero');
    }
    if(tipoPrincipal === 'LENS' ){
        productos.push('lens');
    }
    if(tipoPrincipal === 'PRUEBA'){
        productos.push('prueba');
    }
    if(tipoPrincipal === 'ARTE'){
        if(data.DIFIARTE === 3){
            productos.push('arte_bajo');
        }
        else if(data.DIFIARTE === 2){
            productos.push('arte_medio');
        }
        else if(data.DIFIARTE === 1){
            productos.push('arte_alto');
        } else {
            productos.push('arte');
        }
    }
    if(tipoPrincipal === 'REPOSICION'){
        productos.push('reposicion');
    };
    return productos;
};

const getValuesFromProduct = (cliente, tipoProductoStr ) => {
    return  cliente[0]?.datosFacturacion?.precioOrden?.find(precio => precio?.tipoOrden === tipoProductoStr)?.valor; 
};

const getFilter = (selectedData, type, materialOrden, tipoJobBefore = []) => {
    if(type === 'planchas'){
        const filterPlanchas = {
            or: selectedData?.map(record => ({
                and: [
                    { ORDEN: record?.opSalida },
                    { VERSIONOP: record?.versionSalida }
                ]
            }))
        };
        return filterPlanchas;
    };
    if(type === 'observaciones'){
        const filterObserv = {
            or: selectedData?.map(record => {
                console.log(record)
                return({
                    NUMERO: parseInt(record?.opSalida),
                    VERSIONOP: parseInt(record?.versionSalida) 
              })}, [])
            
        };
        console.log(filterObserv)
        return filterObserv;
    };
    if(type === 'clientes') {
        const filterClientes = {
            nit_cedula: { match: selectedData && selectedData?.length > 0 ? selectedData[0]?.clienteSalidaId?.trim() : null}
        }
        return filterClientes;
    };
    if(type === 'referencia') {
        let filterReferencias = {};
        const tipoTrabajo = selectedData?.reduce((acc, record) => {
            const tipo = selectTipoProducto(record);
            for (let i = 0; i < tipo.length; i++){
                if(acc.find(item => item === tipo[i]) === undefined){
                    acc.push(tipo[i]);
                }
            }
            return acc;
        }, []) || []; 
        if(tipoTrabajo?.includes('fotopolimero', 'prueba')){
            let referencias = []
            if (materialOrden && materialOrden?.length > 0) {
                referencias = selectedData?.reduce((acc) => {
                    if(tipoJobBefore){
                        const refName = selectedData[0]?.refMaterial;
                        if(acc?.find(item => item === refName) === undefined){
                            acc?.push(refName);
                        }
                    }
                    return acc;
                }, []);
            };
            if (tipoTrabajo?.includes('prueba')){
                const ciudad = selectedData[0]?.ciudadSalida;
                const ciudad2 = Sucursales.find(suc => suc?.oldOP === ciudad)?.short;
                referencias?.push(`${ciudad2}-PRUEBA`);
            } if (referencias.length>1) {
                filterReferencias = {
                    or: referencias?.map(referencia => ({ and:{ id: { matchPhrase: referencia}, activa: { eq: true}}}))
                };
            } else {
                filterReferencias = {
                    and: { id: { matchPhrase: referencias[0]}, activa: { eq: true}} 
                };
            };
        };
        return filterReferencias;
    }
};

const getItemsforOrden = (orden, selectedData, stock, clientes, materiales) => {
    let items = [];
    if (orden?.tipoProducto?.includes('fotopolimero') && selectedData && selectedData?.length > 0) {
        let medidas = [];
        medidas = selectedData?.filter(data => parseInt(data?.opSalida) === orden?.numero && parseInt(data?.versionSalida) === orden?.version);
        const medidasReduced = medidas?.reduce((acc, data) => {
            data.materialOrden?.forEach(item => {
                const foundIndex = acc?.findIndex(accItem => 
                    ((accItem?.anchoSalida === item?.anchoSalida && accItem?.largoSalida === item?.largoSalida) ||
                    (accItem?.largoSalida === item?.anchoSalida && accItem?.anchoSalida === item?.largoSalida)) && 
                    accItem?.cajaExpo === item?.cajaExpo
                );

                if (foundIndex !== -1) {
                    acc[foundIndex].cantidadPlanchas += item?.cantidadPlanchas;
                    acc[foundIndex].area = acc[foundIndex].anchoSalida * acc[foundIndex].largoSalida * acc[foundIndex].cantidadPlanchas;
                } else {
                    item.anchoSalida = item.anchoSalida;
                    item.largoSalida = item.largoSalida;
                    item.cantidadPlanchas = item.cantidadPlanchas;
                    item.cajaExpo = item.cajaExpo;
                    item.area = item.anchoSalida * item.largoSalida * item.cantidadPlanchas;
                    acc.push({ ...item });  
                }
            });
            return acc;
        }, []);

        items = medidasReduced?.map(item => {
            const stockIndex = stock?.findIndex(s => {
                const idParts = s.id?.split('-');
                const ordenMaterial = orden?.material?.split('-')[1];
                const planchaNumber = item?.cajaExpo?.toString()?.trim();
                let extractedNumbers = planchaNumber?.match(/\d+/); 
                let numberStr = extractedNumbers ? extractedNumbers[0] : "";    
                const result = idParts[2] === numberStr;
                if (idParts[1] === ordenMaterial && result) {
                    return result;
                }
            });

            let vUnit = getValuesFromProduct(clientes, orden?.tipoPlancha);

            if (orden?.tipoProducto?.includes('reposicion')) {
                vUnit = 0;
            };

            return {
                idItem: orden?.numero+Math.floor(Math.random() * 100),
                alegraItemId: materiales?.find(material => material.id === orden.material)?.alegraId || null,
                odc: orden.odc,
                orden: orden.numero,
                iva: orden.applyIva, 
                type: 'fotopolimero',
                material: orden.material,
                calibre: orden.calibre, 
                ordenVersion: orden.numero + "-" + orden.version,
                descripcion: orden.descripcion,
                valorUnitario: vUnit,
                version: orden.version,
                ancho: item.anchoSalida,
                largo: item.largoSalida,
                cantidad: item.cantidadPlanchas,
                cajaNro: item.cajaExpo?.toString().match(/\d+/)[0] || 0,
                caja: stock && stock.length > 0 && stockIndex !== -1 ? stock[stockIndex].id : stock && stock.length > 0 ? stock[0].id : 'no encontrada',
                area: item.anchoSalida * item.largoSalida * item.cantidadPlanchas, 
                valorTotal: item.anchoSalida * item.largoSalida * item.cantidadPlanchas * vUnit
            };
        })
    };
    if(orden.tipoProducto.includes('prueba')) {
        let vUnit = getValuesFromProduct(clientes, 'PRUEBA_CONTRATO');
        const maxStock = stock?.reduce((prev, current) => {
            if(current?.id?.includes('PRUEBA')){
                return(prev && prev.consecutivo > current.consecutivo) ? prev : current;
            };
            return prev;
        }, {});
        const alegraItemId = materiales?.find(material => material?.id === `${orden?.ciudad.short}-PRUEBA-COLOR`) || null;
        const item = {
            idItem: orden.numero+'-pc' + Math.floor(Math.random() * 100),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva,
            type: 'prueba',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit || 28,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: maxStock?.id || `${orden?.ciudad.short}-PRUEBA_COLOR-1`,
            area: 0, 
            valorTotal: vUnit || 28 
        };
        items.push(item);
    };
    if (orden.tipoProducto.includes('arte_alto')){
        const vUnit = getValuesFromProduct(clientes, 'ARTE_ALTA');
        const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-ARTE`) || null;
        const item = {
            idItem: orden.numero + '-aa' + Math.floor(Math.random() * 100),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva,
            type: 'arte_alto',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: '',
            area: 0, 
            valorTotal: vUnit
        };    
        items.push(item);
    };
    if (orden.tipoProducto.includes('arte_medio')){
        const vUnit = getValuesFromProduct(clientes, 'ARTE_MEDIA');  
        const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-ARTE`) || null;
        const item = {
            idItem: orden.numero + '-am' + Math.floor(Math.random() * 100),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            //odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva,
            type: 'arte_medio',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: '',
            area: 0, 
            valorTotal: vUnit
        };    
        items.push(item);
    };
    if (orden.tipoProducto.includes('arte_bajo')){
        let vUnit = getValuesFromProduct(clientes, 'ARTE_BAJA'); 
        const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-ARTE`).alegraId || null;    
        const item = {
            idItem: orden.numero + '-ab' + Math.floor(Math.random() * 100),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva,
            type: 'arte_bajo',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: '',
            area: 0, 
            valorTotal: vUnit
        };    
        if (orden.tipoProducto.includes('lens')){
            const vUnit = getValuesFromProduct(clientes, 'LENS');   
            const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-LENS`).alegraId || null;
            const item = {
                idItem: orden.numero + '-len' + Math.floor(Math.random() * 100),
                alegraItemId: alegraItemId? alegraItemId.alegraId : null,
                odc: orden.odc,
                orden: orden.numero,
                iva: orden.applyIva,
                type: 'len',
                material: alegraItemId?.id,
                ordenVersion: orden.numero + "-" + orden.version,
                descripcion: orden.descripcion,
                valorUnitario : vUnit,
                version: orden.version,
                ancho: 0,
                largo: 0,
                cantidad: 1,
                cajaNro: 0,
                caja: '',
                area: 0, 
                valorTotal: vUnit
            };    
            items.push(item);
        };
        items.push(item);
    };
    return items;
};

    

