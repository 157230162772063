import{ 
    Edit,
    TextInput, 
    DateInput, 
    SimpleForm,
    NumberInput,
} from 'react-admin';
import { Grid } from '@mui/material';

const editTransform = (data) => {

    console.log(data)
    return(
        {   
            id: data.id,
            opSalida: data.op,
            versionSalida: data.version,
            clienteSalida: data.clienteSalida,
            trabajoSalida: data.trabajoSalida,
            ciudadSalida:  data.ciudadSalida,
            totalPlanchas: data.totalPlanchas,
            tipoTrabajo: data.tipoTrabajo,
            fechaSalida: data.fechaSalida,
            disenhaFac: data.disenhaFac,
        }
    )
};  

export const SalidaOrdenEdit = () => (
    <Edit transform={editTransform}>
        <SimpleForm>
            <Grid container spacing={{ xs: 2, md: 3 }} flexDirection="row" justifyContent="space-evenly">
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <NumberInput label='OP' source="opSalida" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <NumberInput label='version' source="versionSalida" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextInput label='cliente' source="clienteSalida" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextInput label='Nombre Trabajo' source="trabajoSalida" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextInput label='Ciudad' source="ciudadSalida" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <NumberInput label='Planchas' source="totalPlanchas" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextInput label='Tipo Trabajo' source="tipoTrabajo" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <DateInput label='Salida Produccion' source="fechaSalida" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextInput label='Vendedor' source="disenhaFac" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);