import{
    List,
    Datagrid,
    TextField,
    ReferenceField
} from 'react-admin'


const UserList = (props)=>{
    return(
        <List queryOptions={{meta:{searchable:'true', filterable: ['nombres', 'apellidos']}}}>
            <Datagrid rowClick="edit">
                <TextField source="cedula" />
                <TextField source="nombres" /> 
                <TextField source="apellidos" /> 
                <TextField source="sedes" />
            </Datagrid>
        </List>
    )
}
export default UserList