
 import { TextField, DateField  , NumberField, Datagrid, List, 
    SingleFieldList, useRecordContext, Labeled, FunctionField, Pagination,
    DateInput, Loading, AutocompleteInput, useGetList, ReferenceArrayField, 
} from "react-admin"

import Summary from './summary'

import {  Chip, Grid } from '@mui/material';


import Avatar from '@mui/material/Avatar';
import { useForm, FormProvider } from 'react-hook-form';

const TiposMarcaciones=[
    {
        id:'INICIO_TURNO',
        name: 'Inicio Turno',
        short: 'IT',
        variant: 'filled',
        color: '#14fa27'
    },
    {
        id:'INICIO_PAUSA',
        name: 'Inicio Pausa', 
        short: 'IP',
        variant: 'filled',
        color: '#02f2fa'
    },
    {
        id:'FIN_PAUSA',
        name: 'Fin Pausa',
        short: 'FP',
        variant: 'outlined',
        color: '#02d7de'
    },
    {
        id:'FIN_TURNO',
        name: 'Fin Turno',
        short: 'FT',
        variant: 'outlined',
        color: '#10de21'
    }
]

const Details = () => {
    const record = useRecordContext();  

    return (    
        <Grid container spacing={1}>
            <Grid item container xs={12} md={3} justifyItems={'space-between'} alignContent={'center'}>
                    <ReferenceArrayField source="marcacionesIds" reference="marcacions" label="Marcaciones" fullWidth>
                        <SingleFieldList label="Marcaciones">
                            <FunctionField source="fechaHora"  render={record=>{
                                const dateTime = new Date(record.fechaHora)
                                const tipo = TiposMarcaciones.find(tipo=>tipo.id===record.type).short
                                const color = TiposMarcaciones.find(tipo=>tipo.id===record.type).color
                                const variant = TiposMarcaciones.find(tipo=>tipo.id===record.type).variant
                                return (
                                    <Chip sx={{margin:'1px'}}label = {dateTime.toLocaleTimeString()} avatar={<Avatar sx={{ bgcolor: `${color}`}}><b>{tipo}</b></Avatar>} variant="outlined" />
                                )
                            }}/>
                        </SingleFieldList>
                    </ReferenceArrayField>
            </Grid>

            <Grid item container xs={12} justifyContent={'space-between'} alignContent={'center'} md={9}>
                <Grid item xs={4} md={1.4} variant="outlined" >
                    <Labeled alignContent="center">
                        <NumberField  source="hOrdinaria" label={"Hora Ordinaria"} alignSelf="center"/>
                    </Labeled>
                </Grid>
                <Grid item xs={4} md={1.4}>
                    <Labeled>
                    <NumberField source="hExtraDiurna" label="Horas Extras Diurnas" alignSelf="center"/>
                    </Labeled>
                </Grid>
                <Grid item xs={4} md={1.4}>
                <Labeled >
                    <NumberField source="rNocturno" sx={{minHeight:"100%"}} label="Recargo Nocturno" alignSelf="center"/>
                </Labeled>
                </Grid>
                <Grid item xs={4} md={1.4}>
                    <Labeled>
                        <NumberField source="hExtraNocturna" label = "Horas Extras Nocturnas" alignSelf="center"/>
                    </Labeled>
                </Grid>
                <Grid item xs={4} md={1.4}>
                    <Labeled>
                        <NumberField source="hOrdinariaDominical" label ="Hora Ordinaria Dominical Diurna" alignSelf="center"/>
                    </Labeled>
                </Grid>
                <Grid item xs={4} md={1.4}>
                    <Labeled>
                        <NumberField source="rDominicalNocturno"  label="Recargo Dominical Nocturno" alignSelf="center"/>
                    </Labeled>
                </Grid>
                <Grid item xs={4} md={1.4}>
                    <Labeled>
                        <NumberField source="hExtraDominicalDiurna" label = "Hora Extra Dominical" alignSelf="center"/>
                    </Labeled>
                </Grid>
                <Grid item xs={4} md={1.4}>
                    <Labeled>
                        <NumberField source="hExtraDominicalNocturna" label = "Hora Extra Dominical Nocturna" alignSelf="center"/>
                    </Labeled>
                </Grid>
            </Grid>
        </Grid> 
            
    )
}

const ListPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 200]} />;


export const TurnosList = ()=>{
    return(
    <List resource="turnos" filters={TurnoFilter} queryOptions={{meta:{searchable:true}}} pagination={<ListPagination/>} > 
        <Datagrid expand={<Details/>} expandSingle >
            <TextField source="userName" label='Usuario'/>
            <DateField source="inicio"   showTime={true}/>
            <DateField source="fin"   showTime={true}/>
            <NumberField source="totalHoras" label="Total Horas"/>
            <NumberField source="horasLaboradas" label="Horas Laboradas" />
            <NumberField source="hExtrasTotal" label="HORAS EXTRAS"/>
            <NumberField source="tPausas" label="PAUSAS"/>
            <NumberField source="hPendiente" label="HORAS PENDIENTES"/>
            <NumberField source="TotalHorasExtrasConRecargos" label="TOTAL CON RECARGOS"/>
        </Datagrid>
        <Summary/>
      </List>

    )
}

// const TurnoFilter = () => {
//     const { data, isLoading } = useGetList(
//         'Usuarios',
//         { pagination: { page: 1, perPage: 10 } },
//     );
//     const { displayedFilters, filterValues, setFilters, hideFilter }  =  useListContext();
//     const form = useForm({
//         defaultValues:  filterValues,
//     });
//     const onSubmit = (values) => {
//         if (Object.keys(values).length > 0) {
//             setFilters(values);
//         } 
//     }
//     const choices = data?.map(value => ({ id: value.id, name: `${value.nombres} ${value.apellidos}` }));
//     console.log("choices")
//     console.log(choices)

//     ///fix filters
//     if(isLoading){
//         return <Loading/>;
//     }
//     return (
//         <FormProvider {...form}>
//             <form onSubmit={form.handleSubmit(onSubmit)}>
//                 <Box display="flex" alignItems={"flex-start"} minWidth="100%">
//                     <Stack direction="row" spacing={5} justifyItems={"right"}>
//                         <AutocompleteInput  source='userId.eq' label="Usuario" choices={choices}  fullWidth  size="small"/>
//                         <DateInput source="inicio.gt" label="Inicio"  parse={v=>new Date(v).toISOString()}   />
//                         <DateInput source="fin.lt" label="Fin"   />
//                         <Button variant="outlined" color="primary" type="submit" >
//                             Filtrar
//                         </Button>


//                     </Stack>
//                 </Box >
//             </form>
//         </FormProvider>
//     )
// }


const AutocompleteInputCustom = (props) => {
    const { data, isLoading } = useGetList(
        'Usuarios',
        { pagination: { page: 1, perPage: 100 } },
    );
    const choices = data?.map(value => ({ id: value.id, name: `${value.nombres} ${value.apellidos}` }));
    if(isLoading){
        return <Loading/>;
    }
    return (
        <AutocompleteInput  choices={choices} {...props} />
    )
}

const TurnoFilter = [
    <AutocompleteInputCustom source='userId.eq' label="Usuario" fullWidth sx={{ minWidth: "250px" }} size="small" alwaysOn />,
    <DateInput source="inicio.gte" label="Inicio" alwaysOn />,
    <DateInput source="inicio.lte" label="Fin" alwaysOn />,
]




/*

        <Filter>
            <AutocompleteInput  choices={choices}  alwaysOn/>
        </Filter>,

*/ 



/*
type Turno @model @auth(rules: [{allow: public}]) {
    id: ID!
    marcacionesIds: [ID]
    Marcaciones: [Marcacion] @hasMany(indexName: "byTurno", fields: ["id"])
    userId: ID
    userName: String
    inicio: AWSDateTime
    fin: AWSDateTime
    tPausas: Float
    totalHoras: Float
    hOrdinaria: Float
    rNocturno: Float
    rDominicalNocturno: Float
    hExtraDiurna: Float
    hOrdinariaDominical: Float
    hExtraDominicalDiurna: Float
    hExtraDominicalNocturna: Float
    hExtraNocturna: Float
    hExtrasTotal: Float
    hLibre: Float
    hPendiente: Float
    TotalHorasExtrasConRecargos: Float
  }*/



//onEventReceived={handleEventReceived} 