import{ 
    Create,
    TextInput, 
    useGetList,
    SimpleForm,
    SelectInput,
    NumberInput,
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import { Grid } from '@mui/material';

export const SalidaOrdenCreate = () => (
  
  <Create redirect="list">
    <SimpleForm>
      <Grid container spacing={{ xs: 2, md: 3 }} flexDirection="row" justifyContent="space-evenly">
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput fullWidth label='Op' source="opSalida" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput fullWidth label='Version' source="versionSalida" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} size='xs' >
          <ClienteInput fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput fullWidth label='Nombre Trabajo' source="trabajoSalida" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} >
          <SelectInput 
            fullWidth 
            source='ciudadSalida'
            choices={[
              { id: 'Mede', name: 'ENVIGADO' },
              { id: 'Barr', name: 'BARRANQUILLA' },
              { id: 'Cali', name: 'CALI' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <NumberInput fullWidth label='Planchas' source="totalPlanchas" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput fullWidth label='Tipo Trabajo' source="tipoTrabajo" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <DateTimeInput fullWidth label='Salida Produccion' source="fechaSalida" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} size='xs'>
          <VendedorInput fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

const VendedorInput = () => {

  const { data: vendedores } = useGetList('usuarios', {
    filter: {}
  });

  const vendedoresConAlegra = vendedores?.filter(item => item.alegraVendedorId);
  console.log('Vendedores Con Alegra', vendedoresConAlegra);

  return(
    <ReferenceInput source='vendedorId' reference='usuarios' >
      <AutocompleteInput
        fullWidth
        label="Vendedor"
        source='vendedorId'
        optionText='nombres'
        choices={vendedoresConAlegra ? Object.values(vendedoresConAlegra) : []}
        onChange={value => console.log(`Usuario Seleccionado--->${value}`)}
      />
    </ReferenceInput>
  )
};

const ClienteInput = () => {

  const { data: clientes } = useGetList('clientes', {
    pagination: { page: 1, perPage: 250 },
    sort: { field: 'id', order: 'ASC' },
    meta: { searchable: 'true' },
  });

  return(
    <ReferenceInput source='clienteSalidaId' reference='clientes' >
      <AutocompleteInput
        fullWidth
        label="Cliente"
        source='clienteSalidaId'
        optionText='razonSocial'
        choices={clientes ? Object?.values(clientes) : []}
        onChange={value => console.log(`Usuario Seleccionado--->${value}`)}
      />
    </ReferenceInput>
  )
};