import React from "react";
import {  CreateButton, useListContext, useGetList } from "react-admin";
import { Sucursales } from "../../utils";
import LinearProgress from '@mui/material/LinearProgress';


export const SalidasActions = ({vendedores}) => {
    const { data, selectedIds} = useListContext();    
   //console.log('DATA', data)
    const selectedData = data.filter(record => selectedIds.includes(record.id));
    const isSameCliente = selectedData.length > 0 && selectedData.every(record => record.CLIENTE === selectedData[0].CLIENTE);
    const isSameVendedor = selectedData.length > 0 && selectedData.every(record => record.DISENHAFAC === selectedData[0].DISENHAFAC);
    const isFotopolimero = selectedData.length > 0 && selectedData.some(record => record.FOTOPOLIM === 1);
    const hasReposicion = selectedData.length > 1 && selectedData.some(record => record.REPOSICION === 1)
    //console.log('   VENDEDORES', vendedores)
    const user = localStorage.getItem('user')
    //console.log(getFilter(selectedData, 'clientes'))
    const [pendingSomething, setPendingSomething] = React.useState(false);
     
    const { data: clientes, isLoading: pendingClientes } = useGetList('clientes', {filter: getFilter(selectedData, 'clientes'), meta: {searchable: 'true'}}, {enabled: isSameCliente&&isSameVendedor});
    //console.log("CLIENTES", clientes)
    const { data: observaciones , isLoading: pendingObservacion} = useGetList('sqlObservaciones', {filter:getFilter(selectedData, 'observaciones')}, {enabled: isSameCliente&&isSameVendedor});

    const observacionesWithRenamedId = observaciones?.map(observacion => {
        const { id, ...rest } = observacion;
        return {
            ...rest,
            idObserv: id
        };
    });

    
    
    const { data: planchas, isLoading: pendingPlanchas} = useGetList('sqlplanchasop', {pagination: { page: 1, perPage: 100 },filter:getFilter(selectedData, 'planchas')}, {enabled: isSameCliente && isFotopolimero&&isSameVendedor});
    
    //console.log("planchas", planchas)

    const stockFilter = getFilter(selectedData, 'referencia', planchas );
    //console.log("STOCKFILTER", stockFilter)
    const { data: stock , isLoading: pendingStock} = useGetList('stocks', {filter: stockFilter, pagination:{perPage:200, page:1}, sort:{field:'consecutivo', order:'ASC'} , meta:{searchable:true}}, {enabled: planchas&&planchas.length > 0});
    
    const { data: materiales, isLoading: pendingMateriales } = useGetList('materials', { pagination:{perPage:300, page:1}}, {enabled: isSameCliente&&isSameVendedor});
    
    React.useEffect(() => {
        if (pendingClientes || pendingPlanchas || pendingStock || pendingMateriales || pendingObservacion) {
            setPendingSomething(true);
        } else {
            setPendingSomething(false);
        }

    }, [pendingClientes, pendingPlanchas, pendingStock, pendingMateriales, pendingObservacion]);

 
    let ordenes = []
    if ( clientes && clientes?.length > 0 && isSameCliente){
        const calibres = [0, 45, 67, 100, 112, 155, 95, 80, 94, 73];

        for (let i = 0; i < selectedData.length; i++){
            const sucursales = Sucursales.find(suc => suc.oldOP === selectedData[i].CIUDAD)
            let sucursalMod = {}
            if ( sucursales ){
                const { id, ...ciudad } = sucursales;
                sucursalMod = {...ciudad, idCiudad: id}

            }
            let orden = {}
            orden['numero'] = selectedData[i].NUMERO
            orden['odc'] = selectedData[i].ORDENCPR
            orden['version'] = selectedData[i].VERSIONOP
            orden['applyIva'] = clientes[0].datosFacturacion.iva
            orden['tipoProducto'] = selectTipoProducto(selectedData[i])
            orden['ciudad'] = sucursalMod;
            orden['observaciones'] = observacionesWithRenamedId?.filter(observacion => observacion.NUMERO === selectedData[i].NUMERO && observacion.VERSIONOP === selectedData[i].VERSIONOP && observacion.PRIORIDAD === 1)
            orden['descripcion'] = selectedData[i].TRABAJO
            if(orden.tipoProducto.includes('fotopolimero')){
                orden['tipoPlancha'] = selectTipoPlancha(selectedData[i], orden)
                //console.log('TIPO PLANCHAS', orden['tipoPlancha'])
                orden['material'] = getReferenciaName(selectedData[i])
                orden['calibre'] = calibres[selectedData[i].COORDCALIBRE]
                orden['referenciaPlanchas'] = selectedData[i].FOTOPREF1
                orden['totalPlanchas'] = selectedData[i].TOTALPLAN || 0;
                orden['planchasxColor'] = selectedData[i].PLANCOLOR || 0;
                orden['qtyLen'] = selectedData[i].LEN || 0;
            }
           //console.log('ORDEN', orden)
            ordenes.push(orden)
        }

    }    

    
    for (let i = 0; i < ordenes.length; i++){
        let items = getItemsforOrden(ordenes[i], planchas, stock, clientes, materiales);
        ordenes[i].itemsFact = []
       //console.log('ITEMS', items)
        if(items?.length > 0){
            ordenes[i].itemsFact.push(...items);
        }
    }

   
    let itemsFlat = ordenes&&ordenes.length > 0 ? ordenes.reduce((acc, orden) => {
        if (orden.itemsFact.length > 0){
            acc.push(...orden.itemsFact);
        }

        return acc;
    }, []) : [];

    const emails =  clientes && clientes.length && clientes[0].datosFacturacion.emailFE ? clientes[0].datosFacturacion.emailFE.split(',') : []
    const emailsTrim = emails&&emails.length >0 ? emails.map(email=>email.trim()):[]

    let state = {
        record: {
            usuarioID: user,
            clienteId: clientes && clientes.length ? clientes[0].id : null,
            vendedorAlegraId: vendedores?.find(vendedor => vendedor.cedula === selectedData[0]?.DISENHAFAC)?.alegraVendedorId || null, 
            vendedorID: vendedores?.find(vendedor => vendedor.cedula === selectedData[0]?.DISENHAFAC)?.id || null, 
            ciudadId : ordenes[0]?.ciudad?.idCiudad || null,
            applyIva : clientes && clientes.length ? clientes[0].datosFacturacion.iva: false,
            clienteName: clientes && clientes.length ? clientes[0].razonSocial : null,
            clientData: clientes && clientes.length ? clientes[0] : null,
            alegraClienteId: clientes && clientes.length ? clientes[0].alegraId : null,
            ordenes: ordenes,
            formaPago: clientes && clientes.length ? clientes[0].datosFacturacion.formaPago : null,
            itemsFact: itemsFlat,
            emails: emailsTrim || [],
        }
    };
    if (clientes && clientes[0]?.cotiza ){
        //console.log("COTIZA", clientes[0])
        state.record['formaPago'] = clientes[0].datosFacturacion.formaPago;
    }



   //console.log("STATE",state)
    return (
        pendingSomething ? <div>Cargando...</div> :
        <div>{
                clientes && !clientes[0]?.cotiza ? 
                <>
                    <CreateButton label="Crear Remision" resource="remissions" disabled={ itemsFlat?.length===0 || !isSameCliente || !isSameVendedor || hasReposicion || pendingSomething} state={state}/>
                    <CreateButton label="Crear Factura" resource="facturas" disabled={ itemsFlat?.length===0 || !isSameCliente || !isSameVendedor || hasReposicion || pendingSomething} state={state}/>
                </>
                : <CreateButton label="Crear Cotización" resource="cotizacions" disabled={ itemsFlat?.length===0 || !isSameCliente || !isSameVendedor || hasReposicion || pendingSomething} state={state}/>
            }
        </div>
    );
}

const selectTipoProducto = (data) => {
    //console.log('data', data);
    let productos = []
    if(data.FOTOPOLIM ){
        productos.push('fotopolimero');
    }
    if(data.LENS){
        productos.push('lens');
    }
    if(data.PRUEBA){
        productos.push('prueba');
    }
    if(data.ARTE){
       //console.log('ARTE', data.ARTE)
       //console.log('DIFIARTE', data.DIFIARTE)
        if(data.DIFIARTE === 3){
            productos.push('arte_bajo');
        }
        else if( data.DIFIARTE === 2){
            productos.push('arte_medio');
        }
        else if( data.DIFIARTE === 1 ){
            productos.push('arte_alto');
        }
    }
    if(data.REPOSICION){
        productos.push('reposicion');
    }
    return productos;
}

const  selectTipoPlancha = (selectedData, orden) => {
    //console.log('SELECTED DATA', selectedData)
    if (selectedData.FINALIZA){

        if(selectedData.TRESCOLORES){
        return 'FINALIZACION_BAJA'
        } else {
            return 'FINALIZACION_ALTA'
        }
    }
    else if(selectedData.OPENPRINT){
        return 'OPENPRINT'
    }
    else if(selectedData.COPIADO){
        return 'COPIADO'
    }
    else if(selectedData.REPITE){
        return 'REPETICION'
    }
    else if(selectedData.LEN){
        return 'LEN'
    }
    else{
        return ''
    }
}

const getValuesFromProduct = (cliente, tipoProductoStr ) => {
    return  cliente[0]?.datosFacturacion?.precioOrden.find(precio => precio.tipoOrden === tipoProductoStr)?.valor; 
}

const getFilter = (selectedData, type, planchas = []) => {

    if(type === 'planchas' ){
        const filterPlanchas = {
            or: selectedData?.map(record => ({
                and: [
                    { ORDEN: record.NUMERO },
                    { VERSIONOP: record.VERSIONOP }
                ]
            }))
        };
        return filterPlanchas;
    }
    if(type === 'observaciones' ){
        const filterObserv = {
            or: selectedData?.map(record => ({
                 NUMERO: record.NUMERO ,
                 VERSIONOP: record.VERSIONOP 
            }), [])
        };
        return filterObserv;
    }

    if (type === 'clientes' ){
        const filterClientes = {
            nit_cedula:{match: selectedData&&selectedData.length>0 ? selectedData[0].CODCLIENTE.trim() : null}
        }
        return filterClientes;
    }
    if(type === 'referencia' ){
        let filterReferencias = {};
        const tipoTrabajo = selectedData?.reduce((acc, record) => {
            const tipo = selectTipoProducto(record);
            for (let i = 0; i < tipo.length; i++){
                if(acc.find(item => item === tipo[i]) === undefined){
                    acc.push(tipo[i]);
                }
            }
            return acc;
        }, []) || [];
        if(tipoTrabajo.includes('fotopolimero', 'prueba')){
            let referencias = []
            if (planchas && planchas.length > 0) {
                referencias = selectedData.reduce((acc, record) => {
                    if(record.FOTOPOLIM){
                        const refName = getReferenciaName(record);
                        if(acc.find(item => item === refName) === undefined){
                            acc.push(refName);
                        }
                    }
                    return acc;
                }, []);
            }
            if (tipoTrabajo.includes('prueba')){
                const ciudad = selectedData[0].CIUDAD;

                const ciudad2 = Sucursales.find(suc => suc.oldOP === ciudad).short;
                //console.log('CIUDAD2', ciudad2)
                referencias.push(`${ciudad2}-PRUEBA`);
            }
            if (referencias.length>1) {
                filterReferencias = {
                    or: referencias.map(referencia => ({and:{id: {matchPhrase: referencia}, activa: {eq: true}}}))
                };
            }
            else{
                filterReferencias = {
                    and: {id: {matchPhrase: referencias[0]}, activa: {eq: true} } 
                    };
                }
            }
        return filterReferencias;
    }
}
//{and: {activa: {eq: true}, materialID: {eq: material}}}



const getItemsforOrden = (orden, planchas, stock, clientes, materiales)=>{
    let items = [];
    if(orden.tipoProducto.includes('fotopolimero') && planchas && planchas.length > 0 ){
        let medidas = [];
        medidas = planchas.filter(plancha => plancha.ORDEN === orden.numero && plancha.VERSIONOP === orden.version)
        
        const medidasReduced = medidas?.reduce((acc, item) => {
            const foundIndex = acc.findIndex(accItem => 
                ((accItem.ANCHO === item.ANCHO && accItem.AVANCE === item.AVANCE) ||
                (accItem.AVANCE === item.ANCHO && accItem.ANCHO === item.AVANCE)) && 
                accItem.NROCAJA === item.NROCAJA
            );

            if (foundIndex !== -1) {
                acc[foundIndex].CANTIDAD += item.CANTIDAD;
                acc[foundIndex].area = acc[foundIndex].ANCHO * acc[foundIndex].AVANCE * acc[foundIndex].CANTIDAD;
            } else {
                item.area = item.ANCHO * item.AVANCE * item.CANTIDAD;
                acc.push({ ...item });  
            }
            return acc;
        }, []);

        items = medidasReduced.map(item => {
            const stockIndex = stock?.findIndex(s => {
                const idParts = s.id.split('-');
                const ordenMaterial = orden.material.split('-')[1];

                //console.log('ORDEN MATERIAL', ordenMaterial)
                //console.log('IDPARTS', idParts)
                const planchaNumber = item.NROCAJA.trim();
                let extractedNumbers = planchaNumber.match(/\d+/); 
                let numberStr = extractedNumbers ? extractedNumbers[0] : "";    
                const result = idParts[2] === numberStr;
                if (idParts[1]=== orden.material.split('-')[1] && result){
                    return result;
                }
            });
            //console.log('STOCK INDEX', stockIndex)
                
            let vUnit = getValuesFromProduct(clientes, orden.tipoPlancha);
            
            if(orden.tipoProducto.includes('reposicion')){
                vUnit = 0;
            }

            return {
                idItem: item.id,
                alegraItemId:  materiales?.find(material => material.id === orden.material)?.alegraId || null,
                odc: orden.odc,
                orden: item.ORDEN,
                iva: orden.applyIva, 
                type: 'fotopolimero',
                material: orden.material,
                calibre: orden.calibre, 
                ordenVersion: item.ORDEN + "-" + item.VERSIONOP,
                descripcion: orden.descripcion,
                valorUnitario : vUnit,
                version: item.VERSIONOP,
                ancho: item.ANCHO,
                largo: item.AVANCE,
                cantidad: item.CANTIDAD,
                cajaNro: item.NROCAJA?.match(/\d+/)[0]|| 0,
                caja: stock && stock.length > 0 && stockIndex!==-1 ? stock[stockIndex].id : stock && stock.length > 0 ? stock[0].id : 'no encontrada',
                area: item.ANCHO*item.AVANCE*item.CANTIDAD, 
                valorTotal: item.ANCHO * item.AVANCE * item.CANTIDAD * vUnit
                };
            }
        );
    }
    if (orden.tipoProducto.includes('prueba') ){
        let vUnit = getValuesFromProduct(clientes, 'PRUEBA_CONTRATO');  
        
        const maxStock = stock?.reduce((prev, current) => {
            if(current?.id?.includes('PRUEBA')){
                return (prev && prev.consecutivo > current.consecutivo) ? prev : current;
            }
            return prev;
        }, {}) //returns object
        const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-PRUEBA_COLOR`) || null;
        const item = {
            idItem: orden.numero+'-pc'+Math.floor(Math.random() * 1000),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva, 
            type: 'prueba',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit || 28,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: maxStock?.id || `${orden?.ciudad.short}-PRUEBA_COLOR-1`,
            area: 0, 
            valorTotal: vUnit || 0 //// change when querying material or add to stock
        };    
        items.push(item);
    }
    if (orden.tipoProducto.includes('arte_alto')){
       //console.log('ARTE ALTO')
        const vUnit = getValuesFromProduct(clientes, 'ARTE_ALTA');
        const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-ARTE`) || null;
        const item = {
            idItem: orden.numero + '-aa' + Math.floor(Math.random() * 100),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva,
            type: 'arte_alto',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: '',
            area: 0, 
            valorTotal: vUnit
        };    
        items.push(item);
    }
    if (orden.tipoProducto.includes('arte_medio')){
        const vUnit = getValuesFromProduct(clientes, 'ARTE_MEDIA');  
        const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-ARTE`) || null;
   
        const item = {
            idItem: orden.numero + '-am' + Math.floor(Math.random() * 100),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva,
            type: 'arte_medio',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: '',
            area: 0, 
            valorTotal: vUnit
        };    
        items.push(item);
    }
    if (orden.tipoProducto.includes('arte_bajo')){
        let vUnit = getValuesFromProduct(clientes, 'ARTE_BAJA'); 
        const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-ARTE`) || null;   
        const item = {
            idItem: orden.numero + '-ab' + Math.floor(Math.random() * 100),
            alegraItemId: alegraItemId? alegraItemId.alegraId : null,
            odc: orden.odc,
            orden: orden.numero,
            iva: orden.applyIva,
            type: 'arte_bajo',
            material: alegraItemId?.id,
            ordenVersion: orden.numero + "-" + orden.version,
            descripcion: orden.descripcion,
            valorUnitario : vUnit,
            version: orden.version,
            ancho: 0,
            largo: 0,
            cantidad: 1,
            cajaNro: 0,
            caja: '',
            area: 0, 
            valorTotal: vUnit
        };    
        if (orden.tipoProducto.includes('lens')){
            const vUnit = getValuesFromProduct(clientes, 'LENS');   
            const alegraItemId = materiales?.find(material => material.id === `${orden?.ciudad.short}-LENS`).alegraId || null;
      
            const item = {
                idItem: orden.numero + '-len' + Math.floor(Math.random() * 100),
                alegraItemId: alegraItemId? alegraItemId.alegraId : null,
                odc: orden.odc,
                orden: orden.numero,
                iva: orden.applyIva,
                type: 'len',
                material: alegraItemId?.id,
                ordenVersion: orden.numero + "-" + orden.version,
                descripcion: orden.descripcion,
                valorUnitario : vUnit,
                version: orden.version,
                ancho: 0,
                largo: 0,
                cantidad: 1,
                cajaNro: 0,
                caja: '',
                area: 0, 
                valorTotal: vUnit
            };    
            items.push(item);
        }
        items.push(item);
    }
    return items;
}


const getReferenciaName = (record) => {
    const calibres = [0, 45, 67, 100, 112, 155, 95, 250, 94, 73, 250];
    let ref = (record.FOTOPREF1).trim().slice(0,3);
    ///pending the rest of planchas convencionales///
    if (ref === 'ITP'){
        if(record.FOTOPREF1.trim().slice(0,4)==='ITP6'){
            ref = 'ITP60'    
        }
        ref = 'ITPM'
    }
    if (ref === 'ESX'){
        ref = 'ESXR'
    }
    if (ref === 'QF9'){
        ref = 'TOYOBO'
    }
    if(ref === 'WF-'){
        ref = 'WF'
    }
    if(ref === 'KM73' || ref === 'KM'){
        ref = 'KM'
    }
    let ciudadPref = '';

    if(record.CIUDAD === "Mede"){
        ciudadPref = 'E';
    }
    else if(record.CIUDAD === "Barr"){
        ciudadPref = 'B';
    }
    else if(record.CIUDAD === "Cali"){
        ciudadPref = 'C';
    }
    return `${ciudadPref}-${ref}${calibres[record.COORDCALIBRE]}`

}




// id: plancha.id,
// orden: plancha.ORDEN,
// type: 'fotopolimero',
// ordenVersion: plancha.ORDEN + "-" + plancha.VERSIONOP,
// descripcion: selectedData.find(record => record.NUMERO === plancha.ORDEN && record.VERSIONOP === plancha.VERSIONOP).TRABAJO,
// referencia: selectedData.find(record => record.NUMERO === plancha.ORDEN && record.VERSIONOP === plancha.VERSIONOP).FOTOPREF1 || null,
// //valorUnitario: valorUnitario,
// version: plancha.VERSIONOP,
// ancho: plancha.ANCHO,
// largo: plancha.AVANCE,
// cantidad: plancha.CANTIDAD,
// cajaNro: plancha.NROCAJA.trim(),
// caja: stock && stock.length > 0 && stockIndex!==-1 ? stock[stockIndex].id : 'no encontrada',
// area: plancha.ANCHO*plancha.AVANCE*plancha.CANTIDAD,    

