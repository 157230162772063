import{ 
    Show,
    Labeled,
    Datagrid,
    TextField, 
    DateField,
    ArrayField,
    NumberField,
    SimpleShowLayout,
} from 'react-admin';
import { Grid } from '@mui/material';

export const SalidaOrdenShow = () => {

    return (
        <Show>
            <SimpleShowLayout>
                <Grid container spacing={{ xs: 2, md: 3 }} flexDirection='row' justifyContent='space-evenly'>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <NumberField label='OP' source='opSalida'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <NumberField label='version' source='versionSalida'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <TextField label='cliente' source='clienteSalida'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <TextField label='Nombre Trabajo' source='trabajoSalida'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <TextField label='Ciudad' source='ciudadSalida'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <NumberField label='Planchas' source='totalPlanchas'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <TextField label='Tipo Trabajo' source='tipoTrabajo'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <DateField label='Salida Produccion' source='fechaSalida'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <TextField label='Vendedor' source='disenhaFac'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Labeled>
                            <ArrayField source='ordenMaterial'>
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source='anchoSalida'/>
                                    <TextField source='largoSalida'/>
                                    <TextField source='cantidadPlanchas'/>
                                    <TextField source='cajaExpo'/>
                                    <TextField source='areaSalida'/>
                                    <TextField source='totalOrden'/>
                                    <TextField source='observaciones'/>
                                </Datagrid>
                            </ArrayField>
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};



























// else if (resource === ''){
//     const queryText = `
//         INSERT INTO planchasop 
//             ORDEN, ANCHO, AVANCE, CANTIDAD, NROCAJA, VERSIONOP
//     `
//     const values = [ params.filter.op || params.filter?.numero, params.filter.lenWidth, params.filter.lenHeight, params.filter.versionop, 1 || params.filter.cajaId, params.filter.version]
//     query = {text: queryText, values: values}
// };

// else if(resource === ''){
//     const queryText = `
//         UPDATE orden
//         SET FOTOPREF1 = ?
//         WHERE NUMERO = ? AND VERSIONOP = ?
//     `
//     const values = [ params.filter.numero || params.filter.op, params.filter.versionop || params.filter.version]
//     query = {text: queryText, values: values}
// };