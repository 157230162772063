import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form'
import { DialogStylized, ListStyled } from "../style/ExposicionStyles";
import LabeledCustomItem from "../../../utils/LabeledCustomItem";
import { NumberInput, RadioButtonGroupInput, useRecordContext } from "react-admin";

export const RadioButonMaterial = ({ selectedCaja, cajaManual, onChangeMaterial }) => {

    const record = useRecordContext();
    const { setValue } = useFormContext()
    const area = record?.areaTotalLen || 0;
    const [largoRetal, setLargoRetal] = useState(0);
    const [anchoRetal, setAnchoRetal] = useState(0);
    const [desperdicio, setDesperdicio] = useState(0);
    const [desperdicioPerc, setDesperdicioPerc] = useState(0);
    const [selectedMaterial, setSelectedMaterial] = useState('');

    useEffect(() => {
        if (cajaManual) {
            setSelectedMaterial('retal');
            onChangeMaterial('retal');
        }
    }, [cajaManual, onChangeMaterial]);

    useEffect(() => {
        if (anchoRetal > 0 && largoRetal > 0 && selectedMaterial === 'retal') {
            const areaRetal = anchoRetal * largoRetal;
            const desperdicio = areaRetal - area;
            const desperdicioPerc = areaRetal > 0 ? (desperdicio / areaRetal) * 100 : 0;
            setDesperdicio(desperdicio);
            setDesperdicioPerc(desperdicioPerc);
            setValue("anchoRetal", anchoRetal)
            setValue("largoRetal", largoRetal);
            setValue("desperdicio", desperdicio)
            setValue("desperdicioPerc", desperdicioPerc);
            setValue("anchoLamina", null)
            setValue("largoLamina", null);
        } else if (anchoRetal > 0 && largoRetal > 0 && selectedMaterial === 'laminaCompleta') {
            setValue("anchoRetal", null)
            setValue("largoRetal", null);
            setValue("desperdicio", null)
            setValue("desperdicioPerc", null);
        } else {
            return;
        }
    }, [anchoRetal, largoRetal, desperdicio, desperdicioPerc]);

    const handleMaterial = (e) => {
        const value = e.target.value;
        setSelectedMaterial(value);
        onChangeMaterial(value);
    };

    const handleAncho = (e) => setAnchoRetal(parseFloat(e.target.value));
    const handleLargo = (e) => setLargoRetal(parseFloat(e.target.value));

    const isRetal = selectedMaterial === 'retal';
    const filterMedidas = anchoRetal && largoRetal;
    const isLamina = selectedMaterial === 'laminaCompleta';

    const mtTernary = {
        isLamina: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '1em', xl: '0.4em'},
        default: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '0.5em', xl: '0.4em'},
        isRetal: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '0.5em', xl: '0.4em'},
        filterMedidasIsRetal: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '0.6em', xl: '0.4em'},
        filterMedidasIsLamina: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '1.2em', xl: '0.4em'},
    };
    
    const mlTernary = {
        default: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '-0.3em', xl: '2em'},
        isLamina: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '-0.3em', xl: '1em'},
        isRetal: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '-0.3em', xl: '0.8em'},
        filterMedidasIsRetal: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '0.1em', xl: '1.7em'},
    };

    return (
        <Grid container flexDirection="row" justifyContent="space-evenly" sx={{ marginTop: cajaManual ? '-10px' : '-30px' }}>
            {cajaManual ? (
                <>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} ml={filterMedidas > 0 && isRetal ? '2em' : isRetal ? '1em' : '-2em'}>
                        <IsRetal
                            isRetal={isRetal}
                            isLamina={isLamina}
                            cajaManual={cajaManual}
                            handleLargo={handleLargo}
                            handleAncho={handleAncho}
                            selectedCaja={selectedCaja}
                            filterMedidas={filterMedidas}
                        />
                    </Grid>
                    {filterMedidas > 0 && (
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} mt="2.5em" ml={ filterMedidas > 0 && isRetal ? '-1em' : isRetal ? '1em' : '-2em'}>
                            <Grid item sx={{ marginTop: '-18px' }} xs={12} sm={12} md={12} lg={12} xl={6}>
                                <LabeledCustomItem label='Desperdicio' value={desperdicio?.toFixed(1)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                <LabeledCustomItem label='Porcentaje' value={`${desperdicioPerc?.toFixed(2)}%`}/>
                            </Grid>
                        </Grid>
                    )}
                 </>
            ) : (
                <>
                    {selectedCaja && (
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} 
                            ml={ filterMedidas > 0 && isRetal ? mlTernary?.filterMedidasIsRetal
                                : isLamina ? mlTernary?.isLamina 
                                : isRetal ? mlTernary?.isLamina 
                                : mlTernary?.default
                            }
                            mt={ filterMedidas > 0 && isRetal ? mtTernary?.filterMedidasIsRetal
                                : filterMedidas > 0 && isLamina ? mtTernary?.filterMedidasIsLamina 
                                : isLamina ? mtTernary?.isLamina 
                                : isRetal ? mtTernary?.isRetal 
                                : mtTernary?.default
                            }
                        >
                            <RadioButtonGroupInput
                                source="isPlate"
                                label="Tipo Material"
                                onChange={handleMaterial}
                                sx={ListStyled?.radioButton}
                                row={ isLamina || isRetal ? false : true}
                                choices={[{ id: 'laminaCompleta', name: 'Lámina'}, { id: 'retal', name: 'Retal'}]}
                            />
                        </Grid>
                    )}
                    {isLamina && selectedCaja && (
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mr="-65px">
                            <IsLaminaCompleta
                                cajaManual={cajaManual}
                                selectedCaja={selectedCaja}
                                selectedMaterial={selectedMaterial}
                            />
                        </Grid>
                    )}
                    {isRetal && selectedCaja && (
                        <>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} 
                                ml={filterMedidas > 0 && isRetal ? '2em' : isLamina ? '2.5em' : isRetal ? '1em' : '-2em'}
                            >
                                <IsRetal
                                    isRetal={isRetal}
                                    isLamina={isLamina}
                                    cajaManual={cajaManual}
                                    handleLargo={handleLargo}
                                    handleAncho={handleAncho}
                                    selectedCaja={selectedCaja}
                                    filterMedidas={filterMedidas}
                                />
                            </Grid>
                            {filterMedidas > 0 && (
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} mt="2.5em" 
                                    ml={ filterMedidas > 0 && isRetal ? { xs: '', sm: '', md: '', lg: '-2.1em', xl: '-5em'} : isLamina ? '2.5em' : isRetal ? '1em' : '-2em'}
                                >
                                    <Grid item sx={{ marginTop: '-18px' }} xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <LabeledCustomItem label='Desperdicio' value={desperdicio?.toFixed(1)}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <LabeledCustomItem label='Porcentaje' value={`${desperdicioPerc?.toFixed(2)}%`}/>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
};

const IsRetal = ({ handleAncho, handleLargo, selectedCaja, cajaManual, isLamina, isRetal, filterMedidas }) => {

    const cajaManualStyles = {
        marginLeft: filterMedidas > 0 && isRetal 
        ? { sm: '2.5em', md: '2.5em', lg: '2.5em', xl: '-1em' }
        : isRetal ? { xs: '0em', sm: '-1em', md: '-1em', lg: '-1em', xl: '-4em' }
        : { xs: '-6.5em', sm: '-6.8em', md: '-5.5em', lg: '-6.2em', xl: '-6.5em' }
    };

    const selectedCajaStyles = {
        marginLeft: filterMedidas > 0 && isRetal 
        ? { xs: '-4em', sm: '-4.3em', md: '-3.8em', lg: '-4em', xl: '-4em' }
        : isLamina ? { sm: '3em', md: '3em', lg: '3em', xl: '3em' }
        : isRetal ? {  xs: '4em', sm: '-0.5em', md: '-0.5em', lg: '-0.5em', xl: '-0.5em' }
        : { xs: '-4em', sm: '-4.3em', md: '-3.8em', lg: '-4em', xl: '-4em' },
        marginTop: filterMedidas > 0 && isRetal 
        ? { xs: '2.5em', sm: '2.5em', md: '2.5em', lg: '2.5em', xl: '2.5em' }
        : isRetal ? { xs: '-6em', sm: '4em', md: '2.3em', lg: '2.3m', xl: '2.3em' }
        : { xs: '-4em', sm: '-4.3em', md: '-3.8em', lg: '-4em', xl: '-4em' }
    };

    const gridInputSelectedCaja = {
        xl: filterMedidas > 0 && isRetal ? 12 : isRetal ? 12 : 12,
    };

    const gridInputCajaManual = {
        xl: filterMedidas > 0 && isRetal ? 12 : isRetal ? 6 : 12,
    };

    const inputCajaManual = {
        marginLeft: filterMedidas > 0 && isRetal 
        ? { xs: '', sm: '1em', md: '2.5em', lg: '1em', xl: '0em' } 
        : isRetal ? { xs: '0em', sm: '4em', md: '4em', lg: '4em', xl: '7em' } 
        : { xs: '', sm: '1em', md: '1em', lg: '1em', xl: '1em' },
        marginTop: filterMedidas > 0 && isRetal ? '-1em' : isRetal ? '0em' : '0em',
    };

    const sxStyle = {
        ancho: {
            marginLeft: filterMedidas > 0 && isRetal ? { xs: '', sm: '', md: '', lg: '0.5em', xl: '-2.5em' } : isRetal ? '-2.5em' : '0em',
            marginTop: filterMedidas > 0 && isRetal ? { xs: '', sm: '', md: '', lg: '-1.75em', xl: '-2em' } : isRetal ? '-2em' : '0em',
        },
        largo: {
            marginLeft: filterMedidas > 0 && isRetal ? '-2.5em' : isRetal ? '-2.5em' : '0em',
            marginTop: filterMedidas > 0 && isRetal ? '-3em' : isRetal ? '0.3em' : '3em',
        },
    };

    const ternaryWidth = {
        width: filterMedidas > 0 && isRetal ? { xs: '69px', sm: '69px', md: '69px', lg: '65px', xl: '89px'} 
        : isRetal ? { xs: '80px', sm: '80px', md: '80px', lg: '80px', xl: '100px'} 
        : { xs: '100px', sm: '100px', md: '100px', lg: '100px', xl: '100px'}
    };

    return (
        (cajaManual || selectedCaja) ? (
            <Grid container sx={cajaManual ? cajaManualStyles : selectedCajaStyles}>
                <Grid item {...(cajaManual ? gridInputCajaManual : gridInputSelectedCaja )} mt="1em">
                    <NumberInput
                        min={0}
                        label='Ancho'
                        source='anchoRetal'
                        onChange={handleAncho}
                        sx={{
                            width: ternaryWidth?.width,
                            ...DialogStylized?.layout?.inputs,
                            marginTop: cajaManual ? '0em' : sxStyle?.ancho?.marginTop,
                            marginLeft: cajaManual ? '0em' : sxStyle?.ancho?.marginLeft,
                        }}
                    />
                </Grid>
                <Grid item {...(cajaManual ? gridInputCajaManual : gridInputSelectedCaja)} mt="1em">
                    <NumberInput
                        min={0}
                        label='Largo'
                        source='largoRetal'
                        onChange={handleLargo}
                        sx={{
                            width: ternaryWidth?.width,
                            ...DialogStylized?.layout?.inputs,
                            marginTop: cajaManual ? inputCajaManual?.marginTop : sxStyle?.ancho?.marginTop,
                            marginLeft: cajaManual ? inputCajaManual?.marginLeft : sxStyle?.ancho?.marginLeft,
                        }}
                    />
                </Grid>
            </Grid>
        ) : (
            <span/>
        )
    );
};
  
const IsLaminaCompleta = ({ selectedCaja, cajaManual, selectedMaterial }) => {

    const record = useRecordContext();
    const { setValue } = useFormContext();
    const area = record?.areaTotalLen || 0;
    const [desp, setDesperdicio] = useState(0);
    const [despPerc, setDesperdicioPerc] = useState(0);

    useEffect(() => {
        if (selectedMaterial === 'laminaCompleta' && selectedCaja) {
            const ancho = selectedCaja?.anchoLamina || 0;
            const largo = selectedCaja?.largoLamina || 0;
            const areaLamina = ancho * largo;

            const desperdicio = areaLamina - area;
            const desperdicioPerc = areaLamina > 0 ? (desperdicio / areaLamina) * 100 : 0;

            setDesperdicio(desperdicio);
            setDesperdicioPerc(desperdicioPerc);

            setValue("anchoLamina", ancho);
            setValue("largoLamina", largo);
            setValue("desperdicio", desperdicio);
            setValue("desperdicioPerc", desperdicioPerc);
            setValue("anchoRetal", null);
            setValue("largoRetal", null);
        } else if (selectedMaterial === 'retal' && selectedCaja) {
            setValue("anchoLamina", null);
            setValue("largoLamina", null);
            setValue("desperdicio", null);
            setValue("desperdicioPerc", null);
        } else {
            return;
        }
    }, [selectedMaterial, selectedCaja, area, setValue]);

    return (
        (cajaManual || selectedCaja) && selectedMaterial ? (
            <Grid container ml='0.5em' sx={{ marginTop: { xs: '0.5em', sm: '0.5em', md: '0.5em', lg: '0.5em', xl: '0.2em'}}}>
                <Grid item xs={12} mt='1em'>
                    <LabeledCustomItem label='Desperdicio' value={desp.toFixed(1)}/>
                </Grid>
                <Grid item xs={12} mt='1em'>
                    <LabeledCustomItem label='Porcentaje' value={`${despPerc.toFixed(2)}%`} />
                </Grid>
            </Grid>
        ):(<span/>)
    );
};