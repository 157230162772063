import { ExposicionList } from './exposicion/ExposicionList';
import { ExposicionEdit } from './exposicion/ExposicionEdit';
import { ExposicionCreate } from './exposicion/ExposicionCreate';
import { ExposicionShow } from './exposicion/ExposicionShow';

import { OrdenSalidaList } from './ordenproduccion/OrdenSalidaList';
import { OrdenSalidaCreate } from './ordenproduccion/OrdenSalidaCreate';
import { OrdenSalidaEdit } from './ordenproduccion/OrdenSalidaEdit';
import { OrdenSalidaShow } from './ordenproduccion/OrdenSalidaShow';

import { SalidaOrdenList } from './salidaOrden/SalidaOrdenList';
import { SalidaOrdenEdit } from './salidaOrden/SalidaOrdenEdit';
import { SalidaOrdenCreate } from './salidaOrden/SalidaOrdenCreate';
import { SalidaOrdenShow } from './salidaOrden/SalidaOrdenShow';

import { RemisionList } from './remisiones/RemisionList';
import { RemisionEdit } from './remisiones/RemisionEdit';
import { RemisionCreate } from './remisiones/RemisionCreate';
import { RemisionShow } from './remisiones/RemisionShow';

const Produccion = {
    ExposicionList: ExposicionList,
    ExposicionEdit: ExposicionEdit,
    ExposicionCreate: ExposicionCreate,
    ExposicionShow: ExposicionShow,
    OrdenSalidaList: OrdenSalidaList,
    OrdenSalidaCreate: OrdenSalidaCreate,
    OrdenSalidaEdit: OrdenSalidaEdit,
    OrdenSalidaShow: OrdenSalidaShow,
    SalidaOrdenList: SalidaOrdenList,
    SalidaOrdenEdit: SalidaOrdenEdit,
    SalidaOrdenCreate: SalidaOrdenCreate,
    SalidaOrdenShow: SalidaOrdenShow,
    RemisionList: RemisionList,
    RemisionEdit: RemisionEdit,
    RemisionCreate: RemisionCreate,
    RemisionShow: RemisionShow,
};

export default Produccion