import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import Groups3Icon from '@mui/icons-material/Groups3';
import { Card, CardContent, Typography } from '@mui/material';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { removeDuplicates } from '../../../utils/utilsExposicion';
import { FilterStyled, ListStyled } from '../styles/OrdenSalidaStyles';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { Button, TextInput, FilterList, FilterListItem, useUnselectAll, useListContext } from 'react-admin';

export const OrdenFilters = () => {

  const { data, filterValues, setFilters } = useListContext();
  const unselectAll = useUnselectAll('ordenSalidas');
  const ciudadOrden = removeDuplicates(data, 'ciudadOrden');
  const clienteOrden = removeDuplicates(data, 'clienteOrden');
  const responsableSalida = removeDuplicates(data, 'responsableSalida');
    
  useEffect(() => {
    if (filterValues) {
      unselectAll();
    }
  }, [filterValues, data]);

  if (data && data?.length === 0) {
    return (
      <Card sx={FilterStyled?.cardSetFilter}>
        <CardContent>
          <Button onClick={() => setFilters({})} sx={{ marginTop: '0.5em' }}>
            {`Limpiar Filtros`}
          </Button>
        </CardContent>
      </Card>
    )
  };

  const showDiver = (
    responsableSalida && responsableSalida?.length > 0 ? ( <Divider sx={{ mt: 2 }}/> ) : (<span/>)
  );
  
  return (
    data && data?.length > 0 && (
      <Card sx={FilterStyled?.card}>
        <CardContent>
          <FilterList
            label='Ciudad'
            sx={FilterStyled?.filterListItem?.filterList}
            icon={<LocationCityRoundedIcon sx={FilterStyled?.filterListItem?.iconStyled}/>}
          >
            {ciudadOrden?.length > 0 ? ciudadOrden?.map((item) => (
              <FilterListItem
                key={item?.ciudadOrden}
                source='ciudadOrden.eq'
                sx={FilterStyled?.filterListItem?.ciudad}
                value={{ ciudadOrden: { eq: item?.ciudadOrden }}}
                onChange={(value) => console.log('value ciudadOrden', value)}
                label={<Typography sx={FilterStyled?.filterListItem?.label}>
                  <CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{item?.ciudadOrden || ''}
                </Typography>}
              />
            )) : <span/>}
          </FilterList>
          <Divider sx={{ mt: 2 }}/>
          <FilterList
            label='Clientes'
            sx={FilterStyled?.filterListItem?.filterList}
            icon={<Groups3Icon sx={FilterStyled?.filterListItem?.iconStyled}/>}
          >
            {clienteOrden?.length > 0 ? clienteOrden?.map((item) => (
              <FilterListItem
                source='clienteOrden.eq'
                key={item?.clienteOrden}
                sx={FilterStyled?.filterListItem?.cliente}
                value={{ clienteOrden: { eq: item?.clienteOrden }}}
                onChange={(value) => console.log('value clienteOrden', value)}
                label={<Typography sx={FilterStyled?.filterListItem?.label}>
                  <CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{item?.clienteOrden || ''}
                </Typography>}
              />
            )) : <span/>}
          </FilterList>
          <Divider sx={{ mt: 2 }}/>
          <FilterList
            label='Salidas'
            sx={FilterStyled?.filterListItem?.filterListOption}
            icon={<ChecklistRtlIcon sx={FilterStyled?.filterListItem?.iconStyled}/>}
          >
            <FilterListItem
              value={{ salidaProd: { eq: true }}}
              style={FilterStyled?.filterListItem?.style}
              sx={FilterStyled?.filterListItem?.shortOption}
              onChange={(value) => console.log('Salida Pendiente Sí', value)}
              label={<Typography sx={FilterStyled?.filterListItem?.label}>
                <CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'Sí'}
              </Typography>}
            />
            <FilterListItem
              value={{ salidaProd: { eq: false }}}
              style={FilterStyled?.filterListItem?.style}
              sx={FilterStyled?.filterListItem?.shortOption}
              onChange={(value) => console.log('Salida Pendiente No', value)}
              label={<Typography sx={FilterStyled?.filterListItem?.label}>
                <CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'No'}
              </Typography>}
            />
          </FilterList>
          <Divider sx={{ mt: 2 }}/>
          <FilterList
            label='Reposición'
            sx={FilterStyled?.filterListItem?.filterListOption}
            icon={<ProductionQuantityLimitsIcon sx={FilterStyled?.filterListItem?.iconStyled}/>}
          >
            <FilterListItem
              value={{ reposicion: { eq: true }}}
              style={FilterStyled?.filterListItem?.style}
              sx={FilterStyled?.filterListItem?.shortOption}
              onChange={(value) => console.log('Reposición Sí', value)}
              label={<Typography sx={FilterStyled?.filterListItem?.label}>
                <CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'Sí'}
              </Typography>}
            />
            <FilterListItem
              value={{ reposicion: { eq: false }}}
              style={FilterStyled?.filterListItem?.style}
              sx={FilterStyled?.filterListItem?.shortOption}
              onChange={(value) => console.log('Reposición No', value)}
              label={<Typography sx={FilterStyled?.filterListItem?.label}>
                <CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'No'}
              </Typography>}
            />
          </FilterList>
          {showDiver}
          {responsableSalida && responsableSalida?.length > 0 &&
            <>
              <FilterList
                style={{ fontSize: '14px' }}
                sx={FilterStyled?.filterListItem?.filterList}
                label='Responsable' icon={<PersonRoundedIcon color='success' sx={FilterStyled?.filterListItem?.iconStyled}/>}
              >
                {responsableSalida?.map((item) => (
                  <FilterListItem
                    key={item?.responsableNombre}
                    source='responsableNombre?.eq'
                    sx={FilterStyled?.filterListItem?.responsePosition}
                    value={{ responsableNombre: { eq: item?.responsableNombre }}}
                    onChange={(value) => console.log('value responsableNombre', value)}
                    label={<Typography sx={FilterStyled?.filterListItem?.label}>
                      <CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{item?.responsableNombre || ''}
                    </Typography>}
                  />
                ))}
              </FilterList>
            </>
          }
        </CardContent>
      </Card>
    )
  );
};

export const OrdenSalidaFilter = [ 
  <TextInput
    alwaysOn
    source='op.eq'
    sx={ListStyled?.inputs}
    label='Buscar por Orden'
    style={{marginLeft: '0.5em'}}
    parse={(value) => {
      const parsedValue = parseInt(value, 10);
      if (isNaN(parsedValue) || parsedValue === null || parsedValue === undefined) {
        return '';
      };
      return parsedValue;
    }}
  />,
  <TextInput
    alwaysOn
    sx={ListStyled?.inputs}
    source='nameJob.contains'
    label='Buscar por trabajo'
    parse={(value) => value?.toUpperCase()?.trim() || ''}
  />,
  <TextInput
    alwaysOn
    sx={ListStyled?.inputs}
    label='Buscar por cliente'
    source='clienteOrden.contains'
    parse={(value) => value?.toUpperCase()?.trim() || ''}
  />
];