export const ListStyled = {
    componentFont: {
        alignItems: 'center',
        fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '11px', xl: '14px'}
    },
};

export const FilterStyled = {
    iconLabel: {
        fontSize: 6, 
        marginRight: 1.5
    },
    style: {
        marginTop: '0.5em',
        marginLeft: { lg: '-2.5em', xl: '-2.5em'},
    },
    sx: {
        ml: { lg: -5, xl: -6 }, 
        mt: { lg: 0.5, xl: 0.7 },
        width: { lg: 170, xl: 185 }
    },
    searchButton: {
        '& .MuiInputBase-root': {
            height: { xs: '35px', sm: '35px', md: '35px', lg: '35px', xl: '40px'},
            fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '16px'}
        },
        '& .MuiFormLabel-root': {
            lineHeight: { xs: '1.5', sm: '1.5', md: '1.5', lg: '1.5', xl: '1.5'},
            fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '12px', xl: '16px'}
        },
        '& .MuiFormHelperText-root': {
            fontSize: { xs: '9.5px', sm: '9.5px', md: '9.5px', lg: '9.5px', xl: '12px'}
        },
    },
    card: {
        mr: 0,
        mt: 2,
        order: -1,
        marginLeft: '-0.5em',
        width: { xs: 125, sm: 125, md: 125, lg: 170 },
    },
    filterList: {
        marginLeft: { xs: '1em', sm: '1em', md: '1em', lg: '0.3em', xl: '1em'}
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '11px', xl: '13.5px'}
    },
    iconStyled: {
        fontSize: 16,
        marginLeft: { xs: '-0.8em', sm: '-0.8em', md: '-0.8em', lg: '-0.8em', xl: '-0.5em'}
    },
};

export const DatagridSalidaOrden = {
    '& .RaDatagrid-clickableRow	':{
        backgroundColor: 'blue',
    },
    '& .RaDatagrid-headerCell': {
        minWidth: '20px',
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    '& .column-opSalida': { 
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { lg: '65px', xl: '60px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-versionSalida': { 
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { lg: '65px', xl: '60px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-clienteSalidaName': { 
        minWidth: '20px',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        maxWidth: { lg: '90px', xl: '110px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-trabajoSalida': { 
        minWidth: '20px',
        overflow: 'hidden',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        maxWidth: { lg: '209px', xl: '180px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-ciudadSalida': { 
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { lg: '60px', xl: '60px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-totalPlanchas': { 
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { lg: '60px', xl: '60px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-tipoTrabajo': { 
        minWidth: '20px',
        overflow: 'hidden',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        maxWidth: { lg: '160px', xl: '95px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-fechaSalida': {
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { lg: '100px', xl: '100px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-vendedorId': {
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { lg: '80px', xl: '80px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
    '& .column-delete': {
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { lg: '50px', xl: '40px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '12px', xl: '14px'},
    },
};

export const MaterialOrdenStyled = {
    fontSize: { lg: '11.5px', xl: '14px'}
};