import React, { useEffect, useRef } from "react";
import { ReferenceArrayInput, SelectArrayInput, useRecordContext, useGetMany, useDataProvider } from 'react-admin';
import { Grid, Typography, Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { formatCurrencyString } from "../../../utils";
import { options } from "@fullcalendar/core/preact.js";

const OptionRenderer = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const vendedorID = record.vendedorID;
    const [ vendedor, setVendedor ] = React.useState('');
    if (record&&record.vendedorID) {
        dataProvider.getOne('usuarios', { id: vendedorID })
            .then(({ data }) => {
                setVendedor(data.nombres + ' ' + data.apellidos);
            });       
    } 
    const hasVendedor = vendedorID && vendedorID !== '';

    return (
        <Grid container direction='row' justifyContent='space-between' spacing={2} sx={{ minWidth: hasVendedor?'500px':'250px' }}>
            <Grid item xs={12} md={hasVendedor ? 2 : 3.5}>
                <Typography component={'div'} noWrap style={{ 'overflowWrap': 'break-word' }}>
                    <Box sx={{ marginLeft: '2px', marginRight: '5px' }}>{`${record.alegraNumeracion}`}</Box>
                </Typography>
            </Grid>
            <Grid item xs={6} md={hasVendedor ? 2 : 3}>
                <Typography component={'div'}>
                    <Box sx={{ fontStyle: 'italic', fontWeight: 'light', fontSize: '10pt' }}>{`Items: ${record.productFact.items.length}`}</Box>
                </Typography>
            </Grid>
                {hasVendedor ?
                    <Grid item xs={6} md={4}>
                        <Typography component={'div'}>
                        <Box sx={{ fontStyle: 'italic', fontWeight: 'light', fontSize: '10pt' }}>{`Vendedor: ${vendedor}`}</Box>
                    </Typography>
                        
                    </Grid>:<></>
                }
            <Grid item xs={6} md={hasVendedor ? 3 : 5.5}>
                <Typography component={'div'}>
                    <Box sx={{ fontStyle: 'italic', fontWeight: 'light', fontSize: '10pt', textAlign: 'right' }}>{`Total: ${formatCurrencyString(record.total,0)}`}</Box>
                </Typography>
            </Grid>
        </Grid>
    );
}

const RemisionArrayInput = (props) => {
    const { getValues, setValue } = useFormContext();
    const [ids, setIds] = React.useState([]);
    const remisionesIDS = getValues('remisionesIDs');
    const [dataLength, setDataLength] = React.useState(remisionesIDS?.length||0);
    const clienteID = getValues('clienteId');
    let productFact = getValues('productFact');
    const vendedorID = getValues('vendedorID');
    const kindOfDuplicate = getValues('kindOfDuplicate');
    const  [filterRemisiones, setFilterRemisiones] = React.useState({});
    const { data, isPending, error, refetch } = useGetMany(
        'remissions',
        { ids: ids },
        { options: { enabled : ids.length > 0 } }
    );

    //console.log('DATA REMISION', data);
    const handleChange = (e) => {
        setIds(e.target.value);
        props.refreshDatagrid();

    }


    
    useEffect(() => {
        props.refreshDatagrid();
    }, [productFact]);

    useEffect(() => {
        const processItemsFact = () => {
            if (kindOfDuplicate !== 'duplicate' && kindOfDuplicate !== 'remake') {  
                if (data && data.length > dataLength ) {
                    // Add new items from data
                    const newItemsFact = data.flatMap((remision) => {
                        return remision.productFact?.items?.map((producto) => ({
                            ...producto,
                        }));
                    },[]);
                    setDataLength(data.length);
                    setValue('productFact.items', [...newItemsFact]);
                    props.refreshDatagrid();
                } 
                else if (data && data.length < dataLength) {
                    // Keep from productFact items that are in remisionesIDS
                    const updatedItemsFact = productFact.items.filter((item) => {   
                        return remisionesIDS.includes(item.remisionID);
                    } ,[]);
                    
                    setDataLength(data.length);
                    setValue('productFact.items', updatedItemsFact);
                    props.refreshDatagrid();
                }else if(data && data.length === 0){
                    //setDataLength(data.length);
                    //setValue('productFact.items', []);
                    //props.refreshDatagrid();
                }
        }
        };

        processItemsFact();
    }, [data, dataLength, productFact]);
    const vendedorFilter = vendedorID&&vendedorID!==null&&vendedorID!=='' ? { vendedorID: { eq: vendedorID } } : { vendedorID: { exists: false } };

    return (
        <ReferenceArrayInput source="remisionesIDs" reference="remissions" fullWidth
            queryOptions={{ meta: { searchable: true } }}
            filter={{ and: { estado: { ne: "Anulada" }, clienteId: { eq: clienteID }, and: { estado: { ne: "Facturada" }, ...vendedorFilter } } }}
            pagination={{ perPage: 999 }}
            sort={{ field: 'createdAt', order: 'DESC' }}
        >
            <SelectArrayInput optionText={<OptionRenderer />} label="Agregar Remisiones" fullWidth size='small' disabled={props.disabled} onChange={handleChange} />
        </ReferenceArrayInput>
    );
}

export default RemisionArrayInput;
