
import {useListContext, useRecordContext} from 'ra-core'
import get from 'lodash/get';

import {
    List, 
    Datagrid,
    TextField,
    SelectField,
    SearchInput,
    SelectInput,
    BooleanInput
} from 'react-admin'
import { Sucursales } from '../../utils'

const ListFilter =  [
    <SearchInput source='q' alwaysOn />,
  <SelectInput source='sucursalEncargada.eq' label='Sede' choices={Sucursales} />,
    <BooleanInput
      key={3}
      defaultValue={false} 
      source="cotiza.eq"
      label="Cotiza"
      />
]


const AlegraCustomerList = () => {

    return(
    <List title='Clientes' filters={ListFilter} sort={{ field: 'razonSocial', order: 'ASC'}} perPage={50}  queryOptions={{meta:{searchable:'true', filterable: ['razonSocial', 'nit_cedula' ]}}} debounce={1000}>
        <Datagrid rowClick="edit">
            <TextField source="razonSocial" label='Razón Social'/>
            <TextField source="nit_cedula" label='NIT'/>
            <TextField source="direccion" label='Dirección'/>
            <SelectField source="sucursalEncargada" label='Sede' choices={Sucursales}/>
        </Datagrid>
    </List>
    );
}

export default AlegraCustomerList