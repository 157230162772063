import { 
    Form,
    Button,
    useNotify,
    useUpdate,
    TextInput, 
    ArrayInput,
    SaveButton,
    useRefresh,
    useRecordContext,
    SimpleFormIterator,
} from 'react-admin';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconCancel from '@mui/icons-material/Cancel';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

export const LenEdit = ({ lenData, onClose }) => {

    const notify = useNotify();
    const record = useRecordContext();
    const [update, { error }] = useUpdate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
      setShowDialog(true);
    };
  
    const handleCloseClick = () => {
      setShowDialog(false);
    };

    const handleSubmit = async (values) => {
        const lenToUpdate = values?.len || [];

        const updatedLenArray = record.len?.map(item => {
            const updatedItem = lenToUpdate?.find(update => update?.uuid === item?.uuid);
            if (updatedItem) {
                const nameParts = item.name?.split('_');
                const namePrefix = nameParts.length > 1 ? nameParts[0] : '';
                const shouldAddOpVersion = !namePrefix?.match(/^\d+-\d+$/);
                return {
                    ...item,
                    name: shouldAddOpVersion ? `${updatedItem.op}-${updatedItem.version}_${item.name}` : item.name,
                    op: updatedItem.op,
                    version: updatedItem.version
                };
            } else {
                return item; 
            }
        });

        update('exposicions', {
          id: record.id,
          data: {
            id: record.id,
            len: updatedLenArray
          },
          previousData: record,
        },
        {
          onSuccess: () => {
            setShowDialog(false);
            onClose();
            notify('Len actualizado correctamente.');
          },
          onFailure: () => {
            notify('Error al actualizar el Len.', { type: 'error' });
          }
        });
        console.log('Antes --->', record?.len);
        console.log('Después ->', values?.len);
    };

    if (error) {
        return <Button style={{ color: 'white', fontWeight: 'bold', background: 'red' }}>Error {error.message}</Button>;
    }

    const firstLenData = lenData?.[0];
    const titleMerge = firstLenData ? `${firstLenData?.name}` : 'Datos Len';
    const titleTypography = (
        <div style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>
            {`${titleMerge}`}
        </div>
    );

    console.log('lenData', lenData);

    return (
        <>
            <Button 
                label='' 
                size="small"
                onClick={handleClick}
                sx={{
                    maxWidth: '20px',
                    minWidth: '30px',
                    marginLeft: '-4.5em'
                }} 
            >
                <EditIcon fontSize="inherit" />
            </Button>
            <Dialog fullWidth maxwidth="sm" open={showDialog} onClose={handleCloseClick} aria-label="Actualizar Op & Version">
                <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bold' }}>{titleTypography}</Typography>
                </DialogTitle>
                <Form record={{ len: lenData }} resource="exposicions" id="create-exposicion" onSubmit={handleSubmit}>
                    <>
                        <DialogContent>
                            <ArrayInput source='len' label=''>
                                <SimpleFormIterator fullWidth inline disableAdd disableRemove disableReordering sx={{ marginTop:'2em', marginBottom: '-2em' }}>
                                    <TextInput source='op' label='Op' sx={{ fontWeight: 'bold', marginLeft: '5em'}} onChange={(event)=>console.log('Valor de op---->', event.target.value)}/>
                                    <TextInput source='version' label='Versión' sx={{ fontWeight: 'bold' }} onChange={(event)=>console.log('Valor de versión---->', event.target.value)}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </DialogContent>
                        <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <Button 
                                label="ra.action.cancel" 
                                onClick={handleCloseClick}>
                                <IconCancel/>
                            </Button>
                            <SaveButton label='Ingresar'/>
                        </DialogActions>
                    </>
                </Form>
            </Dialog>
        </>
    );
};