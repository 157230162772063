import { generateClient } from "aws-amplify/api";
import { AdminQueries } from "./AdminQueries";
import { SqlProvider } from "./SqlProvider";
import { Filter } from "./Filter";
import { Sort } from "./Sort";
import { Pagination } from "./Pagination";
import { AlegraProvider } from "./AlegraProvider";
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";

function addAsteriskToWildcard(obj) {
  for (const key in obj) {
      if (typeof obj[key] === 'object') {
          // Recursively call the function if the property is an object
          addAsteriskToWildcard(obj[key]);
      } else if (key === 'wildcard') {
          // Trim the current value
          let currentValue = obj[key].trim();
          // Check if the current value already ends with '*', and append '*' if not
          if (!currentValue.endsWith('*')) {
              obj[key] = '*' + currentValue + '*';
          }
      }
  }
}

function splitStringToObjects(obj) {
  const result = [];

  // Helper function to split and transform
  const splitAndTransform = (key, value) => {
      if (typeof value === "string" && value.includes(',')) {
          const values = value.split(',').map(v => v.trim());
          values.forEach(val => {
              const newObject = {};
              newObject[key] = { eq: val };
              result.push(newObject);
          });
      } else if (typeof value === "object") {
          // Recursively handle nested objects
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              splitAndTransform(key, nestedValue);
          });
      } else {
          const newObject = {};
          newObject[key] = { eq: value };
          result.push(newObject);
      }
  };

  // Start the transformation
  Object.entries(obj).forEach(([key, value]) => {
      splitAndTransform(key, value);
  });

  return result;
}

const client = generateClient();

const defaultOptions = {
    authMode: "userPool",
    enableAdminQueries: false,
  };

export class DataProvider {
  
   constructor(operations, options) {
    this.queries = operations.queries;
    this.mutations = operations.mutations;
    this.subscriptions = operations.subscriptions;
    this.authMode = options?.authMode || defaultOptions.authMode;
    this.enableAdminQueries =
      options?.enableAdminQueries || defaultOptions.enableAdminQueries;
    DataProvider.storageBucket = options?.storageBucket;
    DataProvider.storageRegion = options?.storageRegion;
  }

  getList = async (
    resource,
    params
    )  => {
    if (resource.includes('alegra')){
      return AlegraProvider.getList(resource, params);
    }
    let containsTotal = false

    if(resource.indexOf("sql") !== -1){
      return SqlProvider.getList(resource, params);
    }

    if (this.enableAdminQueries && resource === "cognitoUsers") {
      return AdminQueries.listCognitoUsers(params);
    }

    if (this.enableAdminQueries && resource === "cognitoGroups") {
      return AdminQueries.listCognitoGroups(params);
    }

    const { filter, sort, meta } = params;
   // console.log("GET LIST", resource, filter, sort, meta)
   //console.log("GET LIST PARAMS", params)   
    let queryName = Filter.getQueryName(this.queries, filter);
    let queryVariables = {}//Filter.getQueryVariables(filter);

    if ( meta && meta.searchable ) {
       queryName = null;
        containsTotal = true;
      
    const {queryName: queryName2, queryVariables:queryVariables2, filter: filter2} = this.getSeachableParams(resource, params);
      queryName = queryName2;
      queryVariables = queryVariables2;
    }  


    if (!queryName || !queryVariables) {
      // Default list query without filter
      queryName = this.getQueryName("list", resource);
      queryVariables["filter"] = { ...filter };
    }

    //console.log("QUERY NAME", queryName, queryVariables)  
    const query = this.getQuery(queryName);

    const { page, perPage } = params.pagination;
    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,  
      queryVariables,
      perPage,
    });
    const nextToken = Pagination.getNextToken(querySignature, page);
    // console.log ("Query Signature", querySignature)
    // console.log("NEXT TOKEN", nextToken)
    // Checks if page requested is out of range
    if (typeof nextToken === "undefined" ) {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }
    
    const queryData = (
      await this.graphql(query, {
        ...queryVariables,
        limit: perPage,
        nextToken,
      })
    )[queryName];

    Pagination.saveNextToken(queryData.nextToken, querySignature, page);
    // console.log("QUERY DATA", queryData)
    // Computes total

    let total = (page - 1) * perPage + queryData.items.length;
    if (queryData.nextToken) {
      total++; // Tells react admin that there is at least one more page
    }

    return {
      data: queryData.items,
      total: containsTotal && queryData.total ? queryData.total : total,
    };
  };
  
  aggregates = async (
    resource,
    params
  ) => {
    const { filter, sort, meta } = params;
    // console.log("AGGREGATES", resource, filter, sort, meta) 
      if(meta.searchable && meta.agg && meta.searchable){
        let {queryName,queryVariables} = this.getSeachableParams(resource, params);
        queryVariables["aggregates"] = meta.agg;
        queryVariables["limit"] = params.pagination.perPage;
        
        const query = this.getQuery(queryName);

        const queryData = (
          await this.graphql(query, {
            ...queryVariables,
          })
        )[queryName];
        //console.log("AGGREGATES RESULT", queryData)
        return {
          total: queryData.total,
          aggregates: queryData.aggregateItems,
        };
    }
    else{
      //console.log("NO AGGREGATES OR SEARCHABLE IN RESOURCE")
      return {
        total: 0,
        aggregates: {},
      }
    }
  };

  
  getSeachableParams = (resource, params) => {
    const { filter, sort, meta } = params;
    let queryName = null;
    let queryVariables = {};

    //console.log("GET SEARCHABLE RESOURCE", resource)
    //console.log("GET SEARCHABLE FILTER", filter)
    //console.log("GET SEARCHABLE SORT", sort)
    //console.log("GET SEARCHABLE META", meta)

    if ( meta && meta.searchable ) {
      if (sort.field !== "id" && sort.order ) {
        queryName = this.getQueryName("search", resource);
        queryVariables["sort"] =[{
          direction: sort.order.toLowerCase(), 
          field: sort.field      
        }];
      }
      // IF THE FILTER OBJECT CONTAINS SOMETHING
      if(filter && Object.keys(filter).length>0 ){
        queryName = this.getQueryName("search", resource);
        // if(filter.or || filter.and){
        //   if(filter.or){
        //     const transformedArray = splitStringToObjects(filter.or);   
        //     queryVariables["filter"]={or:transformedArray};
        //     delete filter.or;
        //   }
        //   else if(filter.and){
        //     const transformedArray = splitStringToObjects(filter.or); 
        //     queryVariables["filter"]={and:transformedArray};
        //     delete filter.and;
        //   }
        // }

        //THIS IS MAINLY FOR THE MARCACIONS FILTERS
        if(filter.start_gte || filter.start_lte){
          filter["fechaHora"] = {gte: filter.start_gte, lte: filter.start_lte}
          delete filter.start_gte
          delete filter.start_lte
        }

        //THIS IS FOR SEARCH INPUT FILTERS THAT CONTAINS A QUERY CALLED q
        if (meta.filterable && meta.filterable.length > 0 && filter.q) {
          const orFilters = meta.filterable.map((filterable) => {
          if(filterable.indexOf(".") !== -1){
            const splitFilter = filterable.split(".");
            //console.log("SPLIT FILTER", splitFilter)
            if( splitFilter.length > 2){
              if( splitFilter[2] === "str"){
                console.log("STR FILTER", filter.q)
                return {[splitFilter[0]]: {[splitFilter[1]] : filter.q}};
              }
              else if(splitFilter[2] === "int" || splitFilter[2] === "float"){
                //console.log("INT FILTER", typeof filter.q)
                return {[splitFilter[0]]: {[splitFilter[1]]: isNaN(parseInt(filter.q)) ?  0 : filter.q }};
              }
            }
            else{
              if(splitFilter[1] === "wildcard"){
                return {[splitFilter[0]]: {[splitFilter[1]]: `*${filter.q}*`}};
              }
              return {[splitFilter[0]]: {[splitFilter[1]]:filter.q}};
            }
          }
          else{
            return { [filterable]:{matchPhrasePrefix: filter.q }};
          }});
          //console.log("OR FILTERS", orFilters)
          
          
            queryVariables["filter"] = {
              or: orFilters,
            };
        }
        //check if filter contains a main key called or 
        
        if (Object.keys(filter).length > 0) {
          delete filter.q;
          const currentFilter = queryVariables["filter"];
          queryVariables["filter"] = {...currentFilter, ...filter};

        }
        //console.log("QUERY VARIABLES", queryVariables)
        //queryVariables["filter"] = {...filter};
    }
    else{
      queryName = this.getQueryName("search", resource);
      }      
    }
    return {queryName, queryVariables, filter};
  }

    

  getOne = async (
    resource,
    params
    ) => {
      if (resource.includes('alegra')){
        return AlegraProvider.getOne(resource, params);
      }
    if (this.enableAdminQueries && resource === "cognitoUsers") {
      return AdminQueries.getCognitoUser(params);
    }
    const queryName = this.getQueryName("get", resource);
    const query = this.getQuery(queryName);
    const queryData = (await this.graphql(query, {  ...params }))[queryName];

    if(queryData?.items)
      return {
        data: queryData.items[0],
      };
      //console.log("GET ONE", resource, params, queryData)
    return {
      data: queryData,
    };
  };

  getMany = async (
    resource,
    params
  ) => {
    if (resource.includes('alegra')){
      return AlegraProvider.getMany(resource, params);
    }

    if (this.enableAdminQueries && resource === "cognitoUsers") {
      return AdminQueries.getManyCognitoUsers(params);
    }

    if (this.enableAdminQueries && resource === "cognitoGroups") {
      return AdminQueries.listCognitoGroups(params);
    }
    
    const queryName = this.getQueryName("get", resource);
    const query = this.getQuery(queryName);
    //console.log("GET MANY", resource, params)
    const queriesData = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        const queryData = (await this.graphql(query, { id }))[queryName];
        queriesData.push(queryData);
      } catch (e) {
        console.log(e);
      }
    }
    

    return {
      data: queriesData,
    };
  };
  


  getManyReference = async (
    resource,
    params
  ) => {
    const { filter = {}, id, pagination, sort, target, meta } = params;
    const splitTarget = target.split(".");

    // splitTarget is used to build the filter
    // It must be like: queryName.resourceID

    // TEMPORARY  disabling this to send directly the filter to the list/// check if everything works
    /*
    if (splitTarget.length === 2) {
      if (!filter[splitTarget[0]]) {
        filter[splitTarget[0]] = {};
      }

      filter[splitTarget[0]][splitTarget[1]] = id;
    } else {
      const queryName = this.getQueryNameMany("list", resource, target);
      if (!filter[queryName]) {
        filter[queryName] = {};
      }
      filter[queryName][target] = id;
    }*/


    return this.getList(resource, { pagination, sort, filter, meta });
  };

  subscribe = async (topic, subscriptionCallback) => {

    if(topic.split("/")[2]){//check if record is asigned to subscription
      const resource = topic.split("/")[1].charAt(0).toUpperCase() + topic.split("/")[1].slice(1, -1)
      const recordId = topic.split("/")[2]
      const subscription = `onUpdate${resource}`;
      const queryName = this.getQuery(subscription);
      const sub = client.graphql({
        query: queryName, variables: {filter: {id: {eq: recordId}}}}
      ).subscribe({
        next: ({ provider, value }) => ( subscriptionCallback(value) ),
        error: (error) => console.warn(error)
      });

    }else{
      const resource = topic.split("/")[1].charAt(0).toUpperCase() + topic.split("/")[1].slice(1, -1)
      //generate topics for subscription onCreate, onUpdate, onDelete

      //expanding subscriptions from topic  
      const subscriptions = [`onCreate${resource}`, `onUpdate${resource}`, `onDelete${resource}`];
      //check if subcriptions exist in this.subscriptions
     
      for (const subscription of subscriptions) {
        const queryName = this.getQuery(subscription);
        const sub = client.graphql(
          {query:queryName}
        ).subscribe({
          next: ({ provider, value }) => ( subscriptionCallback(value) ),
          error: (error) => console.warn(error)
        });
  
        //subscriptions.push({ queryName, subscriptionCallback });
      }
    }
    
    return Promise.resolve({ data: null });
  };

  unsubscribe = async (topic, subscriptionCallback) => {
    if(topic.split("/")[2]){//check if record is asigned to subscription
      const resource = topic.split("/")[1].charAt(0).toUpperCase() + topic.split("/")[1].slice(1, -1)
      const recordId = topic.split("/")[2]
      const subscription = `onUpdate${resource}`;
      const queryName = this.getQuery(subscription);
      const sub = client.graphql({
        query:queryName, variables: {filter: {id: {eq: recordId}}}}
      ).subscribe({
        next: ({ provider, value }) => ( subscriptionCallback(value) ),
        error: (error) => console.warn(error)
      });
      sub.unsubscribe();
    }else{
      const resource = topic.split("/")[1].charAt(0).toUpperCase() + topic.split("/")[1].slice(1, -1)
      const subscriptions = [`onCreate${resource}`, `onUpdate${resource}`, `onDelete${resource}`];
      for (const subscription of subscriptions) {
        const queryName = this.getQuery(subscription);
        const sub = client.graphql({
          query:queryName}
        ).subscribe({
          next: ({ provider, value }) => ( subscriptionCallback(value) ),
          error: (error) => console.warn(error)
        });
        sub.unsubscribe();
    }

    }


    /*this.subscriptions = this.subscriptions.filter(
      (subscription) =>
        subscription.topic !== topic ||
        subscription.subscriptionCallback !== subscriptionCallback
    );*/
    return Promise.resolve({ data: null });
  };

  create = async (
    resource,
    params
    ) => {
    if (resource.includes('alegra')){
        return AlegraProvider.create(resource, params);
      }
    // console.log("CREATE", resource, params)
    const queryName = this.getQueryName("create", resource);
    const query = this.getQuery(queryName);
    // Executes the query
    const queryData = (await this.graphql(query, { input: params.data }))[
      queryName
    ];

    return {
      data: queryData,
    };
  };
  
  preview = async (
    resource,
    params
    ) => {
    if (resource.includes('alegra')){
        return AlegraProvider.preview(resource, params);
      }

  };
  email = async (
    resource,
    params
    ) => {
    if (resource.includes('alegra')){
        return AlegraProvider.email(resource, params);
      }

  };

  update = async (
    resource,
    params
    ) => {
    if (this.enableAdminQueries && resource === "cognitoGroups") {
      return AdminQueries.addUserToCognitoGroups(params);
    }
    if(resource.indexOf("sql") !== -1){
      return SqlProvider.update(resource, params);
    }
    if (resource.includes('alegra')){
      //console.log("ALEGRA UPDATE", resource, params)
      return AlegraProvider.update(resource, params);
    }
    const queryName = this.getQueryName("update", resource);
    const query = this.getQuery(queryName);
    // Removes non editable fields
    const { data } = params;
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;
    console.log("UPDATE CALLED", resource, params, queryName, data)
    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[queryName];
    return {
      data: queryData,
    };
  };

  // This may not work for API that uses DataStore because
  // DataStore works with a _version field that needs to be properly set
  updateMany = async (
    resource,
    params
  ) => {
    const queryName = this.getQueryName("update", resource);
    const query = this.getQuery(queryName);

    // Removes non editable fields
    const { data } = params;
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;

    const ids = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        await this.graphql(query, { input: { ...data, id } });
        ids.push(id);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      data: ids,
    };
  };

  delete = async (
    resource,
    params
  ) => {
    if (this.enableAdminQueries && resource === "cognitoGroups") {  
      return AdminQueries.removeUserFromCognitoGroups(params);
    }
    if (resource.includes('alegra')){
      //console.log("ALEGRA DELETE", resource, params)
      return AlegraProvider.delete(resource, params);
    }
    
    //console.log("DELETE", resource, params)
    const queryName = this.getQueryName("delete", resource);
    const query = this.getQuery(queryName);

    const { id, previousData } = params;
    const data = { id }

    if (previousData?._version) {
      data._version = previousData._version;
    }

    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[queryName];

    return {
      data: queryData,
    };
  };

  deleteMany = async (
    resource,
    params
  ) => {
    const queryName = this.getQueryName("delete", resource);
    const query = this.getQuery(queryName);

    const ids = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        await this.graphql(query, { input: { id } });
        ids.push(id);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      data: ids,
    };
  };

  getQuery(queryName) {
    if (this.queries[queryName]) {
      return this.queries[queryName];
    }

    if (this.mutations[queryName]) {
      return this.mutations[queryName];
    }
    if (this.subscriptions[queryName]) {
      return this.subscriptions[queryName];
    }

    console.log(`Could not find query ${queryName}`);

    throw new Error("Data provider error");
  }

  getQueryName(operation, resource) {
    const pluralOperations = ["list", "search"];
    
    if (pluralOperations.includes(operation)) {
      return `${operation}${
        resource.charAt(0).toUpperCase() + resource.slice(1)
      }`;
    }

    // else singular operations ["create", "delete", "get", "update"]
    return `${operation}${
      resource.charAt(0).toUpperCase() + resource.slice(1, -1)
    }`;
  }

  getQueryNameMany(
    operation,
    resource,
    target
  ) {
    const queryName = this.getQueryName(operation, resource);
    return `${queryName}By${
      target.charAt(0).toUpperCase() + target.slice(1, -2)
    }Id`;
  }

  isObject(obj) {
    return obj !== null && typeof obj === "object";
  }
  isObjectOfLength(obj, length = 0){
    if (!this.isObject(obj)) {
      return false;
    }

    return Object.keys(obj).length === length;
  }
  
  
  async graphql(
    query,
    variables
  )  {
    const queryResult = await client.graphql({
      query,
      variables,
    });

    if (queryResult.errors || !queryResult.data) {
      throw new Error("Data provider error");
    }

    return queryResult.data;
  }


 
}


