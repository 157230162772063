const ListStyled = {
    titleStyled: {
        color: 'white',
        display: 'flex',
        fontWeight: 'bold',
        alignItems: 'center',
        backgroundColor: 'black', 
        justifyContent: 'center',
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
    },
    pantoneSX: {
        marginTop: '8px',
        textAlign: 'center',
        fontSize: { md: '10px', lg: '12px', xl: '14px'},
    },
    iconStateSize: {
        alignItems: 'center',
        fontSize: { xs: '18px', sm: '18px', md: '18px', lg: '20px', xl: '24px'},
    },
    componentFont: {
        alignItems: 'center',
        fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '12px', xl: '14px'}
    },
    submitButton: {
        marginLeft: 'auto',
        fontSize: { xs: '10.5px', sm: '10.5px', md: '10.5px', lg: '10.5px', xl: '13px'},
    },
    inputs: {
        '& .MuiInputBase-root': {
            height: { xs: '35px', sm: '35px', md: '35px', lg: '35px', xl: '40px'},
            fontSize: { xs: '13.5px', sm: '13.5px', md: '13.5px', lg: '13.5px', xl: '16px'},
        },
        '& .MuiFormLabel-root': {
            lineHeight: { xs: '1.5', sm: '1.5', md: '1.5', lg: '1.5', xl: '1.5'},
            fontSize: { xs: '13.5px', sm: '13.5px', md: '13.5px', lg: '13.5px', xl: '16px'},
        },
        '& .MuiFormHelperText-root': {
            fontSize: { xs: '9.5px', sm: '9.5px', md: '9.5px', lg: '9.5px', xl: '12px'},
        },
    },
};

const BubbleStyles = {
    sx: {
        marginLeft: { lg: '-2em', xl: '-2em'}
    },
    textStyle: {
        color: 'black',
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.4)',
        fontSize: { lg: '0.88em', xl: '1em'},
    },
    titleStyle: {
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: { lg: '0.75em', xl: '1.25em'},
    },
    body: {
        margin: 'auto',
        padding: '1em',
        display: 'flex',
        minWidth: '20px',
        maxWidth: '360px',
        borderRadius: '15px',
        flexDirection: 'column',
        backgroundColor: 'lightgreen',
        justifyContent: 'space-between',
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
    },
};

const FilterStyled = {
    card: {
        mt: 2,
        order: -1,
        mr: { xs: 0, sm: 0, md: 0, lg: -2, xl: 0 },
        width: { xs: 165, sm: 165, md: 165, lg: 165, xl: 180 },
    },
    cardSetFilter: {
        mt: 2,
        mr: 0,
        order: -1,
        width: 100,
        height: 104,
    },
  
    filterListItem : {
        iconLabel: {
            fontSize: 6, 
            marginRight: 1.5,
        },
        style: {
            marginLeft: '-0.7em',
        },
        ciudad: {
            width: { xs: 165.5, sm: 165.5, md: 165.5, lg: 165, xl: 180 },
            marginLeft: { xs: -5.5, sm: -5.5, md: -7, lg: -5.5, xl: -5 },
        },
        cliente: {
            width: { xs: 165, sm: 165, md: 175, lg: 165, xl: 190 },
            marginLeft: { xs: -5.7, sm: -5.7, md: -7, lg: -5.1, xl: -4.7 },
        },
        shortOption: {
            width: { xs: 128.5, sm: 128.5, md: 128.5, lg: 130, xl: 140 },
            marginLeft: { xs: -7, sm: -7, md: -7, lg: -5, xl: -5 }, 
        },
        responsePosition: {
            width: { xs: 155, sm: 155, md: 155, lg: 155, xl: 178 },
            marginLeft: { xs: -4, sm: -4, md: -4, lg: -2.8, xl: -4 },
        },
        filterList: {
            marginLeft: { xs: '1em', sm: '1em', md: '1em', lg: '0.3em', xl: '1em'},
        },
        filterListOption: {
            marginLeft: { xs: '1em', sm: '1em', md: '1em', lg: '0.5em', xl: '1em'},
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '12px', xl: '13.5px'}
        },
        iconStyled: {
            fontSize: 16,
            marginLeft: { xs: '-0.8em', sm: '-0.8em', md: '-0.8em', lg: '-0.8em', xl: '-0.2em'},
        },
    },
};

const ToolBarStyled = {
    toolBar: {
        top: 13, 
        left: -2, 
        transform: 'scale(0.75)', 
        '& .MuiSwitch-switchBase.Mui-checked': { 
            color: 'white',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { 
            backgroundColor: 'black',
        },
    },
    formControlLabel: {
        marginLeft: '15px',
    },
    typography: {
        fontSize: '10px',
        marginTop: '-1rem', 
        marginLeft: '-4rem', 
    },
    topToolBar: {
        marginTop: { xs: '-4em', sm: '-4.5em', md: '0em', lg: '0em', xl: '0em'}
    }
};

const AddDiferencia = {
    sxButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: { xs: '65px', sm: '65px', md: '65px', lg: '65px', xl: '85px'},
        fontSize: { xs: '9.5px', sm: '9.5px', md: '19.5px', lg: '9.5px', xl: '12px'},
    },
    styleButton: {
        marginLeft: '-1em',
        alignItems: 'center', 
    },
    dialogTitleStyle: {
        display: 'flex', 
        color: 'whitesmoke', 
        background: 'black', 
        alignItems: 'center', 
        justifyContent: 'center',
    },
    typography: {
        fontWeight: 'bold', 
        alignItems: 'center', 
        justifyContent: 'center',
    },
    dialogActions: {
        zIndex: 1,
        bottom: 0, 
        position: 'sticky', 
        backgroundColor: 'white', 
    },
    errorButton: {
        color: 'white',
        background: 'red',
        fontWeight: 'bold',
    },
};

const DialogSalidaStyled = {
    dialogTitle: {
        display: 'flex',
        marginTop: '-0.75em',
        alignItems: 'center',
        justifyContent: 'center',
    },
    typography: {
        fontWeight: 'bold',
    },
    dialogActions: {
        bottom: 0, 
        zIndex: 1,
        position: 'sticky', 
        backgroundColor: 'white', 
    },
    button: {
        width: { xs: '70px', sm: '75px', md: '80px', lg: '80px', xl: '85px'},
        fontSize: { xs: '11.5px', sm: '11.5px', md: '11.5px', lg: '11.5px', xl: '14px'}
    },
};

const StyledDatagridOrden = {
    '& .RaDatagrid-headerCell': {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    '& .column-estado': {
        flexGrow: 1,
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { xs: '70px', sm: '70px', md: '70px', lg: '70px', xl: '60px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-op': {
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        maxWidth: { xs: '70px', sm: '70px', md: '70px', lg: '70px', xl: '60px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-version': {
        flexGrow: 1, 
        minWidth: '20px', 
        textAlign: 'center',
        maxWidth: { xs: '70px', sm: '70px', md: '70px', lg: '70px', xl: '60px'},
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-nameJob': {
        flexGrow: 1,
        minWidth: '20px',
        overflow: 'hidden',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',        
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
        maxWidth: { xs: '120px', sm: '120px', md: '120px', lg: '150px', xl: '210px'},
    },
    '& .column-tipoJob': {
        minWidth: '20px',
        overflow: 'hidden',
        fontWeight: 'bold',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
        maxWidth: { xs: '125px', sm: '125px', md: '125px', lg: '120px', xl: '165px'}, 
    },
    '& .column-clienteOrden': {
        flexGrow: 1,
        minWidth: '20px',
        overflow: 'hidden',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '100px', xl: '120px'}, 
    },
    '& .column-totalPlanchasExp': {
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        maxWidth: { xs: '70px', sm: '70px', md: '70px', lg: '80px', xl: '60px'}, 
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-totalPlanchasOrden': {
        flexGrow: 1, 
        minWidth: '20px', 
        textAlign: 'center',
        maxWidth: { xs: '70px', sm: '70px', md: '70px', lg: '80px', xl: '60px'}, 
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-calibresExp': {
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '80px', xl: '60px'}, 
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-calibreOrden': {
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        maxWidth: { xs: '70px', sm: '70px', md: '70px', lg: '80px', xl: '60px'}, 
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-salidaOrdenes': {
        flexGrow: 1, 
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { xs: '90px', sm: '90px', md: '90px', lg: '90px', xl: '65px'}, 
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-color': {
        flexGrow: 1, 
        minWidth: '20px',
        textAlign: 'center',
        maxWidth: { xs: '90px', sm: '90px', md: '90px', lg: '90px', xl: '65px'}, 
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
    '& .column-createdAt': { 
        minWidth: '20px',
        overflow: 'hidden',
        fontWeight: 'bold',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        maxWidth: { xs: '30px', sm: '30px', md: '30px', lg: '95px', xl: '80px'}, 
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '11.5px', xl: '12px'},
    },
};

const fontTableCell = {
    cell: {
        fontSize: { lg: '12px', xl: '14px'},
    },
    title: {
        fontSize: { lg: '11px', xl: '14px'}
    },
};
  
const StyledDatagridShowExposicion = {
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: { lg: '17px', xl: '20px'},
    },
    datagrid: {
        '& .RaDatagrid-headerCell': {
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: { xs: '8px', sm: '8px', md: '10px', lg: '11px', xl: '14px'},
        },
        '& .column-imageExp': {
            minWidth: '20px',
            textAlign: 'center',
            fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '90px', xl: '100px'},
        },
        '& .column-responsable': {
            minWidth: '20px',
            textAlign: 'center',
            fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '100px', xl: '100px'},
        },
        '& .column-dateExposed': {
            minWidth: '20px',
            textAlign: 'center',
            fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '100px', xl: '100px'},
        },
        '& .column-updatedAt': {
            minWidth: '20px',
            textAlign: 'center',
            fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '100px', xl: '100px'},
        },
        '& .column-gestion': {
            minWidth: '20px',
            textAlign: 'center',
            fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '100px', xl: '100px'},
        },
    },
    lenDatagrid: {
        '& .column-image': {
            minWidth: '20px',
            textAlign: 'center',
            maxWidth: { xs: '40px', sm: '40px', md: '40px', lg: '75px', xl: '75px'},
            fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '12px'}, 
        },
        '& .column-pantone': {
            minWidth: '20px',
            fontWeight: 'none',
            textAlign: 'center',
            maxWidth: { xs: '40px', sm: '40px', md: '40px', lg: '40px', xl: '50px'},
            fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '12px'}, 
        },
        '& .column-uuid': {
            minWidth: '20px',
            overflow: 'hidden',
            textAlign: 'center',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            maxWidth: { xs: '80px', sm: '80px', md: '80px', lg: '70px', xl: '80px'},
            fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        },
        '& .column-name': {
            minWidth: '20px',
            overflow: 'hidden',
            textAlign: 'center',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            maxWidth: { xs: '80px', sm: '80px', md: '80px', lg: '70px', xl: '80px'},
            fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        },
        '& .column-lenWidth': { 
            minWidth: '20px', 
            fontWeight: 'bold',
            textAlign: 'center', 
            maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
            fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'}, 
        },
          '& .column-lenHeight': { 
            minWidth: '20px', 
            fontWeight: 'bold',
            textAlign: 'center', 
            maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
            fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'},
        },
        '& .column-area': { 
            minWidth: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
            fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'},
        },
    }
};

export { ListStyled, FilterStyled, StyledDatagridOrden, DialogSalidaStyled, StyledDatagridShowExposicion, ToolBarStyled, BubbleStyles, AddDiferencia, fontTableCell };