

import React, { useState, useCallback } from "react";
import { Edit, SimpleForm, TextInput, Toolbar, useRecordContext, SaveButton, useRedirect, 
        TextField, LinearProgress, useGetOne, SelectInput, useNotify, ListButton, 
        useRegisterMutationMiddleware, useDataProvider, TopToolbar, ReferenceField, Labeled,
    } from 'react-admin';
import { Box, Typography, Grid, Button} from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { Sucursales } from "../../utils";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import PrintIcon from '@mui/icons-material/Print';
import { DeleteRemision } from "../components/DeleteRemision";
import { EditableDatagridCustom } from "../components/EditableDatagridCustom";
import { OrdenesPendientesShow } from "../components/OrdenesPendientesShow";
import { TotalesComponent } from "../components/TotalesComponent";
import AlegraIcon from "../components/alegraIcon";
import { generatePDF } from "../functions/generateRemisionPDF";



const RemisionEditActions = () => (
    <TopToolbar>
        <ListButton resource="facturas" label='Facturas'/>
        <ListButton resource="remissions" label='Salidas'/>
        <ListButton resource="sqlSalidas" label='Salidas'/>
    </TopToolbar>
);



const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        let newParams = {};
        const userEdit = localStorage.getItem('user');
        //console.log('Params INSIDE MIDDLEWARE', params);
        //console.log('record INSIDE MIDDLEWARE', record);
        try {
            const response = await editAlegraRemision(data);
            if (response.data.id) {
                notify('Remisión Editada en Alegra', { type: 'success' }, { smart_count: 1 });
            }
            const { productFact, ...dataNew } = data;
            newParams = {
                id: dataNew.id,
                data: {
                    ...dataNew,
                    estado: 'Editada',
                    fechaEdita: new Date(),
                    usuarioEdita: userEdit,
                },
                previousData: { ...dataNew },
            };
        } catch (error) {
            //console.error('ERROR in createAlegraRemision', error);
            if (error.message === 'El id del ítem es un campo obligatorio') {
                notify('Error: Caja No Seleccionada', { type: 'error' });
            } else {
                notify(`Error: ${error.message}`, { type: 'error' });
            }
            return; // Exit if there is an error in creating Alegra Remision
        }
        

        try{
            await updateStock(data.itemsFact, record.itemsFact);

        }
        catch (error) {
            console.error('ERROR actualizando stock', error);
            notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 1 });
            return; // Exit if there is an error in updating stock
        }

         try {
            await next(resource, newParams, options);
         } catch (error) {
             console.error('ERROR Saving in app', error);
             notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 1 });
         }
         try{
            const res = await Promise.all(
                data.itemsFact.map(async item => {
                    const productId = data.productFact.items.filter(pf => pf.idItem === item.idItem)[0].id;
                    if (productId) {
                        const newItem = { ...item, vendedorID: data.vendedorID , remisionID: data.id, id: productId};
                        //console.log('UPDATING NAME', newItem);
                        //console.log('PRODUCT ID', productId);
                        return dataProvider.update('productFacts', { id: productId, data: { ...newItem } });
                    }
                    else if(!productId){
                        const newItem = { ...item, vendedorID: data.vendedorID , remisionID: data.id };
                        return dataProvider.create('productFacts', { data: { ...newItem } });
                    }
                })
            );
            const res2 = await Promise.all(
                data.productFact.items.map(async item => {
                    const found = data.itemsFact.find(it => it.idItem === item.idItem);
                    if (!found) {
                        //console.log('DELETING NAME', item);
                        return dataProvider.delete('productFacts', { id: item.id });
                    }
                })
            );
            
            } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 3 });
        }
    }, [dataProvider, notify]);

    const updateStock = async (itemsNew, itemsOld) => {
        const cajasOld = itemsOld.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0) {
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        const cajasNew = itemsNew.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0) {
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);

        const cajas = cajasOld.reduce((acc, cajaOld) => {
            const found = cajasNew.find(cajaNew => cajaNew.caja === cajaOld.caja);
            // if the caja is in both lists, then the area is the difference between the new and old areas else the area is the old area
            //console.log('found', found)
            if (found) {                
                acc.push({ caja: cajaOld.caja, area: cajaOld.area - found.area });
            }else{   
                acc.push({ caja: cajaOld.caja, area: cajaOld.area });
            }

            
            return acc;
            
        }, []);
        // find cajas new that are not in cajas old and return them with area negative
        const cajasNotOld = cajasNew.filter(cajaNew => !cajasOld.find(cajaOld => cajaOld.caja === cajaNew.caja))
        cajasNotOld.forEach(caja => caja.caja?cajas.push({ caja: caja.caja, area: -caja.area }):null);
        //console.log("CAJAS NOT OLD", cajasNotOld);

        //console.log('CAJAS', cajas);


        for (const caja of cajas) {
            try {
                if(caja === null || caja.area<=0 || caja.caja === undefined) continue;
                const stock = await dataProvider.getOne('stocks', { id: caja?.caja });
                const newStock = {
                    ...stock.data,
                    areaRestante: stock.data.areaRestante + caja.area,
                };
                delete newStock.retales;
                //console.log('NEW STOCK', newStock);
                await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            } catch (error) {
                console.error(`ERROR updating stock for caja ${caja.caja}`, error);
                notify(`Error updating stock for caja ${caja.caja}: ${error.message}`, { type: 'error' });
            }
        }
    };

    const editAlegraRemision = async (values) => {
        const alegraJson = {
            documentName: 'remission',
            date: new Date(),
            dueDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            anotation: values.observaciones,
            client: { id: values.alegraClienteId },
            seller: values.vendedorAlegraId ? { id: values.vendedorAlegraId } : undefined,
            items: values.itemsFact.map(item => {
                let descripcion = '';
                if (item.ancho > 0 && item.largo > 0 && item.area > 0) {
                    descripcion = `${item.descripcion}\n${item.ancho} x ${item.largo}   Cant: ${item.cantidad}`;
                    if (item.odc && item.odc.trim().length > 0) {
                        descripcion = `${descripcion} - ODC:${item.odc}`;
                    }
                } else {
                    descripcion = item.descripcion;
                    if (item.odc) {
                        descripcion = `${descripcion} - ODC:${item.odc}`;
                    }
                }
                return {
                    id: item.alegraItemId,
                    reference: item.ordenVersion,
                    description: descripcion,
                    quantity: item.area > 0 ? item.area : item.cantidad,
                    price: item.valorUnitario,
                    tax: [{ id: item.iva ? 3 : 0 }],
                };
            }),
            costCenter: { id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).costCenterId },
            warehouse: { id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).alegraID },
        };
        //console.log('ALEGRA JSON', alegraJson);
        return dataProvider.update('alegraRemissions', { ...alegraJson, id: values.alegraId });
    };

    useRegisterMutationMiddleware(middleware);

    return (
        <Toolbar>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} md={6}>
                    <SaveButton
                        key={'emitir'}
                        label="Actualizar Remisión"
                        icon={<SendAndArchiveIcon />}
                        alwaysEnable
                    />
                </Grid>
             
                <Grid item xs={12} md={5}>
                    {/* Additional content can go here */}
                </Grid>
                <Grid item xs={12} md={1}>
                    <DeleteRemision />
                </Grid>
            </Grid>
        </Toolbar>
    );
};



const EditTitle = () => {
    const record = useRecordContext();
    return <span>Remisión {record ? `${record.alegraNumeracion}` : ''}</span>;    
};

const RemisionEdit = () => {
    const [ refreshDatagrid, setRefreshDatagrid ] = useState();
    
    const refetchData = (v) => {
        setRefreshDatagrid(v)
    }


    return (
        <Edit resource="facturas"  redirect='list' title={<EditTitle/>} actions={<RemisionEditActions/>}>
            <SimpleForm /*toolbar={<CustomToolbar/>}*/>
                <Grid container spacing={1} justifyContent={'space-between'} alignContent={'stretch'}>
                    <Grid item xs={12} md={9} container direction='row'>
                        <Grid item xs={12} md={12}>
                            <Labeled>
                                <ReferenceField source = 'clienteId' reference='clientes'>
                                    <TextField source="razonSocial" label="Cliente"sx={{padding:'10px', fontSize: '25px'}} />
                                </ReferenceField>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SelectInput source="ciudadId" label="Ciudad"  choices={Sucursales} optionText='name' fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{justifyContent: 'right'}}>
                        <NumeracionEstadoField/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <EditableDatagridCustom refreshDatagrid={refreshDatagrid}/>
                    </Grid>
                    <Grid item  xs={12} md={12} container  direction='row' justifyContent={'flex-end'} >
                        <Grid item xs={12} md={7} >
                            <TextInput source="observaciones"  fullWidth/>
                        </Grid>
                        <TotalesComponent changed={refetchData}/>
                    </Grid>
                </Grid>
            </SimpleForm>
           <OrdenesPendientesShow/>
        </Edit>
    );
};

export default RemisionEdit;

const NumeracionEstadoField = () => {
    const record  = useRecordContext()
    const { setValue, getValues } = useFormContext();
    const { data,  isLoading2  } = useGetOne('alegraRemissions', {id:record?.alegraId});
    const { data: cliente,  isLoading  } = useGetOne('clientes', {id:record?.clienteId});
    
    //console.log('DATA ALEGRA REMISSION', data);
    let estado = getValues('estado');
    const alegraPdf = getValues('pdf');
    const alegraUrl = getValues('url');
    const dataRem = getValues();
    //console.log('DATA REM', dataRem);
    //estado = 'Anulada'
    let style = { Facturada: {backgroundColor: 'green', color: 'white'}, Anulada: {backgroundColor: '#c91104', color: 'white'}, Emitida: {backgroundColor: 'lightgrey', color: 'black'}, Editada: {backgroundColor: 'lightblue', color: 'black'}};


    if (data&&data.status === "closed") {
        if (data.status === "closed") {
            record.estado = "Facturada";
            setValue('estado', 'Facturada');
        }
        if (data.status === "void") {
            record.estado = "Anulada";
            setValue('estado', 'Anulada');
        }
    }
    const handlePrint = (e) => {
        // Open data.pdf link in another window
        //window.open(alegraPdf, '_blank');
        generatePDF(dataRem, data?.client);

    }
    const handleOpen = (e) => {
        // Open data.url link in another window
        window.open(alegraUrl, '_blank');
    }


    return (
        <Grid container direction='row' spacing={3} alignitems='stretch'>
            <Grid item xs={12} md={6} container direction='column' justifyContent='space-evenly'>
                <Grid item xs={12} md={6}  >
                    <Button type='button' onClick={(e)=>handlePrint(e)} endIcon={<PrintIcon />} variant='outlined' sx={{width:'100%', marginTop:'3px'}} disabled={isLoading}>
                        Imprimir
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Button type='button' onClick={(e)=>handleOpen(e)} endIcon={<AlegraIcon />} variant='outlined' sx={{width:'100%'}} >
                        VER 
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} >
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' ,boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.1)', width:'123px', marginBottom:'5px'}}>
                    <Typography variant="h6" style={{ fontSize: '24px',fontWeight: 'bold'}}>{record.alegraNumeracion}</Typography>
                    {isLoading ? <LinearProgress sx ={{width:'100px'}} />
                    :
                    <Box /*sx={{backgroundColor: style[estado].backgroundColor, width: '120%', padding:0 }}*/>
                        <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold', display:'flex', justifyContent:'center', width:'100%',/* color: style[estado].color*/}}>{estado}</Typography>
                    </Box>
                    }   
                </Box>
            </Grid> 
        </Grid>
    );
};







