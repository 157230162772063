import { generateClient } from 'aws-amplify/api';
import { EditDialog } from '@react-admin/ra-form-layout';
import { DialogTitle, Grid, Typography } from '@mui/material';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { UserInput } from '../../exposicion/components/InputsMedidas';
import { validarExistenciaPin } from '../../../utils/utilsExposicion';
import { DialogSalidaStyled, ListStyled } from '../styles/OrdenSalidaStyles';
import { useRecordContext, useGetList, SaveButton, useNotify, SimpleForm, Toolbar, useRedirect, useDataProvider, useRefresh } from 'react-admin';

export const SalidaOrden = () => {

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const client = generateClient();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const repo = record?.reposicion;
    const clientId = record?.clienteOrdenId;
    const materialOrden = record?.ordenMaterial;
    const totalPlanchasOrden = record?.totalPlanchasOrden;
    const tipoJob = record?.tipoJob?.match(/^[^/]+/)?.[0];
    const tipoTrabajo = repo ? 'REPOSICIÓN' : record?.tipoJob;

    const jobType = record?.tipoJob;

    const { data: clientes } = useGetList('clientes', { 
        filter: { nit_cedula: { eq: clientId }},
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'nit_cedula', order: 'DESC'}}
    );
    const cliente = clientes?.[0];
    const datosFacturacion = cliente?.datosFacturacion;
    const precioOrden = datosFacturacion?.precioOrden;
    
    const groupedMaterials = materialOrden?.reduce((acc, material) => {
        const { caja, opAncho: ancho, opLargo: largo, cantidad } = material;
        const [dim1, dim2] = [ancho, largo].sort((a, b) => a - b);
        const key = `${dim1}-${dim2}-${caja}`;
    
        if (!acc[key]) {
            acc[key] = {
                ancho: dim1,
                largo: dim2,
                caja,
                cantidad: 0,
                area: 0,
            };
        }
    
        acc[key].cantidad += cantidad;
        acc[key].area += dim1 * dim2 * cantidad;
    
        return acc;
    }, {});
    
    const materialArray = Object?.values(groupedMaterials)?.map(group => {
        const anchoSalida = group?.ancho;
        const largoSalida = group?.largo;
        const areaSalida = anchoSalida * largoSalida * group?.cantidad;
        const precioObj = precioOrden?.find(p => p?.tipoOrden === tipoJob);
        const valorUnitario = repo ? 0 : (precioObj ? precioObj?.valor : 0);
        const valorUnitarioOrden = repo ? 0 : parseInt(valorUnitario * areaSalida);

        return {
            anchoSalida: anchoSalida,
            largoSalida: largoSalida,
            cantidadPlanchas: group?.cantidad,
            cajaExpo: group?.caja,
            areaSalida: areaSalida,
            totalOrden: group?.cantidad,
            valorUnitarioOrden: valorUnitarioOrden,
        };
    });
    
    const handleSubmit = async (values) => {
        setTimeout(async () => {
            try {
                const { pinSalida, responsableSalida } = values;
                if (responsableSalida === null) {
                    notify('Usuario no encontrado, vuelva a intentarlo.', { type: 'warning'});
                    return;
                };
        
                const pinExists = await validarExistenciaPin(pinSalida, client, notify);
                if (!pinExists) {
                    notify('El pin del responsable no se ha encontrado.', { type: 'warning' });
                    return;
                };

                const op = record?.op;
                const qtyLen = record?.qtyLen;
                const nameJob = record?.nameJob;
                const version = record?.version;
                const updatedAt = record?.createdAt;
                const ciudadOrden = record?.ciudadOrden;
                const refMaterial = record?.refMaterial;
                const refPlanchas = record?.refPlanchas;
                const clienteOrden = record?.clienteOrden;
                const calibreOrden = record?.calibreOrden;
                const planchasXColor = record?.planchasXColor;
                const clienteOrdenId = record?.clienteOrdenId;
        
                const cityJob = ciudadOrden === 'ENVIGADO' ? 'Mede' : ciudadOrden === 'BARRANQUILLA' ? 'Barr' : ciudadOrden === 'CALI' ? 'Cali' : 'No hay ciudad';
                const city = cityJob;
        
                await dataProvider.create('salidaOrdens', {
                    data: {
                        opSalida: op,
                        versionSalida: version,
                        clienteSalidaId: clienteOrdenId,
                        clienteSalidaName: clienteOrden,
                        trabajoSalida: nameJob,
                        ciudadSalida: city,
                        totalPlanchas: totalPlanchasOrden,
                        tipoTrabajo: jobType,
                        fechaSalida: updatedAt,
                        materialOrden: materialArray,
                        refMaterial: refMaterial,
                        refPlanchas: refPlanchas,
                        lenQty: qtyLen,
                        calibreOrden: calibreOrden,
                        planchasXColor: planchasXColor,
                    },
                });
        
                await dataProvider.update('ordenSalidas', {
                    id: record?.id,
                    data: {
                        id: record?.id,
                        salidaProd: true,
                        pinSalida: values?.pinSalida ?? record?.pinSalida,
                        responsableSalida: values?.responsableSalida ?? record?.responsableSalida,
                        responsableNombre: values?.responsableNombre ?? record?.responsableNombre,
                    },
                    previousData: record,
                });

                console.log('Antes', record);
                console.log('Después', values);

                notify(`Salida registrada correctamente para la op ${record?.op}`, { type: 'success' });
                redirect('list', 'ordenSalidas');
                refresh();
            } catch (error) {
                notify('No se pudo dar salida a la orden, porque hay un error.', { type: 'error' });
                console.warn('Error al verificar la existencia del PIN:', error.message);
            }
        }, 500);
    };

    return (
        <SimpleForm 
            toolbar={<TolButton/>}
            resource='ordenSalidas'
            onSubmit={handleSubmit}
            initialValues={{ pinSalida: '', responsableSalida: null, responsableNombre: ''}}
        >
            <Grid container item>
                <UserInput source='pinSalida' idField='responsableSalida' nameField='responsableNombre' label='Responsable'/>
            </Grid>
        </SimpleForm>
    );
};

const TolButton = ({ ...props }) => {
    return (
        <Toolbar {...props}>
            <SaveButton
                size='small'
                label='Dar Salida'
                icon={<SendAndArchiveIcon/>}
                sx={ListStyled?.submitButton}
            />
        </Toolbar>
    );
};

export const DialogSalida = () => {
    const title = (
        <DialogTitle style={DialogSalidaStyled.dialogTitle}>
            <Typography style={DialogSalidaStyled?.typography}>
                {`REGISTRAR LA SALIDA`}
            </Typography>
        </DialogTitle>
    );

    return (
        <EditDialog title={title} maxWidth='sm'>
            <SalidaOrden/>
        </EditDialog>
    );
};