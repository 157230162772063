
import React, { useState } from 'react';
import { useDataProvider, useNotify, useRefresh, useRedirect } from 'react-admin';
import { Button, CircularProgress } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const ImportInvoice = ({ remisiones }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [importing, setImporting] = useState(false);

    const handleCreate = async () => {
        setImporting(true);

        try {
            // Group remissions by client and vendor
            const remissionsByClientVendor = remisiones.reduce((acc, remision) => {
                const key = `${remision.clienteId}_${remision.vendedorID}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(remision);
                return acc;
            }, {});

            // Process each group separately
            for (const groupKey in remissionsByClientVendor) {
                const remissionsGroup = remissionsByClientVendor[groupKey];
                const firstRemision = remissionsGroup[0];
                const { clienteId, vendedorID } = firstRemision;

                // Fetch client data
                const clientResponse = await dataProvider.getOne('clientes', { id: clienteId });
                const clientData = clientResponse.data;

                // Prepare initial state for this group
                const user = localStorage.getItem('user');
                const productFactItems = remissionsGroup.flatMap(remision => remision.productFact.items);
                const remisionesIDs = remissionsGroup.map(remision => remision.id);

                const state = {
                    record: {
                        usuarioID: user,
                        clienteId: clienteId || null,
                        vendedorAlegraId: firstRemision.vendedorAlegraId || null,
                        vendedorID: vendedorID || null,
                        ciudadId: firstRemision.ciudadId || null,
                        applyIva: firstRemision.applyIva || null,
                        clienteName: firstRemision.clienteName || null,
                        clientData: clientData || null,
                        alegraClienteId: firstRemision.alegraClienteId || null,
                        productFact: { items: productFactItems },
                        formaPago: clientData ? clientData.datosFacturacion.formaPago : null,
                        remisionesIDs: remisionesIDs,
                        itemsFact: [],
                        emails: clientData ? clientData.datosFacturacion.emailFE.replaceAll(' ', '').split(',') : [],
                    }
                };

                // Proceed with the existing logic for this group
                const remisionesToProcess = remissionsGroup//.filter(remision => !remision.facturaID);

                // Step 2: Fetch all Alegra remissions in parallel
                const alegraRemissionsPromises = remisionesToProcess.map(remision =>
                    dataProvider.getOne('alegraRemissions', { id: remision.alegraId })
                        .then(res => ({ remision, alegraRemision: res.data }))
                        .catch(async error => {
                            console.error('Error fetching Alegra remission:', error);
                            notify('Error: No se pudo obtener la remisión de Alegra', { type: 'error' });

                            // If the remission is void in Alegra, update its status to 'Anulada' in your system
                            if (error.message.includes('void')) {
                                delete remision.facturaID;
                                delete remision.facturaNro;
                                delete remision.productFact;
                                delete remision.createdAt;
                                delete remision.updatedAt;
                                delete remision.factura;
                                delete remision.cotiza;
                                await dataProvider.update('remissions', {
                                    id: remision.id,
                                    data: { ...remision, estado: 'Anulada' },
                                });
                            }
                            return null;
                        })
                );
                const alegraRemissionsResults = await Promise.all(alegraRemissionsPromises);

                // Filter out any null results due to errors
                const validAlegraRemissions = alegraRemissionsResults.filter(result => result && result.alegraRemision);

                // Step 3: Group remissions by invoice
                const invoiceToRemissions = new Map();

                for (const { remision, alegraRemision } of validAlegraRemissions) {
                    if (!alegraRemision.invoices || alegraRemision.invoices.length === 0) {
                        if (alegraRemision.status === 'void') {
                            console.warn('No invoices found in Alegra remission because it is void:', alegraRemision);
                            // Delete unnecessary fields
                            delete remision.facturaID;
                            delete remision.facturaNro;
                            delete remision.productFact;
                            delete remision.createdAt;
                            delete remision.updatedAt;
                            delete remision.factura;
                            delete remision.cotiza;
                            await dataProvider.update('remissions', { id: remision.id, data: { ...remision, estado: 'Anulada' } });
                            continue;
                        } else {
                            notify('Error: No se pudo obtener la factura de Alegra', { type: 'error' });
                            continue;
                        }
                    }
                    const invoice = alegraRemision.invoices[0];
                    const invoiceId = invoice.id;

                    // Initialize the map entry if it doesn't exist
                    if (!invoiceToRemissions.has(invoiceId)) {
                        invoiceToRemissions.set(invoiceId, { invoice, remissions: [] });
                    }

                    invoiceToRemissions.get(invoiceId).remissions.push(remision);
                }

                // Step 4: Fetch all remissions associated with each invoice
                for (const [invoiceId, { invoice }] of invoiceToRemissions) {
                    // Fetch all remission numbers from the invoice items
                    const remissionNumbers = invoice.items
                        .map(item => item.remissionNumber)
                        .filter((num, index, self) => num && self.indexOf(num) === index);

                    // Fetch all remissions corresponding to these remission numbers
                    const allRemissionsPromises = remissionNumbers.map(remissionNumber =>
                        dataProvider.getList('remissions', {
                            filter: { alegraNumeracion: { eq: remissionNumber } },
                            meta: { searchable: 'true' },
                            pagination: { perPage: 1, page: 1 },
                            sort: { field: 'createdAt', order: 'DESC' },
                        }).then(res => res.data[0])
                            .catch(error => {
                                console.error(`Error fetching remission ${remissionNumber}:`, error);
                                return null;
                            })
                    );
                    const allRemissions = await Promise.all(allRemissionsPromises);

                    // Remove any null or undefined entries
                    const validRemissions = allRemissions.filter(remision => remision);

                    // Update the map entry to include all associated remissions
                    invoiceToRemissions.get(invoiceId).remissions = validRemissions;
                }

                // Step 5: Fetch all invoice PDFs in parallel
                const invoiceIds = Array.from(invoiceToRemissions.keys());
                const invoicePdfPromises = invoiceIds.map(invoiceId =>
                    dataProvider.getOne('alegraInvoices', { id: invoiceId })
                        .then(res => ({ invoiceId, pdf: res.data.pdf }))
                        .catch(error => {
                            console.error('Error fetching invoice PDF:', error);
                            notify('Error: No se pudo obtener el PDF de la factura', { type: 'error' });
                            return null;
                        })
                );
                const invoicePdfResults = await Promise.all(invoicePdfPromises);
                const invoiceIdToPdf = new Map();
                for (const result of invoicePdfResults) {
                    if (result) {
                        invoiceIdToPdf.set(result.invoiceId, result.pdf);
                    }
                }
                console.log('invoiceToRemissions', invoiceToRemissions);

                // Step 6: Process each invoice only once
                for (const [invoiceId, { invoice, remissions }] of invoiceToRemissions) {
                    const invoicePdf = invoiceIdToPdf.get(invoiceId);
                    if (!invoicePdf) {
                        console.error('No se pudo obtener el PDF de la factura', invoiceId);
                        continue;
                    }

                    // Prepare items invoiced
                    const itemsInvoiced = [...invoice.items];

                    // Prepare itemsFact and remissionsNumber
                    let itemsFact = [];
                    const remissionsNumber = itemsInvoiced.reduce((acc, item) => {
                        const remissionNumber = item.remissionNumber;
                        if (!remissionNumber) {
                            itemsFact.push(item);
                            return acc;
                        }
                        if (!acc.includes(remissionNumber)) {
                            acc.push(remissionNumber);
                        }
                        return acc;
                    }, []);

                    // Prepare retentions
                    let retenciones = [];
                    const retentionsArray = invoice.retentionsSuggested || invoice.retentions || [];
                    for (const ret of retentionsArray) {
                        const retencion = {
                            id: ret.id,
                            name: ret.name,
                            type: ret.referenceKey,
                            percentage: parseFloat(ret.percentage),
                            valor: parseFloat(ret.amount),
                            valorBase: ret.referenceKey === 'IVA' ? invoice.iva : invoice.subtotal,
                        };
                        retenciones.push(retencion);
                    }

                    // Prepare newItemsFact
                    const newItemsFact = itemsFact.map(item => ({
                        idItem: item.id,
                        odc: item.odc || '',
                        alegraItemId: item.id,
                        orden: 0,
                        iva: item.tax.length > 0,
                        type: "",
                        plateType: '',
                        material: item.name,
                        calibre: 0,
                        ordenVersion: '',
                        descripcion: item.description || '',
                        valorUnitario: parseFloat(item.price),
                        version: 0,
                        ancho: 0,
                        largo: 0,
                        cantidad: item.quantity,
                        cajaNro: 0,
                        caja: item.name,
                        area: 0,
                        valorTotal: parseFloat(item.price) * item.quantity,
                    }));

                    // Calculate total area
                    const totalArea = invoice.items.reduce(
                        (acc, item) => item.unit === 'centimeterSquared' ? acc + item.quantity : acc,
                        0
                    );

                    // Prepare invoice data
                    const { clientData, productFact, ...newState } = state.record;
                    invoice.stamp.date = new Date(invoice.stamp.date+"-05:00").toISOString();
                    const newParams = {
                        ...newState,
                        alegraClienteId: parseInt(invoice.client.id),
                        alegraId: parseInt(invoice.id),
                        alegraNumeracion: invoice.numberTemplate.fullNumber,
                        observaciones: invoice.anotation,
                        applyIva: invoice.tax > 0,
                        remisionesIDs: remissions.map(rem => rem.id),
                        itemsFact: newItemsFact,
                        fechaCrea: new Date(invoice.datetime).toISOString(),
                        fechaVence: new Date(invoice.dueDate).toISOString(),
                        retenciones: retenciones,
                        totalSinRetenciones:
                            invoice.total - retenciones.reduce((acc, retencion) => acc + retencion.valor, 0),
                        subtotal: invoice.subtotal,
                        iva: invoice.tax,
                        total: invoice.total,
                        totalArea: totalArea,
                        pdf: `https://app.alegra.com/invoice/print/id/${invoice.id}`,
                        pdfAlt: invoicePdf,
                        url: `https://app.alegra.com/invoice/view/id/${invoice.id}`,
                        estado: invoice.status,
                        valorPendiente: invoice.balance,
                        stamp: invoice.stamp,
                    };

                    // Create the invoice
                    let fact;
                    try {
                        fact = await dataProvider.create('facturas', { data: newParams });
                        console.log('Factura creada:', fact);
                    } catch (error) {
                        console.error('Error al crear la factura:', error);
                        notify(`Error al crear la factura: ${error.message}`, { type: 'error' });
                        continue; // Skip to the next invoice if there's an error
                    }

                    // Step 7: Process itemsFact and update productFacts
                    try {
                        // Create new productFacts for itemsFact
                        if (newItemsFact.length > 0) {
                            const productFactsPromises = newItemsFact.map(item => {
                                const newItem = {
                                    ...item,
                                    vendedorID: fact.data.vendedorID,
                                    ciudad: fact.data.ciudadId,
                                    facturaID: fact.data.id,
                                    facturaNro: fact.data.alegraNumeracion,
                                    clienteID: fact.data.clienteId,
                                    clienteName: fact.data.clienteName,
                                };
                                return dataProvider.create('productFacts', { data: newItem });
                            });
                            const productFactsResults = await Promise.all(productFactsPromises);
                            console.log('ItemsFact creados:', productFactsResults);
                        }

                        // Update existing productFact items associated with remissions
                        for (const remision of remissions) {
                            if (
                                remision.productFact &&
                                remision.productFact.items &&
                                remision.productFact.items.length > 0
                            ) {
                                const productFactUpdatePromises = remision.productFact.items.map(item => {
                                    const newItem = {
                                        ...item,
                                        ciudad: fact.data.ciudadId,
                                        facturaID: fact.data.id,
                                        facturaNro: fact.data.alegraNumeracion,
                                        clienteID: fact.data.clienteId,
                                        clienteName: fact.data.clienteName,
                                    };
                                    // Clean up unnecessary fields
                                    delete newItem.remision;
                                    delete newItem.createdAt;
                                    delete newItem.updatedAt;
                                    delete newItem.factura;
                                    delete newItem.cotiza;
                                    return dataProvider.update('productFacts', { id: item.id, data: newItem });
                                });
                                const productFactUpdateResults = await Promise.all(productFactUpdatePromises);
                                console.log('ProductFacts actualizados:', productFactUpdateResults);
                            }
                        }

                        // Update all associated remissions with the new facturaID
                        const remisionUpdatePromises = remissions.map(remision => {
                            const updatedRemision = {
                                ...remision,
                                facturaID: fact.data.id,
                            };
                            // Clean up unnecessary fields
                            delete updatedRemision.createdAt;
                            delete updatedRemision.updatedAt;
                            delete updatedRemision.factura;
                            delete updatedRemision.facturaNro;
                            delete updatedRemision.productFact;
                            return dataProvider.update('remissions', { id: remision.id, data: updatedRemision });
                        });
                        const remisionUpdateResults = await Promise.all(remisionUpdatePromises);
                        console.log('Remisiones actualizadas:', remisionUpdateResults);
                    } catch (error) {
                        console.error('Error al procesar items y remisiones:', error);
                        notify(`Error al actualizar datos: ${error.message}`, { type: 'error' });
                    }
                }
            }

            // After processing all groups
            refresh();
            notify('Factura(s) importada(s) exitosamente', { type: 'info' });
        } catch (error) {
            console.error('Error general en el proceso:', error);
            notify(`Error en el proceso: ${error.message}`, { type: 'error' });
        } finally {
            setImporting(false);
        }
    };

    return (
        <Button
            onClick={handleCreate}
            variant='outlined'
            size='small'
            startIcon={importing ? <CircularProgress size={20} /> : <CloudDownloadIcon />}
            disabled={importing}
        >
            Importar Factura
        </Button>
    );
};

export default ImportInvoice;


// import React, { useState } from 'react';
// import { useDataProvider, useNotify, useRefresh, useRedirect, useUpdateMany } from 'react-admin';
// import { Button, CircularProgress } from '@mui/material';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// const ImportInvoice = ({state, remisiones, isSameCliente, isSameVendedor}) => {

//     const dataProvider = useDataProvider();
//     const notify = useNotify();
//     const refresh = useRefresh();
//     const redirect = useRedirect();
//     const [importing, setImporting] = useState(false);
//     const [updateMany, { isPending, error }] = useUpdateMany();


//     // const handleCreate = async () => {
//     //    //get entire alegraRemission
//     //    setImporting(true);
//     //     for (const remision of remisiones) {
//     //         ///  FINDING REMISION IN ALEGRA
//     //         if(remision.facturaID) continue;
//     //         const res = await dataProvider.getOne('alegraRemissions', { id: remision.alegraId });
//     //         console.log('ALEGRA REMISION', await res);
//     //         if(!res.data){
//     //             notify('Error: No se pudo obtener la remisión de Alegra', { type: 'error' });
//     //             return;
//     //         }
//     //         const itemsInvoiced =[...res.data.invoices[0].items];
//     //         console.log('ITEMS INVOICED', res.data.invoices[0]);
//     //         const invoice = res.data.invoices[0];
//     //         const invoicePdf = await dataProvider.getOne('alegraInvoices', { id: res.data.invoices[0].id });
//     //         console.log('INVOICE', invoice);
//     //         ///FIND INVOICES IN REMISION            
            
//     //         let itemsFact = [];
//     //         const remissionsNumber = itemsInvoiced.reduce((acc, item) => {
//     //             const remissionNumber = item.remissionNumber; 
//     //             if(!remissionNumber){
//     //                 itemsFact.push(item);
//     //                 return acc;
//     //             }
//     //             if ( acc.indexOf( remissionNumber) === -1) {
//     //                 acc.push( remissionNumber );
//     //             }
//     //             return acc;
//     //         }, []);
//     //         console.log('RemissionsNumber', remissionsNumber);
//     //         console.log('ItemsFact', itemsFact);
//     //         const remissions = [];

//     //         //Get All Remissions in Invoice

//     //         for(const remissionNumber of remissionsNumber){
//     //             const remision = await dataProvider.getList('remissions', {filter: {alegraNumeracion: {eq:remissionNumber}}, meta: {searchable: 'true'}, pagination: {perPage: 1, page: 1}, sort: {field: 'createdAt', order: 'DESC'}});
//     //             if(remision?.data.length>0){
//     //                 remissions.push(remision.data[0]);
//     //             }
//     //         }
//     //         console.log('REMISIONS FINAL', remissions);


//     //         const {clientData, productFact, ...newState} = state.record;

//     //         let retenciones = [];
//     //         if( invoice?.retentionsSuggested?.length > 0){
//     //         for (const ret of invoice.retentionsSuggested) {
//     //                 const retencion = {
//     //                     id: ret.id,
//     //                     name: ret.name,
//     //                     type: ret.referenceKey,
//     //                     percentage: parseFloat(ret.percentage),
//     //                     valor: parseFloat(ret.amount),
//     //                     valorBase: ret.referenceKey === 'IVA' ? invoice.iva : invoice.subtotal,
//     //                 };
//     //                 retenciones.push(retencion);
//     //             }
//     //         }
//     //         if(invoice.retentions&& invoice.retentions.length > 0){
//     //             retenciones = [];
//     //             for (const ret of invoice.retentions) {
//     //                 const retencion = {
//     //                     id: ret.id,
//     //                     name: ret.name,
//     //                     type: ret.referenceKey,
//     //                     percentage: parseFloat(ret.percentage),
//     //                     valor: parseFloat(ret.amount),
//     //                     valorBase: ret.referenceKey === 'IVA' ? invoice.iva : invoice.subtotal,
//     //                 };
//     //                 retenciones.push(retencion);
//     //             }

//     //         }
//     //         console.log('RETENCIONES', retenciones);
//     //         ////////////////////////START BY TRYING TO CREATE THE INVOICE//////////////////////

//     //         const totalArea = invoice.items.reduce((acc, item) => item.unit === 'centimeterSquared' ? acc + item.quantity : acc, 0);

//     //         const newItemsFact = itemsFact?.map(item => {
//     //             return {
//     //                 idItem: item.id,
//     //                 odc: item.odc || '',
//     //                 alegraItemId: item.id,
//     //                 orden: 0,
//     //                 iva: item.tax.length > 0 ? true : false,
//     //                 type: "",
//     //                 plateType: '',
//     //                 material: item.name,
//     //                 calibre: 0,
//     //                 ordenVersion: '',
//     //                 descripcion: item.description || '',
//     //                 valorUnitario: parseFloat(item.price),
//     //                 version: 0,
//     //                 ancho: 0,
//     //                 largo: 0,
//     //                 cantidad: item.quantity,
//     //                 cajaNro: 0,
//     //                 caja: item.name,
//     //                 area: 0,
//     //                 valorTotal:  parseFloat(item.price)* item.quantity,
//     //                 }
//     //             }
//     //         , []);
//     //         console.log('NEW ITEMS FACT', newItemsFact);
//     //         const newParams = {
//     //                 ...newState,
//     //                 alegraClienteId: parseInt(invoice.client.id),
//     //                 alegraId: parseInt(invoice.id),
//     //                 alegraNumeracion: invoice.numberTemplate.fullNumber,
//     //                 observaciones: invoice.anotation,
//     //                 applyIva: invoice.tax>0?true:false,
//     //                 remisionesIDs: remissions.map(remision => remision.id),
//     //                 itemsFact: newItemsFact,
//     //                 fechaCrea: new Date(invoice.datetime).toISOString(),
//     //                 fechaVence: new Date(invoice.dueDate).toISOString(),
//     //                 retenciones: retenciones,
//     //                 totalSinRetenciones: invoice.total - retenciones.reduce((acc, retencion) => acc + retencion.valor, 0),
//     //                 subtotal: invoice.subtotal,
//     //                 iva: invoice.tax,
//     //                 total: invoice.total,
//     //                 totalArea: totalArea,      
//     //                 pdf: `https://app.alegra.com/invoice/print/id/${invoice.id}`,
//     //                 pdfAlt: await invoicePdf.data.pdf,
//     //                 url: `https://app.alegra.com/invoice/view/id/${invoice.id}`,
//     //                 estado: invoice.status,
//     //                 valorPendiente : invoice.balance,
//     //             };

//     //         //CREATE INVOICE, 
//     //         let fact;
//     //         try{
//     //             fact =  await dataProvider.create('facturas', { data: newParams })
//     //             // if (fact) {
//     //             //     notify('Factura Importada', { type: 'info' });
//     //             // }
//     //             console.log('FACTURA ',await  fact);
//     //         }
//     //         catch (error) {
//     //             console.error('ERROR', error);
//     //             notify(`Error ${error.message}`, {type:'error'}, { smart_count: 4 });
//     //         }


//     //         // CREATE ITEMSFACT TO PRODUCT FACT AND APPEND REMISION AND FACT TO THEM
//     //         // UPDATE PRODUCTFACT TO ADD THE FACTURAID TO IT

            
//     //         try{
//     //             if (newItemsFact&&newItemsFact.length > 0) {
//     //                 console.log('DATA ITEMS FACT', newItemsFact);
//     //                 const res = await Promise.all(
//     //                     newParams.itemsFact?.map(async item => {
//     //                     const factura = await fact;
//     //                     const newItem = { ...item, vendedorID: factura.data.vendedorID , ciudad: factura.data.ciudadId, facturaID: factura.data.id, facturaNro: factura.data.alegraNumeracion, clienteID:factura.data.clienteId, clienteName: factura.data.clienteName};
//     //                     return dataProvider.create('productFacts', { data: { ...newItem } });
//     //                     }),
//     //                 );
//     //             }
//     //             console.log('RES', res);
//     //             if(remissions&&remissions.length > 0){
//     //             // update existing productfact
//     //                 for(const remission of remissions){
//     //                     console.log('REMISION.PRODUCT_FACT', remission.productFact);
//     //                     const res2 = await Promise.all(
//     //                             remission.productFact?.items?.map(async item => {
//     //                                 const factura = await fact;
//     //                                 delete item.remision;
//     //                                 delete item.createdAt;
//     //                                 delete item.updatedAt;
//     //                                 delete item.factura
//     //                                 delete item.cotiza
//     //                                 const newItem = { ...item, vendedorID: factura.data.vendedorID , ciudad: factura.data.ciudadId, facturaID: factura.data.id, facturaNro: factura.data.alegraNumeracion, clienteID:factura.data.clienteId, clienteName: factura.data.clienteName};
//     //                                 console.log('UPDATING PRODUCTFACT', newItem);
//     //                                 return dataProvider.update('productFacts', {id:item.id, data: { ...newItem } });
//     //                             }));
//     //                             console.log('RES2', res2);
//     //                         }
//     //                 }
//     //                 const res3 = await Promise.all(
//     //                     remissions.map(async remision => {
//     //                         const factura = await fact;
//     //                         delete remision.createdAt;
//     //                         delete remision.updatedAt;
//     //                         delete remision.factura
//     //                         delete remision.facturaID
//     //                         delete remision.facturaNro
//     //                         delete remision.productFact
//     //                         console.log('FACTURA', factura);    
//     //                         console.log('UPDATING REMISION', remision);
//     //                         return dataProvider.update('remissions', { id: remision.id, data: { ...remision, facturaID: factura.data.id} });
//     //                     }),
//     //                 );
//     //                 console.log('RES3', res3);
                
//     //             } catch (error) {
//     //                 console.error('ERROR', error);
//     //                 notify(`Error ${error.message}`, {type:'error'}, { smart_count: 3 });
//     //             }            
//     //     }

//     //     setImporting(false);
//     // };

//     const handleCreate = async () => {
//         setImporting(true);
    
//         try {
//             // Step 1: Collect remissions that need processing
//             const remisionesToProcess = remisiones.filter(remision => !remision.facturaID);
    
//             // Step 2: Fetch all Alegra remissions in parallel
//             const alegraRemissionsPromises = remisionesToProcess.map(remision =>
//                 dataProvider.getOne('alegraRemissions', { id: remision.alegraId })
//                     .then(res => ({ remision, alegraRemision: res.data }))
//                     .catch(error => {
//                         console.error('Error fetching Alegra remission:', error);
//                         notify('Error: No se pudo obtener la remisión de Alegra', { type: 'error' });
//                         return null;
//                     })
//             );
//             const alegraRemissionsResults = await Promise.all(alegraRemissionsPromises);
    
//             // Filter out any null results due to errors
//             const validAlegraRemissions = alegraRemissionsResults.filter(result => result && result.alegraRemision);
    
//             // Step 3: Group remissions by invoice
//             const invoiceToRemissions = new Map();
    
//             for (const { remision, alegraRemision } of validAlegraRemissions) {
//                 if (!alegraRemision.invoices || alegraRemision.invoices.length === 0) {
//                     if (alegraRemision.status === 'void') {
//                         console.warn('No invoices found in Alegra remission because it is void:', alegraRemision);
//                         //delete unnessecary fields
//                         delete remision.facturaID;
//                         delete remision.facturaNro;
//                         delete remision.productFact;
//                         delete remision.createdAt;
//                         delete remision.updatedAt;
//                         delete remision.factura;
//                         delete remision.cotiza;
//                         await dataProvider.update('remissions', { id: remision.id, data: { ...remision, estado: 'Anulada' } });
//                         continue;
//                     }
//                     else {
//                     notify('Error: No se pudo obtener la factura de Alegra', { type: 'error' });
//                     continue;
//                     }   
//                 }
//                 const invoice = alegraRemision.invoices[0];
//                 const invoiceId = invoice.id;
    
//                 // Initialize the map entry if it doesn't exist
//                 if (!invoiceToRemissions.has(invoiceId)) {
//                     invoiceToRemissions.set(invoiceId, { invoice, remissions: [] });
//                 }
    
//                 invoiceToRemissions.get(invoiceId).remissions.push(remision);
//             }
    
//             // Step 4: Fetch all remissions associated with each invoice
//             for (const [invoiceId, { invoice }] of invoiceToRemissions) {
//                 // Fetch all remission numbers from the invoice items
//                 const remissionNumbers = invoice.items
//                     .map(item => item.remissionNumber)
//                     .filter((num, index, self) => num && self.indexOf(num) === index);
    
//                 // Fetch all remissions corresponding to these remission numbers
//                 const allRemissionsPromises = remissionNumbers.map(remissionNumber =>
//                     dataProvider.getList('remissions', {
//                         filter: { alegraNumeracion: { eq: remissionNumber } },
//                         meta: { searchable: 'true' },
//                         pagination: { perPage: 1, page: 1 },
//                         sort: { field: 'createdAt', order: 'DESC' },
//                     }).then(res => res.data[0])
//                       .catch(error => {
//                           console.error(`Error fetching remission ${remissionNumber}:`, error);
//                           return null;
//                       })
//                 );
//                 const allRemissions = await Promise.all(allRemissionsPromises);
    
//                 // Remove any null or undefined entries
//                 const validRemissions = allRemissions.filter(remision => remision);
    
//                 // Update the map entry to include all associated remissions
//                 invoiceToRemissions.get(invoiceId).remissions = validRemissions;
//             }
    
//             // Step 5: Fetch all invoice PDFs in parallel
//             const invoiceIds = Array.from(invoiceToRemissions.keys());
//             const invoicePdfPromises = invoiceIds.map(invoiceId =>
//                 dataProvider.getOne('alegraInvoices', { id: invoiceId })
//                     .then(res => ({ invoiceId, pdf: res.data.pdf }))
//                     .catch(error => {
//                         console.error('Error fetching invoice PDF:', error);
//                         notify('Error: No se pudo obtener el PDF de la factura', { type: 'error' });
//                         return null;
//                     })
//             );
//             const invoicePdfResults = await Promise.all(invoicePdfPromises);
//             const invoiceIdToPdf = new Map();
//             for (const result of invoicePdfResults) {
//                 if (result) {
//                     invoiceIdToPdf.set(result.invoiceId, result.pdf);
//                 }
//             }
//             console.log('invoiceToRemissions', invoiceToRemissions);
    
//             // Step 6: Process each invoice only once
//             for (const [invoiceId, { invoice, remissions }] of invoiceToRemissions) {
//                 const invoicePdf = invoiceIdToPdf.get(invoiceId);
//                 if (!invoicePdf) {
//                     console.error('No se pudo obtener el PDF de la factura', invoiceId);
//                     continue;
//                 }
    
//                 // Prepare items invoiced
//                 const itemsInvoiced = [...invoice.items];
    
//                 // Prepare itemsFact and remissionsNumber
//                 let itemsFact = [];
//                 const remissionsNumber = itemsInvoiced.reduce((acc, item) => {
//                     const remissionNumber = item.remissionNumber;
//                     if (!remissionNumber) {
//                         itemsFact.push(item);
//                         return acc;
//                     }
//                     if (!acc.includes(remissionNumber)) {
//                         acc.push(remissionNumber);
//                     }
//                     return acc;
//                 }, []);
    
//                 // Prepare retentions
//                 let retenciones = [];
//                 const retentionsArray = invoice.retentionsSuggested || invoice.retentions || [];
//                 for (const ret of retentionsArray) {
//                     const retencion = {
//                         id: ret.id,
//                         name: ret.name,
//                         type: ret.referenceKey,
//                         percentage: parseFloat(ret.percentage),
//                         valor: parseFloat(ret.amount),
//                         valorBase: ret.referenceKey === 'IVA' ? invoice.iva : invoice.subtotal,
//                     };
//                     retenciones.push(retencion);
//                 }
    
//                 // Prepare newItemsFact
//                 const newItemsFact = itemsFact.map(item => ({
//                     idItem: item.id,
//                     odc: item.odc || '',
//                     alegraItemId: item.id,
//                     orden: 0,
//                     iva: item.tax.length > 0,
//                     type: "",
//                     plateType: '',
//                     material: item.name,
//                     calibre: 0,
//                     ordenVersion: '',
//                     descripcion: item.description || '',
//                     valorUnitario: parseFloat(item.price),
//                     version: 0,
//                     ancho: 0,
//                     largo: 0,
//                     cantidad: item.quantity,
//                     cajaNro: 0,
//                     caja: item.name,
//                     area: 0,
//                     valorTotal: parseFloat(item.price) * item.quantity,
//                 }));
    
//                 // Calculate total area
//                 const totalArea = invoice.items.reduce(
//                     (acc, item) => item.unit === 'centimeterSquared' ? acc + item.quantity : acc,
//                     0
//                 );
    
//                 // Prepare invoice data
//                 const { clientData, productFact, ...newState } = state.record;
    
//                 const newParams = {
//                     ...newState,
//                     alegraClienteId: parseInt(invoice.client.id),
//                     alegraId: parseInt(invoice.id),
//                     alegraNumeracion: invoice.numberTemplate.fullNumber,
//                     observaciones: invoice.anotation,
//                     applyIva: invoice.tax > 0,
//                     remisionesIDs: remissions.map(rem => rem.id),
//                     itemsFact: newItemsFact,
//                     fechaCrea: new Date(invoice.datetime).toISOString(),
//                     fechaVence: new Date(invoice.dueDate).toISOString(),
//                     retenciones: retenciones,
//                     totalSinRetenciones:
//                         invoice.total - retenciones.reduce((acc, retencion) => acc + retencion.valor, 0),
//                     subtotal: invoice.subtotal,
//                     iva: invoice.tax,
//                     total: invoice.total,
//                     totalArea: totalArea,
//                     pdf: `https://app.alegra.com/invoice/print/id/${invoice.id}`,
//                     pdfAlt: invoicePdf,
//                     url: `https://app.alegra.com/invoice/view/id/${invoice.id}`,
//                     estado: invoice.status,
//                     valorPendiente: invoice.balance,
//                 };
    
//                 // Create the invoice
//                 let fact;
//                 try {
//                     fact = await dataProvider.create('facturas', { data: newParams });
//                     console.log('Factura creada:', fact);
//                 } catch (error) {
//                     console.error('Error al crear la factura:', error);
//                     notify(`Error al crear la factura: ${error.message}`, { type: 'error' });
//                     continue; // Skip to the next invoice if there's an error
//                 }
    
//                 // Step 7: Process itemsFact and update productFacts
//                 try {
//                     // Create new productFacts for itemsFact
//                     if (newItemsFact.length > 0) {
//                         const productFactsPromises = newItemsFact.map(item => {
//                             const newItem = {
//                                 ...item,
//                                 vendedorID: fact.data.vendedorID,
//                                 ciudad: fact.data.ciudadId,
//                                 facturaID: fact.data.id,
//                                 facturaNro: fact.data.alegraNumeracion,
//                                 clienteID: fact.data.clienteId,
//                                 clienteName: fact.data.clienteName,
//                             };
//                             return dataProvider.create('productFacts', { data: newItem });
//                         });
//                         const productFactsResults = await Promise.all(productFactsPromises);
//                         console.log('ItemsFact creados:', productFactsResults);
//                     }
    
//                     // Update existing productFact items associated with remissions
//                     for (const remision of remissions) {
//                         if (
//                             remision.productFact &&
//                             remision.productFact.items &&
//                             remision.productFact.items.length > 0
//                         ) {
//                             const productFactUpdatePromises = remision.productFact.items.map(item => {
//                                 const newItem = {
//                                     ...item,
//                                     vendedorID: fact.data.vendedorID,
//                                     ciudad: fact.data.ciudadId,
//                                     facturaID: fact.data.id,
//                                     facturaNro: fact.data.alegraNumeracion,
//                                     clienteID: fact.data.clienteId,
//                                     clienteName: fact.data.clienteName,
//                                 };
//                                 // Clean up unnecessary fields
//                                 delete newItem.remision;
//                                 delete newItem.createdAt;
//                                 delete newItem.updatedAt;
//                                 delete newItem.factura;
//                                 delete newItem.cotiza;
//                                 return dataProvider.update('productFacts', { id: item.id, data: newItem });
//                             });
//                             const productFactUpdateResults = await Promise.all(productFactUpdatePromises);
//                             console.log('ProductFacts actualizados:', productFactUpdateResults);
//                         }
//                     }
    
//                     // Update all associated remissions with the new facturaID
//                     const remisionUpdatePromises = remissions.map(remision => {
//                         const updatedRemision = {
//                             ...remision,
//                             facturaID: fact.data.id,
//                         };
//                         // Clean up unnecessary fields
//                         delete updatedRemision.createdAt;
//                         delete updatedRemision.updatedAt;
//                         delete updatedRemision.factura;
//                         delete updatedRemision.facturaNro;
//                         delete updatedRemision.productFact;
//                         return dataProvider.update('remissions', { id: remision.id, data: updatedRemision });
//                     });
//                     const remisionUpdateResults = await Promise.all(remisionUpdatePromises);
//                     console.log('Remisiones actualizadas:', remisionUpdateResults);
//                 } catch (error) {
//                     console.error('Error al procesar items y remisiones:', error);
//                     notify(`Error al actualizar datos: ${error.message}`, { type: 'error' });
//                 }
//             }
//         } catch (error) {
//             console.error('Error general en el proceso:', error);
//             notify(`Error en el proceso: ${error.message}`, { type: 'error' });
//         } finally {
//             setImporting(false);
//         }
//     };
    
    
//     return(
//         <Button onClick={handleCreate} variant='outlined' size='small' startIcon={importing ? <CircularProgress size={20}/> : <CloudDownloadIcon/> } disabled={!state}>Importar Factura</Button>
//     );
    
// }    


// export default ImportInvoice;
