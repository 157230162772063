

import React, { useState, useCallback, useEffect, useRef } from "react";
import { Create, SimpleForm, Toolbar, SaveButton,
        TextField,  SelectInput, useNotify, Button, ReferenceInput,
        useRegisterMutationMiddleware, useDataProvider, ReferenceField, Labeled,
        SelectArrayInput, useRefresh,
        useRecordContext,
    } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import ObservacionesCliente from "../components/ObservacionesCliente";
import { Grid } from '@mui/material';
import { Sucursales } from "../../utils";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { OrdenesPendientesShow } from "../components/OrdenesPendientesShow";
import { EditableDatagridCustom } from "../components/EditableDatagridCustom";
import CircularProgress from '@mui/material/CircularProgress';
import { TotalesComponentFactura } from "./components/TotalesComponentFactura";
import { TiempoPago } from '../../utils'
import RemisionArrayInput from "./components/RemisionArrayInput";
import RemisionesShow from "./components/RemisionesShow";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormContext } from 'react-hook-form';
import { Dialog, DialogContent, DialogActions, Button as MuiButton, TextField as TextFieldMui } from '@mui/material';
import { useCreateSuggestionContext } from 'react-admin';
import { formatCurrencyString } from "../../utils";

// DO NOT DELETE, USED FOR TESTING
import { testFact, pdfResponseTest } from "./components/testFact";
import ItemsDatagridShow from "./components/ItemsDatagridShow";



const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { getValues } = useFormContext();
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const isProcessingRef = useRef(false);
    const record = useRecordContext();
    const setProcessingState = (value) => {
        isProcessingRef.current = value;
        setIsProcessing(value);
    };

    const middleware = useCallback(async (resource, params, options, next) => {
        if (isProcessingRef.current) {
            notify('La factura ya está siendo procesada.', { type: 'info' });
            return;
        }
        setProcessingState(true);

        let { data } = params;
        let newParams = {};
        const uniqueId = uuidv4();
        let response;
        let pdfResponse;


        //CREATING REMISIONES IN ALEGRA
        try {
            console.log("Starting Alegra creation");
            const alegraJson = getAlegraJson(data, true, isDraft, uniqueId);
            console.log('ALEGRA JSON', alegraJson);

            // UNCOMMENT THE FOLLOWING FOR PRODUCTION
            response = await dataProvider.create('alegraInvoices', { data: alegraJson });

            // //pdfResponse =  await dataProvider.getOne('alegraInvoices', { id: response.data.id });
            //response = testFact;
            // //pdfResponse = pdfResponseTest;
            // throw new Error('Error: Testing error');
            //console.log('RESPONSE', response);
            //console.log('PDF RESPONSE', pdfResponse);
            } catch (error) {
                console.error('Error creando la factura, intentando conseguir datos', error);
                notify(`Error creando la factura, Intentando nuevamente...`, { type: 'warning', autoHideDuration: 10000 });
                // Retry logic parameters
                const maxRetries = 8;
                const retryDelay = 1000; // Starting delay in milliseconds
            
                for (let attempt = 1; attempt <= maxRetries; attempt++) {
                  try {
                    console.log(`Attempt ${attempt} to find the invoice in Alegra`);
            
                    // Fetch recent invoices, ordered by creation date
                    const searchResponse = await dataProvider.getList('alegraInvoices', {
                      filter: {},
                      sort: { field: 'datetime', order: 'DESC' },
                      pagination: { page: 1, perPage: 2 }, 
                    });
            
                    // Search for the invoice with the matching UUID in 'observaciones'
                    console.log('Search response', searchResponse);
                    const existingInvoice = searchResponse.data.find(invoice => {
                      return invoice.observations && invoice.observations.includes(`UUID:${uniqueId}`);
                    });
            
                    if (existingInvoice) {
                      response = { data: existingInvoice };
                      //pdfResponse = { data: existingInvoice };;
            
                      notify('La Factura fue creada en alegra a pesar del error', { type: 'success',  autoHideDuration: 5000 });
                      console.log('Found existing invoice', existingInvoice);
                      break; // Exit the retry loop since we've found the invoice
                    } else {
                      if (attempt === maxRetries) {
                        // Maximum retries reached without finding the invoice
                        notify(`Error: No se pudo encontrar la factura en alegra [${error.message}]`, { type: 'error',  autoHideDuration: 5000 });
                        setProcessingState(false);
                        return;
                      } else {
                        // Wait before the next retry
                        await new Promise(resolve => setTimeout(resolve, retryDelay * attempt)); // Exponential backoff
                        continue; // Continue to the next attempt
                      }
                    }
                  } catch (searchError) {
                    console.error(`Error during attempt ${attempt} to search for existing invoice`, searchError);
                    if (attempt === maxRetries) {
                      notify(`No se encontró factura relacionada en alegra: ${searchError.message}`, { type: 'error',  autoHideDuration: 5000  });
                      setProcessingState(false);
                      return;
                    } else {
                      // Wait before the next retry
                      await new Promise(resolve => setTimeout(resolve, retryDelay * attempt)); // Exponential backoff
                      continue; // Continue to the next attempt
                    }
                  }
                }
              }
            
            ///
            if (response?.data) {
                notify('Factura creada en Alegra', { type: 'success',  autoHideDuration: 3000  });
                console.log('FACTURA CREADA', response);
            }
            // if (pdfResponse?.data) {
            //     //notify('Factura PDF ok', { type: 'success' }, { smart_count: 1 });
            //     console.log('PDF RESPONSE', pdfResponse);
            // }
            const { productFact, clientData, ordenes, remisiones, createdAt, updatedAt, oldFacturaIDs, ...dataNew } = data;
            newParams = {
                ...params,
                data: {
                    ...dataNew,
                    alegraId: response.data.id,
                    alegraNumeracion: response.data.numberTemplate.fullNumber,
                    pdf: `https://app.alegra.com/invoice/print/id/${response.data.id}`,
                    url: `https://app.alegra.com/invoice/view/id/${response.data.id}`,
                    estado: response.data.status,
                    fechaCrea: new Date().toISOString(),
                    fechaVence: new Date(response.data.dueDate).toISOString(),
                    usuarioID: localStorage.getItem('user'),
                    valorPendiente: response.data.balance,
                    stamp: {...response.data.stamp, date:new Date(response.data.stamp.date+"-05:00")},
                    sharedID: uniqueId,
                },
            };
            console.log("newParams", newParams)
        
        
        // CREATING FACTURA GRAFIFLEX INTERACTIVO
        let factNew;
        try {
            console.log("Starting Factura GI Creation");
            factNew = await next(resource, newParams, options);
            console.log("FACTURA GI RESPONSE:", factNew);
            console.log("Data Before SQLORDENES", data);
        } catch (error) {
            console.error('Error creando factura internamente', error);
            notify(`Error creando factura internamente ${error.message}`, { type: 'error', autoHideDuration: 5000  });
            setProcessingState(false);
            return;
        }
        // UPDATING SQL ORDENES
        try {
            const testing = false; // Set to true if you don't want to update the sqlOrdenOut table
            console.log("Starting SQL UPDATES");
            if (!testing) {
                const facturaNumber = newParams.data.alegraNumeracion.match(/\d+/)[0];
                const updatePromises = [];

                

                /// FOR NEW INVOICE CREATION
                if (data?.kindOfDuplicate !== 'duplicate' && data?.kindOfDuplicate !== 'remake') {
                    if (data?.ordenes) {
                        updatePromises.push(
                            ...data.ordenes.map((orden) =>
                                dataProvider.update('sqlOrdenFacturada', {
                                    filter: { numero: orden.numero, versionop: orden.version, factura: facturaNumber },
                                })
                            )
                        );
                    }
                    if (data?.productFact?.items) {
                        updatePromises.push(
                            ...data.productFact.items.map((product) =>
                                dataProvider.update('sqlOrdenFacturada', {
                                    filter: { numero: product.orden, versionop: product.version, factura: facturaNumber },
                                })
                            )
                        );
                    }
            } else {
                // if remake check in itemsFact 
                if ( data?.itemsFact.length > 0 && data?.kindOfDuplicate === 'remake' ) {
                    const groupedItems = data.itemsFact.reduce((acc, item) => {
                        // ignore items that don't have orden or version
                        if (!item.orden || !item.version) {
                            return acc;
                        }
                        // generate key for the group orden-version
                        const key = `${item.orden}-${item.version}`;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(item);
                        return acc;
                    }, {});

                    updatePromises.push(
                        ...Object.keys(groupedItems).map((key) => {
                            const [orden, version] = key.split('-');
                            return dataProvider.update('sqlOrdenFacturada', {
                                filter: { numero: orden, versionop: version, factura: facturaNumber },
                            });
                        })
                    );
                }

                //if duplicate check and productFact
                if (data?.productFact?.items?.length > 0 && data?.kindOfDuplicate === 'duplicate') {
                    const groupedItems = data.productFact.items.reduce((acc, item) => {
                        // ignore items that don't have orden or version
                        if (!item.orden || !item.version) {
                            return acc;
                        }
                        // generate key for the group orden-version
                        const key = `${item.orden}-${item.version}`;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(item);
                        return acc;
                    }, {});

                    updatePromises.push(
                        ...Object.keys(groupedItems).map((key) => {
                            const [orden, version] = key.split('-');
                            return dataProvider.update('sqlOrdenFacturada', {
                                filter: { numero: orden, versionop: version, factura: facturaNumber },
                            });
                        })
                    );
                }
            }
                
                await Promise.all(updatePromises);

            }
        } catch (error) {
            console.error('Error Actualizando OP', error);
            setProcessingState(false);
            notify(`Error Actualizando OP ${error.message}`, { type: 'error',  autoHideDuration: 5000 });
        }
        //UPDATING STOCK
        // discount only itemFacts because ProductFacts are already discounted in remisiones
        try{
            if (data.itemsFact?.length > 0 && data.kindOfDuplicate !== 'duplicate' && data.kindOfDuplicate !== 'remake') {
                discountFromStock(data.itemsFact);
            }
            else if (data.kindOfDuplicate === 'remake' && data.itemsFact?.length > 0) {
                updateStock(data.itemsFact, record.itemsFact);
            }
        }catch(error){
            console.error('Error Actualizando Inventario', error);
            notify(`Error Actualizando Inventario ${error.message}`, { type: 'error',  autoHideDuration: 5000  });
            setProcessingState(false);
        }
        
        /// CREATING AND UPDATING PRODUCTFACTS
        try {
            
            if (data.itemsFact?.length > 0) {
                console.log("STARTING ITEMS FACT CREATES");
                const res = await Promise.all(
                    newParams.data.itemsFact.map((item) => {
                        const newItem = {
                            ...item,
                            vendedorID: factNew.vendedorID,
                            ciudad: factNew.ciudadId,
                            facturaID: factNew.id,
                            facturaNro: factNew.alegraNumeracion,
                            clienteID: factNew.clienteId,
                            clienteName: factNew.clienteName,
                        };
                        return dataProvider.create('productFacts', { data: newItem });
                    })
                );
            }
    
            if (data.productFact?.items?.length > 0) {
                console.log("Starting PRODUCTFACT UPDATES");
                const res2 = await Promise.all(
                    data.productFact.items.map((item) => {
                        const { remision, createdAt, updatedAt, factura, cotiza, ...rest } = item;
                        const newItem = {
                            ...rest,
                            vendedorID: factNew.vendedorID,
                            ciudad: factNew.ciudadId,
                            facturaID: factNew.id,
                            facturaNro: factNew.alegraNumeracion,
                            clienteID: factNew.clienteId,
                            clienteName: factNew.clienteName,
                        };
                        return dataProvider.update('productFacts', { id: item.id, data: newItem });
                    })
                );
            }
        } catch (error) {
            console.error('ERROR Actualizando Inventario', error);
            notify(`Error Actualizando Inventario ${error.message}`, { type: 'error',  autoHideDuration: 5000  });
            setProcessingState(false);
        }
        // UPDATING REMISIONES
        try {
            if (data.productFact?.items?.length > 0) {
                console.log("Starting REMISION UPDATES");
                const remisionIds = [...new Set(data.productFact.items.map((item) => item.remisionID))];
                const remisiones = await dataProvider.getMany('remissions', { ids: remisionIds });
    
                const remisionUpdatePromises = remisiones.data.map((remision) => {
                    const { factura, productFact, ...remisionData } = remision;
                    return dataProvider
                        .update('remissions', {
                            id: remisionData.id,
                            data: { ...remisionData, estado: 'Facturada', facturaID: factNew.id },
                        })
                        .then(() => {
                            notify('Remisiones Actualizadas', { type: 'info' });
                        });
                });
    
                await Promise.all(remisionUpdatePromises);
            }
        } catch (error) {
            console.error('Error Actualizando Remisiones', error);
            notify(`Error Actualizando Remisiones ${error.message}`, { type: 'error' ,  autoHideDuration: 5000 });
            setProcessingState(false);
        }
        // SENDING EMAILS
        try {
            if (data.emails) {
                console.log("Starting EMAIL SENDING", newParams.data.alegraId, data.emails);
                const emails = data.emails.map((email) => email.id);
                await dataProvider.email('alegraInvoices', {
                    id: newParams.data.alegraId,
                    emails: [...data.emails],
                });
            }
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error Enviando Correos: ${error.message}`, { type: 'error',  autoHideDuration: 5000  });
            setProcessingState(false);
        }
        finally{
            setProcessingState(false);
        }
    }, [isProcessing, notify, setIsProcessing, dataProvider]);
    
    const updateStock = async (itemsNew, itemsOld) => {
        // Filter out items with invalid area or undefined 'caja'
        const validItemsOld = itemsOld.filter(
            item => item.area > 0 && item.caja !== undefined && item.caja !== null
        );
        const validItemsNew = itemsNew.filter(
            item => item.area > 0 && item.caja !== undefined && item.caja !== null
        );
    
        // Summarize total area per 'caja' for old and new items
        const cajasOld = validItemsOld.reduce((acc, item) => {
            const found = acc.find(it => it.caja === item.caja);
            if (found) {
                found.area += item.area;
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
    
        const cajasNew = validItemsNew.reduce((acc, item) => {
            const found = acc.find(it => it.caja === item.caja);
            if (found) {
                found.area += item.area;
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
    
        // Compute area differences per 'caja'
        const cajasMap = new Map();
    
        // Add areas from 'cajasOld' (stock increases)
        for (const cajaOld of cajasOld) {
            cajasMap.set(cajaOld.caja, (cajasMap.get(cajaOld.caja) || 0) + cajaOld.area);
        }
    
        // Subtract areas from 'cajasNew' (stock decreases)
        for (const cajaNew of cajasNew) {
            cajasMap.set(cajaNew.caja, (cajasMap.get(cajaNew.caja) || 0) - cajaNew.area);
        }
    
        // Convert map to array
        const cajas = Array.from(cajasMap.entries()).map(([caja, area]) => ({ caja, area }));
    
        // Update stocks
        for (const caja of cajas) {
            try {
                if (caja === null || caja.caja === undefined) continue;
    
                const stock = await dataProvider.getOne('stocks', { id: caja.caja });
                const newStock = {
                    ...stock.data,
                    areaRestante: stock.data.areaRestante + caja.area,
                };
                delete newStock.retales;
                await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            } catch (error) {
                console.error(`ERROR updating stock for caja ${caja.caja}`, error);
                notify(
                    `Error updating stock for caja ${caja.caja}: ${error.message}`,
                    { type: 'error' }
                );
            }
        }
        refresh();
    };

    const checkRemisionStatus = async (values) => {
        const productosRemisionados = values.productFact;
        const items = productosRemisionados?.items || [];
    
        // Extract unique remissions based on remision.id
        const remisionMap = new Map();
        items.forEach(item => {
            const remisionId = item.remision.id;
            if (!remisionMap.has(remisionId)) {
                remisionMap.set(remisionId, item.remision);
            }
        });
    
        // Convert the map values to an array of unique remissions
        const remisions = Array.from(remisionMap.values());
    
        console.log('REMISIONS', remisions);
    
        let updatedRemisionsList = []; // List to track updated remissions
    
        // Fetch the status of each remision from Alegra
        const statusPromises = remisions.map(remision =>
            dataProvider.getOne('alegraRemissions', { id: remision.alegraId })
                .then(response => {
                    const status = response?.data.status;
                    if (status === "closed") {
                        remision.estado = "Facturada";
                        updatedRemisionsList.push(remision);
                    } else if (status === "void") {
                        remision.estado = "Anulada";
                        updatedRemisionsList.push(remision);
                    } else {
                        console.log('No se debe actualizar el estado', remision.alegraNumeracion);
                    }
                    return remision; // Return the remision (updated or not)
                })
                .catch(error => {
                    console.error('ERROR', error);
                    notify('Error: no se pudo actualizar el estado', { type: 'error' });
                    throw error; // Rethrow the error to be caught in the calling function
                })
        );
    
        // Wait for all status updates to complete
        const updatedRemisions = await Promise.all(statusPromises);
    
        // Update the remisions in your database if needed
        const updatePromises = updatedRemisionsList.map(remision => {
            const { productFact, factura, ...updatedItem } = remision;
            return dataProvider.update('remissions', {
                id: updatedItem.id,
                data: { ...updatedItem },
                previousData: updatedItem,
            });
        });
    
        await Promise.all(updatePromises);
    
        // If any remisions were updated, throw an error with details
        if (updatedRemisionsList.length > 0) {
            const updatedRemisionNumbers = updatedRemisionsList
                .map(rem => rem.alegraNumeracion || rem.id)
                .join(', ');
            throw new Error(`Las siguientes remisiones han cambiado de estado y fueron actualizadas: ${updatedRemisionNumbers}`);
        }
    };
    
    const discountFromStock = async (items) => {
        const cajas = items.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        for (const caja of cajas) {
            if(caja === null || caja.area<=0 || caja.caja === undefined) continue;
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante - caja.area,
            }
            delete newStock.retales
            await dataProvider.update('stocks', { id: caja.caja, data: newStock });
        }
    }
    
    const handlePreview = async () => {
        setLoadingPreview(true);
        const values = getValues();
        ////console.log('VALUES', values);
        const alegraJson =  getAlegraJson(values, false);
        const response = await dataProvider.preview('alegraInvoices', { data: alegraJson })
        ////console.log('RESPONSE', response);
        ////console.log('PDF URL', await response.data.pdfUrl);
        window.open(await response.data.pdfUrl, '_blank');
        setLoadingPreview(false);
    }

    const createItemObject = (item) => {
        let descripcion = item.descripcion;
        if (item.ancho > 0 && item.largo > 0 && item.area > 0) {
            descripcion += `\n${item.ancho} x ${item.largo}   Cant: ${item.cantidad}`;
        }
        if (item.odc && item.odc.trim().length > 0) {
            descripcion += ` - ODC:${item.odc}`;
        }
        return {
            id: item.alegraItemId,
            reference: item.ordenVersion,
            description: descripcion,
            quantity: item.area > 0 ? item.area : item.cantidad,
            price: item.valorUnitario,
            tax: [{ id: item.iva ? 3 : 0 }],
        };
    };
    
    const getAlegraJson = (values, stamp = false, isDraft, uniqueId) => {
        const customer = values.clientData;
        const diasPago = TiempoPago.find(tp => tp.id === values.formaPago);
        const dueDateEnd = new Date(new Date().setDate(new Date().getDate() + diasPago.value));
    
        let odcsArr = values?.itemsFact?.map(item => item.odc?.trim()) || [];
        const odcArr2 = values?.productFact?.items?.map(item => item.odc?.trim()) || [];
    
        if (odcsArr.length > 0 && odcArr2.length > 0) {
            odcsArr = [...odcsArr, ...odcArr2];
        } else if (odcsArr.length === 0 && odcArr2.length > 0) {
            odcsArr = odcArr2;
        }
    
        const odcs = [...new Set(odcsArr)].toString().replaceAll(',', '-');
    
        // Initialize 'alegraRemisionIds' and 'remisionNumbers'
        let alegraRemisionIds = [];
        let remisionNumbers = [];
    
        // Initialize 'itemsFactArray' from 'values.itemsFact' using the helper function
        let itemsFactArray = values.itemsFact.map(item => createItemObject(item));
    
        // Process 'productFact.items'
        values.productFact?.items?.forEach(item => {
            // Collect all 'remisionNro' for annotations
            if (item.remisionNro && !remisionNumbers.includes(item.remisionNro)) {
                remisionNumbers.push(item.remisionNro);
            }
            //itemsFactArray.push(createItemObject(item));

    
            if (item.oldFacturaID) {
                // Convert item to 'item fact' and add to 'itemsFactArray'
                itemsFactArray.push(createItemObject(item));
            } else {
                // Add 'remision.alegraId' to 'alegraRemisionIds' if not already included
                const toFind = alegraRemisionIds.find(r => r.id === item.remision.alegraId.toString());

                if (item.remision?.alegraId && !toFind) {
                    alegraRemisionIds.push({id: item.remision.alegraId.toString()});
                }
            }
        });
    
        // Build annotations
        let anotaciones = '';
        if (values.observaciones) {
            const title = 'Nota: ';
            const value = values.observaciones;
            const emptyChar = 120 - value.length - title.length;
            anotaciones += emptyChar > 0 ? `${title}${value} ${' '.repeat(emptyChar)}` : `${title}${value}`;
        }
        if (remisionNumbers.length > 0) {
            const title = 'Remisiones Asociadas: ';
            const value = remisionNumbers.join(',  ');
            if (value.length > 38) {
                const value1 = value.substring(0, 38);
                const value2 = value.substring(38);
                const emptyChar = 120 - value1.length - title.length;
                anotaciones += emptyChar > 0 ? `${title}${value1} ${' '.repeat(emptyChar)}` : `${title}${value1}`;
                anotaciones += 120 - value2.length > 0 ? `${value2} ${' '.repeat(120 - value2.length)}` : `${value2}`;
            } else {
                const emptyChar = 120 - value.length - title.length;
                anotaciones += emptyChar > 0 ? `${title}${value} ${' '.repeat(emptyChar)}` : `${title}${value}`;
            }
        }
        if (odcs.length > 0) {
            const title = 'Orden de Compra: ';
            const value = odcs;
            const emptyChar = 120 - value.length - title.length;
            anotaciones += emptyChar > 0 ? `${title}${value} ${' '.repeat(emptyChar)}` : `${title}${value}`;
        }
        if (values.retenciones.length > 0) {
            const title = 'Total Menos Retenciones: ';
            const value = formatCurrencyString(values.totalSinRetenciones, 1);
            const emptyChar = 120 - value.length - title.length;
            anotaciones += emptyChar > 0 ? `${title}${value} ${' '.repeat(emptyChar)}` : `${title}${value}`;
        }
    
        // Handle 'kindOfDuplicate' case
        // if (values.kindOfDuplicate === 'duplicate') {
        //     console.log('DUPLICADO', values.kindOfDuplicate);
        //     alegraRemisionIds = [];
        //     remisionNumbers = [];
        //     let itemsFactDuplicates = values.productFact.items.map(item => {
        //         // Collect 'remisionNro' for annotations
        //         if (item.remisionNro && !remisionNumbers.includes(item.remisionNro)) {
        //             remisionNumbers.push(item.remisionNro);
        //         }
        //         return createItemObject(item);
        //     });
        //     itemsFactArray = itemsFactDuplicates;
        // }
    
        // Build the final Alegra JSON
        const alegraJson = {
            paymentForm: customer.formaPago === 'De_Contado' ? 'CASH' : 'CREDIT',
            paymentMethod: 'BANK_DEPOSIT',
            type: 'NATIONAL',
            stamp: { generateStamp: stamp },
            operationType: 'STANDARD',
            observations: `UUID:${uniqueId}`,
            purchaseOrderNumber: odcs || '',
            status: isDraft ? 'draft' : 'open',
            numberTemplate: { id: '23' },
            date: new Date(),
            dueDate: dueDateEnd,
            anotation: anotaciones,
            client: { id: values.alegraClienteId },
            seller: values.vendedorAlegraId ? { id: values.vendedorAlegraId } : undefined,
            items: itemsFactArray,
            retentions: values.retenciones.map(retencion => {
                return {
                    id: retencion.id,
                    amount:
                        retencion.type === 'IVA'
                            ? (retencion.percentage / 100) * values.iva
                            : (retencion.percentage / 100) * values.subtotal,
                };
            }),
            remissions: alegraRemisionIds,
            costCenter: {
                id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).costCenterId,
            },
            warehouse: {
                id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).alegraID,
            },
        };
        if (alegraJson.items.length === 0) {
            delete alegraJson.items;
        }
    
        return alegraJson;
    };
    
    
    useRegisterMutationMiddleware(middleware);
    
    return (
        <Toolbar>
            <Grid container spacing={1}>
                {/* <Grid item md={2}>
                    <SaveButton key='borrador' label="Guardar Borrador"  variant="outlined" alwaysEnable />            
                </Grid>     */}
                <Grid item md={2}>
                    <Button
                        key={'preview'}
                        label="Vista Previa"
                        sx={{ padding: '8px 16px'}}
                        startIcon={loadingPreview ? <CircularProgress size={20} />:<VisibilityIcon/>}
                        onClick={handlePreview}
                        variant="outlined"
                    />   
                </Grid>    
                <Grid item md={10} >
                    <SaveButton
                        key={'emitir'}
                        label="Emtir Factura"
                        icon={<SendAndArchiveIcon />}
                        alwaysEnable
                        sx={{float: 'right'}}
                        disabled={isProcessing}
                    />
                </Grid>   
            </Grid> 
        </Toolbar>
    );
};





const FacturaCreate = () => {
    const [ refreshDatagrid, setRefreshDatagrid ] = useState();
    
    const refetchData = (v) => {
        console.log('REFETCHING DATA', v);
        setRefreshDatagrid(prev => !prev);
    }
        
    return (
        <Create redirect='show'>
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container spacing={1}>
                    <Grid container direction="row" justifyContent={'space-evenly'} spacing={2}>
                        <Grid item xs={12} md={12} container alignItems={'center'}>
                           < Grid item >
                                <Labeled>
                                    <ReferenceField source='clienteId' reference='clientes'>
                                        <TextField source="razonSocial" label="Cliente" sx={{ padding: '10px', fontSize: '25px' }} />
                                    </ReferenceField>
                                </Labeled>
                           </Grid>
                           < Grid item >
                                <ObservacionesCliente />
                           </Grid>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <RemisionesArrayInputDisableable refreshDatagrid={() => setRefreshDatagrid((prev) => !prev)} />
                        </Grid>
                        <Grid item xs={12} md={5} container alignItems="center">
                            <EmailArrayInput />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SelectInput source="ciudadId" label="Ciudad" choices={Sucursales} optionText='name' fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ReferenceInput source='vendedorID' reference='usuarios'>
                                <SelectInput optionText={(r) => `${r.nombres} ${r.apellidos}`} label="Vendedor" fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SelectInput source='formaPago' choices={TiempoPago} label="Tiempo Pago" fullWidth />
                        </Grid>
                    </Grid>
                    <ItemsDisplay refreshDatagrid={refreshDatagrid}/>
                    {/* <Grid item xs={12} md={12}>
                        <EditableDatagridCustom refreshDatagrid={refreshDatagrid} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <RemisionesShow />
                    </Grid> */}
                    
                    <TotalesComponentFactura changed={refetchData} type='factura' />
                </Grid>
            </SimpleForm>
            <OrdenesPendientesShow />
        </Create>
    );
};

const RemisionesArrayInputDisableable = (props) => {
    const { getValues } = useFormContext();
    const kindOfDuplicate = getValues('kindOfDuplicate');
    return kindOfDuplicate&&(kindOfDuplicate === 'duplicate' || kindOfDuplicate=== 'remake') ? <RemisionArrayInput disabled={true} {...props} /> : <RemisionArrayInput {...props} />;
};

const ItemsDisplay = (props) => {
    const { getValues } = useFormContext();
    const kindOfDuplicate = getValues('kindOfDuplicate')
    return (<>
            {
                kindOfDuplicate&&kindOfDuplicate === 'duplicate' ?
                <Grid item xs={12} md={12}>
                    <ItemsDatagridShow />
                </Grid>                
                :
                <>
                    <Grid item xs={12} md={12}>
                        <EditableDatagridCustom refreshDatagrid={props.refreshDatagrid} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <RemisionesShow />
                    </Grid>
                </>
            }
            </>
    )
}


const EmailArrayInput = (props) => {
    const { getValues, setValue } = useFormContext();
    const emailsString = getValues('clientData.datosFacturacion.emailFE').replaceAll(' ', '');
    //console.log('EMAILS', emailsString)
    const [emails, setEmails] = useState([]);
    const [openDialog , setOpenDialog] = useState(false);
    useEffect(() => {
        if (emailsString) {
            const emailArray = emailsString.split(',');
            if (emailArray.length === 1 && emailArray[0] === '') {
                setEmails([]);
                return;
            }
            const formattedEmails = emailArray.map(email => ({
                id:email.trim(),
                name:email.trim()
            }));
            setEmails(formattedEmails);
            //console.log(formattedEmails)
        }
    }, [emailsString]);

    return (
        <>
            <SelectArrayInput
                source="emails"
                label="Emails"
                fullWidth
                choices={emails}
                create={<CreateEmailDialog />}
                createLabel="Agregar Email"
            />
            {/* //<CreateEmailDialog open={openDialog} setOpen={setOpenDialog}/> */}

        </>
        
    );
};

const CreateEmailDialog = (props) => {
    const { onCancel, onCreate } = useCreateSuggestionContext();
    const [values2, setValues2] = React.useState('');
    const [error, setError] = React.useState(false);
    const [touched, setTouched] = React.useState(false); // New state to track if the user has interacted with the input
    const { setValue, getValues } = useFormContext();
    const existingEmails = getValues('clientData.datosFacturacion.emailFE');
    const refresh = useRefresh();

    const handleEmailChange = event => {
        setValues2(event.target.value);
        if (touched && error) {
            // Clear error if user starts typing again after an error
            setError(false);
        }
    };

    const handleEmailBlur = () => {
        if (!touched) setTouched(true);
        validateEmail(values2);
    };

    const validateEmail = (email) => {
        if (!touched) return; // Skip validation if the field hasn't been interacted with
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setError(!isValidEmail);
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (!touched) setTouched(true);
        validateEmail(values2);

        if (error || !values2) return; // Prevent submission if there's an error or no value

        const newOption = { id: values2, name: values2 };
        setValue('clientData.datosFacturacion.emailFE', `${existingEmails},${values2}`);
        setValues2('');
        onCreate(newOption);
        refresh();
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextFieldMui
                        error={touched && error} // Only show error if the field has been touched
                        label="Email"
                        value={values2}
                        onChange={handleEmailChange}
                        onBlur={handleEmailBlur}
                        autoFocus
                        helperText={touched && error ? "Please enter a valid email address" : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <MuiButton type="submit" disabled={error || !values2}>
                        Save
                    </MuiButton>
                    <MuiButton onClick={onCancel}>Cancel</MuiButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

// const ObservacionesCliente = (props) => {
//     const record = useRecordContext()
//     //console.log('RECORD', record)


//     return(
//         <StickyNote title="Precios" clientData={record.clientData} label="Observaciones" multiline fullWidth/>
//     )
// }


export default FacturaCreate;
