import React from "react"
import { SimpleForm } from "react-admin"
import { CreateDialog } from "@react-admin/ra-form-layout"
import CreateProductLayout from "./components/CreateProductLayout"


const ProductCreateDialog = (props)=>{
    
    return(
    <CreateDialog fullWidth maxWidth="md">
        <SimpleForm>
            <CreateProductLayout />
        </SimpleForm>
    </CreateDialog>  
    )
}

export default ProductCreateDialog