import { LenEdit } from './LenEdit';
import { ImageCDI } from './ImageCDI';
import { useFormContext } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import ReportIcon from '@mui/icons-material/Report';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import { LenStyled, ListStyled } from '../style/ExposicionStyles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'; 
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import BeenhereRoundedIcon from '@mui/icons-material/BeenhereRounded';
import { checkOp, checkLen, compararArrays, findPantoneColor, extractColorLenId } from '../../../utils/utilsExposicion';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Tooltip, Fade, Button, LinearProgress } from '@mui/material';
import { useList, Datagrid, useNotify, TopToolbar, useGetList, NumberField, FunctionField, useRecordContext, ListContextProvider } from 'react-admin';
import { ChatBubble } from './chatBubbleExpo';

export const LenContent = () => {
  
  const record = useRecordContext();
  const anulada = record?.anulada;
  const calibreExp = record?.calibre
  const [lenData] = useState(record?.len || []);
  const pendienteSalida = record?.pendienteSalida;
  const [showWarningIcon, setShowWarningIcon] = useState([]);
  const { data: dataOrdenSalidas, isLoading: loadingOrdenSalidas } = useGetList('OrdenSalidas');

  const filterObsr = record?.len?.map(len => ({
    NUMERO: parseInt(len?.op),
    VERSIONOP: parseInt(len?.version),
  }));
  
  const { data: dataObs } = useGetList('sqlObservaciones', {
    filter: { or: filterObsr },
    pagination: { page: 1, perPage: 25 }
  });

  useEffect(() => {
    const functionLenContent = async () => {
      if (dataOrdenSalidas && dataOrdenSalidas?.length > 0) {
        const lenDataArray = await checkLen(organizedLen, calibreExp);
        const opDataArray = await checkOp(dataOrdenSalidas);
        const showIcons = compararArrays(opDataArray, lenDataArray);
        console.log('ICONS ARRAY', showIcons);
        setShowWarningIcon(showIcons);
      }
    };

    if (!loadingOrdenSalidas) {
      functionLenContent();
    }                                                                                        
  }, [loadingOrdenSalidas, dataOrdenSalidas, calibreExp]);

  const groupByOpAndVersion = (lenArray) => {
    return lenArray?.reduce((acc, curr) => {
      const key = `${curr?.op}-${curr?.version}`;
      if (!acc[key]) {
        acc[key] = {
          data: [],
          op: curr?.op,
          version: curr?.version,
          totalPlanchasExp: 0,
        };
      }
      acc[key].data.push(curr);
      acc[key].totalPlanchasExp = acc[key].data?.length;
      return acc;
    }, {});
  };

  const organizedLen = groupByOpAndVersion(lenData);
  const stateFilter = pendienteSalida === true && anulada === false;

  if (loadingOrdenSalidas) {
    return <LinearProgress/>;
  };

  return (
    <div style={{ marginTop: '2em' }}>
      {Object?.entries(organizedLen)?.map(([key, lenGroup], index) => {
        const dataLen = lenGroup?.data;
        const [op, version] = key?.split('-');
        const totalPlanchasExp = Array?.isArray(dataLen) ? dataLen?.length : 0;
        const shouldShowEditButton = Array?.isArray(dataLen) && dataLen?.some(item => !item?.op || !item?.version);
        const observaciones = dataObs?.filter(observacion => observacion?.NUMERO === parseInt(op) && observacion?.VERSIONOP === parseInt(version));
        const hasLenReposicion = dataLen?.some(item => item?.reposicion === true);
        return (
          <Accordion square key={key} sx={LenStyled?.accordion}>
            <AccordionSummary sx={LenStyled?.accordion?.accordionSummary} id={`${key}-header`} aria-controls={`${key}-content`} expandIcon={<ExpandMoreIcon/>}>
              {stateFilter ? (
                <>
                {shouldShowEditButton && (
                  <Tooltip title='Alguna plancha tiene algún error, corríjalo'>
                    <ReportRoundedIcon color='warning' sx={LenStyled?.accordion?.reportIcon}/>
                  </Tooltip>
                )}
                {shouldShowEditButton ? (
                  <LenEdit sx={LenStyled?.accordion?.lenEdit} lenData={dataLen}/>
                ):(
                  <>
                    {showWarningIcon[index]?.warning ? (
                      <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={showWarningIcon[index]?.message || 'Advertencia sin mensaje'}>
                        <WarningRoundedIcon sx={LenStyled?.accordion?.warningIcon}/>
                      </Tooltip>
                    ) : showWarningIcon[index]?.caution ? (
                      <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={showWarningIcon[index]?.message || 'Advertencia sin mensaje'}>
                        <NewReleasesIcon sx={LenStyled?.accordion?.newReleasesIcon}/>
                      </Tooltip>
                    ) : (
                      <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={showWarningIcon[index]?.message || 'La Orden de Producción se puede exponer'}>
                        <BeenhereRoundedIcon sx={LenStyled?.accordion?.beenHereIcon}/>
                      </Tooltip>
                    )}
                  </>
                )}
                </>
              ):(
                <span/>
              )}
              <Typography style={{ marginLeft: shouldShowEditButton ? '2.3em' : '0.5em' }} variant='h6' sx={LenStyled?.accordion?.typography}>
                {`OP: ${op}-${version} - Total Planchas (${totalPlanchasExp})`}
              </Typography>
              {hasLenReposicion && (
                <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={'Hay len(s) marcados como reposición'}>
                  <ReportIcon color='warning' sx={{ marginTop: '1.5px', marginLeft: '5px',  fontSize: '28px'}}/>
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetailsCustom newList={dataLen} obs={observaciones}/>
          </Accordion>
        );
      })}
    </div>
  );
};

const AccordionDetailsCustom = (props) => {

  const record = useRecordContext();
  const { newList, obs } = props;
  const anulada = record?.anulada;
  const listContext = useList({ data: newList });
  const pendienteSalida = record?.pendienteSalida;
  const filterExpo = pendienteSalida === true && anulada === false;
  const hasRepo = listContext?.data?.every(item => item?.reposicion === true);
  const hasNotReposicion = listContext?.data?.some(item => item?.reposicion === false);

  newList?.map((item) => {
    item.id = item.lenId;
    return item;
  });

  return (
    <>
      <AccordionDetails>
        <ChatBubble observaciones={obs}/>
        <Grid style={{ marginTop: '1rem' }}>
          <ListContextProvider value={listContext}>
            <Datagrid
              sx={LenStyled?.datagrid}
              bulkActionButtons={ filterExpo && hasNotReposicion ? <ActionsLen selection={listContext?.selectedIds}/> : false}
            >
              <ImageCDI source='image' label='IMAGEN'/>
              <PantoneColor source='pantone' label='COLOR' sx={LenStyled?.pantoneSX}/>
              <NumberField source='uuid' label='UUID' sx={LenStyled?.componentStyled}/>
              <NumberField source='name' label='NOMBRE' sx={LenStyled?.componentStyled}/>
              <NumberField source='lenWidth' label='ANCHO' sx={LenStyled?.componentStyled}/>
              <NumberField source='lenHeight' label='ALTURA' sx={LenStyled?.componentStyled}/>
              {hasRepo ? (
                <NumberField source='area' label='ÁREA' sx={LenStyled?.componentStyled}/>
              ):(
                <AreaLenWithRepo source='area' lens={listContext}/>
              )}
            </Datagrid>
          </ListContextProvider>
        </Grid>
      </AccordionDetails>
    </>
  );
};

const AreaLenWithRepo = () => {
  return(
    <FunctionField
      source='area'
      label='Reposición'
      render={record => 
        record?.reposicion === true ? (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={1} md={1}>
              <Tooltip title="Este len está marcado como reposición">
                <PriorityHighIcon color="error" sx={{ marginLeft: '4.7em', position: 'relative'}}/>
              </Tooltip>
            </Grid>
            <Grid item xs={11} md={11} sx={{ marginTop: '-3.2em' }}>
              <NumberField source="area" label="ÁREA" sx={LenStyled?.componentStyled}/>
            </Grid>
          </Grid>
        ) : <NumberField source='area' label='ÁREA' sx={LenStyled?.componentStyled}/>
      }
    />
  );
};

const ActionsLen = ({ selection }) => {

  const notify = useNotify();
  const record = useRecordContext();
  const { setValue, getValues } = useFormContext();

  const handleClick = async () => {
    if (!selection || selection?.length === 0) {
      console.log('No hay ningún Len seleccionado.');
      return;
    };
    console.log("VALUES", getValues())
    try {
      const updatedLen = record?.len?.map(item => {
        if (selection?.includes(item?.lenId)) {
          return {
            ...item,
            reposicion: true,
          };
        }
        return item;
      });
  
      const lenUpdatedNames = updatedLen?.filter(item => selection?.includes(item?.lenId))?.map(item => ({
        id: item?.lenId,
        nombreLen: item?.name,
        reposicion: item?.reposicion,
      }));
  
      console.log('Len Actualizados', lenUpdatedNames);
      setValue('len', updatedLen)

      const haslenRepo = lenUpdatedNames?.every(item => item?.reposicion === true);

      if (lenUpdatedNames?.length === 1) {
        if (haslenRepo) {
          notify(`Len seleccionado se marcó como reposición.`);
        } else {
          notify(`No se logró actualizar el len correctamente.`);
        }
      } else if (lenUpdatedNames?.length > 1) {
        notify(`Todos los len seleccionados se marcaron como reposición.`);
      } else {
        notify('No se actualizaron len seleccionados.');
      }
     } catch (error) {
      notify(`Error marcando Len como reposición: ${error.message}`);
      console.error('Error marcando Len como reposición:', error);
    }
  };

  return (
    <TopToolbar>
      <Button sx={ListStyled?.submitButton} variant='contained' color='error' startIcon={<SyncProblemIcon/>} onClick={handleClick}>
        {'Reposición Len'}
      </Button>
    </TopToolbar>
  );
};

const PantoneColor = ({ sx }) => {
  return(
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <FunctionField 
        label='COLOR'
        source='color'
        render={(dataLen) => { 
          const colorCode = extractColorLenId(dataLen?.uuid);
          const pantoneColor = findPantoneColor(colorCode);
          if (pantoneColor) {
            const { R, G, B, name } = pantoneColor;
            const colorStyle = { 
              width: '55px',
              height: '60px',
              borderRadius: '10px 100px / 120px',
              backgroundColor: `rgb(${R}, ${G}, ${B})`
            };
            return (
              <div style={LenStyled?.pantoneStyle?.div1}>
                <div style={colorStyle}/>
                <Typography variant='body2' sx={sx}>
                  {name}
                </Typography>
              </div>
            );
          };
          return <span/>;
        }}
      />
    </Grid>
  );
};