



import React, { useState } from 'react';
import { ReferenceInput, SelectInput, TextInput, TextField, AutocompleteInput, Labeled } from 'react-admin';
import { get, useFormContext } from 'react-hook-form';
import { Divider, Grid, Paper } from '@mui/material';

import { useEffect } from 'react';  
import { WizardForm } from "@react-admin/ra-form-layout";
import LabeledCustomItem from '../../../utils/LabeledCustomItem';
import { formatCurrencyString } from '../../../utils';


const filterToQuery = searchText => ({  number: searchText });

const typeChoicesWithReference=[{ id: 'VOID_ELECTRONIC_INVOICE', name: 'Anulación de Factura Electrónica' },
                    { id: 'PARTIALL_DEVOLUTION', name: 'Devolución de parte de los bienes y/o no aceptación de partes del servicio' },
                    { id: 'REDUCTION_DISCOUNT_PARTIAL_TOTAL', name: 'Rebaja o descuento parcial o total' },
                    { id: 'PRICE_ADJUSTMENT', name: 'Ajuste de Precio' },
                    { id: 'OTHER', name: 'Otro' }]
const typeOperationChoices=[{ id:'REFERENCE_TO_ELECTRONIC_INVOICE', name:'Referencia a Factura Electrónica' },
                            { id:'NO_REFERENCE_TO_ELECTRONIC_INVOICE', name:'Sin Referencia a Factura Electrónica' },
                            ]

const estadosDian = [{ id: 'PENDING', name: 'Pendiente' },
                    { id: 'STAMPED_AND_WAITING_RESPONSE', name: 'Esperando Respuesta DIAN' },
                    { id: 'STAMPED_AND_ACCEPTED', name: 'Aceptada' },
                    { id: 'STAMPED_AND_REJECTED', name: 'Rechazada' },
                    { id: 'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS', name: 'Aceptada con Observaciones' }]



export const InvoiceVoidLayout = (props) => {
    const { record } = props;
    const { getValues, setValue } = useFormContext();
    const [cnData , setCnData] = useState(null);
    
    const handleCreditNoteChange = (event, newValue) => {
        //console.log('handleCNChange',event, newValue);
        setValue('creditNoteData', newValue);
        setCnData(newValue);
    }    
   
    
    return (
        <Grid container spacing={2} direction={'row'} justifyContent={'center'}>
            <Grid item md={11} >
                <ReferenceInput sort={{order:'DESC', field:'dateTime'}} 
                                source="creditNoteID" 
                                reference="alegraCreditNotes" 
                                page={1}
                                enableGetChoices={({ q }) => q && q.length >= 4}
                                filter={{client_id: record?.alegraClienteId}}
                                >
                    <AutocompleteInput label="Nota Crédito"  noOptionsText='No se encontraron notas crédito asociadas al cliente' optionText="numberTemplate.fullNumber" optionValue="id" onChange={handleCreditNoteChange} />
                </ReferenceInput>
            </Grid>
            <Grid item md={11} >
                {cnData!==null?  <CreditNoteLayout cn={cnData}  />:<> </>}
            </Grid>
            <Grid item md={6} >
                <TextField source="clientName" label="Cliente" />
            </Grid>
        </Grid>
    )
}

const CreditNoteLayout = (props) => {
    const { cn } = props;
    //console.log('CreditNoteLayout', cn);
    if (!cn ) return null;
    return (
        <Paper sx={{padding:'10px'}}>
            <Grid container spacing={2} direction={'row'} justifyContent={'flex-start'}>
                <Grid item md={2} >
                    <LabeledCustomItem value={cn?.numberTemplate.fullNumber} label="Número" bold={true} />
                </Grid>
                <Grid item md={4} >
                    <LabeledCustomItem value={cn?.date.replaceAll('-','/')} label="Fecha" />
                </Grid>
                <Grid item md={4} >
                    <LabeledCustomItem value={cn?.client.name} label="Cliente" />
                </Grid>
                <Grid item md={6} >
                    <LabeledCustomItem value={typeOperationChoices.find(r=>r.id===cn.creditNoteOperationType)?.name} label="Operación" />
                </Grid>
                <Grid item md={6} >
                    <LabeledCustomItem value={typeChoicesWithReference.find(r=>r.id===cn.type)?.name} label="Tipo" />
                </Grid>
                <Grid item md={6} >
                    {cn.cause && <LabeledCustomItem value={cn.cause} label="Razón" />}
                </Grid>
                <Grid item md={6} >
                   <LabeledCustomItem value={cn?.invoices[0]?.fullNumber||''} label="Factura" bold={true}/>
                </Grid>
                <Grid item md={2} >
                    {cn.warehouse.name && <LabeledCustomItem value={cn.warehouse.name} label="Bodega:" />}
                </Grid>
                <Grid item md={4} >
                    {cn.warehouse.name && <LabeledCustomItem value={estadosDian.find(r=>r.id === cn.stamp.legalStatus).name} label="Estado DIAN:" />}
                </Grid>
                <Divider />
                <Grid item md={6} >
                    <LabeledCustomItem value={formatCurrencyString(cn.total, 2)} label="Total" bold={true}/>
                </Grid>    
            </Grid>
        </Paper>
    )
}
    
