import React, {useState} from 'react';
import ActionDelete from '@mui/icons-material/Delete';

import {
    useRecordContext,
    useResourceContext,
    useTranslate,
    Confirm, 
    useDelete,
    useDataProvider,
    useRefresh,
    useRedirect, 
    useNotify
} from 'react-admin';

import { IconButton, Button } from '@mui/material';

export const DeleteRemision = (props) => {
    const record = useRecordContext(props);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [deleteOne, { isPending, error }] = useDelete()
    const {
        className,
        confirmTitle = `Eliminar Remisión # ${record?.alegraNumeracion}`,
        confirmContent = 'ra.message.delete_content',
        confirmColor = 'primary',
        icon = defaultIcon,
        label = 'ra.action.delete',
        mutationMode = 'pessimistic',
        onClick,
        redirect = 'list',
        translateOptions = {},
        mutationOptions,
        color = 'error',
        type, 
        ...rest
    } = props;
    const translate = useTranslate();
    const resource = useResourceContext(props);
    
    const redirectTo = useRedirect();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = React.useState(false);


    const deleteAndRestoreAll = async(props) => {
        setLoading(true);
        try {
            //check if any product is already has oldFacturaID
            console.log('RECORD delete', record)
            const oldFacturaIDIsPresent = record.productFact.items.some(item => item.oldFacturaID !== null);
            console.log('OLDFACTURAID', oldFacturaIDIsPresent)
            if(record.estado !== 'Facturada' && !oldFacturaIDIsPresent ){
                const resAlegra = dataProvider.delete('alegraRemissions', { id: record.alegraId });
                //console.log ('ALEGRA',await resAlegra)
            }
          

            for (const item of record.itemsFact) {
                const resSQLin =  await dataProvider.update('sqlOrdenIn', { filter: { numero: item.orden, versionop: item.version } });
                //console.log('SQLIN', resSQLin)  

              }
            const resStock =  await restoreStock(record.itemsFact);
            //console.log('STOCK', resStock)
            const res = await deleteOne('remissions', { id: record.id , previousData: record});
            const res2 = await Promise.all(
                record.productFact.items.map(async item => {
                    //console.log("ITEM", item)
                        dataProvider.delete('productFacts', { id: item.id });
                    }
            ));
            //console.log( 'REMISSION', res )
            return true;
        }catch (error) {
            console.error('ERROR', error);
            return false;
        }
    }
    const refresh = useRefresh();

    const handleConfirm = async () => {
        //console.log('RECORD', record)
        const done =  await deleteAndRestoreAll();
        if(done){
            setOpen(false);
            redirectTo('list', '/remissions');
            //refresh();
            setLoading(false);
        }
    };
    
    const restoreStock = async (items) => {
        const cajas = items.reduce((acc, item) => {

            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        let responseArray = []; 
        for (const caja of cajas) {
            //console.log('CAJA', caja)
            if(caja === null || caja.area<=0) continue;
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            //console.log('STOCK INSIDE RESTORE', stock)
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante + caja.area,
            }
            delete newStock.retales
            const response = await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            responseArray.push(response);
        }
        return responseArray;
    }
    

    return (
        <>{
        type === 'icon' ?
            <IconButton
                variant='outlined'
                onClick={()=>setOpen(true)}
                key="button"
                disabled={record.estado === 'Facturada'}
                color={color}
                {...rest}
            >
             {icon}
            </IconButton>
            : 
            <Button
                onClick={()=>setOpen(true)}
                label='ELIMINAR'
                key="button"
                color={color}
                startIcon={icon}
                {...rest}
            >
                ELIMINAR
            </Button>
        }     
            <Confirm
                isOpen={open}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                confirmColor={confirmColor}
                onConfirm={handleConfirm}
                onClose={()=>setOpen(false)}

            />
        </>
    );
};

const defaultIcon = <ActionDelete />;


