import { ReposicionExpo } from './ReposicionExpo';
import { Typography, Grid, Box } from '@mui/material';
import { ImageCDI } from '../../exposicion/components/ImageCDI';
import { PantoneColor } from '../../exposicion/components/LenContentView';
import { ShowInfoMedidas } from '../../exposicion/layouts/ShowInfoMedidas';
import { ResponsableView } from '../../exposicion/components/VariousComponets';
import { ListStyled, StyledDatagridShowExposicion } from '../styles/OrdenSalidaStyles';
import { Datagrid, useGetMany, useRecordContext, ReferenceManyField, NumberField, DateField, FunctionField } from 'react-admin';

export const ShowExposicion = () => {
  
  const record = useRecordContext();
  const op = record?.op || 0;
  const expId = record?.expID || [];
  const version = record?.version || 0;
  const { data: expo } = useGetMany('exposicions', { ids: expId });
  const lenMap = expo?.flatMap(exposicion => exposicion?.len || []);
  const expoReposicion = expo?.every(exposicion => exposicion?.reposicion === true);
  const opFilteredLenMap = lenMap?.filter(lenItem => parseInt(lenItem?.op) === op);

  const title = (
    <Typography>
      <div style={ListStyled?.titleStyled}>
        {`INFORMACIÓN DE LEN`}
      </div>
    </Typography>
  );

  return (
    <ReferenceManyField reference='exposicions' target='id'>
      <Datagrid
        data={expo}
        rowClick={false}
        sx={StyledDatagridShowExposicion?.datagrid}
        bulkActionButtons={expoReposicion ? false : <ReposicionExpo expo={expo} title={title}/>}
      >
        <ImageCDI source='imageExp' label='IMAGEN' style={{ marginLeft: '-5rem' }}/>
        <ResponsableView source='responsable' label='RESPONSABLE' uppercase={true}/>
        <DateField source='dateExposed' label='CREACIÓN' showTime={true} sx={ListStyled?.componentFont}/>
        <DateField source='updatedAt' label='MODIFICACIÓN' showTime={true} sx={ListStyled?.componentFont}/>
        <ShowInfoMedidas source='gestion' label='GESTIÓN'/>
      </Datagrid>
      {title}
      <LenView dataLen={opFilteredLenMap} op={op} version={version}/>
    </ReferenceManyField>
  );
};

const LenView = ({ dataLen }) => {
  
  if (!dataLen || dataLen?.length === 0) {
    return <Typography>No hay len disponibles</Typography>;
  };
  
  return (
    <Grid style={{ marginTop: '1rem' }}>
      <Datagrid data={dataLen} sx={StyledDatagridShowExposicion?.lenDatagrid} bulkActionButtons={false}>
        <ImageCDI source='image' label='IMAGEN'/>
        <PantoneColor source='pantone' label='COLOR' sx={ListStyled?.pantoneSX}/>
        <NameParsed source='name' label='NOMBRE' sx={ListStyled?.componentFont}/>
        <NumberField source='lenWidth' label='ANCHO' sx={ListStyled?.componentFont} options={{ maximumFractionDigits: 2 }}  />
        <NumberField source='lenHeight' label='ALTURA' sx={ListStyled?.componentFont} options={{ maximumFractionDigits: 2 }}/>
        <NumberField source='area' label='ÁREA' sx={ListStyled?.componentFont} options={{ maximumFractionDigits: 2 }}/>
      </Datagrid>
    </Grid>
  );
};

const NameParsed = ({ source, label, sx }) => {
  return (
    <FunctionField
      sx={sx}
      source={source}
      label={label}
      render={record => {
        const regex = /\d+-\d+_(\w+_\w+_\w+_p\d+_\d+_\w+)/;
        const match = record?.name?.match(regex);
        return match ? match[1]?.replace(/(_rot)$/, '') : record?.name?.replace(/\.len$/, '');
      }}
    />
  );
};