import { Grid } from '@mui/material';
import { Calibre } from '../../utils';
import { useFormContext } from 'react-hook-form';
import { Create, SimpleForm, useGetList, NumberInput, ReferenceInput, AutocompleteInput, AutocompleteArrayInput } from 'react-admin';

export const OrdenSalidaCreate = () => {
    const { data: clientes, isLoading } = useGetList('clientes', { 
        pagination: { page: 1, perPage: 250 },
        sort: { field: 'nit_cedula', order: 'DESC' }
    });

    if (isLoading) return <div>Cargando...</div>;

    return (
        <Create redirect="list">
            <SimpleForm p={2}>
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between'>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth source="op" label='Orden Producción'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <ClienteAutocompleteInput clienteChoices={clientes}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth source="version" label='Versión'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth source="totalPlanchasOrden" label='Total Planchas Orden'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth source="totalPlanchasExp" label='Total Planchas Exposición'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <AutocompleteInput size='small' fullWidth variant='outlined' source='calibreOrden' label='Calibre(s) Orden' choices={Calibre}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <AutocompleteArrayInput size='small' fullWidth variant='outlined' source='calibresExp' label='Calibre(s) Exposición' choices={Calibre}/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

const ClienteAutocompleteInput = ({ clientes }) => {

    const { setValue } = useFormContext();
    const handleClienteChange = (value) => {
        console.log('Valor a ingresar', value);
        setValue('clienteOrden', value.razonSocial || '');
        setValue('clienteOrdenId', value.nit_cedula || '');
    };

    return (
        <ReferenceInput source='clienteOrden' reference='clientes'>
            <AutocompleteInput 
                fullWidth
                source='clienteOrden'
                variant='outlined'
                label='Cliente(s)'
                size='small'
                choices={clientes}
                optionText={(choice) => choice ? `${choice.razonSocial} (${choice.nit_cedula})` : ''}
                onChange={(value)=>console.log('value', value)}
            />
        </ReferenceInput>
    );
};
