import React from 'react';
import {
    Toolbar,
    Datagrid,
    DateField,
    NumberField,
    BooleanField,
    TextField,
    ReferenceField,
    WithRecord,
    SimpleForm,
    FunctionField,
    useGetList,
    useList,
    ListContextProvider,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { CreateInDialogButton, EditInDialogButton } from "@react-admin/ra-form-layout";
import StockCreateLayout from './StockCreateLayout';
import StockEditLayout from './StockEditLayout';
import { Stack, Chip } from '@mui/material';


export default function StockStandalone(props) {
  const { data, isLoading, total, sort } = useGetList(
    'stocks',
    { 
      pagination: { page: 1, perPage: 200 }, 
      filter: { materialID: { eq:  props.materialID } },
      sort: { field: 'consecutivo', order: 'DESC' },
      meta: { searchable: 'true' }
    },
);
const listContext = useList({ data });
  if (isLoading) return null;

    return (
      <ListContextProvider value={listContext}>
        <Stack alignItems={'flex-end'} width={'100%'}>

        <WithRecord render={record => {
          console.log('RECORD', record)
          return(
            <CreateInDialogButton resource='stocks' fullWidth maxWidth="md" 
            transform={(data)=>{
              delete data.manualConsec;
              return({
                id: `${data.materialID}-${data.consecutivo}`,
                ...data
              }); 
            }} 
            record={{ materialID: record.id , activa:false, ingresada:false, cerrada: false, alegraItemId: record.alegraId}}
            >
          <SimpleForm toolbar={<CustomToolbar/>} >
            <StockCreateLayout standalone={true}/>
          </SimpleForm>
        </CreateInDialogButton>
        )}}/>
        </Stack>
        <Datagrid>
          <NumberField source="consecutivo" label='#'/>
          <TextField source="id" />
          <DateField source="createdAt" label='Fecha Creación' />
          <NumberField source="anchoLamina" />
          <NumberField source="largoLamina" />
          <NumberField source="cantidad" />
          <NumberField source="areaTotal" />
          <NumberField source="areaRestante" />
          <FunctionField label='%' render={(r)=>(`${(r.areaRestante/r.areaTotal*100).toFixed(1)}%`)} />
          <FunctionField label='Estado' render={(r)=>( 
                                                      r.activa&&r.cerrada ? <Chip label="Error" variant="outlined" color ='error' />:
                                                      r.cerrada           ? <Chip label="Cerrada" variant="outlined" color ='primary' /> : 
                                                      r.ingresada         ? <Chip label="Ingresada" variant="outlined" color ='success' /> : 
                                                      r.activa            ? <Chip label="Activa" variant="outlined" color ='info' /> : 
                                                                            <Chip label="Nueva" variant="outlined" color ='warning' />
                                                      )} />
          {/* <BooleanField source="activa" />
          <BooleanField source="ingresada" />
          <BooleanField source="cerrada" /> */}
          <ReferenceField source="usuarioActiva" reference='usuarios'>
            <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
          </ReferenceField>
          <ReferenceField source="usuarioIngresa" reference='usuarios'>
            <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
          </ReferenceField>
          <DateField  source="fechaIngreso" showTime/>
          <ReferenceField source="usuarioCierra" reference='usuarios'>
            <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
          </ReferenceField>
          <EditInDialogButton resource='stocks' label='' fullWidth maxWidth="md"
            transform={(data)=>{
              console.log('DATA EDIT STOCK TRANSFORM', data)
              delete data.manualConsec;
              delete data.retales
              return({
                  ...data
              }); 
              }} 
          >
              <SimpleForm toolbar={<CustomToolbar/>}>
                <StockEditLayout   />
              </SimpleForm>
          </EditInDialogButton>
        </Datagrid>
      </ListContextProvider>
    )
}

export const CustomToolbar = () => (
  <Toolbar sx={{ justifyContent: "space-between" }}>
      <SaveButton mutationMode="optimistic" />
      <DeleteButton redirect={false} resource='stocks'  mutationMode="pessimistic"  />
  </Toolbar>
);