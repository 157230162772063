import { 
  List,
  UrlField,
  Datagrid,
  DateField,
  TextInput,
  ChipField,
  TextField,
  useNotify,
  useRefresh,
  EditButton,
  TopToolbar,
  NumberField,
  CreateButton,
  useListContext,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import {Button} from '@mui/material';
import { IconButton } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import PrintIcon from '@mui/icons-material/Print';
import { DeleteRemision } from './components/DeleteRemision';

const RemisionFilters = [
  <TextInput label="Numero" source="alegraNumeracion.wildcard" alwaysOn format={v=>v?.replaceAll('*', '')||''}/>,
  <TextInput source="clienteName.wildcard" label='Cliente' alwaysOn format={v=>v?.replaceAll('*', '')||''}/>,
];

export const RemisionList = () => {
  return (
    <List 
      debounce={1000}
      actions={<ListActions/>}
      filters={RemisionFilters} 
      sort={{ field: 'fechaCrea', order: 'DESC'}} 
      //queryOptions={{meta:{searchable:'true'}}}
    >
      <Datagrid >
        <TextField source="usuarioID" label='usuarioID'/>
        <TextField source="alegraNumeracion" label='Número'/>
        <TextField source="clienteName" label='Cliente' />
        <DateField source="fechaCrea" label='Fecha Creación'/>
        <StatusField/>
        <NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP', maximumFractionDigits: 0 }} />
        <UrlField source="url" />
        <ImprimirButton/>
        <EditButton />
        <DeleteRemision />
      </Datagrid>
    </List>
  ) 
};

const StatusField = () => {
  const record = useRecordContext();
  if (record.estado === "Facturada") {
    return <ChipField source="estado" color="success" variant='outlined'/>;
  }
  return <ChipField source="estado" color="primary" variant='outlined'/>;
};

const ImprimirButton = () => {
  const record = useRecordContext();
  return(
    <IconButton
      onClick={() => {
        window.open(record.pdf, '_blank');
      }}
    >
      <PrintIcon/>
    </IconButton>
  );
};

const ListActions = () => {

  const { data } = useListContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleUpdate = async () => {
      // Filter the data to get only the elements with estado "emitida"
    const emitidaData = data.filter(item => item.estado === "Emitida");
      // Loop through the emitidaData and update the estado to "Facturada" if the status is "closed"
    for (const item of emitidaData) {
      try {
        const response = await dataProvider.getOne('alegraRemissions', { id: item.alegraId });
        if (response?.data.status === "closed") {
          item.estado = "Facturada";
          await dataProvider.update('remissions', {
            id: item.id,
            data: {  ...item },
            previousData: item,
          });
          refresh();
        } else {
          console.log('No se debe actualizar el estado', item.alegraNumeracion)
        }
      } catch (error) {
        console.error('ERROR', error);
        notify('Error: no se pudo actualizar el estado', { type: 'error' });
      };
    };
  };

  return(
    <TopToolbar>
      <Button startIcon={<SyncIcon/>}onClick={handleUpdate} size='small'>
        Actualizar Estado
      </Button>
      <CreateButton/>
    </TopToolbar>
  );
};

const WrappedDelete = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const record = useRecordContext();

  const handleDelete = async (e) => {    
    console.log('RECORD', record); 
    console.log ("event", e);
  };
  
  return (
    <Button type='button' onClick={handleDelete}>
      Borrar
    </Button>
  );
};
