export const testFact = {"data":{
    "id": "3887",
    "date": "2024-10-03",
    "dueDate": "2024-11-02",
    "datetime": "2024-10-03 11:53:11",
    "observations": null,
    "anotation": "Orden de Compra: 1212                                                                                                    Total Menos Retenciones: $ 249.745,5",
    "termsConditions": "BANCOLOMBIA\nCuenta Ahorros No. 1005 - 233 86 09 a nombre de Grafiflex S.A.S.\n\nEsta factura se asimila en todos sus efectos a una letra de cambio de conformidad con el Art. 774 del código de comercio. Autorizo que en caso de incumplimiento de esta obligación sea reportado a las centrales de riesgo, se cobraran intereses por mora.",
    "status": "open",
    "client": {
        "id": "115",
        "name": "SOLUCIONES EMPRESARIALES MARLING S.A.S",
        "identification": "901064826",
        "phonePrimary": "5368900",
        "phoneSecondary": null,
        "fax": null,
        "mobile": null,
        "email": "contabilidad@semarling.com",
        "regime": "COMMON_REGIME",
        "address": {
            "address": "CRA 75DA # 2BSUR-320 INT 724",
            "department": "Antioquia",
            "city": "Medellín"
        },
        "kindOfPerson": "LEGAL_ENTITY",
        "identificationObject": {
            "type": "NIT",
            "number": "901064826"
        }
    },
    "numberTemplate": {
        "id": "23",
        "prefix": "GF",
        "number": "27849",
        "text": "Autorización de numeración de facturación N° 18764072908675 de 2024-06-13 Factura Electrónica Desde N° GF25001 hasta GF45000 con vigencia hasta 2026-06-13",
        "documentType": "invoice",
        "fullNumber": "GF27849",
        "formattedNumber": "27849",
        "isElectronic": true
    },
    "subtotal": 217170,
    "discount": 0,
    "tax": 41262.3,
    "total": 258432.3,
    "totalPaid": 0,
    "balance": 258432.3,
    "decimalPrecision": "2",
    "warehouse": {
        "id": "1",
        "name": "ENV"
    },
    "term": "30 días",
    "type": "NATIONAL",
    "operationType": "STANDARD",
    "paymentForm": "CREDIT",
    "paymentMethod": "BANK_DEPOSIT",
    "purchaseOrderNumber": "1212",
    "seller": {
        "id": 1,
        "name": "CARLOS CASTANO",
        "identification": "71381450",
        "observations": "Noviembre 3 / 2023\nCARLOS CASTAÑO - CRISTINA FLÓREZ\nArtes, Montajes y Finalizaciones\nClientes GF: 8%\nTopasa: GF 10%\nClientes Carlos - Criss: 12% : Tamaprint-Cintandina-Juan Toro -\nLabel Prime - Plastibol Colombia\n\n"
    },
    "priceList": {
        "id": 1,
        "name": "General"
    },
    "retentionsSuggested": [
        {
            "id": "32",
            "name": "Rte Fte x Servicios",
            "percentage": "4.0000",
            "amount": 8686.8,
            "referenceKey": "FUENTE",
            "base": null
        }
    ],
    "stamp": {
        "legalStatus": "STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS",
        "cufe": "fb0b84ffa517672e4b33da0b71df29cd47eca9a0e4efdcde63ddecc8650cb787ee62d51f9230b91e4fe3ea3abc50d3ad",
        "barCodeContent": "NumFac: GF27849\nFecFac: 2024-10-03\nHorFac: 11:52:13-05:00\nNitFac: 800256139\nDocAdq: 901064826\nValFac: 217170.00\nValIva: 41262.30\nValOtroIm: 0.00\nValTolFac: 258432.30\nCUFE: fb0b84ffa517672e4b33da0b71df29cd47eca9a0e4efdcde63ddecc8650cb787ee62d51f9230b91e4fe3ea3abc50d3ad\nQRCode: https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=fb0b84ffa517672e4b33da0b71df29cd47eca9a0e4efdcde63ddecc8650cb787ee62d51f9230b91e4fe3ea3abc50d3ad\n",
        "date": "2024-10-03 11:53:16",
        "warnings": [
            "Regla: FAZ09, Notificación: Debe existir el grupo de información de identificación del bien o servicio"
        ]
    },
    "items": [
        {
            "name": "E-ESM67",
            "description": "PRUEBAS FACTURACION 1\n30 x 20   Cant: 1 - ODC:1212",
            "price": 114,
            "discount": 0,
            "reference": "168766-1",
            "quantity": 600,
            "id": 15,
            "productKey": null,
            "unit": "centimeterSquared",
            "tax": [
                {
                    "id": "3",
                    "name": "IVA",
                    "percentage": "19.00",
                    "description": "",
                    "status": "active",
                    "type": "IVA",
                    "categoryFavorable": {
                        "id": "5011",
                        "idParent": "5010",
                        "name": "Impuesto a las ventas a favor",
                        "text": "Impuesto a las ventas a favor",
                        "code": null,
                        "description": "",
                        "type": "asset",
                        "readOnly": false,
                        "nature": "debit",
                        "blocked": "no",
                        "status": "active",
                        "categoryRule": {
                            "id": "44",
                            "name": "IVA a favor",
                            "key": "IVA_IN_FAVOR_COL"
                        },
                        "use": "movement",
                        "showThirdPartyBalance": true
                    },
                    "categoryToBePaid": {
                        "id": "5043",
                        "idParent": "5042",
                        "name": "IVA Generado en Ventas ",
                        "text": "IVA Generado en Ventas ",
                        "code": "24080501",
                        "description": "24080501 IVA Generado en Ventas  19%",
                        "type": "liability",
                        "readOnly": false,
                        "nature": "credit",
                        "blocked": "no",
                        "status": "active",
                        "categoryRule": {
                            "id": "47",
                            "name": "IVA por pagar",
                            "key": "IVA_TO_PAY_COL"
                        },
                        "use": "movement",
                        "showThirdPartyBalance": true
                    },
                    "rate": null,
                    "amount": 12996
                }
            ],
            "total": 81396
        },
        {
            "name": "E-ESM67",
            "description": "PRUEBAS FACTURACION 1\n30 x 23.5   Cant: 1 - ODC:1212",
            "price": 114,
            "discount": 0,
            "reference": "168766-1",
            "quantity": 705,
            "id": 15,
            "productKey": null,
            "unit": "centimeterSquared",
            "tax": [
                {
                    "id": "3",
                    "name": "IVA",
                    "percentage": "19.00",
                    "description": "",
                    "status": "active",
                    "type": "IVA",
                    "categoryFavorable": {
                        "id": "5011",
                        "idParent": "5010",
                        "name": "Impuesto a las ventas a favor",
                        "text": "Impuesto a las ventas a favor",
                        "code": null,
                        "description": "",
                        "type": "asset",
                        "readOnly": false,
                        "nature": "debit",
                        "blocked": "no",
                        "status": "active",
                        "categoryRule": {
                            "id": "44",
                            "name": "IVA a favor",
                            "key": "IVA_IN_FAVOR_COL"
                        },
                        "use": "movement",
                        "showThirdPartyBalance": true
                    },
                    "categoryToBePaid": {
                        "id": "5043",
                        "idParent": "5042",
                        "name": "IVA Generado en Ventas ",
                        "text": "IVA Generado en Ventas ",
                        "code": "24080501",
                        "description": "24080501 IVA Generado en Ventas  19%",
                        "type": "liability",
                        "readOnly": false,
                        "nature": "credit",
                        "blocked": "no",
                        "status": "active",
                        "categoryRule": {
                            "id": "47",
                            "name": "IVA por pagar",
                            "key": "IVA_TO_PAY_COL"
                        },
                        "use": "movement",
                        "showThirdPartyBalance": true
                    },
                    "rate": null,
                    "amount": 15270.3
                }
            ],
            "total": 95640.3
        },
        {
            "name": "E-ESM67",
            "description": "PRUEBAS FACTURACION 1\n30 x 20   Cant: 1 - ODC:1212",
            "price": 114,
            "discount": 0,
            "reference": "168766-1",
            "quantity": 600,
            "id": 15,
            "productKey": null,
            "unit": "centimeterSquared",
            "tax": [
                {
                    "id": "3",
                    "name": "IVA",
                    "percentage": "19.00",
                    "description": "",
                    "status": "active",
                    "type": "IVA",
                    "categoryFavorable": {
                        "id": "5011",
                        "idParent": "5010",
                        "name": "Impuesto a las ventas a favor",
                        "text": "Impuesto a las ventas a favor",
                        "code": null,
                        "description": "",
                        "type": "asset",
                        "readOnly": false,
                        "nature": "debit",
                        "blocked": "no",
                        "status": "active",
                        "categoryRule": {
                            "id": "44",
                            "name": "IVA a favor",
                            "key": "IVA_IN_FAVOR_COL"
                        },
                        "use": "movement",
                        "showThirdPartyBalance": true
                    },
                    "categoryToBePaid": {
                        "id": "5043",
                        "idParent": "5042",
                        "name": "IVA Generado en Ventas ",
                        "text": "IVA Generado en Ventas ",
                        "code": "24080501",
                        "description": "24080501 IVA Generado en Ventas  19%",
                        "type": "liability",
                        "readOnly": false,
                        "nature": "credit",
                        "blocked": "no",
                        "status": "active",
                        "categoryRule": {
                            "id": "47",
                            "name": "IVA por pagar",
                            "key": "IVA_TO_PAY_COL"
                        },
                        "use": "movement",
                        "showThirdPartyBalance": true
                    },
                    "rate": null,
                    "amount": 12996
                }
            ],
            "total": 81396
        }
    ],
    "costCenter": {
        "code": "",
        "name": "Env",
        "description": "",
        "status": "active",
        "id": "1"
    },
    "printingTemplate": {
        "id": "2",
        "name": "Clásico (Carta electrónica)",
        "pageSize": "letter"
    },
    "isFirstInvoiceCreated": false,
    "isFirstDraftOrOpenInvoiceCreated": false
}}


export const pdfResponseTest = { "data":
    {
        "data": {
            "id": "3887",
            "date": "2024-10-03",
            "dueDate": "2024-11-02",
            "datetime": "2024-10-03 11:53:11",
            "observations": null,
            "anotation": "Orden de Compra: 1212                                                                                                    Total Menos Retenciones: $ 249.745,5",
            "termsConditions": "BANCOLOMBIA\nCuenta Ahorros No. 1005 - 233 86 09 a nombre de Grafiflex S.A.S.\n\nEsta factura se asimila en todos sus efectos a una letra de cambio de conformidad con el Art. 774 del código de comercio. Autorizo que en caso de incumplimiento de esta obligación sea reportado a las centrales de riesgo, se cobraran intereses por mora.",
            "status": "open",
            "client": {
                "id": "115",
                "name": "SOLUCIONES EMPRESARIALES MARLING S.A.S",
                "identification": "901064826",
                "phonePrimary": "5368900",
                "phoneSecondary": null,
                "fax": null,
                "mobile": null,
                "email": "contabilidad@semarling.com",
                "regime": "COMMON_REGIME",
                "address": {
                    "address": "CRA 75DA # 2BSUR-320 INT 724",
                    "department": "Antioquia",
                    "city": "Medellín"
                },
                "kindOfPerson": "LEGAL_ENTITY",
                "identificationObject": {
                    "type": "NIT",
                    "number": "901064826"
                }
            },
            "numberTemplate": {
                "id": "23",
                "prefix": "GF",
                "number": "27849",
                "text": "Autorización de numeración de facturación N° 18764072908675 de 2024-06-13 Factura Electrónica Desde N° GF25001 hasta GF45000 con vigencia hasta 2026-06-13",
                "documentType": "invoice",
                "fullNumber": "GF27849",
                "formattedNumber": "27849",
                "isElectronic": true
            },
            "subtotal": 217170,
            "discount": 0,
            "tax": 41262.3,
            "total": 258432.3,
            "totalPaid": 0,
            "balance": 258432.3,
            "decimalPrecision": "2",
            "warehouse": {
                "id": "1",
                "name": "ENV"
            },
            "term": "30 días",
            "type": "NATIONAL",
            "operationType": "STANDARD",
            "paymentForm": "CREDIT",
            "paymentMethod": "BANK_DEPOSIT",
            "purchaseOrderNumber": "1212",
            "seller": {
                "id": 1,
                "name": "CARLOS CASTANO",
                "identification": "71381450",
                "observations": "Noviembre 3 / 2023\nCARLOS CASTAÑO - CRISTINA FLÓREZ\nArtes, Montajes y Finalizaciones\nClientes GF: 8%\nTopasa: GF 10%\nClientes Carlos - Criss: 12% : Tamaprint-Cintandina-Juan Toro -\nLabel Prime - Plastibol Colombia\n\n"
            },
            "priceList": {
                "id": 1,
                "name": "General"
            },
            "retentionsSuggested": [
                {
                    "id": "32",
                    "name": "Rte Fte x Servicios",
                    "percentage": "4.0000",
                    "amount": "8686.800000",
                    "referenceKey": "FUENTE",
                    "base": null
                }
            ],
            "stamp": {
                "legalStatus": "STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS",
                "cufe": "fb0b84ffa517672e4b33da0b71df29cd47eca9a0e4efdcde63ddecc8650cb787ee62d51f9230b91e4fe3ea3abc50d3ad",
                "barCodeContent": "NumFac: GF27849\nFecFac: 2024-10-03\nHorFac: 11:52:13-05:00\nNitFac: 800256139\nDocAdq: 901064826\nValFac: 217170.00\nValIva: 41262.30\nValOtroIm: 0.00\nValTolFac: 258432.30\nCUFE: fb0b84ffa517672e4b33da0b71df29cd47eca9a0e4efdcde63ddecc8650cb787ee62d51f9230b91e4fe3ea3abc50d3ad\nQRCode: https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=fb0b84ffa517672e4b33da0b71df29cd47eca9a0e4efdcde63ddecc8650cb787ee62d51f9230b91e4fe3ea3abc50d3ad\n",
                "date": "2024-10-03 11:53:16",
                "warnings": [
                    "Regla: FAZ09, Notificación: Debe existir el grupo de información de identificación del bien o servicio"
                ]
            },
            "items": [
                {
                    "name": "E-ESM67",
                    "description": "PRUEBAS FACTURACION 1\n30 x 20   Cant: 1 - ODC:1212",
                    "price": 114,
                    "discount": 0,
                    "reference": "168766-1",
                    "quantity": 600,
                    "id": 15,
                    "productKey": null,
                    "unit": "centimeterSquared",
                    "tax": [
                        {
                            "id": "3",
                            "name": "IVA",
                            "percentage": "19.00",
                            "description": "",
                            "status": "active",
                            "type": "IVA",
                            "categoryFavorable": {
                                "id": "5011",
                                "idParent": "5010",
                                "name": "Impuesto a las ventas a favor",
                                "text": "Impuesto a las ventas a favor",
                                "code": null,
                                "description": "",
                                "type": "asset",
                                "readOnly": false,
                                "nature": "debit",
                                "blocked": "no",
                                "status": "active",
                                "categoryRule": {
                                    "id": "44",
                                    "name": "IVA a favor",
                                    "key": "IVA_IN_FAVOR_COL"
                                },
                                "use": "movement",
                                "showThirdPartyBalance": true
                            },
                            "categoryToBePaid": {
                                "id": "5043",
                                "idParent": "5042",
                                "name": "IVA Generado en Ventas ",
                                "text": "IVA Generado en Ventas ",
                                "code": "24080501",
                                "description": "24080501 IVA Generado en Ventas  19%",
                                "type": "liability",
                                "readOnly": false,
                                "nature": "credit",
                                "blocked": "no",
                                "status": "active",
                                "categoryRule": {
                                    "id": "47",
                                    "name": "IVA por pagar",
                                    "key": "IVA_TO_PAY_COL"
                                },
                                "use": "movement",
                                "showThirdPartyBalance": true
                            },
                            "rate": null,
                            "amount": 12996
                        }
                    ],
                    "total": 81396
                },
                {
                    "name": "E-ESM67",
                    "description": "PRUEBAS FACTURACION 1\n30 x 23.5   Cant: 1 - ODC:1212",
                    "price": 114,
                    "discount": 0,
                    "reference": "168766-1",
                    "quantity": 705,
                    "id": 15,
                    "productKey": null,
                    "unit": "centimeterSquared",
                    "tax": [
                        {
                            "id": "3",
                            "name": "IVA",
                            "percentage": "19.00",
                            "description": "",
                            "status": "active",
                            "type": "IVA",
                            "categoryFavorable": {
                                "id": "5011",
                                "idParent": "5010",
                                "name": "Impuesto a las ventas a favor",
                                "text": "Impuesto a las ventas a favor",
                                "code": null,
                                "description": "",
                                "type": "asset",
                                "readOnly": false,
                                "nature": "debit",
                                "blocked": "no",
                                "status": "active",
                                "categoryRule": {
                                    "id": "44",
                                    "name": "IVA a favor",
                                    "key": "IVA_IN_FAVOR_COL"
                                },
                                "use": "movement",
                                "showThirdPartyBalance": true
                            },
                            "categoryToBePaid": {
                                "id": "5043",
                                "idParent": "5042",
                                "name": "IVA Generado en Ventas ",
                                "text": "IVA Generado en Ventas ",
                                "code": "24080501",
                                "description": "24080501 IVA Generado en Ventas  19%",
                                "type": "liability",
                                "readOnly": false,
                                "nature": "credit",
                                "blocked": "no",
                                "status": "active",
                                "categoryRule": {
                                    "id": "47",
                                    "name": "IVA por pagar",
                                    "key": "IVA_TO_PAY_COL"
                                },
                                "use": "movement",
                                "showThirdPartyBalance": true
                            },
                            "rate": null,
                            "amount": 15270.3
                        }
                    ],
                    "total": 95640.3
                },
                {
                    "name": "E-ESM67",
                    "description": "PRUEBAS FACTURACION 1\n30 x 20   Cant: 1 - ODC:1212",
                    "price": 114,
                    "discount": 0,
                    "reference": "168766-1",
                    "quantity": 600,
                    "id": 15,
                    "productKey": null,
                    "unit": "centimeterSquared",
                    "tax": [
                        {
                            "id": "3",
                            "name": "IVA",
                            "percentage": "19.00",
                            "description": "",
                            "status": "active",
                            "type": "IVA",
                            "categoryFavorable": {
                                "id": "5011",
                                "idParent": "5010",
                                "name": "Impuesto a las ventas a favor",
                                "text": "Impuesto a las ventas a favor",
                                "code": null,
                                "description": "",
                                "type": "asset",
                                "readOnly": false,
                                "nature": "debit",
                                "blocked": "no",
                                "status": "active",
                                "categoryRule": {
                                    "id": "44",
                                    "name": "IVA a favor",
                                    "key": "IVA_IN_FAVOR_COL"
                                },
                                "use": "movement",
                                "showThirdPartyBalance": true
                            },
                            "categoryToBePaid": {
                                "id": "5043",
                                "idParent": "5042",
                                "name": "IVA Generado en Ventas ",
                                "text": "IVA Generado en Ventas ",
                                "code": "24080501",
                                "description": "24080501 IVA Generado en Ventas  19%",
                                "type": "liability",
                                "readOnly": false,
                                "nature": "credit",
                                "blocked": "no",
                                "status": "active",
                                "categoryRule": {
                                    "id": "47",
                                    "name": "IVA por pagar",
                                    "key": "IVA_TO_PAY_COL"
                                },
                                "use": "movement",
                                "showThirdPartyBalance": true
                            },
                            "rate": null,
                            "amount": 12996
                        }
                    ],
                    "total": 81396
                }
            ],
            "pdf": "https://cdn3.alegra.com/invoices/pdf/4046c6cef5f9fef531ce1e0eb30f3341adcfde11-factura-electronica-de-venta-GF27849-1727974399.pdf?Expires=1728010400&Signature=Kc67m-41c3bWR5VTAWiDxeqiQLtCbi3TL1UPfqepjNNmdOkVUqXRLkqUtZHRrL0zRoUkuba8k4-Up4TcQ3q7i1T~h4mq195ZAdAWQNGIIvMUhUtDG8ZnkHQWsvw-oplyoNsQm7HQbhUPWDypljpkfDgG4mvV28Ocgb3kmTsE6AVFR9AR6FgMCJgmcjsIaLoIuO2TtldFm57cP5VPo-JzjsijXkT7FwgQisY5VnkikwtfF59bNZtm-1q7UhdzlRjRq9hkjWcAj8lOAj3XQQCGwVxZmZwTFyxy3~ppVMDY2a39KOCac5rj9RyVg-uWuFpifdl8d1urfoKkQ7g3t~n71g__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
            "costCenter": {
                "code": "",
                "name": "Env",
                "description": "",
                "status": "active",
                "id": "1"
            },
            "printingTemplate": {
                "id": "2",
                "name": "Clásico (Carta electrónica)",
                "pageSize": "letter"
            }
        }
    }
}