

import React from "react";
import { useRecordContext } from 'react-admin';
import { Table, TableBody, TableCell, TableHead, TableRow, Box, Typography, Grid } from '@mui/material';
import { ChatBubbleObserv } from "../../utils/chatBubbleObserv";

export const OrdenesPendientesShow = () => {
    const record = useRecordContext();

    return (
        <Grid container direction='row'  padding={4} justifyContent={"space-evenly"} sx={{backgroundColor:'#F9F9F9'}} >
            {record?.ordenes?.map(( orden , index) => (
                <Grid key={`${index}`} item md={4}  sx={{ border: '1px solid #ccc', borderRadius:'15px', backgroundColor:'white', padding:3}}  >
                    <Grid item md={12} container direction='row' justifyContent='space-between'>
                        <Grid item md={6}>
                            <Box sx={{ display: 'flex', flexDirection:'column'}}>
                                <Typography variant="h6" component="h2" >
                                    Orden {orden.numero} - {orden.version}
                                </Typography>
                                <Typography variant="p" component="p" sx={{  marginLeft:'1em', fontSize: '13px' }}>
                                    Referencia: <strong>{orden.referenciaPlanchas}</strong>
                                </Typography>
                                <Typography variant="p" component="p" sx={{  marginLeft:'1em', fontSize: '13px' }}>
                                    Tipo Plancha: <strong>{orden.tipoPlancha}</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box sx={{ display: 'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                                <Typography variant="p" component="p" sx={{  marginLeft:'1em', fontSize: '13px', textAlign: 'right' }} >
                                    # Len: <strong>{orden.qtyLen}</strong>
                                </Typography>
                                <Typography variant="p" component="p" sx={{  marginLeft:'1em', fontSize: '13px', textAlign: 'right' }}>
                                    Planchas/Color: <strong>{orden.planchasxColor}</strong>
                                </Typography>
                                <Typography variant="p" component="p" sx={{  marginLeft:'1em', fontSize: '13px', textAlign: 'right' }}>
                                    Total Planchas: <strong>{orden.totalPlanchas}</strong>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Ancho</b></TableCell>
                                <TableCell><b>Largo</b></TableCell>
                                <TableCell><b>Cantidad</b></TableCell>
                                <TableCell><b>Caja</b></TableCell>
                                <TableCell><b>Área</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orden.itemsFact?.map((item, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{item.ancho}</TableCell>
                                    <TableCell>{item.largo}</TableCell>
                                    <TableCell>{item.cantidad}</TableCell>
                                    <TableCell>{item.cajaNro}</TableCell>
                                    <TableCell>{item.area}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={2}  ><b>Total</b></TableCell>
                                <TableCell colSpan={2}  > {orden?.itemsFact?.reduce((acc, item) => acc + item.cantidad, 0)} </TableCell>
                                <TableCell>{orden?.itemsFact?.reduce((acc, item) => acc + item.area, 0)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {
                       orden?.observaciones && orden?.observaciones.length > 0 ?(
                        orden.observaciones.map((observation, idx) => ( 
                            <Box key={idx} sx={{ margin: '1em' }}>
                                <ChatBubbleObserv key={idx} observation={observation} />
                            </Box>
                        ))
                        ):(null)
                    }
                </Grid>
            ))}
        </Grid>
    );
}