import { Grid } from '@mui/material';
import { MaterialOrden } from './VariousComponents';
import { useGetList, useRecordContext } from 'react-admin';
import { ChatBubble } from '../../../utils/chatBubbleObserv';

export const Detalles = () => {

    const record = useRecordContext();
    const op = parseInt(record?.opSalida);
    const version = parseInt(record?.versionSalida);
  
    const filterObservaciones = {
        NUMERO: op,
        VERSIONOP: version
    }; 
    
    const { data: observaciones, isLoading: loadingObserv } = useGetList('sqlObservaciones', { 
        filter: filterObservaciones,
        pagination: { page: 1, perPage: 10 },
        sort: { 'field': 'VERSIONOP', 'order': "DESC" }},
    );

    console.log('observaciones', observaciones || ['No hay observaciones...']);
      
    if (loadingObserv) {
        return <p>Loading...</p>;
    };
  
    return (    
        <Grid container minWidth='100%' spacing={3} justifyItems={'space-between'}>
            <Grid item container md={12} lg={4}>
                <MaterialOrden/>
            </Grid>
            <Grid item xs ={12} sm ={12} md={12} lg={8} container>
                <ChatBubble observaciones={observaciones}/>
            </Grid>
        </Grid>
    )
};