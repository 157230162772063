import RedoIcon from '@mui/icons-material/Redo';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { ShowInfoMedidas } from '../layouts/ShowInfoMedidas';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Switch, Typography, FormControlLabel, Tooltip } from '@mui/material';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import { DialogStylized, ListStyled, ToolBarStyled } from '../style/ExposicionStyles';
import { Pagination, EditButton, ListButton, TopToolbar, ExportButton, CreateButton, FunctionField, ReferenceField } from 'react-admin';

export const ExpoPagination = () => {
    return (
        <Pagination rowsPerPageOptions={[ 1, 5, 10, 25, 50, 100, 150, 250, 500, 999 ]}/>
    );
};
  
export const LabelProps = ({ showFilters }) => {
    return ( showFilters ?
        <Typography sx={ListStyled?.labelProps}>
            {`Ocultar Filtros`}
        </Typography>
            :
        <Typography sx={ListStyled?.labelProps}>
            {`Mostrar Filtros`}
        </Typography>
    );
};
  
export const ExpoShowTopBar = () => {  
    return (
        <TopToolbar sx={ListStyled?.topToolBar}>
            <ExportButton sx={ToolBarStyled}/>
            <ListButton sx={ToolBarStyled} icon={<RedoIcon/>} resource='ordenSalidas' label='Ver Ordenes'/>
        </TopToolbar>
    );
};
  
export const DialogShow = ({ setRecord, expo }) => {
  
    const filterDisabled = expo && expo?.pendienteSalida === false && expo?.anulada === true;
  
    return (
        <>
            <FunctionField render={r => r?.pendienteSalida === false || r?.anulada === true ? (
                <ShowInfoMedidas/> 
            ) : ( 
                <EditButton
                    id={expo?.id}
                    variant='contained'
                    label='AÑADIR MEDIDAS'
                    icon={<PostAddIcon />}
                    disabled={filterDisabled}
                    onClick={() => setRecord(r)}
                    sx={DialogStylized?.dialog?.button}
                />
            )}/>
        </>
    );
};
  
export const TopBarExpo = ({ showFilters, toggleFilters }) => {
    return (
        <TopToolbar sx={ListStyled?.topToolBarML}>
            {showFilters ? <ExpoShowTopBar/> : null}
            <FormControlLabel
                onClick={toggleFilters}
                style={{ marginLeft: '15px' }}
                control={<Switch sx={ListStyled?.switchSx}/>}
                label={<LabelProps showFilters={showFilters}/>}
            />
        </TopToolbar>
    );
};
  
export const ResponsableView = ({ uppercase = false }) => {
    const formatName = (nombres, apellidos) => {
        if (uppercase) {
            return `${nombres?.toUpperCase()} ${apellidos?.toUpperCase()}`;
        }
        return `${nombres} ${apellidos}`;
    };
    return (
        <FunctionField
            label='RESPONSABLE'
            render={r => {
                if (r?.pendienteSalida === false && r?.anulada === false) {
                    return (
                        <ReferenceField source='responsableId' reference='usuarios' link={false}>
                            <FunctionField render={r => formatName(r?.nombres, r?.apellidos)} sx={ListStyled?.responsable}/>
                        </ReferenceField>
                    );
                } else if (r?.anulada === true) {
                    return (
                        <ReferenceField source='responsableId' reference='usuarios' link={false}>
                            <FunctionField render={r => formatName(r?.nombres, r?.apellidos)} sx={ListStyled?.responsable}/>
                        </ReferenceField>
                    );
                } else if (r?.reposicion === true && r?.pendienteSalida === false) {
                    return (
                        <ReferenceField source='responsableReposicion' reference='usuarios' link={false}>
                            <FunctionField render={r => formatName(r?.nombres, r?.apellidos)} sx={ListStyled?.responsable}/>
                        </ReferenceField>
                    );
                } else {
                    return <span/>;
                }
            }}
        />
    );
};
  
export const IconState = () => {
  
    const hasInvalidLenField = (len) => {
        return (
            !len?.lenId || !len?.lenWidth || !len?.lenHeight || !len?.name || !len?.uuid || !len?.area || !len?.op || !len?.version || !len?.image ||
            Number?.isNaN(len?.lenWidth) || Number?.isNaN(len?.lenHeight) || Number?.isNaN(len?.area)
        );
    };
  
    return (
        <FunctionField
            render={record => {
                if (record?.pendienteSalida === false && record?.anulada === false && record?.reposicion === false) {
                    return (
                        <Tooltip title={'Exposición correcta'}>
                            <AssignmentTurnedInIcon sx={ListStyled?.iconStateSize} color='success'/>
                        </Tooltip>
                    );
                } else if (record?.anulada === true) {
                    return (
                        <Tooltip title={'Exposición anulada'}>
                            <HighlightOffRoundedIcon color='warning'/>
                        </Tooltip>
                    );
                } else if (record?.pendienteSalida === false && record?.reposicion === true && record?.anulada === false) {
                    return (
                        <Tooltip title={'Exposición correcta con reposición'}>
                            <AssignmentTurnedInIcon sx={ListStyled?.iconStateSize} color='success'/>
                        </Tooltip>
                    );
                } else if (record?.reposicion === true) {
                    return (
                        <Tooltip title={'Exposición pendiente con reposición'}>
                            <AssignmentOutlinedIcon color='error'/>
                        </Tooltip>
                    );
                } else if (Array.isArray(record?.len) && record?.len?.length === 0) {
                    return (
                        <Tooltip title={'Exposición sin planchas'}>
                            <AssignmentLateOutlinedIcon color='error'/>
                        </Tooltip>
                    );
                } else if (Array.isArray(record?.len) && record?.len?.some(len => hasInvalidLenField(len))) {
                    return (
                        <Tooltip title={'Exposición o planchas con datos inválidos'}>
                            <ReportGmailerrorredIcon color='warning'/>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title={'Exposición pendiente'}>
                            <AssignmentOutlinedIcon color='action'/>
                        </Tooltip>
                    );
                }
            }}
        />
    );
};