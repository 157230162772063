

import React, { useState, useCallback } from "react";
import { Edit, SimpleForm, TextInput, Toolbar, useRecordContext, SaveButton, useRedirect, 
        TextField, LinearProgress, useGetOne, SelectInput, useNotify, ListButton, 
        useRegisterMutationMiddleware, useDataProvider, TopToolbar, ReferenceField, Labeled,
    } from 'react-admin';
import { Box, Typography, Grid, Button} from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { Sucursales } from "../../utils";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import PrintIcon from '@mui/icons-material/Print';
import { DeleteRemision } from "../components/DeleteRemision";
import { EditableDatagridCustom } from "../components/EditableDatagridCustom";
import { OrdenesPendientesShow } from "../components/OrdenesPendientesShow";
import { TotalesComponent } from "../components/TotalesComponent";
import { generatePDF } from "../functions/generateCotizacionPDFTest";
import PaymentsIcon from '@mui/icons-material/Payments';
import AgregarPagoButton from "./AgregarPagoButton";
const CotizacionEditActions = () => (
    <TopToolbar>
        <ListButton resource="cotizacions" label='Cotizaciones'/>
        <ListButton resource="sqlSalidas" label='Salidas'/>
    </TopToolbar>
);



const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        let newParams = {};
        const userEdit = localStorage.getItem('user');
        const { productFact, ...dataNew } = data;
        newParams = {
            id: dataNew.id,
            data: {
                ...dataNew,
                estado: 'Editada',
                fechaEdita: new Date(),
                usuarioEdita: userEdit,
            },
            previousData: { ...dataNew },
        };

        try{
            await updateStock(data.itemsFact, record.itemsFact);
        }
        catch (error) {
            console.error('ERROR actualizando stock', error);
            notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 1 });
            return; // Exit if there is an error in updating stock
        }
        let cot;
        try {
            cot = await next(resource, newParams, options);
        } catch (error) {
            console.error('ERROR actualizando Cotizacion en Grafiflex Interactivo', error);
            notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 1 });
            return;
        }
         try{
            const res = await Promise.all(
                data.itemsFact.map(async item => {
                    const productId = data.productFact.items.filter(pf => pf.idItem === item.idItem)[0]?.id ;
                    if (productId) {

                        const newItem = { ...item, vendedorID: data.vendedorID, ciudad: data.ciudadId, cotizaID: data.id, id: productId};

                        return dataProvider.update('productFacts', { id: productId, data: { ...newItem } });
                    }
                    else if(!productId){
                        const newItem = { ...item, vendedorID: data.vendedorID || '', ciudad:data.ciudadId, cotizaID: data.id, cotizaNro: data.consecutivo, clienteID:data.clienteId, clienteName: data.clienteName};
                        return dataProvider.create('productFacts', { data: { ...newItem } });
                    }
                })
            );
            const res2 = await Promise.all(
                data.productFact.items.map(async item => {
                    const found = data.itemsFact.find(it => it.idItem === item.idItem);
                    if (!found) {
                        //console.log('DELETING NAME', item);
                        return dataProvider.delete('productFacts', { id: item.id });
                    }
                })
            );
            
            } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 3 });
        }
    }, [dataProvider, notify]);

    const updateStock = async (itemsNew, itemsOld) => {
        const cajasOld = itemsOld.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0) {
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        //console.log('CAJAS OLD', cajasOld);
        const cajasNew = itemsNew.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0) {
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        //console.log('CAJAS NEW', cajasNew);
        const cajas = cajasOld.reduce((acc, cajaOld) => {
            const found = cajasNew.find(cajaNew => cajaNew.caja === cajaOld.caja);
            if (found) {                
                acc.push({ caja: cajaOld.caja, area: cajaOld.area - found.area });
            }else{   
                acc.push({ caja: cajaOld.caja, area: cajaOld.area });
            }
            return acc;
            
        }, []);
        //console.log('CAJAS', cajas);
        // find cajas new that are not in cajas old and return them with area negative
        const cajasNotOld = cajasNew.filter(cajaNew => !cajasOld.find(cajaOld => cajaOld.caja === cajaNew.caja))
        cajasNotOld.forEach(caja => caja.caja?cajas.push({ caja: caja.caja, area: -caja.area }):null);
        //console.log("CAJAS NOT OLD", cajasNotOld);

        //console.log('CAJAS', cajas);


        for (const caja of cajas) {
            if(caja === null || caja.area<=0 || caja.caja === undefined) continue;
            try {
                const stock = await dataProvider.getOne('stocks', { id: caja?.caja });
                const newStock = {
                    ...stock.data,
                    areaRestante: stock.data.areaRestante + caja.area,
                };
                delete newStock.retales;
                //console.log('NEW STOCK', newStock);
                await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            } catch (error) {
                console.error(`ERROR updating stock for caja ${caja.caja}`, error);
                notify(`Error updating stock for caja ${caja.caja}: ${error.message}`, { type: 'error' });
            }
        }
    };


    useRegisterMutationMiddleware(middleware);

    return (
        <Toolbar>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} md={6}>
                    <SaveButton
                        key={'emitir'}
                        label="Actualizar Cotización"
                        icon={<SendAndArchiveIcon />}
                        alwaysEnable
                    />
                </Grid>
             
                <Grid item xs={12} md={5}>
                    {/* Additional content can go here */}
                </Grid>
                {/* <Grid item xs={12} md={1}>
                    <DeleteRemision />          //fix todelete cotizacion later
                </Grid> */}
            </Grid>
        </Toolbar>
    );
};



const EditTitle = () => {
    const record = useRecordContext();
    //console.log('RECORD', record);
    return <span>Remisión {record ? `${record.alegraNumeracion}` : ''}</span>;    
};

const CotizacionEdit = () => {
    const [ refreshDatagrid, setRefreshDatagrid ] = useState();
    
    const refetchData = (v) => {
        setRefreshDatagrid(v)
    }


    return (
        <Edit resource="cotizacions"  redirect='list' title={<EditTitle/>} actions={<CotizacionEditActions/>}>
            <SimpleForm toolbar={<CustomToolbar/>} >
                <Grid container spacing={1} justifyContent={'space-between'} alignContent={'stretch'}>
                    <Grid item xs={12} md={9} container direction='row'>
                        <Grid item xs={12} md={12}>
                            <Labeled>
                                <ReferenceField source = 'clienteId' reference='clientes'>
                                    <TextField source="razonSocial" label="Cliente"sx={{padding:'10px', fontSize: '25px'}} />
                                </ReferenceField>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SelectInput source="ciudadId" label="Ciudad"  choices={Sucursales} optionText='name' fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{justifyContent: 'right'}}>
                        <NumeracionEstadoField/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <EditableDatagridCustom refreshDatagrid={refreshDatagrid}/>
                    </Grid>
                    <Grid item  xs={12} md={12} container  direction='row' justifyContent={'flex-end'} >
                        <Grid item xs={12} md={7} >
                            <TextInput source="observaciones"  fullWidth/>
                        </Grid>
                        <TotalesComponent changed={refetchData}/>
                    </Grid>
                </Grid>
            </SimpleForm>
           <OrdenesPendientesShow/>
        </Edit>
    );
};

export default CotizacionEdit;

const NumeracionEstadoField = () => {
    const record  = useRecordContext()
    const { setValue, getValues } = useFormContext();

    const { data: cliente,  isLoading  } = useGetOne('clientes', {id:record?.clienteId});
    
    //console.log('DATA ALEGRA REMISSION', data);
    let estado = getValues('estado');
    const dataRem = getValues();
    //console.log('DATA REM', dataRem);
    //estado = 'Anulada'
    let style = { Cobrada: {backgroundColor: 'green', color: 'white'}, Abonada: {backgroundColor: '#FFA500', color: 'white'}, Emitida: {backgroundColor: 'lightgrey', color: 'black'}, Editada: {backgroundColor: 'lightblue', color: 'black'}};


    const handlePrint = (e) => {
        // Open data.pdf link in another window
        //window.open(alegraPdf, '_blank');
        const client = {name : cliente?.razonSocial, identification: cliente?.nit_cedula, address:{address: cliente?.direccion}, phonePrimary: cliente?.telefono1}
        generatePDF(dataRem, client);

    }
    const handleOpen = (e) => {
        // Open data.url link in another window
        //window.open(alegraUrl, '_blank');
    }


    return (
        <Grid container direction='row' spacing={3} alignitems='stretch'>
            <Grid item xs={12} md={6} container direction='column' spacing={1} justifyContent='space-evenly'>
                <Grid item xs={12} md={3}  >
                    <Button type='button' onClick={(e)=>handlePrint(e)} endIcon={<PrintIcon />} variant='outlined' sx={{width:'100%', marginTop:'3px'}} disabled={isLoading}>
                        Imprimir
                    </Button>
                </Grid>
                <Grid item xs={12} md={3} >
                    <AgregarPagoButton type='edit'/>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} >
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' ,boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.1)', width:'123px', marginBottom:'5px'}}>
                    <Typography variant="h6" style={{ fontSize: '24px',fontWeight: 'bold'}}>{record.consecutivo}</Typography>
                    {isLoading ? <LinearProgress sx ={{width:'100px'}} />
                    :
                    <Box sx={{backgroundColor: style[estado].backgroundColor, width: '120%', padding:0 }}>
                        <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold', display:'flex', justifyContent:'center', width:'100%', color: style[estado].color}}>{estado}</Typography>
                    </Box>
                    }   
                </Box>
            </Grid>
        </Grid>
    );}







