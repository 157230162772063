import { Box, Grid, Typography } from '@mui/material';

export const ChatBubbleObserv = ({ observation, maxObs }) => {

  const wordsSize = observation?.OBSERV?.length < 91;
  const bubbleStyle = {
    margin: 'auto',
    padding: '1em',
    display: 'flex',
    minWidth: '20px',
    borderRadius: '15px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
    maxWidth: maxObs ? '360px' : wordsSize ? '360px' : '100%',
    backgroundColor: observation?.PRIORIDAD === 1 ? 'rgba(25, 255, 25, 0.4)' : 'lightblue', 
  };

  const textStyle = {
    color: 'black',
    textAlign: 'center',
    fontSize: { lg: '0.85em', xl: '1em'},
    fontWeight: observation?.PRIORIDAD === 1 ? 'bold' : 'normal',
  };

  const ordenInfoStyle = {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: { lg: '0.85em', xl: '1em'},
  };
  
  const userInfoStyle = {
    textAlign: 'right',
    color: 'rgba(1, 1, 1, 0.4)',
    fontSize: { lg: '0.85em', xl: '1em'},
  };

  return (
    <Box sx={bubbleStyle}>
      <Typography variant='body3' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={ordenInfoStyle}>
          {`${observation?.NUMERO}-${observation?.VERSIONOP}`}
        </Box>
        <Box sx={userInfoStyle}>
          {observation?.USUARIO}
        </Box>
      </Typography>
      <Typography variant='body2' sx={textStyle}>
        {observation?.OBSERV}
      </Typography>
    </Box>
  );
};

export const ChatBubble = ({ observaciones }) => {
  
  const maxObs = observaciones?.length > 1;
  const minObs = observaciones?.length > 0;

  return (
    <>
      {minObs ? (
        <Box sx={{ margin: '1em', width: '100%' }}>
          <Grid container spacing={2} justifyContent='center' wrap={maxObs ? 'nowrap' : 'wrap'}>
            {observaciones?.map((observation, idx) => {
              const wordsSize = observation?.OBSERV?.length < 91;
              return (
                <Grid item xs={12} lg={6} key={idx} sx={{ marginLeft: wordsSize ? '0em' : '10em' }}>
                  <ChatBubbleObserv observation={observation} maxObs={maxObs}/>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ):( <span/>)}
    </>
  );
};