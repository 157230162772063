import { useFormContext } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import IconCancel from '@mui/icons-material/Cancel';
import OutputIcon from '@mui/icons-material/Output';
import { DialogStylized, ListStyled } from '../style/ExposicionStyles';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { UserAnulacion, UserReposicion } from '../components/InputsMedidas';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, DialogContentText } from '@mui/material';
import { Form, Button, useNotify, TextInput, useUpdate, SaveButton, useRedirect, RadioButtonGroupInput } from 'react-admin';

export const  AnulacionSolicitud = ({ expo }) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [motivo, setMotivo] = useState(null);
  const { setValue, getValues } = useFormContext();
  const [update, { isLoading, error }] = useUpdate();
  const [showDialog, setShowDialog] = useState(false);
  const [tipoSolicitud, setTipoSolicitud] = useState('');
  const [selectedUser, setSelectedUser] = useState({ id: null, name: null });
  const lenEmpty = Array?.isArray(expo?.len) && expo?.len?.length === 0;
      
  useEffect(() => {
    if (lenEmpty) {
      setTipoSolicitud('anulada');
    }
  }, [setTipoSolicitud]);
  
  useEffect(() => {
    if (expo?.motivo) {
      setMotivo(expo?.motivo);
    };
  }, [expo]);

  useEffect(() => {
    setSelectedUser({ id: expo?.responsableId, name: expo?.responsableName });
  }, [expo]);
  
  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);
  const handleMotivoChange = (e) => setMotivo(e?.target?.value);
  const handleActionChange = (e) => {
    setTipoSolicitud(e?.target?.value);
    setSelectedUser({ id: null, name: null });
  };
  
  const handleSubmit = async (values) => {
    try {
      const isAnulada = tipoSolicitud === 'anulada';
      const isReposicion = tipoSolicitud === 'reposicion';
      const motivo = values?.motivo !== '' || values?.motivo !== null ? values?.motivo : expo?.motivo;
    
      if (!expo?.id) {
        notify('Error: El ID del registro es nulo o indefinido.', { type: 'error' });
        return;
      };
    
      if (!motivo?.trim()) {
        notify('El motivo es obligatorio.', { type: 'error' });
        return;
      };
        
      if (isAnulada && (!selectedUser?.id || !selectedUser?.name?.trim())) {
        notify('El responsable para la anulación es obligatorio.', { type: 'error' });
        return;
      };
        
      if (isReposicion && (!selectedUser?.id || !selectedUser?.name?.trim())) {
        notify('El responsable para la reposición es obligatorio.', { type: 'error' });
        return;
      };

      const dataToUpdate = {
        id: expo?.id,
        motivo: motivo,
        anulada: isAnulada,
        reposicion: isReposicion,
        responsableId: isAnulada ? selectedUser?.id : expo?.responsableId,
        responsableName: isAnulada ? selectedUser?.name : expo?.responsableName,
        reposicionName: isReposicion ? selectedUser?.name : expo?.reposicionName,
        responsableReposicion: isReposicion ? selectedUser?.id : expo?.responsableReposicion,
      };
    
      if (!dataToUpdate?.id) {
        notify('Error: No se puede actualizar sin un ID válido.', { type: 'error' });
        return;
      };
    
      await update('exposicions', {
        id: expo?.id,
        data: dataToUpdate,
        previousData: expo,
      },{
        onSuccess: async () => {
          if (isAnulada) {
            setValue('motivo', motivo);
            setValue('anulada', true);
            setValue('responsableId', selectedUser?.id || expo?.responsableId);
            setValue('responsableName', selectedUser?.name || expo?.responsableName);
            setShowDialog(false);
            redirect('list', 'exposicions');
            notify('Se agregó el motivo y el responsable para anulación.');
          } else if (isReposicion) {
            setValue('motivo', motivo);
            setValue('reposicion', true);
            setValue('responsableReposicion', selectedUser?.id || expo?.responsableReposicion);
            setValue('reposicionName', selectedUser?.name || expo?.reposicionName);
            setShowDialog(false);
            notify('Se agregó el motivo y el responsable para reposición.');
          }
        },  
        onFailure: () => {
          redirect('list', 'exposicions');
          console.error('Error al agregar la solicitud:', error?.message || error);
        }}
      );
      console.log('Antes de Agregar el Motivo', expo);
      console.log('Después de Agregar el Motivo', values);
    } catch (error) {
      setShowDialog(false);
      console.error('Error al agregar la solicitud:', error?.message || error);
      notify('Error al agregar la solicitud. Intenta nuevamente.', { type: 'error' });
    };
  };

  const isSaveButtonDisabled = () => {
    const motivoValido = motivo?.trim();
    
    if (lenEmpty) {
      return !motivoValido && tipoSolicitud === 'anulada';
    } else {
      if (tipoSolicitud === 'anulada') {
        return !(motivoValido && selectedUser);
      } else if (tipoSolicitud === 'reposicion') {
        return !motivoValido || !selectedUser?.id || !selectedUser?.name?.trim();
      };
    };
    return true;
  };    
  
  if (error) {
    return (
      <Button sx={{ color: 'white', fontWeight: 'bold', background: 'red' }}>
        {`Error`} {error?.message}
      </Button>
      )
    };

    const labelRadioButton = (
      <div style={{ marginLeft: tipoSolicitud ? '0rem' : '5.5em' }}>
        {`Tipo de Solicitud`}
      </div>
    );

    const gridRadioButton = {
      display: 'flex',
      marginTop: tipoSolicitud ? '0rem' : '1em',
      marginLeft: tipoSolicitud ? '0rem' : '1em',
      marginBottom: tipoSolicitud ? '0rem' : '-1em',
      justifyContent: tipoSolicitud ? 'flex-start' : 'center',
    };

    const typeSolicitud = {
      button: {
        color: 'white',
        background: 'black', 
      },
      dialogTyle : {
        color: 'white',
        display: 'flex',
        background: 'black',
        alignItems: 'center',
        justifyContent: 'center',
      },
      typography: {
        fontWeight: 'bold',
      },
      dialogActions: {
        zIndex: 1,
        bottom: 0, 
        position: 'sticky', 
        backgroundColor: 'white', 
      },
    }
  
    const valuesInput = getValues(['motivo', 'reposicion', 'responsableReposicion', 'reposicionName', 'anulada', 'responsableId', 'responsableName']);
    const filterButton = expo?.reposicion === true || valuesInput?.[1] === true;
  
  return (
    <>
      {filterButton ? (
        <span/>
      ):(
        <>
          <Button variant='contained' onClick={handleClick} sx={ListStyled?.submitButton} label='Solicitar anulación' startIcon={<CancelScheduleSendIcon/>} style={typeSolicitud?.button}/>
          <Dialog fullWidth maxWidth={ tipoSolicitud && lenEmpty ? 'xs' : tipoSolicitud ? 'sm' : 'xs' } open={showDialog} onClose={handleCloseClick}>
            <DialogTitle style={typeSolicitud?.dialogTyle}>
              <Typography style={typeSolicitud?.typography}>
                {lenEmpty ? 'Describa el motivo de la anulación' : 'Solicitud de anulación o reposición'} 
              </Typography>
            </DialogTitle>
            <Form resource='exposicions' onSubmit={handleSubmit}>
              <DialogContent>
                {!tipoSolicitud && (
                  <Grid item container>
                    <DialogContentText textAlign='center'>
                      <Typography variant='body2' display='block' textAlign={'center'}>
                        {`Por favor seleccione el responsable para su solicitud y describa el motivo, para dicha solicitud.`}
                      </Typography>
                    </DialogContentText>
                  </Grid>
                )}
                <Grid container spacing={2} justifyContent='space-between' flexDirection={tipoSolicitud ? 'row' : 'column'}>
                  {lenEmpty ? (
                    <Grid container item sx={{ marginTop: lenEmpty ? '1em' : '0em' }}>
                      <Grid item lg={12}>
                        <UserAnulacion onUserSelect={setSelectedUser}/> 
                      </Grid>
                      <Grid item lg={12}>
                        <TextInput sx={DialogStylized?.layout?.inputs} fullWidth multiline maxRows={4} size='small' label='Motivo' source='motivo' onChange={handleMotivoChange}/>
                      </Grid>
                    </Grid>
                  ):(
                    <>
                      <Grid item xs={ tipoSolicitud ? 2 : 11.5 } sx={gridRadioButton}>
                        <RadioButtonGroupInput
                          size='small'
                          source='TipoSolicitud'
                          label={labelRadioButton}
                          onChange={handleActionChange}
                          sx={ListStyled?.radioButton || {}}
                          row={tipoSolicitud ? false : true}
                          choices={[
                            { id: 'anulada', name: 'Anulación' }, 
                            { id: 'reposicion', name: 'Reposición' }
                          ]}
                          style={{ marginTop: tipoSolicitud ? '-0.5rem' : '0rem', marginLeft: tipoSolicitud ? '0em' : '1em'}}
                        />
                      </Grid>
                      <Grid item xs={8.5} sm={9.2} md={9.5} lg={9.5} xl={9.5} style={{ marginTop: '0.8em' }}>
                        <Grid container>
                          {tipoSolicitud && (
                            <Grid item xs={12}>
                              <TextInput sx={DialogStylized?.layout?.inputs} fullWidth multiline maxRows={4} size='small' label='Motivo' source='motivo' onChange={handleMotivoChange}/>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            {tipoSolicitud === 'anulada' && <UserAnulacion onUserSelect={setSelectedUser}/>}
                            {tipoSolicitud === 'reposicion' && <UserReposicion onUserSelect={setSelectedUser}/>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions style={typeSolicitud?.dialogActions}>
                <Button sx={ListStyled?.submitButton} label='ra.action.cancel' onClick={handleCloseClick} disabled={isLoading}>
                  <IconCancel/>
                </Button>
                <SaveButton sx={ListStyled?.submitButton} size='small' icon={<OutputIcon/>} label='Enviar Solicitud' disabled={isSaveButtonDisabled()}/>
              </DialogActions>
            </Form>
          </Dialog>
        </>
      )}
    </>
  );
};