import { Sucursales } from '../../utils';
import { ImageCDI } from './components/ImageCDI';
import React, { useState, useEffect } from 'react';
import { DialogExpo } from './layouts/DialogLayout';
import { ListStyled } from './style/ExposicionStyles';
import { List, Datagrid, TextField, useGetOne, DateField } from 'react-admin';
import { ExposicionFilter, FiltersExposicion } from './components/FiltersExposicion';
import { DialogShow, ExpoPagination, IconState, ResponsableView, TopBarExpo } from './components/VariousComponets';

export const ExposicionList = () => {

  const [record, setRecord] = useState({});
  const user = localStorage?.getItem('user');
  const [filterCity, setFilterCity] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const { data: usuario } = useGetOne('usuarios', { id: user });

  const toggleFilters = () => setShowFilters(!showFilters);

  useEffect(() => {
    if (usuario && usuario?.sedes && usuario?.sedes?.length > 0) {
      const filterSedeUsuario = usuario?.sedes?.map(sede => {
        const ciudadSucursal = Sucursales?.find(sucursal => sucursal?.user === sede)?.id;
        return ciudadSucursal ? { ciudad: { eq: ciudadSucursal }} : null;
      })?.filter(ciudad => ciudad !== null);
      if (filterSedeUsuario?.length > 0) {
        setFilterCity({ or: filterSedeUsuario });
        console.log('FilterCity set:', { or: filterSedeUsuario });
      } else {
        setFilterCity({});
        console.log('No se aplicó el filtro, no hay ciudades encontradas.');
      };
    };
  }, [usuario]);

  return (
    <>
      <List 
        perPage={50}
        filter={filterCity}
        pagination={<ExpoPagination/>}
        sort={{ field: 'dateExposed', order: 'DESC'}}
        filters={showFilters ? ExposicionFilter : []}
        queryOptions={{ meta: { searchable: 'true'}}}
        aside={showFilters ? <FiltersExposicion /> : null}
        actions={<TopBarExpo showFilters={showFilters} toggleFilters={toggleFilters}/>}
      >
        <Datagrid expandSingle sx={ListStyled?.datagrid} render={(record) => setRecord(record)} bulkActionButtons={false}>
          <IconState source='estado' label='ESTADO'/>
          <ImageCDI source='imageExp' label='IMAGEN' sx={ListStyled?.componentFont}/>
          <TextField source='expoName' label='NOMBRE' sx={ListStyled?.componentFont}/>
          <TextField source='plateName' label='PLANCHA' sx={ListStyled?.componentFont}/>
          <TextField source='areaTotalLen' label='ÁREA TOTAL' sx={ListStyled?.componentFont}/>
          <TextField source='referencia' label='REFERENCIA' sx={ListStyled?.componentFont}/>
          <DateField source='dateExposed' label='FECHA EXPOSICIÓN' showTime={true} sx={ListStyled?.componentFont}/>
          <DateField source='updatedAt' label='FECHA ACTUALIZACIÓN' showTime={true} sx={ListStyled?.componentFont}/>
          <ResponsableView source='responsable' label='RESPONSABLE'/>
          <DialogShow label='GESTIÓN' source='gestion' setRecord={setRecord}/>
        </Datagrid>
      </List>
      {record && <DialogExpo expo={record}/>}
    </>
  );
};