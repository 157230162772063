import { useTheme, useMediaQuery } from '@mui/material';
import pantoneSolidCoated from './pantoneSolidCoated.json';
import { getUserExposicionByPin } from '../graphql/queries';

export const validarExistenciaPin = async (pin, client, notify) => {
  try {
    if (!pin || typeof pin !== 'string') {
      console.error('Error: El PIN no es un valor válido.');
      return false;
    };
    
    const userResponse = await client?.graphql({
      query: getUserExposicionByPin, 
      variables: { pin: pin }
    });
    
    const user = userResponse?.data?.getUserExposicionByPin?.items?.map(user => {
      console.log(`Usuario: ${user?.nombres} ${user?.apellidos} ingresado exitosamente.`);
      return `${user?.nombres} ${user?.apellidos}`
    });

    if (userResponse?.data?.getUserExposicionByPin?.items?.length > 0) {
      notify(`Usuario encontrado: ${user}`, { type: 'success' });
      return true;
    } else {
      notify('No se encontro el usuario', { type: 'error' });
      return false;
    };
  } catch (error) {
    console.error('Error al verificar la existencia del PIN:', error);
    notify('Error al verificar la existencia del PIN.', { type: 'error' });
    return false;
  };
};

const groupByOpAndVersion = (lenArray) => {
  const organizedLen = lenArray?.reduce((acc, curr) => {
    if (curr?.op && curr?.version) {
      const key = `${curr?.op}-${curr?.version}`;
      if (!acc[key]) {
        acc[key] = {
          data: [],
          op: curr?.op,
          version: curr?.version,
          totalPlanchasExp: 0,
        };
      }
      acc[key].data.push(curr);
      acc[key].totalPlanchasExp = acc[key].data?.length;
    } else {
      console.warn('No se encontró una Op o una versión:', curr);
    }
    return acc;
  }, {});
  return organizedLen;
};

const checkLen = async (organizedLen, calibreExp) => {
  try {
    let lenDataArray = [];
    const calibre = parseInt(calibreExp, 10);
    for (const key in organizedLen) {
      if (organizedLen.hasOwnProperty(key)) {
        const lenData = organizedLen[key];
        const Op = parseInt(lenData?.op, 10);
        const version = parseInt(lenData?.version, 10);
        const totalPlanchasExp = lenData?.totalPlanchasExp;

        if (isNaN(Op) || isNaN(version) || isNaN(totalPlanchasExp) || isNaN(calibre)) {
          console.error('Se ha detectado número inválido:', { Op, version, totalPlanchasExp });
        } else {
          lenDataArray.push({ Op, version, totalPlanchasExp, calibre });
        }
      }
    }
    return lenDataArray;
  } catch (error) {
    console.error('Error en checkLen:', error);
    return [];
  }
};

const checkOp = async (dataOrdenSalidas) => {
  try {
    let dataArray = [];
    for (let i = 0; i < dataOrdenSalidas?.length; i++) {
      const op = dataOrdenSalidas[i]?.op;
      const version = dataOrdenSalidas[i]?.version;
      const totalPlanchasExp = dataOrdenSalidas[i]?.totalPlanchasExp;
      const totalPlanchasOrden = dataOrdenSalidas[i]?.totalPlanchasOrden;
      const calibreOrden = dataOrdenSalidas[i]?.calibreOrden;

      if (isNaN(op) || isNaN(version) || isNaN(totalPlanchasExp) || isNaN(totalPlanchasOrden) || isNaN(calibreOrden)) {
        console.error('Se ha detectado número inválido:', { op, version, totalPlanchasExp, totalPlanchasOrden, calibreOrden });
      } else {
        dataArray.push({ op, version, totalPlanchasExp, totalPlanchasOrden, calibreOrden });
      }
    }
    return dataArray;
  } catch (error) {
    console.error('Error en checkOp', error);
  }
  return [];
};

const compararArrays = (dataArray, lenDataArray) => {

  const ordenWarning = [];
  const dataMap = new Map(dataArray?.map(item => [item.op, item]));

  lenDataArray?.forEach(lenDataItem => {
    const dataItem = dataMap?.get(lenDataItem?.Op);

    if (!dataItem) {
      const messageWarning = `La OP ${lenDataItem?.Op} está correcta para añadir medidas.`;
      ordenWarning.push({ 
        op: lenDataItem?.Op, 
        warning: false, 
        message: messageWarning
      });
      console.log(messageWarning);
      return;
    };

    const lensExposicion = lenDataItem?.totalPlanchasExp;
    const totalPlanchasExp = dataItem?.totalPlanchasExp;
    const totalPlanchasOrden = dataItem?.totalPlanchasOrden;

    if (totalPlanchasExp >= totalPlanchasOrden) {
      const messageWarning = `La OP ${lenDataItem?.Op} ya superó su total planchas.`;
      ordenWarning?.push({
        op: lenDataItem?.Op, 
        warning: true, 
        message: messageWarning
      });
      console.warn(`La OP ${lenDataItem?.Op} ya superó su total planchas.`);
      return;
    };

    const calibreExp = lenDataItem?.calibre;
    const calibreOrden = dataItem?.calibreOrden;
    const planchasFaltantes = totalPlanchasOrden - totalPlanchasExp;

    if (!calibreExp !== !calibreOrden) {
      const messageWarning = `La OP ${lenDataItem?.Op} tiene un calibre ${calibreExp} diferente a la OP expuesta ${calibreOrden}.`;
      ordenWarning?.push({
        op: lenDataItem?.Op, 
        warning: true, 
        message: messageWarning
      });
      console.warn(`La OP ${lenDataItem?.Op} tiene un calibre ${calibreExp} diferente a la OP expuesta ${calibreOrden}.`);
      return;
    };

    if (lensExposicion > planchasFaltantes) {
      const messageWarning = `Se está intentando agregar ${lensExposicion} plancha(s) para la OP ${lenDataItem?.Op} y solo se permite ${planchasFaltantes} plancha(s).`;
      ordenWarning?.push({ 
        op: lenDataItem?.Op, 
        warning: true, 
        caution: true,
        message: messageWarning,
      });
      console.warn(`Se está intentando agregar ${lensExposicion} plancha(s), para la OP ${lenDataItem?.Op} y solo se permite ${planchasFaltantes} plancha(s).`);
    } else {
      const messageWarning = `Se va a ingresar ${lensExposicion} plancha(s) para la OP ${lenDataItem?.Op}.`;
      ordenWarning?.push({ 
        op: lenDataItem?.Op, 
        warning: false, 
        message: messageWarning
      });
      console.warn(`Se va a ingresar ${lensExposicion} plancha(s) para la OP ${lenDataItem?.Op}.`);
    };
  });
  return ordenWarning;
};

const extractColorLenId = (lenExpo) => {
  if (typeof lenExpo !== 'string') {
    return null;
  };

  const cmykw = lenExpo.match(/[^_]+$/);
  if (cmykw) {
    return cmykw[0];
  };

  const pantoneNumber = lenExpo?.match(/(\d+)$/);

  if (pantoneNumber) {
    const pantoneName = pantoneNumber[0];
    const pantoneColor = findPantoneColor(`PANTONE ${pantoneName}`);
    if (pantoneColor) {
      return `PANTONE ${pantoneName}`;
    };
  };
  return null;
};

const findPantoneColor = (colorCode) => {

  const cmykwColors = ['CYAN', 'MAGENTA', 'YELLOW', 'BLACK', 'BLANCO', 'OPAQUE WHITE'];

  if (cmykwColors?.includes(colorCode)) {
    const color = pantoneSolidCoated?.find(item => item?.name?.toLowerCase() === colorCode?.toLowerCase());
    return color;
  };

  if (colorCode === null || colorCode === undefined) {
    return null;
  };

  let pantoneName = colorCode?.replace('PANTONE', '')?.trim();

  const pantoneColor = pantoneSolidCoated?.find(item => 
    item?.name?.toUpperCase()?.trim() === `PANTONE ${pantoneName?.toUpperCase()}` ||
    item?.name?.toUpperCase()?.trim() === pantoneName?.toUpperCase()?.replace('PANTONE', '')?.trim() ||
    item?.name?.toUpperCase()?.trim() === `PANTONE ${pantoneName?.toUpperCase()?.replace('PANTONE', '')}`
  );

  if (pantoneColor) {
    return pantoneColor;
  } else {
    return null;
  };
};

const GetMaxWidth = () => {

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  if (isXs) return 'sm';
  if (isSm || isMd) return 'md';
  if (isLg) return 'md';
  if (isXl) return 'xl';
  return 'md';
};

const MaxWidthBox = ({filterConsecutivo}) => {

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  if (filterConsecutivo) {
    if (isXs) return 'sm';
    if (isSm || isMd) return 'sm';
    if (isLg) return 'sm';
    if (isXl) return 'md';
    return 'md';
  } else {
    if (isXs || isSm || isMd || isLg || isXl) return 'xs';
  };
};

const removeDuplicates = (data, uniqueKey) => {
  return data ? data?.reduce((accumulator, current) => {
    if (!accumulator?.some((item) => item[uniqueKey] === current[uniqueKey])) {
      if (current[uniqueKey]) {
        accumulator?.push(current);
      }
    }
    return accumulator;
  }, []) : [];
};

export { groupByOpAndVersion, checkLen, checkOp, compararArrays, extractColorLenId, findPantoneColor, removeDuplicates, GetMaxWidth, MaxWidthBox};