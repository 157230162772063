import { useGetList, Form } from 'react-admin';
import { DialogTitle, Box } from '@mui/material';
import { AnulacionSolicitud } from './TypeSolicitud';
import { EditDialog } from '@react-admin/ra-form-layout';
import { DialogStylized } from '../style/ExposicionStyles';
import { GetMaxWidth } from '../../../utils/utilsExposicion';
import { ExposicionForm } from '../components/ExposicionForm';

export const DialogExpo = ({ expo }) => {
  
  const { data: exposicion } = useGetList('exposicions', {
    meta: { searchable: 'true' },
    filter: { id: { eq: expo?.id }},
    pagination: { page: 1, perPage: 999 },
  });

  const expose = exposicion?.[0];
  const anuExpo = expose?.anulada === true;
  const repoExpo = expose?.reposicion === true;
  const hasConditions = repoExpo === true || anuExpo === true;
  const lenEmpty = Array?.isArray(expose?.len) && expose?.len?.length === 0;
  const expoName = expose?.expoName ? expose?.expoName?.toUpperCase()?.replace(/[_-]/g, '') : 'EXPOSICIÓN';

  const title = (
    <>
      {hasConditions ? (
        <DialogTitle style={DialogStylized?.dialog?.dialogTitle}>
          <Box color={repoExpo ? '#a70000' : ''} sx={hasConditions ? DialogStylized?.dialog?.box1HasConditions : DialogStylized?.dialog?.box1}>
            {`EXPOSICIÓN: ${expoName}`}
          </Box>
        </DialogTitle>  
      ):(
        <DialogTitle style={DialogStylized?.dialog?.dialogTitle}>
          <Box color={repoExpo ? '#a70000' : ''} sx={hasConditions ? DialogStylized?.dialog?.box1HasConditions : DialogStylized?.dialog?.box1}>
            {`EXPOSICIÓN: ${expoName}`}
          </Box>
          {lenEmpty ? (
            <span/>
          ):(
            <Box sx={{ marginLeft: 'auto' }}>
              <Form noValidate record={{}} defaultValues={{}}>
                <AnulacionSolicitud expo={expose} lenEmpty={lenEmpty}/>
              </Form>
            </Box>
          )}
        </DialogTitle>
      )}
    </>
  );
  
  return (
    <EditDialog closeAfterTransition title={title} maxWidth={GetMaxWidth(lenEmpty)}>
      <ExposicionForm expo={expo}/>
    </EditDialog>
  );
};