import React, {useState} from 'react';
import { SimpleForm, ListActions, Datagrid, DateField, List, ShowButton,
        NumberField, useNotify, TextField, Pagination, useRecordContext,
        useDataProvider, FunctionField, WrapperField } from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import { AlegraCreditNotesCreateLayout } from './AlegraCreditNotesCreateLayout';  
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadingIcon from '@mui/icons-material/Downloading';
import AlegraIcon from "../components/alegraIcon";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ListPagination = () => <Pagination rowsPerPageOptions={[5, 15, 30]} />;


const AlegraCreditNotesList = () => (
        <>
            <List actions={<ListActions hasCreate />} pagination={<ListPagination/>}
            sx={{
                "& .column-client.name": { 
                    maxWidth: "20px",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                "& .column-cause": { 
                    maxWidth: "300px",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                }}
                title = 'Notas Crédito'
            >
                <Datagrid >
                    <TextField source="numberTemplate.number" label='Número' />
                    <TextField source="client.name" />
                    <TextField source="warehouse.name" label='ciudad'/>
                    <NumberField source="total" />
                    <TextField source="cause" />
                    {/* <TextField source="creditNoteOperationType" /> */}
                    <FunctionField source="stamp.legalStatus" label='DIAN' render={record=>(getSelectedReferencesDIANStatus(record))}/>
                    {/* <TextField source="type" /> */}
                    <DateField source="date" />
                    <WrapperField label="Acciones">
                        <ShowButton label={false}/>
                        <ImprimirButton />
                        <AlegraButton/>
                    </WrapperField>
                </Datagrid>
            </List>
            <CreateDialog title={'Crear Nota Crédito'} maxWidth="lg">
                <SimpleForm sx={{minWidth:'1000px'}}>
                    <AlegraCreditNotesCreateLayout/>
                </SimpleForm>
            </CreateDialog>
        </>
    );
export default AlegraCreditNotesList;


const AlegraButton = () => {
    const record = useRecordContext();
    return(
        <IconButton
            variant='outlined'
            onClick={() => {
                window.open(`https://app.alegra.com/credit-note/view/id/${record.id}`, '_blank');
            }}
        >
            <AlegraIcon/>
        </IconButton>
    );
}

const ImprimirButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false); 
    const notify = useNotify();
    
    //console.log('RECORD', record)
    const handlePrint = async () => {
        setLoading(true);
        const cn = await dataProvider.getOne('alegraCreditNotes', {id: record.id})
        //console.log('CN', cn);
        if (cn && cn.data && cn.data.pdf === null){
            setLoading(false);
            notify('No fue posible generar el PDF', 'error');
            return;
        }
        window.open(cn.data.pdf, '_blank');
        setLoading(false);
    }
    return(
    <IconButton
        variant='outlined'
        onClick={handlePrint}
    >{

        loading ?  <DownloadingIcon/> :
        <PrintIcon/>
    }
    </IconButton>
);
}

////////////////FIX AND UPLOAD ALEGRA CONNECT TO SDK7 TO BE ABLE TO GET ONE RECORD BY ID, AND BRING PDF URL

const getSelectedReferencesDIANStatus = (record) => {
    if(record.stamp.legalStatus === 'PENDING'){ return <Tooltip title='Pendiente por Emitir'><PendingOutlinedIcon label = 'Pendiente' color='info'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_WAITING_RESPONSE'){ return <Tooltip title='Pendiente Respuesta DIAN'><TimerOutlinedIcon label = 'Esperando Respuesta' color='warn'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_ACCEPTED'){ return <Tooltip title='Aprobada'><CheckCircleIcon label = 'Aprobada' color='success'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_REJECTED'){ return <Tooltip title="Rechazada"><BlockOutlinedIcon  label = 'Rechazada' color='error'/></Tooltip>}
    if(record.stamp.legalStatus === 'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS'){ return <Tooltip title="Aprobada con Observaciones"> <CheckCircleOutlinedIcon label = 'Aprobada' color='success'/> </Tooltip>}
}

/* <TextField source="numberTemplate.fullNumber" label="Numero" />
<TextField source="id" />
<DateField source="dueDate" />
<DateField source="datetime" />
<TextField source="observations" />
<TextField source="status" />
<TextField source="client.name" />
<NumberField source="subtotal" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
<NumberField source="tax" label='Iva' locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
<NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
<NumberField source="totalPaid" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
<NumberField source="balance" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
<TextField source="warehouse.id" />
<TextField source="term" />
<TextField source="type" />
<TextField source="operationType" />
<TextField source="paymentForm" />
<NumberField source="seller.id" />
<NumberField source="priceList.id" />
<ArrayField source="retentionsSuggested"><SingleFieldList><ChipField source="id" /></SingleFieldList></ArrayField>
<TextField source="stamp.legalStatus" />
<DateField source="costCenter.code" />
<TextField source="printingTemplate.id" />
<TextField source="paymentMethod" /> */