import{
    List,
    Datagrid,
    TextField,
    ReferenceField,
    SimpleForm,
    Button,
    TopToolbar,
} from 'react-admin'
import { AmplifyImageField } from '../Amplify/components/AmplifyImageField'
//import ProductCreateDialog from './ProductCreateDialog'
import ProductCreateDialog from './ProductCreateDialog'
import CreateProductDialog from './components/CreateProductDialog'
import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout"
import CreateProductLayout from "./components/CreateProductLayout"
import EditProductLayout from "./components/EditProductLayout"


const ProductList = (props)=>{
    return(
    <>
        <List {...props} hasCreate >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <ReferenceField  label="Cliente" source="clienteID" reference="Clientes">
                    <TextField source="razonSocial" />
                </ReferenceField>
                <TextField source="nombre" />
                <ReferenceField  label="Marca" source="marcaID" reference="Marcas">
                    <TextField source="nombre" />
                </ReferenceField>
                <AmplifyImageField source="mainFileThumbnail" />
            </Datagrid>
        </List>
        <CreateDialog fullWidth maxWidth="md">
            <SimpleForm>
                <CreateProductLayout />
            </SimpleForm>
        </CreateDialog>    
        <EditDialog fullWidth maxWidth="md">
            <SimpleForm>
                <EditProductLayout />
            </SimpleForm>
        </EditDialog> 
    </>
    )
}


export default ProductList