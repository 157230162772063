import {  
    useList, 
    TextInput, 
    TextField, 
    useRefresh, 
    SelectInput,
    NumberInput, 
    NumberField, 
    BooleanField, 
    BooleanInput, 
    ReferenceInput, 
    ReferenceField,
    useDataProvider, 
    useRecordContext, 
    AutocompleteInput, 
    ListContextProvider, 
} from 'react-admin';
import { v4 as uuid } from 'uuid';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState} from "react";
import DeleteRowItemButton from "../components/DeleteRowItemButton";
import { EditableDatagrid, RowForm, useRowContext, EditRowButton, } from "@react-admin/ra-editable-datagrid";

export const CustomEditableDatagrid = (props) => {

    const refresh = useRefresh();
    const { getValues } = useFormContext();
    const [changed, setChanged] = useState(false);
    const listContext = useList({ data: getValues('itemsFacts') });

    const handleUpdate = (v) => {
        refresh();
        console.log('REFRESHING') 
        setChanged(v);  
    };

    return (
        <ListContextProvider value={listContext}>
            <EditableDatagrid    
                size='small'              
                actions={<ActionsCED/>}
                bulkActionButtons={false}
                pagination={{ page: 1, perPage: 100}} 
                editForm={<ItemFormEdit data={listContext.data} changed={(v)=>handleUpdate(v)}/>} 
                createForm={<ItemFormCreate data={listContext.data} changed={(v)=>handleUpdate(v)}/>}
            >
                <TextField source='ordenVersion' label='Orden' refresh={changed?.toString()}/>
                <TextField source="descripcion" refresh={changed?.toString()}/>
                <TextField source="material" refresh={changed?.toString()} label='Producto'/>
                <ReferenceField source="caja" reference='stocks' label='Caja'>
                    <TextField source="consecutivo" refresh={changed?.toString()} />
                </ReferenceField>
                <NumberField source="ancho" refresh={changed?.toString()}/>
                <NumberField source="largo" refresh={changed?.toString()}/>
                <NumberField source="cantidad" refresh={changed?.toString()}/>
                <NumberField source="area" refresh={changed?.toString()}/>
                <NumberField source="valorUnitario" label='V. Unitario' refresh={changed?.toString()}/>
                <BooleanField source="iva" refresh={props.refreshDatagrid?.toString() || changed?.toString() } />
                <NumberField source="valorTotal" label='V. Total' refresh={changed?.toString()}/>
            </EditableDatagrid>
        </ListContextProvider>
    )
};

const ItemFormEdit = (props) => {

    const refresh = useRefresh();
    const { close } = useRowContext();
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const { setValue, getValues } = useFormContext();
    const indexOfRow = props?.data?.findIndex(item => item?.idItem === record?.idItem);
    const material = getValues(`itemsFacts.${indexOfRow}.material`);

    const handleSubmit = (data) => {
        console.log('DATA EDIT', data)
        data.area = data.ancho * data.largo * data.cantidad;
        data.valorTotal = data.valorUnitario * data.area;

        if (data.material?.includes("ARTE") || data.material?.includes("FLETE")){
            data.valorTotal = data.valorUnitario * data.cantidad;
            data.area = 0
            //data.iva = data.material?.includes("FLETES") ? false : true;
        };

        for (let key in data){
            setValue(`itemsFacts.${indexOfRow}.${key}`, data[key]);
        };

        close();
        props.changed(true);
    };

    const filter2query = (filter) => {
        if (filter){
            return { id: { contains: filter?.toUpperCase()}};
        };
        return {};
    };

    const handleMaterialChange = async (event) => {
        if (!event || event === '' ) return;
        setValue(`itemsFacts.${indexOfRow}.material`, event);
        await dataProvider.getOne('materials', { id: event }).then((response) => {
            setValue(`itemsFacts.${indexOfRow}.alegraItemId`, response.data?.alegraId);
        });
        refresh();
    };

    return (
        <RowForm onSubmit={handleSubmit} >
            <TextInput source='ordenVersion' label='Orden'/>
            <TextInput source="descripcion" fullWidth/>
            <ReferenceInput source="material" reference="materials" perPage={500}  >
                <AutocompleteInput optionText='id' optionValue='id' size='small' label='Producto' filterToQuery={filter2query} onChange={handleMaterialChange}/>
            </ReferenceInput>
            {material&&(!material.includes("ARTE") && !material.includes("FLETES")) ?
                <ReferenceInput source="caja" reference="stocks" filter= {{ and: { activa: { eq: true }, materialID: { eq: material}}}}  
                    sort={{ field: 'consecutivo', order: 'DESC'}} queryOptions={{ meta: { searchable: 'true'}}} perPage={500} >
                    <SelectInput optionText='id' optionValue='id' size='small' sx={{ width:'50px'}}/>
                </ReferenceInput> : null}
            {material&&(!material.includes("ARTE") && !material.includes("FLETES")) ? <NumberInput source="ancho" sx={{ width: 100 }}/> : null}
            {material&&(!material.includes("ARTE") && !material.includes("FLETES")) ? <NumberInput source="largo" sx={{ width: 100 }}/> : null}
            <NumberInput source="cantidad"/>
            <NumberInput source="valorUnitario" label='V.Unitario'/>
            <BooleanInput source="iva" label='IVA'/>
        </RowForm>
    );
};

const ItemFormCreate = (props) => {

    const { close } = useRowContext();
    const refresh = useRefresh();
    const { setValue, getValues } = useFormContext();
    const [indexOfRow, setInitialRowIndex] = useState(null);
    console.log('INDEX', indexOfRow)
    useEffect(() => {
        if(indexOfRow === null){
            setInitialRowIndex(props.data.length);
            console.log('propsdatalength', props.data.length)
        }
    }, [indexOfRow]);
    
    const dataProvider = useDataProvider();
    const [materialName, setMaterialName] = useState('');
    const [materialAlegraID, setMaterialAlegraID] = useState('');
    const material = getValues(`itemsFacts.${indexOfRow}.material`);

    const handleSubmit = (data) => {
        console.log('DATA create', data)
        setValue(`itemsFacts.${indexOfRow}.ordenVersion`, data.ordenVersion);
        data.area = data.ancho * data.largo * data.cantidad;
        data.valorTotal = data.valorUnitario * data.area;

        if (data.material?.includes("ARTE") || data.material?.includes("FLETES")){
            data.valorTotal = data.valorUnitario * data.cantidad;
            data.area = 0
            data.iva = data.material?.includes("FLETES") ? false : true;
        };

        setValue(`itemsFacts.${indexOfRow}.idItem`, uuid());
        setValue(`itemsFacts.${indexOfRow}.ordenVersion`, data.ordenVersion);
        setValue(`itemsFacts.${indexOfRow}.descripcion`, data.descripcion);
        setValue(`itemsFacts.${indexOfRow}.material`, materialName);
        setValue(`itemsFacts.${indexOfRow}.caja`, data.caja);
        setValue(`itemsFacts.${indexOfRow}.ancho`, data.ancho);
        setValue(`itemsFacts.${indexOfRow}.largo`, data.largo);
        setValue(`itemsFacts.${indexOfRow}.cantidad`, data.cantidad);
        setValue(`itemsFacts.${indexOfRow}.valorUnitario`, data.valorUnitario);
        setValue(`itemsFacts.${indexOfRow}.area`, data.area);
        setValue(`itemsFacts.${indexOfRow}.valorTotal`, data.valorTotal);
        setValue(`itemsFacts.${indexOfRow}.iva`, true);
        setValue(`itemsFacts.${indexOfRow}.alegraItemId`, materialAlegraID);
                
        close();
        props.changed(true);
    };

    const filter2query = (filter) => {
        if (filter){
            return { id: {contains: filter?.toUpperCase()}};
        }
        return {};
    };

    const handleMaterialChange = async (event) => {
        if (!event || event === '' ) return;
        setValue(`itemsFacts.${indexOfRow}.material`, event);
        await dataProvider.getOne('materials', {id: event})?.then((response) => {
            setValue(`itemsFacts.${indexOfRow}.alegraItemId`, response?.data?.alegraId);
            setMaterialAlegraID(response?.data?.alegraId)
        });

        if(event.includes("FLETES")){
            setValue(`itemsFacts.${indexOfRow}.iva`, false);
        }
        setMaterialName(event);
        refresh();
    };
    
    return (
        <RowForm onSubmit={handleSubmit}>
            <TextInput source='ordenVersion' label='Orden'/>
            <TextInput source="descripcion" fullWidth/>
            <ReferenceInput source="material" reference="materials" perPage={500} >
                <AutocompleteInput optionText='id' optionValue='id' size='small' label='Producto' filterToQuery={filter2query} onChange={handleMaterialChange} />
            </ReferenceInput>   
            {material&&(!material?.includes("ARTE") && !material?.includes("FLETES")) ?
                <ReferenceInput source="caja" reference="stocks"  filter= {{ and: { activa: { eq: true}, materialID: { eq: material}}}} 
                    sort={{ field: 'consecutivo', order:'ASC'}} queryOptions={{ meta:{ searchable: 'true'}}} perPage={500} >
                    <SelectInput optionText='consecutivo' optionValue='id' size='small' sx={{ width:'50px'}}/>
                </ReferenceInput>:null}
            {material&&(!material?.includes("ARTE") && !material?.includes("FLETES"))?<NumberInput source="ancho" sx={{ width: 100 }}/> : null}
            {material&&(!material?.includes("ARTE") && !material?.includes("FLETES"))?<NumberInput source="largo" sx={{ width: 100 }}/> : null}
            <NumberInput source="cantidad"/>
            <NumberInput source="valorUnitario" label='V.Unitario'/>
            <BooleanInput source="iva" label='IVA' refresh={materialName} defaultValue={true}/>
        </RowForm>
    );
};

const removeByAttr = (arr, attr, value) => {
    if (value === undefined) {
        throw new Error("Value must be provided");
    };

    let i = arr.length;

    while (i--) {
        if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
            arr.splice(i, 1);
        }
    };
    return arr;
};

const ActionsCED = () => {

    const refresh = useRefresh();
    const record = useRecordContext();
    const { getValues, setValue } = useFormContext();
    const data = getValues();

    const handleDelete = () => {
        const newItems = removeByAttr(data.itemsFacts, 'idItem', record.idItem);
        setValue('itemsFacts', newItems);
        refresh();
    };

    return (
        <>
            <EditRowButton />
            <DeleteRowItemButton deleteItem={handleDelete} record={record}/>
        </>
    );
};