import {ListGuesser, EditGuesser} from 'react-admin'
import ProductCreateDialog from './ProductCreateDialog'
import ProductList from './ProductList'

const ProductEdit = EditGuesser
export default {
    list: ProductList,
    edit: ProductEdit,
    create: ProductCreateDialog,
};


