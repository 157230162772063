import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, Chip, Box,
    TextField as TextFieldMUI, Paper, Grid, Divider, Table, TableHead, TableBody, TableCell, TableRow, Button, 
    Typography, Switch, FormControlLabel, Tooltip} from '@mui/material';
import { useDataProvider, useNotify, TopToolbar, useRefresh, useGetList } from 'react-admin';
import { formatCurrencyString, estadosDian, Sucursales } from '../../../utils';
import  LabeledCustomItem  from '../../../utils/LabeledCustomItem';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { options } from '@fullcalendar/core/preact.js';





const ListActions = () => {
    const dataProvider = useDataProvider();
    
    const refresh = useRefresh();
    const notify = useNotify();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedRemissions, setSelectedRemissions] = useState([]);
    const [invoiceOptions, setInvoiceOptions] = useState([]);
    const [loadingInvoices, setLoadingInvoices] = useState(false);
    
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerAlegraId, setCustomerAlegraId] = useState(null);
    const [query, setQuery] = useState('');
    const [queryInvoice, setQueryInvoice] = useState(null);
    const [queryRemission, setQueryRemission] = useState('');
    const [defaultRemissions, setDefaultRemissions] = useState(null);
    const [doImportItems, setDoImportItems] = useState(false);
    
    const { data: customers, isPending:loadingCustomers } = useGetList('clientes',
        {
            pagination: { page:1, perPage:20 },
            sort: { field: 'razonSocial', order: 'ASC' },
            filter:{razonSocial: {matchPhrasePrefix: query}},
            meta: {searchable: true}
        },
    );
    const { data: remissions, isPending:loadingRemissions } = useGetList('remissions',
        {
            pagination: { page:1, perPage:50 },
            sort: { field: 'alegraId', order: 'DESC' },
            filter:{and: {alegraNumeracion: {wildcard: `*${queryRemission}*`}, clienteId: {eq: selectedCustomer?.id }} },
            meta: {searchable: true}
        },
        {options: {enabled: selectedCustomer!==null && selectedInvoice!==null}}
    );
    const handleSearchInvoices = useCallback(async () => {
        setLoadingInvoices(true);
        try {
            let res;
            if (queryInvoice !== null) {
                res= await dataProvider.getList('alegraInvoices', {
                    filter: {
                        client_id: customerAlegraId, 
                        numberTemplate_fullNumber: queryInvoice
                    },
                    pagination: { page: 1, perPage: 10 },
                    sort: { field: 'date', order: 'DESC' },
                });
            }
            else{
                res = await dataProvider.getList('alegraInvoices', {
                    filter: {
                        client_id: customerAlegraId
                    },
                    pagination: { page: 1, perPage: 10 },
                    sort: { field: 'date', order: 'DESC' },
                });
                console.log('INVOICES', res.data);
            }

            setInvoiceOptions(res.data);

        } catch (error) {
            console.error('Error fetching invoices:', error);
            notify('Error buscando facturas', { type: 'error' });
        }
        setLoadingInvoices(false);
    },[selectedCustomer, queryInvoice, customerAlegraId, dataProvider, notify]);

    useEffect(() => {
        if (customers && selectedCustomer) {
            handleSearchInvoices();
        }
    }, [selectedCustomer, customers, handleSearchInvoices]);


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedCustomer(null);
        setCustomerAlegraId(null);
        setSelectedInvoice(null);
        setInvoiceOptions([]);
    };


    const handleSelectCustomer = (event, value) => {
        setCustomerAlegraId(value?.alegraId || null); 
        setSelectedCustomer(value??null);
    }
    
    
    const handleImport = async () => {
      
        const user = localStorage.getItem('user');
        const {invoice, itemFact, remissionsNotSelection} = await constructInvoiceData(selectedInvoice, selectedCustomer, selectedRemissions, user, remissions);
        if (remissionsNotSelection.length>0){
            console.log('No se seleccionaron todas las remisiones referenciadas en la factura', remissionsNotSelection);
            notify('No se seleccionaron todas las remisiones referenciadas en la factura', { type: 'error' });
            return;
        }
        // Create the invoice
        let fact;

        try {
            fact = await dataProvider.create('facturas', { data: invoice });
            console.log('Factura creada:', fact);
        } catch (error) {
            console.error('Error al crear la factura:', error);
            notify(`Error al crear la factura: ${error.message}`, { type: 'error' });
        }

        //Create the itemsFact in productFacts if they are not in remissions
        if (itemFact?.length > 0 && doImportItems) {
            const productFactsPromises = itemFact.map(item => {
                const newItem = {
                    ...item,
                    vendedorID: fact.data.vendedorID,
                    ciudad: fact.data.ciudadId,
                    facturaID: fact.data.id,
                    facturaNro: fact.data.alegraNumeracion,
                    clienteID: fact.data.clienteId,
                    clienteName: fact.data.clienteName,
                };
                return dataProvider.create('productFacts', { data: newItem });
            });
            const productFactsResults = await Promise.all(productFactsPromises);
            console.log('ItemsFact creados:', productFactsResults);
        }


        // Update existing productFact items associated with remissions
        for (const remision of selectedRemissions) {
            if (
                remision.productFact &&
                remision.productFact.items &&
                remision.productFact.items.length > 0
            ) {
                const productFactUpdatePromises = remision.productFact.items.map(item => {
                    const newItem = {
                        ...item,
                        ciudad: fact.data.ciudadId,
                        facturaID: fact.data.id,
                        facturaNro: fact.data.alegraNumeracion,
                        clienteID: fact.data.clienteId,
                        clienteName: fact.data.clienteName,
                    };
                    // Clean up unnecessary fields
                    delete newItem.remision;
                    delete newItem.createdAt;
                    delete newItem.updatedAt;
                    delete newItem.factura;
                    delete newItem.cotiza;
                    return dataProvider.update('productFacts', { id: item.id, data: newItem });
                });
                const productFactUpdateResults = await Promise.all(productFactUpdatePromises);
                console.log('ProductFacts actualizados:', productFactUpdateResults);
            }
        }
        // Update all associated remissions with the new facturaID
        const remisionUpdatePromises = selectedRemissions.map(remision => {
            const updatedRemision = {
                ...remision,
                facturaID: fact.data.id,
                estado: 'Facturada',
            };
            // Clean up unnecessary fields
            delete updatedRemision.createdAt;
            delete updatedRemision.updatedAt;
            delete updatedRemision.factura;
            delete updatedRemision.facturaNro;
            delete updatedRemision.productFact;
            return dataProvider.update('remissions', { id: remision.id, data: updatedRemision });
        });
        const remisionUpdateResults = await Promise.all(remisionUpdatePromises);
        console.log('Remisiones actualizadas:', remisionUpdateResults);
        handleCloseDialog();
        notify('Factura importada correctamente', { type: 'info' });
        refresh();
    }

    

    // const handleAppendRemisiones = async () => {
    //     if (!selectedInvoice) {
    //         notify('Por favor, seleccione una factura', { type: 'warning' });
    //         return;
    //     }

    //     try {
    //         // Update selected remisiones to associate them with the selected factura
    //         await Promise.all(
    //             selectedIds.map(async (remisionId) => {
    //                 const remision = data.find((r) => r.id === remisionId);
    //                 const { productFact, factura, ...updatedRemision } = remision;
    //                 await dataProvider.update('remissions', {
    //                     id: remisionId,
    //                     data: {
    //                         ...updatedRemision,
    //                         facturaID: selectedInvoice.id,
    //                         estado: 'Facturada',
    //                     },
    //                     previousData: remision,
    //                 });
    //             })
    //         );

    //         // Import invoice details similar to ImportInvoice.js
    //         await importInvoiceDetails(selectedInvoice.id);

    //         notify('Remisiones actualizadas correctamente', { type: 'info' });
    //         refresh();
    //     } catch (error) {
    //         console.error('Error actualizando remisiones:', error);
    //         notify('Error actualizando remisiones', { type: 'error' });
    //     }
    //     handleCloseDialog();
    // };

    // const importInvoiceDetails = async (invoiceId) => {
    //     try {
    //         const invoiceRes = await dataProvider.getOne('alegraInvoices', { id: invoiceId });
    //         const invoice = invoiceRes.data;

    //         const facturaData = {
    //             // ...building factura data from invoice...
    //             alegraId: invoice.id,
    //             alegraNumeracion: invoice.numberTemplate.fullNumber,
    //             clienteId: data.find(r => r.id === selectedIds[0]).clienteId,
    //             // ...other necessary fields...
    //         };

    //         // Create or update Factura in your system
    //         await dataProvider.create('facturas', { data: facturaData });

    //     } catch (error) {
    //         console.error('Error importing invoice details:', error);
    //         notify('Error importando detalles de la factura', { type: 'error' });
    //     }
    // };


    useEffect(() => {
        if (selectedInvoice && remissions && remissions.length > 0) {
        const itemsInvoiced = [...selectedInvoice.items];

        // Prepare itemsFact and remissionsNumber
        let itemsFact = [];
        const remissionsNumber = itemsInvoiced.reduce((acc, item) => {
            const remissionNumber = item.remissionNumber;
            if (!remissionNumber) {
                itemsFact.push(item);
                return acc;
            }
            if (!acc.includes(remissionNumber)) {
                acc.push(remissionNumber);
            }
            return acc;
        }, []);

        console.log('RemissionsNumber', remissionsNumber);
        //get all remissions that are in the invoice from allRemissions
        if (remissions){
            const invoiceRemissions = remissions.filter(rem => remissionsNumber.includes(rem.alegraNumeracion));
            console.log('Remissions in invoice', invoiceRemissions);
            if (invoiceRemissions?.length>0 ){
                setDefaultRemissions(invoiceRemissions);
                setSelectedRemissions(invoiceRemissions);
            }else{
                setDefaultRemissions([]);
            }
        }
        }
    },[selectedInvoice, remissions])

    return(
        <TopToolbar>
            <Button onClick={handleOpenDialog} startIcon={<CloudDownloadIcon />}  size='small'>
                Importar Factura
            </Button>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>Importar Factura Desde Alegra</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} direction={'row'} justifyContent={'center'} alignContent={'center'}>
                        <Grid item md={8} >
                            <Autocomplete //CUSTOMERS
                                freeSolo
                                sx={{paddingTop:'5px'}}
                                options={customers || []}
                                getOptionLabel={(option) => `${option.razonSocial}`}
                                onChange={handleSelectCustomer}
                                loading={loadingCustomers}
                                renderInput={(params) => (
                                    <TextFieldMUI
                                        {...params}
                                        label="Cliente"
                                        variant="outlined"
                                        onChange={(event) => {
                                            setCustomerAlegraId(null)
                                            setSelectedCustomer(null)
                                            setQuery(`${event.target.value}`);
                                            }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingCustomers ? <CircularProgress size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={4} >
                        {
                            selectedCustomer!==null ?
                            <Autocomplete //INVOICES
                                freeSolo
                                options={invoiceOptions || []}
                                sx={{paddingTop:'5px'}}
                                getOptionLabel={(option) => `${option?.numberTemplate.fullNumber}`}
                                loading={loadingInvoices}
                                onChange={(event, value) => setSelectedInvoice(value)}
                                renderInput={(params) => (
                                    <TextFieldMUI
                                        {...params}
                                        label="Buscar Factura"
                                        variant="outlined"
                                        onChange={(event) => {
                                            setSelectedInvoice(null)
                                            setQueryInvoice(`${event.target.value}`);
                                            }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingInvoices ? <CircularProgress size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        :
                        <></>}
                        </Grid>
                        <Grid item container md={12} spacing={3} justifyContent={'space-between'} >
                        {selectedInvoice!==null && defaultRemissions!== null ? 
                        <>
                        <Grid item md={9} >
                            <Typography variant='h6' color='textSecondary'>Asociar Remisiones </Typography>
                            <Autocomplete  
                                multiple
                                freeSolo
                                sx={{paddingTop:'5px'}}
                                options={remissions||[]}
                                getOptionLabel={(option) => `${option.alegraNumeracion}`}
                                onChange={(event, value) => setSelectedRemissions(value)}
                                defaultValue={defaultRemissions} 
                                loading={loadingRemissions}
                                isOptionEqualToValue={(option, newValue) => {
                                    return option.id === newValue.id;
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return <Chip variant={'outlined'} color={'primary'} size={"small"} key={key} label={option.alegraNumeracion} {...tagProps} />;
                                    })
                                }
                                renderInput={(params) => (
                                    <TextFieldMUI
                                        {...params}
                                        label="Buscar Remisiones"
                                        variant="outlined"
                                        onChange={(event) => {
                                            setQueryRemission(`${event.target.value}`);
                                            console.log('QUERY REMISSION', event.target.value);
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingRemissions ? <CircularProgress size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={3} alignContent={'center'} justifyItems={'right'} >
                        <Tooltip title="Importar Items no Remisionados: Esta opción permite importar items existentes en Alegra que no están asociados a ninguna remisión.">
                            <FormControlLabel
                                control={<Switch color="primary"  onChange={(event) => setDoImportItems(event.target.checked)}/>}
                                label="Importar Items"
                                labelPlacement="top"
                            />
                        </Tooltip>
                        </Grid>

                            <Divider direction='horizontal' sx={{margin:'15px'}}/>
                        <Grid item md={12} >
                            <InvoiceToImport invoice={selectedInvoice} />
                        </Grid>   
                            
                        </>
                        :<></>
                        }
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button 
                    onClick={handleImport} 
                    variant='contained'
                    disabled = {selectedInvoice===null }
                    color="primary" >
                        Importar
                    </Button>
                </DialogActions>
            </Dialog>
        </TopToolbar>
    )
}

const InvoiceToImport = ({ invoice }) => {
    console.log('INVOICE', invoice);

    const getRemissionNumbers = (items) => {
        const remissionNumbers = items.reduce((acc, item) => {
            const remissionNumber = item.remissionNumber;
            if (!remissionNumber) {
                return acc;
            }
            if (!acc.includes(remissionNumber)) {
                acc.push(remissionNumber);
            }
            return acc;
        }, []);
        return remissionNumbers.toString().replaceAll(',', ', ');
    }
    return (
    <Paper sx={{padding:'10px'}}>
            <Grid container spacing={2} direction={'row'} justifyContent={'center'} alignContent={'center'}>
                <Grid item md={2} >
                    <LabeledCustomItem value={invoice?.numberTemplate.fullNumber} label="Número" bold={true} />
                </Grid>
                <Grid item md={2} >
                    <LabeledCustomItem value={invoice?.date.replaceAll('-','/')} label="Fecha" />
                </Grid>
                <Grid item md={4} >
                    <LabeledCustomItem value={invoice?.client?.name} label="Cliente" />
                </Grid>
                <Grid item md={4} >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        mb: 1, // margin bottom 1 spacing unit
                    }}>
                            <Typography variant="body2" sx={{ 
                                fontSize: { xs: '0.595rem', sm: '0.595rem', md: '0.595rem', lg: '0.6rem', xl: '0.675rem' },
                                fontWeight: 'lighter',
                                mb: 0.3, // margin bottom 0.3 spacing units
                            }}>
                                Remisiones Asociadas:
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: { xs: '0.85rem', sm: '0.85rem', md: '0.85rem', lg: '0.9rem', xl: '1em'}}}>
                                {getRemissionNumbers(invoice.items)}
                            </Typography>
                    </Box>
                    {/* <LabeledCustomItem value={getRemissionNumbers(invoice.items) || ''} label="Remisiones" /> */}
                </Grid>
                <Grid item md={12} >
                    <Table sx={{padding:'20px'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Remision</TableCell>
                                <TableCell>Producto</TableCell>
                                <TableCell>Descripcion</TableCell>
                                <TableCell>Cantidad</TableCell>
                                <TableCell>Precio</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoice.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item?.remissionNumber || ''}</TableCell>
                                    <TableCell>{item?.name||''}</TableCell>
                                    <TableCell style={{ maxWidth: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{formatCurrencyString(item.price, 2)}</TableCell>
                                    <TableCell>{formatCurrencyString(item.total, 2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item md={1} >
                    {invoice.warehouse?.name && <>
                    <LabeledCustomItem value={invoice.warehouse?.name} label="Bodega:" />
                    <LabeledCustomItem value={invoice.seller?.name} label="Vendedor:" />
                    </>
                    }
                </Grid>
                <Grid item md={5} >
                    {invoice.anotation && <LabeledCustomItem value={invoice.anotation} label="Observaciones:" />}
                </Grid>
                <Grid item md={3} >
                    {invoice.warehouse.name && <LabeledCustomItem value={estadosDian.find(r=>r.id === invoice.stamp.legalStatus).name} label="Estado DIAN:" />}
                </Grid>
                <Divider />
                <Grid item md={3} >
                    <LabeledCustomItem value={formatCurrencyString(invoice.total, 2)} label="Total" bold={true}/>
                </Grid>    
            </Grid>
        </Paper>
    );
};

 const constructInvoiceData = async (invoice, clientData, remissions, user) => {
            console.log("INVOICE", invoice);
            console.log("CLIENT DATA", clientData);
            console.log("REMISSIONS", remissions);
            console.log("USER", user);
    
            const itemsInvoiced = [...invoice.items];

            // Prepare itemsFact and remissionsNumber
            let itemsFact = [];
            const remissionsNumber = itemsInvoiced.reduce((acc, item) => {
                const remissionNumber = item.remissionNumber;
                if (!remissionNumber) {
                    itemsFact.push(item);
                    return acc;
                }
                if (!acc.includes(remissionNumber)) {
                    acc.push(remissionNumber);
                }
                return acc;
            }, []);

            // Check if all remissions are in the remissions array
            const remissionsNumbers = remissions.map(rem => rem.alegraNumeracion);
            //filter remissions that are in the invoice
            const remissionsNotSelection = remissionsNumber.filter(rem => !remissionsNumbers.includes(rem));
            // Check if all remissions are in the remissions array
            if (remissionsNotSelection?.length > 0) {
                console.error('No se seleccionaron todas las remisiones referenciadas en la factura', remissionsNotSelection);
            }



            
            // Prepare retentions
            let retenciones = [];
            const retentionsArray = invoice.retentionsSuggested || invoice.retentions || [];
            for (const ret of retentionsArray) {
                const retencion = {
                    id: ret.id,
                    name: ret.name,
                    type: ret.referenceKey,
                    percentage: parseFloat(ret.percentage),
                    valor: parseFloat(ret.amount),
                    valorBase: ret.referenceKey === 'IVA' ? invoice.iva : invoice.subtotal,
                };
                retenciones.push(retencion);
            }

            // Prepare newItemsFact
            const newItemsFact = itemsFact.map(item => ({
                idItem: item.id,
                odc: item.odc || '',
                alegraItemId: item.id,
                orden: 0,
                iva: item.tax.length > 0,
                type: "",
                plateType: '',
                material: item.name,
                calibre: 0,
                ordenVersion: '',
                descripcion: item.description || '',
                valorUnitario: parseFloat(item.price),
                version: 0,
                ancho: 0,
                largo: 0,
                cantidad: item.quantity,
                cajaNro: 0,
                caja: item.name,
                area: 0,
                valorTotal: parseFloat(item.price) * item.quantity,
            }));

            // Calculate total area
            const totalArea = invoice.items.reduce(
                (acc, item) => item.unit === 'centimeterSquared' ? acc + item.quantity : acc,
                0
            );

            // if vendedor is defined in the invoice, get the id from the seller and consult remiss


            //invoice.stamp.date = new Date(invoice.stamp.date+"-05:00").toISOString();
            const newParams = {
                alegraClienteId: parseInt(invoice.client.id),
                alegraId: parseInt(invoice.id),
                alegraNumeracion: invoice.numberTemplate.fullNumber,
                observaciones: invoice.anotation,
                applyIva: invoice.tax > 0,
                remisionesIDs: remissions.map(rem => rem.id),
                itemsFact: newItemsFact,
                fechaCrea: new Date(invoice.datetime +"-05:00").toISOString(),
                fechaVence: new Date(invoice.dueDate).toISOString(),
                retenciones: retenciones,
                totalSinRetenciones: invoice.total - retenciones.reduce((acc, retencion) => acc + retencion.valor, 0),
                subtotal: invoice.subtotal,
                iva: invoice.tax,
                total: invoice.total,
                totalArea: totalArea,
                pdf: `https://app.alegra.com/invoice/print/id/${invoice.id}`,
                url: `https://app.alegra.com/invoice/view/id/${invoice.id}`,
                estado: invoice.status,
                valorPendiente: invoice.balance,
                stamp: {...invoice.stamp, date:new Date(invoice.stamp.date+"-05:00")},
                usuarioID: user,
                clienteId: clientData.id,
                vendedorID: remissions && remissions.length>0? remissions[0].vendedorID : null,
                ciudadId: Sucursales.find(sucursal => sucursal.alegraID.toString() === invoice.warehouse.id)?.id || null,// find(sucursal => sucursal.alegraID === invoice.warehouse.id) || null,
                clienteName: clientData?.razonSocial || null,
                formaPago: clientData.id ? clientData.datosFacturacion.formaPago : null,
                emails: clientData.id ? clientData.datosFacturacion.emailFE.replaceAll(' ', '').split(',') : [],
            };
            console.log('NEW PARAMS -> ConstructiInvoiceData', newParams);
            return {invoice: newParams, itemFact: newItemsFact, remissionsNotSelection };
            // Create the invoice
            // let fact;
            // try {
            //     fact = await dataProvider.create('facturas', { data: newParams });
            //     console.log('Factura creada:', fact);
            // } catch (error) {
            //     console.error('Error al crear la factura:', error);
            //     notify(`Error al crear la factura: ${error.message}`, { type: 'error' });
            // }

            // // Step 7: Process itemsFact and update productFacts
            //     // Create new productFacts for itemsFact
            //     if (newItemsFact.length > 0) {
            //         const productFactsPromises = newItemsFact.map(item => {
            //             const newItem = {
            //                 ...item,
            //                 vendedorID: fact.data.vendedorID,
            //                 ciudad: fact.data.ciudadId,
            //                 facturaID: fact.data.id,
            //                 facturaNro: fact.data.alegraNumeracion,
            //                 clienteID: fact.data.clienteId,
            //                 clienteName: fact.data.clienteName,
            //             };
            //             return dataProvider.create('productFacts', { data: newItem });
            //         });
            //         const productFactsResults = await Promise.all(productFactsPromises);
            //         console.log('ItemsFact creados:', productFactsResults);
            //     }

                // Update existing productFact items associated with remissions
                // for (const remision of remissions) {
                //     if (
                //         remision.productFact &&
                //         remision.productFact.items &&
                //         remision.productFact.items.length > 0
                //     ) {
                //         const productFactUpdatePromises = remision.productFact.items.map(item => {
                //             const newItem = {
                //                 ...item,
                //                 ciudad: fact.data.ciudadId,
                //                 facturaID: fact.data.id,
                //                 facturaNro: fact.data.alegraNumeracion,
                //                 clienteID: fact.data.clienteId,
                //                 clienteName: fact.data.clienteName,
                //             };
                //             // Clean up unnecessary fields
                //             delete newItem.remision;
                //             delete newItem.createdAt;
                //             delete newItem.updatedAt;
                //             delete newItem.factura;
                //             delete newItem.cotiza;
                //             return dataProvider.update('productFacts', { id: item.id, data: newItem });
                //         });
                //         const productFactUpdateResults = await Promise.all(productFactUpdatePromises);
                //         console.log('ProductFacts actualizados:', productFactUpdateResults);
                //     }
                // }

                // // Update all associated remissions with the new facturaID
                // const remisionUpdatePromises = remissions.map(remision => {
                //     const updatedRemision = {
                //         ...remision,
                //         facturaID: fact.data.id,
                //     };
                //     // Clean up unnecessary fields
                //     delete updatedRemision.createdAt;
                //     delete updatedRemision.updatedAt;
                //     delete updatedRemision.factura;
                //     delete updatedRemision.facturaNro;
                //     delete updatedRemision.productFact;
                //     return dataProvider.update('remissions', { id: remision.id, data: updatedRemision });
                // });
                //const remisionUpdateResults = await Promise.all(remisionUpdatePromises);
                //console.log('Remisiones actualizadas:', remisionUpdateResults);
           
    
    }



export { ListActions };