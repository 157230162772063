import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Typography, Grid } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import { Datagrid, ArrayField, NumberField, SimpleShowLayout, useRecordContext } from 'react-admin';

export const OrdenSalidaShow = () => {

    const record = useRecordContext();
    const version = record.version || '0'.toUpperCase();
    const opName = record.op || 'ORDEN DE SALIDA'.toUpperCase();
    const title = <Typography style={{textAlign:'center', fontWeight: 'bold', fontSize:'20px'}}>{`${opName}-${version}`}</Typography>
    
    return (
        <ShowInDialogButton title={title} maxWidth='xl' redirect="list">
            <SimpleShowLayout>
                <Grid item xs={6}>
                    <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
                        <Table sx={{ minWidth: 450 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Orden Material</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">
                                        <ArrayField source="ordenMaterial">
                                            <Datagrid bulkActionButtons={false}>
                                                <NumberField label='Ancho' source="opAncho"/>
                                                <NumberField label='Largo' source="opLargo"/>
                                                <NumberField options={{ maximumFractionDigits: 0 }} label='Área' source="area"/>
                                                <NumberField label='Cantidad' source="cantidad"/>
                                                <NumberField label='Caja' source="caja"/>
                                            </Datagrid>
                                        </ArrayField>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </SimpleShowLayout>
        </ShowInDialogButton>
    );
};