import { 
    Create,
    Labeled,
    Toolbar,
    useNotify,
    TextInput,
    TextField,
    SimpleForm,
    SaveButton,
    SelectInput,
    ReferenceField,
    useDataProvider,
    useRegisterMutationMiddleware } from 'react-admin';
import { Grid } from '@mui/material';
import { Sucursales } from '../../utils';
import React, { useState, useCallback } from 'react';
import { TotalesComponent } from './components/TotalesComponent';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { CustomEditableDatagrid } from './components/CustomEditableDatagrid';
import { OrdenesPendientesShow } from '../../facturacion/components/OrdenesPendientesShow';

const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        console.log('DATA INSIDE MIDDLEWARE', data);
        // if (data.ordenes && Array.isArray(data.ordenes)) {
        //     const ordenesWithoutItems = data.ordenes.map(orden => {
        //         const { itemsFact, ...restOfOrden } = orden; // Destructure to separate items from the rest
        //         return restOfOrden; // Return the modified orden without items
        //     });
        //     data = {
        //         ...data,
        //         ordenes: ordenesWithoutItems
        //     }
        // }
        /// FIXING PARAMS FOR D+SAVING AS BORRADOR newParams = { ...params, data:{...params.data, ordenes: } };
        /////////if save draft button was pressed, skip the middleware logic
        // if (!emitPressed) {
        //     return next(resource, newParams, options); 
        // }
        /////////
        let newParams = {};
        try {
            const response = await createAlegraRemision(data);
            console.log('RESPONSE inside middleware', response);
            if(response.data.id){
                notify('Remisión creada en Alegra', {type:'success'}, { smart_count: 1 });
            };
            newParams = { 
                ...params, 
                data: { 
                    ...data, 
                    alegraId: response.data.id,
                    alegraNumeracion: response.data.number,
                    pdf: `https://app.alegra.com/remission/print/id/${response.data.id}`,
                    url: `https://app.alegra.com/remission/view/id/${response.data.id}`,
                    estado: 'Emitida',
                    fechaCrea: new Date(),
                    fechaVence: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                }
            };
            console.log('NEW PARAMS', newParams);
        } catch (error) {
            if(error.message === 'El id del ítem es un campo obligatorio') {
                notify('Error: Caja No Seleccionada', {type:'error'});
            } else {
                notify(`Error: ${error.message}` , {type:'error'});
            };
        };

        try { 
            /////////// UNCOMMENT THIS WHEN PDFALT RESULTS IN THE SAME PDF AS ALEGRA////////

            //const resp2 = await dataProvider.getOne('alegraRemissions', {id: response.data.id});
            //const newParams2 = { ...newParams, data: {...newParams.data, pdfAlt: resp2.data.pdf} };
            //await next(resource, newParams2, options);
            
            
            await next(resource, newParams, options);

            const testing = false;  // true if u dont want to update the sqlOrdenOut table

            if(!testing){
                if (data?.ordenes) {
                    for (const orden of data.ordenes) {
                        await dataProvider.update('sqlOrdenOut', {filter: {numero: orden.numero, versionop: orden.version, remision: newParams.data.alegraNumeracion.match(/\d+/)[0]}});
                    }
                }
                discountFromStock(data.itemsFact);
            };
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 1 });
        }   
    }, []);

    const discountFromStock = async (items) => {
        const cajas = items.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            };
            return acc;
        }, []);
        for (const caja of cajas) {
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante - caja.area,
            }
            delete newStock.retales
            await dataProvider.update('stocks', { id: caja.caja, data: newStock });
        };
    };

    const createAlegraRemision = async (values) => {
        //const values = getValues(); 
        const alegraJson = {
            documentName: 'remission',
            date: new Date(),
            dueDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            anotation: values.observaciones,
            client: { id: values.alegraClienteId },
            seller: values.vendedorAlegraId ? { id: values.vendedorAlegraId } : undefined,
            items: values.itemsFact.map(item => {
                let descripcion = '';
                if(item.ancho > 0 && item.largo > 0 && item.area > 0){
                    descripcion = `${item.descripcion}\n${item.ancho} x ${item.largo} Cant: ${item.cantidad}`
                    if(item.odc && item.odc.trim().length > 0){
                        descripcion = `${descripcion} - ODC:${item.odc}`
                }} else {
                    descripcion = item.descripcion
                    if(item.odc){
                        descripcion = `${descripcion} - ODC:${item.odc}`
                    }
                };
                return(
                    {
                        id: item.alegraItemId,
                        reference: item.ordenVersion,
                        description: descripcion,
                        quantity: item.area > 0 ? item.area : item.cantidad,
                        price: item.valorUnitario,
                        tax: [{ id: item.iva ? 3 : 0 }],
                    }
                )
            }),
            costCenter: { id: Sucursales?.find(sucursal => sucursal?.id === values?.ciudadId)?.costCenterId },
            warehouse:  { id: Sucursales?.find(sucursal => sucursal?.id === values?.ciudadId)?.alegraID },
        };
        console.log('ALEGRA JSON', alegraJson);
        return  dataProvider.create('alegraRemissions', { data: alegraJson })
    };
        // Use the `useCreate` hook or directly call the `dataProvider` with the prepared JSON
    useRegisterMutationMiddleware(middleware);

    return (
        <Toolbar>
            <SaveButton
                key={'emitir'}
                label="Emitir Remisión"
                icon={<SendAndArchiveIcon />}
                alwaysEnable
            />
        </Toolbar>
    );
};

export const RemisionCreate = () => {

    const [ refreshDatagrid, setRefreshDatagrid ] = useState();

    const refetchData = (v) => {
        setRefreshDatagrid(v)
    };

    return (
        <Create redirect="list">
            <SimpleForm toolbar={<CustomToolbar/>}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Labeled>
                            <ReferenceField source = 'clienteId' reference='clientes' link={false}>
                                <TextField source="razonSocial" label="Cliente"sx={{padding:'10px', fontSize: '25px'}} />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectInput fullWidth source="ciudadId" label="Ciudad" choices={Sucursales} optionText='name'/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <CustomEditableDatagrid refreshDatagrid={refreshDatagrid}/>
                    </Grid>
                    <Grid item  xs={12} md={12} container direction={'row'} justifyContent={'flex-end'} >
                        <Grid item xs={12} md={7}>
                            <TextInput source="observaciones" fullWidth/>
                        </Grid>
                        <TotalesComponent changed={refetchData}/>
                    </Grid>
                </Grid>
            </SimpleForm>
            <OrdenesPendientesShow/>
        </Create>
    );
};

{/* <SaveButton key='borrador' label="Guardar Borrador" variant="outlined" alwaysEnable /> */}