



import React, { useState } from 'react';
import { ReferenceInput, SelectInput, TextInput, DateInput, AutocompleteInput, BooleanInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
const filterToQuery = searchText => ({ razonSocial:{wildcard:`*${searchText}*`} });

const typeChoicesWithReference=[{ id: 'VOID_ELECTRONIC_INVOICE', name: 'Anulación de factura electrónica' },
                    { id: 'PARTIALL_DEVOLUTION', name: 'Devolución de parte de los bienes y/o no aceptación de partes del servicio' },
                    { id: 'REDUCTION_DISCOUNT_PARTIAL_TOTAL', name: 'Rebaja o descuento parcial o total' },
                    { id: 'PRICE_ADJUSTMENT', name: 'Ajuste de precio' },
                    { id: 'OTHER', name: 'Otro' }]
const typeChoicesWithoutReference=[ { id: 'PARTIALL_DEVOLUTION', name: 'Devolución de parte de los bienes y/o no aceptación de partes del servicio' },
                                    { id: 'REDUCTION_DISCOUNT_PARTIAL_TOTAL', name: 'Rebaja o descuento parcial o total' },
                                    { id: 'PRICE_ADJUSTMENT', name: 'Ajuste de precio' },
                                    { id: 'OTHER', name: 'Otro' }]


export const AlegraCreditNotesCreateLayout = (props) => {
    const [client, setClient] = useState(null);
    const [hasReference2Invoice, setHasReference2Invoice] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const { getValues, setValues } = useFormContext();
    console.log('AlegraCreditNotesCreateLayout', getValues());
    const handleClientChange = (event, newValue) => {
        console.log('handleClientChange',event, newValue);
        setClient(newValue);
    }    
    const handleReference2Invoice = (event) => {
        console.log('handleReference2Invoice',event);
        if (event.target.value === "REFERENCE_TO_ELECTRONIC_INVOICE") {
            setHasReference2Invoice(true);
        }
        else{
            setHasReference2Invoice(false);
        }
    }
    const handleInvoiceAdd = (event, newValue) => {
        console.log('handleInvoiceAdd',event, newValue);
        setInvoice(newValue);
        //setValues({ ...getValues(), invoice: newValue });
    }
    
    return (
        <Grid container spacing={2} direction={'row'}>
            <Grid item md={4} >
            <ReferenceInput source="client.id" reference="clientes"     fullWidth    queryOptions={{ meta: { searchable: true } }}>
                <AutocompleteInput optionText={'razonSocial'} filterToQuery={filterToQuery} label='Cliente ' fullWidth onChange={handleClientChange}/>
            </ReferenceInput>
            </Grid>{
                client ?
                <>  
                    <Grid item md={4}>
                        <SelectInput choices={[{ id: 'REFERENCE_TO_ELECTRONIC_INVOICE', name: 'Referencia a Factura Electrónica' },
                                            { id: 'NO_REFERENCE_TO_ELECTRONIC_INVOICE', name: 'Sin referencia a factura Electrónica' },
                        ]} source="creditNoteOperationType" onChange={handleReference2Invoice}/>
                    </Grid>
                    {hasReference2Invoice ?
                    <Grid item md = {4}><SelectInput source='type' choices={typeChoicesWithReference} /></Grid>:
                    <Grid item md = {4}><SelectInput source='type' choices={typeChoicesWithoutReference} /></Grid>
                    }

                    <ReferenceInput source = 'invoice.id' reference = 'facturas' filter={{'clienteId':{eq:client.id}}} queryOptions={{ meta: { searchable: true }}}>
                        <AutocompleteInput optionText="alegraNumeracion" onChange={handleInvoiceAdd} label='Factura'/>
                    </ReferenceInput>

                    <TextInput source="subtotal" />
                    <TextInput source="discount" />
                    <TextInput source="tax" />
                    <TextInput source="total" />
                    <TextInput source="cause" label='Razón' multiline/>
                
                </>:
                <></>
            }     
            
        </Grid>
    )
}
    
