import { 
    Button,
    FilterList,
    useUnselectAll, 
    useListContext,
    FilterListItem,
} from 'react-admin';
import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import { FilterStyled } from './style/SalidaStyles';
import Groups2Icon from '@mui/icons-material/Groups2';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { removeDuplicates } from '../../../utils/utilsExposicion';
import { Card, CardContent, TextField as TextFieldStandalone, Typography } from '@mui/material';

export const SalidaFilters = () => {

    const { data, filterValues, setFilters } = useListContext();
    const unselectAll = useUnselectAll('salidaOrdens');
  
    const ciudadesMap ={
        "Mede": "ENVIGADO",
        "Barr": "BARRANQUILLA",
        "Cali": "CALI",
    }
    
    const ciudades = removeDuplicates(data, 'ciudadSalida');
    const clientes = removeDuplicates(data, 'clienteSalidaName'); 
    
    useEffect(() => {
        if (filterValues) {
            unselectAll();
        }
    }, [filterValues, data]);

    const [innerFilter, setInnerFilter] = React.useState('');
    const handleInnerFilter = (e) => {
        setInnerFilter(e.target.value);
    };

    if (!data && data?.length === 0) {
        return (
            <Card sx={{ order: -1, mr: 1, mt: 9, width: 170 }}>
                <CardContent>
                    <Button onClick={() => setFilters({})}>
                        {`Limpiar Filtros`}
                    </Button>
                </CardContent>
            </Card>
        );
    };
  
    return (
        data && data?.length > 0 && (
            <Card sx={FilterStyled?.card}>
                <CardContent>
                    <FilterList sx={FilterStyled?.filterList} label="Ciudades" icon={<TravelExploreIcon sx={FilterStyled?.iconStyled}/>}>
                        {ciudades?.length > 0 ? ciudades?.map((item) => (
                            <FilterListItem
                                sx={FilterStyled?.sx}
                                source='ciudadSalida.eq'
                                key={item?.ciudadSalida}
                                style={FilterStyled?.style}
                                value={{ ciudadSalida: { eq: item?.ciudadSalida }}}
                                onChange={(value) => console.log("value ciudadSalida", value)}
                                label={<Typography sx={FilterStyled?.label}><CircleIcon sx={FilterStyled?.iconLabel}/>{ciudadesMap[item?.ciudadSalida] || ''}</Typography>}
                            />
                        )) : <FilterListItem label="Sin Ciudades"/>}
                    </FilterList>
                    <Divider sx={{ mt: 2 }}/>
                    <FilterList sx={FilterStyled?.filterList} label="Clientes" icon={<Groups2Icon sx={FilterStyled?.iconStyled}/>}>
                        <TextFieldStandalone sx={FilterStyled?.searchButton} label={<Typography sx={{ 
                            fontSize: { lg: '13px', xl: '15px' }}}>{'Buscar'}</Typography>} onChange={handleInnerFilter} size='small'/>
                            {clientes ? clientes?.map((item) => {
                                if(item?.clienteSalidaName?.toLowerCase()?.includes(innerFilter?.toLowerCase())){
                                    return(
                                        <FilterListItem 
                                            sx={FilterStyled?.sx}
                                            style={FilterStyled?.style}
                                            source='clienteSalidaName.eq'
                                            key={item?.clienteSalidaName}
                                            value={{ clienteSalidaName: { eq: item?.clienteSalidaName }}}
                                            onChange={(value) => console.log("value clienteSalidaId", value)}
                                            label={<Typography sx={FilterStyled?.label}><CircleIcon sx={FilterStyled?.iconLabel}/>{item?.clienteSalidaName || ''}</Typography>}
                                        />
                                )
                            }}): <FilterListItem  label="Sin Clientes Pendientes"/>
                        }
                    </FilterList>
                </CardContent>
            </Card>
        )
    )
};