import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AmplifyImageField } from '../../../Amplify';
import { Grid, Modal, IconButton } from '@mui/material';
import { ImageExpoStyle } from '../style/ExposicionStyles';

export const ImageCDI = ({ source, label }) => {
    
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const gridStyle = {
    'img': {
      minWidth: '26.5%',
      minHeight: { xs: 460, sm: 460, md: 460, lg: 460, xl: 640 },
    },
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid sx={ImageExpoStyle?.sx} item xs={12} sm={12} md={12} lg={12}>
          <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
            <AmplifyImageField
              label={false}
              source={source}
              sx={ImageExpoStyle?.imageStyle}
            />
          </div>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Grid container justifyContent='center' alignItems="center" style={{ height: '100vh' }}>
          <Grid style={{ textAlign: 'center' }} sx={gridStyle} item xs={12} sm={12} md={12} lg={12} xl={12}>
            <IconButton style={ImageExpoStyle?.iconButtonStyle} onClick={handleClose}>
              <CloseIcon/>
            </IconButton>
            <AmplifyImageField source={source} label={label}/>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};