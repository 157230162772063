

import React, { useEffect } from "react";
import { useFormContext } from 'react-hook-form';
import { Typography, Grid } from '@mui/material';
import { formatCurrencyString } from "../../../utils";
import { useRefresh, BooleanInput, } from 'react-admin';

export const TotalesComponent = (props) => {
    const { getValues, setValue } = useFormContext();
    const refresh = useRefresh();
    const data = getValues();

    useEffect(() => {
        if(data && data?.itemsFacts && data.itemsFacts?.length > 0) {
            let totalArea = data.itemsFacts?.reduce((acc, item) => acc + item.area, 0);
            let subtotalIva = data.itemsFacts?.reduce((acc, item) => item.iva ? acc + item.valorTotal : acc, 0);
            let subtotalNoIva = data.itemsFacts?.reduce((acc, item) => !item.iva ? acc + item.valorTotal : acc, 0);
            let iva = subtotalIva * 0.19 ;
            let subtotal = subtotalIva + subtotalNoIva;
            let total = subtotal + iva;
            setValue('totalArea', totalArea);
            setValue('subtotal', subtotal);
            setValue('iva', iva);
            setValue('total', total);
        } else {
            setValue('totalArea', 0);
            setValue('subtotal', 0);
            setValue('iva', 0);
            setValue('total', 0);
        };
    }, [data]);
 
    const handleDisableIva = (e) => {
        console.log('CHECKBOX', e.target?.checked)
        for (let i = 0; i < data?.itemsFacts?.length; i++){
            if (data.itemsFacts[i].iva === e.target.checked) continue;
            if (data.itemsFacts[i].material?.includes("FLETES") && data?.itemsFacts[i].iva === false) continue;
            setValue(`itemsFacts.${i}.iva`, e.target.checked);
        };
        refresh();
        props.changed(e.target.checked);
    };

    return (
        <Grid item sm={12} md={5} container direction='row'>
            <Grid item md={6}>
                <Typography variant="h6" align="right">Total Area:</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    {data?.totalArea?.toLocaleString('es-CO') || 0}
                </Typography>   
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    <b>Subtotal:</b>
                </Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    {formatCurrencyString(data?.subtotal) || 0} 
                </Typography>
            </Grid>
            <Grid item container direction='row' justifyContent='flex-end' md={6}>
                <Grid item md={1.5}>    
                    <BooleanInput source="applyIva" label={false} onChange={handleDisableIva} helperText={false}/>
                </Grid>
                <Grid item md={2} alignContent='center'>
                    <Typography  variant="h6" align="right">
                        <b>IVA:</b>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    {formatCurrencyString(data?.iva) || 0} 
                </Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    <b>Total:</b>
                </Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    {formatCurrencyString(data?.total) || 0} 
                </Typography>
            </Grid>
        </Grid>
    );
};