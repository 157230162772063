import { Show, SimpleShowLayout} from 'react-admin';

export const RemisionShow = () => {

  return (
    <Show>
      <SimpleShowLayout>
      </SimpleShowLayout>
  </Show>
)};
